import { validateSearchParamsDate, validateSearchParamsEnum } from '@components/common';
import { callType, ChartType, periodShort, periodType } from '../Statistics.interfaces';

export const searchParamsValidators = {
  from: { validate: validateSearchParamsDate },
  to: { validate: validateSearchParamsDate },
  filterShort: { validate: validateSearchParamsEnum(periodShort) },
  chartType: { validate: validateSearchParamsEnum(ChartType) },
  callType: { validate: validateSearchParamsEnum(callType) },
  period: { validate: validateSearchParamsEnum(periodType) },
  hiddenId: null,
  numbersId: null,
};
