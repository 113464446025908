import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDepartmentsStyles } from '@/features/Departments/Departments.styles';
import Typography from '@shared/components/Typography';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { Employee } from '@/components/EmployeesSelection';
import { Department } from '../Departments';

export const DepartmentNameCell = ({ item }: ICellProps<Department>) => {
  const [translate] = useTranslation();
  const classes = useDepartmentsStyles();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  if (item) {
    const { name = '', activeEmployeesCount, employees } = item;

    let description = '';
    if (!employees || employees.length === 0) {
      description = translate('DEPT_NO_EMPLOYEES');
    } else if (!activeEmployeesCount) {
      description = translate('DEPT_NO_ACTIVE_EMPLOYEES');
    }

    const employeesFilteredByExt: Employee[] = [];
    const employeesFilteredByName: Employee[] = [];
    if (searchText) {
      employees?.forEach((employee) => {
        const { ext, user } = employee;
        if (ext?.includes(searchText)) {
          employeesFilteredByExt.push(employee);
        } else if (user.name?.toLowerCase().includes(searchText.toLowerCase())) {
          employeesFilteredByName.push(employee);
        }
      });
    }

    return (
      <div className={classes.cellContent}>
        <div>
          <div className={classes.departmentName}>
            <HighlightedText
              type={'text'}
              text={name || ''}
              query={searchText}
              isBold
              textOverflow
            />
          </div>
          {description && (
            <Typography type={'text3'} color={'danger600'}>
              {description}
            </Typography>
          )}
        </div>
        {employeesFilteredByExt.map((employee) => (
          <HighlightedText
            key={employee.id}
            type={'text'}
            text={employee.ext || ''}
            query={searchText}
            textOverflow
          />
        ))}
        {employeesFilteredByName.map((employee) => (
          <HighlightedText
            key={employee.id}
            type={'text'}
            text={employee.user.name || ''}
            query={searchText}
            textOverflow
          />
        ))}
      </div>
    );
  }

  return <div />;
};

export default DepartmentNameCell;
