import { CallDirection } from '@/client/generated/graphql';
import { PeriodValues } from '@/utils';
import {
  validateSearchParamsEnum,
  validateSearchParamsDate,
  validateSearchParamsTime,
} from '@components/common';

export const searchParamsValidators = {
  search: null,
  phone: null,
  employee: null,
  department: null,
  filterName: null,
  other: null,
  direction: { validate: validateSearchParamsEnum(CallDirection) },
  missed: { validate: (value: string) => value === 'true' || value === 'false' },
  from: { validate: validateSearchParamsDate },
  to: { validate: validateSearchParamsDate },
  fromTime: { validate: validateSearchParamsTime },
  toTime: { validate: validateSearchParamsTime },
  period: { validate: validateSearchParamsEnum(PeriodValues) },
  via: null,
};
