import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { CALLS_RESTRICTIONS_QUERY, COMMON_CALLS_RESTRICTION_QUERY } from '@/client/queries';
import Button from '@shared/components/Button';
import QuickSearch from '@components/QuickSearch';
import Typography from '@shared/components/Typography';
import Breadcrumbs from '@/components/Breadcrumbs';
import PagePreloader from '@components/PagePreloader';
import PageError from '@shared/components/PageError';
import LazyTable, { CellPreloader, ContentHint, IColumn } from '@components/LazyTable';
import BodyContainer from '@/layouts/BodyContainer';
import { PhoneIcon } from '@shared/assets/images/icons';
import { CallsRestrictionsQuery, CallRestrictionType } from '@/client/generated/graphql';
import { useSearchParamsValidator } from '@components/common';
import { useCallsRestrictionsStyles } from './CallsRestrictions.styles';
import CommonCallsRestrictions from '../CommonCallsRestrictions/CommonCallsRestrictions';
import CallRestrictions from '../CallRestrictions/CallRestrictions';
import {
  CallRestrictionsActionsCell,
  CallRestrictionsDirectionsCell,
  CallRestrictionsDirectionsField,
  CallRestrictionsNameCell,
  CallRestrictionsTypeCell,
  CallRestrictionsTypeHeaderCell,
} from './modules';

export const columns: IColumn<CallsRestrictionsQuery['getCallRestrictions'][number]>[] = [
  {
    id: 'type',
    label: 'TYPE',
    align: 'center',
    minWidth: 16,
    width: '3em',
    Renderer: CallRestrictionsTypeCell,
    HeaderRenderer: CallRestrictionsTypeHeaderCell,
  },
  {
    id: 'name',
    label: 'EMPLOYEES_AND_DEPARTMENTS',
    minWidth: 300,
    Renderer: CallRestrictionsNameCell,
  },
  {
    id: 'directions',
    label: 'CALLS_DIRECTIONS',
    minWidth: 200,
    width: '30%',
    Renderer: CallRestrictionsDirectionsCell,
  },
  {
    id: 'actions',
    label: '',
    minWidth: 170,
    Renderer: CallRestrictionsActionsCell,
    align: 'right',
  },
];

export const CallsRestrictions = () => {
  const classes = useCallsRestrictionsStyles();
  const [translate] = useTranslation();
  const {
    loading = false,
    error = false,
    data: restrictionData,
    called,
  } = useQuery(CALLS_RESTRICTIONS_QUERY);
  const allRestrictionsData = restrictionData?.getCallRestrictions;
  const { searchParams, setSearchParams } = useSearchParamsValidator({
    query: null,
    filter: null,
    id: null,
  });
  const sQuery = searchParams.get('query')?.toUpperCase();
  const filterQuery = searchParams.getAll('filter');
  const callRestrictionId = searchParams.get('id');

  const {
    data: commonCallRestrictionsData,
    loading: commonRestrictionsLoading,
    called: commonRestrictionsCalled,
  } = useQuery<{ getCommonCallRestriction: { restriction: CallRestrictionType[] } }>(
    COMMON_CALLS_RESTRICTION_QUERY
  );
  const restriction = commonCallRestrictionsData?.getCommonCallRestriction?.restriction;

  const formMethods = useForm({
    defaultValues: {
      local: true,
      mg: restriction?.includes(CallRestrictionType.Mg),
      mn: restriction?.includes(CallRestrictionType.Mn),
      query: sQuery,
    },
  });

  const { reset, getValues } = formMethods;

  useEffect(() => {
    if (!commonRestrictionsLoading && commonRestrictionsCalled) {
      reset({
        local: true,
        mg: restriction?.includes(CallRestrictionType.Mg),
        mn: restriction?.includes(CallRestrictionType.Mn),
      });
    }
  }, [reset, restriction, commonRestrictionsCalled, commonRestrictionsLoading]);

  const tableData = useMemo((): CallsRestrictionsQuery['getCallRestrictions'] => {
    if (allRestrictionsData) {
      const filteredData = sQuery
        ? allRestrictionsData.filter(
            ({ department, employee }) =>
              department?.name?.toUpperCase().includes(sQuery) ||
              employee?.user?.name?.toUpperCase().includes(sQuery)
          )
        : allRestrictionsData;
      if (!filterQuery.length || filterQuery[0] === 'all') return filteredData;

      return filterQuery.reduce(
        (r, el) =>
          r.concat(
            filteredData.filter(
              (config) =>
                !!config[el as keyof CallsRestrictionsQuery['getCallRestrictions'][number]]
            )
          ),
        [] as CallsRestrictionsQuery['getCallRestrictions']
      );
    }
    return [];
  }, [allRestrictionsData, filterQuery, sQuery]);

  function handleClickCommonControls() {
    searchParams.set('id', 'common');
    setSearchParams(searchParams);
  }

  const handleClickAddButton = () => {
    if (commonRestrictionsCalled) {
      if (restriction && restriction?.length >= 1) {
        searchParams.set('id', 'add');
        setSearchParams(searchParams);
      }
    }
  };

  const renderCommonControls = () => {
    if (commonRestrictionsLoading) {
      return <PagePreloader />;
    }

    return (
      <>
        <Typography
          color={'tertiary900'}
          type={'text2'}
          medium
          className={classes.tableHeaderTitle}
        >
          {translate('COMMON_RULES_FOR_EMPLOYEES_AND_DEPARTMENTS')}
        </Typography>
        <div className={classes.tableTitleControls}>
          <form id={'edit-company-rules'} action={''}>
            <CallRestrictionsDirectionsField showOnly disabled getValues={getValues()} />
          </form>
        </div>
        <Button
          title={translate('CONFIG')}
          variant={'secondary'}
          onClick={handleClickCommonControls}
        />
      </>
    );
  };

  const renderEmptyContent = () => {
    if (called && !loading) {
      if (!allRestrictionsData?.length) {
        return (
          <ContentHint isMiddleOfTable>
            <PhoneIcon className={classes.phoneNumberIcon} />
            <Typography color={'tertiary600'} type={'text3'}>
              {translate('ADD_PERSONAL_RULES')}
            </Typography>
          </ContentHint>
        );
      }
      if (!tableData.length) {
        return (
          <CellPreloader>
            <Typography color={'tertiary900'} type={'text3'}>
              {translate('NOTHING_FOUND')}
            </Typography>
          </CellPreloader>
        );
      }
    }
    return null;
  };

  const renderDrawerContent = () => {
    if (callRestrictionId) {
      switch (callRestrictionId) {
        case 'common':
          return <CommonCallsRestrictions restriction={restriction} />;
        case 'add':
          return <CallRestrictions />;
        default:
          return (
            <CallRestrictions
              item={tableData.find(
                ({ id: outgoingId }) => outgoingId === Number(callRestrictionId)
              )}
            />
          );
      }
    }
    return null;
  };

  const renderCallsRestrictionsContent = () => {
    if (loading) return <PagePreloader />;
    if (error) return <PageError />;

    return (
      <FormProvider {...formMethods}>
        <div className={classes.rootContainer}>
          <Breadcrumbs>
            <QuickSearch parameterName={'query'} />
            <Button
              variant={'primary'}
              title={translate('ADD_PERSONAL_RULE')}
              className={classes.actionButton}
              onClick={handleClickAddButton}
            />
          </Breadcrumbs>
          <div className={classes.content}>
            <div className={classes.tablePanel}>
              <div className={classes.tablePanelHeader} />
              <div className={classes.tablePanelContent}>{renderCommonControls()}</div>
            </div>
            <div className={classes.tableContainer}>
              <LazyTable<CallsRestrictionsQuery['getCallRestrictions'][number]>
                columns={columns}
                data={tableData}
                renderEmptyDataMessage={renderEmptyContent()}
              />
            </div>
            {renderDrawerContent()}
          </div>
        </div>
      </FormProvider>
    );
  };

  return <BodyContainer disableOverflow>{renderCallsRestrictionsContent()}</BodyContainer>;
};

export default CallsRestrictions;
