import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useDepartmentsStyles = makeStyles(
  ({ color: { tertiary, primary } }) => ({
    root: {
      height: '100%',
      width: '100%',
    },
    content: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      textOverflow: 'ellipsis',
    },
    tableRow: {
      borderBottom: `1px solid ${tertiary[100]}`,
      height: '3em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    action: {
      display: 'flex',
      height: '2em',
    },
    usersIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    cellContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5em',
      textOverflow: 'ellipsis',
      width: '100%',
      height: '100%',
    },
    addButton: {
      display: 'flex',
      margin: '0.5em',
      padding: '0 1.5em',
      marginLeft: '1em',
      height: '2em',
    },
    actionButton: {
      height: '2em',
      width: '2em',
      marginLeft: '1em',
      padding: 'initial',
    },
    possibleRemoveDepartmentDialog: {
      width: '21em',
    },
    removeDepartmentDialog: {
      width: '23em',
    },
    removeDepartmentCenterBlock: {
      paddingBottom: '1.5em',
      display: 'flex',
      flexDirection: 'column',
      lineHeight: '150%',
      margin: '0 auto',
    },
    removeDepartmentBtnBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    departmentName: {
      height: '1.5em',
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);
