import React, { useMemo, useState } from 'react';
import { Button } from '@shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '@shared/assets/images/icons';
import Typography from '@shared/components/Typography';
import { useMutation, useQuery } from '@apollo/client';
import {
  ABORT_REFUSE_NUMBER_MUTATION,
  BUY_NUMBER_MUTATION,
  REFUSE_NUMBER_MUTATION,
  REMOVE_RESERVED_NUMBER_MUTATION,
} from '@/client/mutations';
import { CAN_BOUGHT_NUMBER_QUERY, DOMAIN_TEL_NUMBERS_QUERY, USER_QUERY } from '@/client/queries';
import MessageDialog from '@shared/components/MessageDialog';
import ControlButtons from '@shared/components/ControlButtons';
import { ICellProps } from '@components/LazyTable';
import { getErrorMessageFromGraphqlError, useCatchAbleLazyQuery } from '@shared/utils/apollo';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import * as Sentry from '@sentry/react';
import { NumberStatus, NumberType } from '@/client/generated/graphql';
import { getCurrentClient } from '@/utils';
import DialogPayForNumber from '../DialogPayForNumber/DialogPayForNumber';
import { useNumbersStyle } from '../PhoneNumbers.styles';
import { DomainNumber, OpenModalType } from '../PhoneNumbers.interfaces';
import { phoneNumbersContext$ } from '../PhoneNumbers';

export const NumberActionsCell = ({ item }: ICellProps<DomainNumber>) => {
  const classes = useNumbersStyle();
  const [translate] = useTranslation();
  const [modal, setModal] = useState<OpenModalType>(OpenModalType.Off);
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { isModerated } = getCurrentClient(userData?.user) || {};

  const mutationOptions = useMemo(
    () => ({
      refetchQueries: [{ query: DOMAIN_TEL_NUMBERS_QUERY }],
    }),
    []
  );

  const [refuseBoughtNumber, { loading: loadingRefuseBoughtNumber }] = useMutation(
    REFUSE_NUMBER_MUTATION,
    mutationOptions
  );
  const [abortRefuseNumber, { loading: loadingAbortRefuseNumber }] = useMutation(
    ABORT_REFUSE_NUMBER_MUTATION,
    mutationOptions
  );
  const [removeReservedNumber, { loading: loadingRemoveReservedNumber }] = useMutation(
    REMOVE_RESERVED_NUMBER_MUTATION,
    mutationOptions
  );
  const [buyNumber, { loading: loadingBuyNumber }] = useMutation(
    BUY_NUMBER_MUTATION,
    mutationOptions
  );

  const [
    queryCanPayForNumber,
    { data: canPayForNumberQueryData, loading: loadingCanPayForNumberQueryData },
  ] = useCatchAbleLazyQuery(CAN_BOUGHT_NUMBER_QUERY, { fetchPolicy: 'network-only' });

  const canPayForNumberData = canPayForNumberQueryData?.canBoughtNumber;

  const commonMutationsVariables = {
    variables: {
      domainNumberId: item.id,
    },
  };

  const handlePayForNumberButtonClick = () => {
    const isRunexisNumberWithoutResponsible =
      item.type === NumberType.Runexis &&
      typeof item.code === 'number' &&
      item.code >= 900 &&
      !item.endUser;

    if (isRunexisNumberWithoutResponsible) {
      phoneNumbersContext$.domainNumberId.set(item.id);
      phoneNumbersContext$.phoneNumber.set(item.phone);
      phoneNumbersContext$.additionalNotification.set(
        translate('RUNEXIS_NUMBER_REGISTRATION_PERIOD_INFO')
      );
      phoneNumbersContext$.isDialogShouldSelectResponsibleOpen.set(true);
      return;
    }

    queryCanPayForNumber(commonMutationsVariables)
      .then(() => {
        setModal(OpenModalType.Bought);
      })
      .catch(() => {
        globalNotification$.show('danger', 'COULD_NOT_GET_DATA');
      });
  };

  const handleRefuseNumber = () => {
    switch (item.numberStatus) {
      case NumberStatus.WillBeDeleted:
      case NumberStatus.Bought:
        refuseBoughtNumber(commonMutationsVariables)
          .then(() => {
            globalNotification$.show('success', 'SUCCESS');
            setModal(OpenModalType.Off);
          })
          .catch(() => {
            globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
          });
        return;
      case NumberStatus.Reserved:
        removeReservedNumber(commonMutationsVariables)
          .then(() => {
            globalNotification$.show('success', 'SUCCESS');
            setModal(OpenModalType.Off);
          })
          .catch(() => {
            globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
          });
        return;
      case NumberStatus.Quarantine:
      case NumberStatus.Free:
        Sentry.captureException(
          Error(
            `Unexpected NumberStatus in handleRefuseNumber function. Received: ${item.numberStatus}.`
          )
        );
        return;
      default:
        item.numberStatus satisfies never;
    }
  };

  // оформление номера
  const handleConfirmBoughtNumber = () => {
    buyNumber({
      variables: {
        catalogNumberId: item.numbersCatalogId,
      },
    })
      .then(() => {
        globalNotification$.show('success', 'SUCCESS');
      })
      .catch((error) => {
        let errorMessage = 'SOMETHING_WENT_WRONG';

        const messageFromError = getErrorMessageFromGraphqlError(error);
        if (messageFromError === 'Balance too low.') {
          errorMessage = 'NUMBER_LOW_BALANCE_ERROR';
        }

        globalNotification$.show('danger', errorMessage);
      });
  };

  const handleAbortRefuseButtonClick = () => {
    abortRefuseNumber(commonMutationsVariables)
      .then(() => {
        globalNotification$.show('success', 'SUCCESS');
        setModal(OpenModalType.Off);
      })
      .catch(() => {
        globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      });
  };

  const handleOpenModal = (typeModal: OpenModalType) => () => {
    setModal(typeModal);
  };

  const { numberStatus } = item;

  const renderButtonsGroup = () => {
    const renderRemoveButton = () => {
      const getModalTexts = () => {
        switch (numberStatus) {
          case NumberStatus.Reserved:
            return {
              body: translate('NUMBER_REMOVE_RESERVED'),
            };
          case NumberStatus.Bought:
            return {
              body: translate('NUMBER_OFF_CHANGE'),
            };
          default:
            return {
              body: '',
            };
        }
      };

      const texts = getModalTexts();

      const renderDialogContent = () => (
        <>
          <div className={classes.numberActionsDialogContent}>
            <Typography type={'text3'} color={'tertiary900'}>{`${texts.body}`}</Typography>
          </div>
          <div className={classes.numberActionsDialogActions}>
            <ControlButtons
              confirmColor={'error'}
              confirmTitle={'CONFIRM'}
              cancelTitle={'CANCEL'}
              cancelVariant="secondary"
              flexDirection={'row-reverse'}
              justifyContent={'start'}
              onConfirmClick={handleRefuseNumber}
              onCancelClick={() => {
                setModal(OpenModalType.Off);
              }}
              loading={loadingRefuseBoughtNumber || loadingRemoveReservedNumber}
              small
            />
          </div>
        </>
      );

      return (
        <>
          <Button
            className={classes.numberActionsActionButton}
            variant={'secondary'}
            color={'error'}
            onClick={handleOpenModal(OpenModalType.RefuseBought)}
            disabled={loadingCanPayForNumberQueryData}
          >
            <TrashIcon />
          </Button>
          <MessageDialog
            isOpen={modal === OpenModalType.RefuseBought}
            title={translate('ARE_YOU_SURE')}
            contentClass={classes.numberActionsDialogPaper}
            disableClose={loadingRefuseBoughtNumber || loadingRemoveReservedNumber}
            onCancel={() => {
              setModal(OpenModalType.Off);
            }}
            renderContent={renderDialogContent()}
          />
        </>
      );
    };

    const renderBoughtButton = () => (
      <>
        <Button
          title={translate('PAY')}
          className={classes.numberActionsActionButtonText}
          variant={'secondary'}
          onClick={handlePayForNumberButtonClick}
          disabled={isModerated === false}
          loading={loadingCanPayForNumberQueryData}
        />
        <DialogPayForNumber
          balance={canPayForNumberData?.balance || 0}
          totalNumberCost={canPayForNumberData?.totalNumberCost || 0}
          onConfirm={handleConfirmBoughtNumber}
          onCancel={() => {
            setModal(OpenModalType.Off);
          }}
          isOpen={modal === OpenModalType.Bought && !loadingCanPayForNumberQueryData}
          loading={loadingBuyNumber}
        />
      </>
    );

    const renderContinueButton = () => {
      const renderDialogContent = () => (
        <>
          <div className={classes.numberActionsDialogContent}>
            <Typography type={'text3'} color={'tertiary900'}>
              {translate('DELETED_NUMBER')}
            </Typography>
          </div>
          <div className={classes.numberActionsDialogActions}>
            <ControlButtons
              confirmColor={'error'}
              confirmTitle={'CONFIRM'}
              cancelTitle={'CANCEL'}
              cancelVariant="secondary"
              flexDirection={'row-reverse'}
              justifyContent={'start'}
              onConfirmClick={handleAbortRefuseButtonClick}
              onCancelClick={() => {
                setModal(OpenModalType.Off);
              }}
              loading={loadingAbortRefuseNumber}
              small
            />
          </div>
        </>
      );

      return (
        <>
          <Button
            title={translate('NUMBER_UNRESERVED_BUTTON')}
            className={classes.numberActionsActionButtonText}
            variant={'secondary'}
            onClick={handleOpenModal(OpenModalType.AbortRefuse)}
            disabled={loadingCanPayForNumberQueryData}
          />
          <MessageDialog
            isOpen={modal === OpenModalType.AbortRefuse}
            title={translate('ARE_YOU_SURE')}
            contentClass={classes.numberActionsDialogPaper}
            disableClose={loadingAbortRefuseNumber}
            onCancel={() => {
              setModal(OpenModalType.Off);
            }}
            renderContent={renderDialogContent()}
          />
        </>
      );
    };

    switch (numberStatus) {
      case NumberStatus.Reserved:
        return (
          <>
            {renderRemoveButton()}
            {renderBoughtButton()}
          </>
        );
      case NumberStatus.WillBeDeleted:
        return renderContinueButton();
      case NumberStatus.Bought:
        return renderRemoveButton();
      default:
        return null;
    }
  };

  return <div className={classes.numberActionsContent}>{renderButtonsGroup()}</div>;
};
