import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { useTranslation } from 'react-i18next';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import { useHistoryStyle } from '../HistoryPage.styles';
import { IHistoryList } from '../HistoryPage.interfaces';

export const EmployeeCell: FunctionComponent<ICellProps<IHistoryList>> = ({ item }) => {
  const classes = useHistoryStyle();
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { employee, employeeId, employeeExt, departmentId, other } = item || {};
  const sFilterName = searchParams.get('filterName');
  const sExt = searchParams.get('ext');
  const sSearch = searchParams.get('search');

  function getEmployee(): string {
    if (employee || employeeId || departmentId) {
      if (employee) {
        return employee.match(/^([0-9]{6,})$/) ? formatPhone(employee) : employee;
      }
    }
    if (other?.ivr) {
      return translate('HISTORY_IVR');
    }
    if (other?.voicemail) {
      return translate('HISTORY_VOICEMAIL');
    }
    if (other?.fax) {
      return translate('HISTORY_FAX');
    }
    return translate('HISTORY_GREETING');
  }

  function handleEmployeeClick() {
    searchParams.set('search', '');
    searchParams.set('employee', '');
    searchParams.set('department', '');
    searchParams.set('other', '');
    searchParams.set('phone', '');
    searchParams.set('ext', '');
    searchParams.set('via', '');
    if (employeeId) {
      searchParams.set('employee', String(employeeId));
    } else if (departmentId) {
      searchParams.set('department', String(departmentId));
    } else if (other?.ivr || other?.voicemail || other?.greeting || other?.fax) {
      searchParams.set('other', JSON.stringify(other));
    } else if (employeeExt) {
      searchParams.set('ext', employeeExt);
    } else {
      searchParams.set('phone', employee || '');
    }
    searchParams.set('filterName', getEmployee());
    setSearchParams(searchParams);
  }

  const handleEmployeeExtClick = () => {
    searchParams.set('search', '');
    searchParams.set('employee', '');
    searchParams.set('department', '');
    searchParams.set('other', '');
    searchParams.set('phone', '');
    searchParams.set('ext', '');
    searchParams.set('via', '');
    if (employeeExt) {
      searchParams.set('ext', employeeExt);
      searchParams.set('filterName', employeeExt);
      setSearchParams(searchParams);
    }
  };

  if (item) {
    return (
      <Flex direction="column" alignItems="flexStart">
        <Button variant="text" className={classes.textHover} onClick={handleEmployeeClick}>
          <HighlightedText type={'text'} text={getEmployee()} query={sFilterName || sSearch} />
        </Button>
        {employeeExt && (
          <Button variant="text" className={classes.textHover} onClick={handleEmployeeExtClick}>
            <HighlightedText
              type="text"
              text={employeeExt}
              query={sFilterName || sSearch || sExt}
              typographyType="text4"
              typographyColor="tertiary500"
            />
          </Button>
        )}
      </Flex>
    );
  }

  return <div />;
};
