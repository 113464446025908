import { CSSProperties } from 'react';
import { AssistantType, scenarioTypes } from '@components/typings/interfaces';
import { TariffModel } from '@/client/generated/graphql';
import { scenarioInterface, scenarioListType } from './Contract.interfaces';

export const stepsNamesList = [
  { title: 'ENTER_DATA', key: 'passport', path: '/register/personal-data', step: 1 },
  { title: 'CHOOSE_TARIFF', key: 'tariff', path: '/register/select-tariff', step: 2 },
  { title: 'CHOOSE_NUMBER', key: 'numbers', path: '/register/phone-number', step: 3 },
  { title: 'CHOOSE_SCENARIO_TITLE', key: 'assistants', path: '/register/scenario', step: 4 },
  { title: 'CHECK', key: 'check', path: '/register/confirm', step: 5 },
];

export const MAX_ACCOUNT_AMOUNT = 999;

export const FlFieldsList = [
  {
    labelName: 'PAYER',
    fieldName: '',
    fields: [
      {
        labelName: 'LEGAL_STATUS',
        format: 'raw-legal-status',
        fieldName: '',
      },
      {
        labelName: 'FULL_NAME',
        fieldName: 'person',
      },
    ],
  },
];

export const IpFieldsList = [
  {
    labelName: 'PAYER',
    fieldName: '',
    fields: [
      {
        labelName: 'LEGAL_STATUS',
        format: 'raw-legal-status',
        fieldName: '',
      },
      {
        labelName: 'COMPANY_NAME',
        fieldName: 'companyName',
      },
    ],
  },
];

export const UlFieldsList = [
  {
    labelName: 'PAYER',
    fieldName: '',
    fields: [
      {
        labelName: 'LEGAL_STATUS',
        format: 'raw-legal-status',
        fieldName: '',
      },
      {
        labelName: 'COMPANY_NAME',
        fieldName: 'companyName',
      },
    ],
  },
];

export enum formType {
  PersonalData = 'personalData',
  Tariff = 'tariff',
  PhoneNumber = 'phoneNumber',
  Scenario = 'scenario',
}

export const scenarioList: scenarioListType[] = [
  {
    title: 'SCENARIOS_BASE_TITLE',
    description: 'SCENARIO_BASE_DESCRIPTION',
    monthPrice: 5000,
    installationPrice: 5250,
    type: scenarioTypes.Market,
    assistantType: AssistantType.Market,
  },
  {
    title: 'SCENARIOS_INDIVIDUAL_TITLE',
    description: 'SCENARIO_INDIVIDUAL_DESCRIPTION',
    monthPrice: 10000,
    type: scenarioTypes.Individual,
    assistantType: AssistantType.Individual,
    flexiblePrice: true,
  },
];

export const schemaType: scenarioInterface = {
  [scenarioTypes.Market]: { title: 'EXAMPLE_SCENARIOS' },
  [scenarioTypes.Individual]: { title: 'EXAMPLE_SCENARIOS' },
};

export const individualScenarios = {
  audio: '/audio/individual.mp3',
};

export const setTopStyle = (style: CSSProperties, padding: number): string | number => {
  if (typeof style.top === 'string') {
    return `${parseFloat(style.top) + padding}px`;
  }
  if (typeof style.top === 'number') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return `${style.top + padding}px`;
  }
  return 0;
};

export const getAccountsInformation = (tariffModel: TariffModel, accountType: string) =>
  tariffModel.options.filter((i: { type: string }) => i.type === accountType)[0];
