import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';
import { pxToEm } from '@shared/utils/styles';

export const useContractStyle = makeStyles(
  ({
    color: { base, tertiary, primary, secondary, links },
    spacing,
    breakpoints: { down },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    contractRoot: {
      position: 'relative',
    },
    contractRootFullHeight: {
      position: 'relative',
      height: '100%',
    },
    root: {},
    wideSidebarMargin: {
      marginLeft: '9em',
    },
    documentSteps: {
      height: '5em',
      position: 'fixed',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTop: `solid 1px ${tertiary['100']}`,
      display: 'flex',
      padding: '0 2em',
      bottom: 0,
      right: 0,
      left: spacing(10),
      backgroundColor: base,
      [down('md')]: {
        right: 'auto',
        width: '100%',
        boxSizing: 'border-box',
        bottom: 0,
        left: 0,
        position: 'absolute',
      },
    },
    documentStepsLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    selectChevron: {
      visibility: 'hidden',
    },
    selectDisabledWrap: {
      width: '100%',
      '& fieldset': {
        visibility: 'hidden',
      },
      '& .MuiFormLabel-root.Mui-disabled': {
        color: tertiary[700],
      },
    },
    selectDisabled: {
      backgroundColor: tertiary[100],
      color: tertiary[900],
    },
    stepItem: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '1em',
      '&:first-child': {
        marginLeft: 0,
      },
      [down('md')]: {
        '&:first-child': {
          marginLeft: '3em',
        },
      },
    },
    stepIcon: {
      width: '2em',
      minWidth: '2em',
      height: '2em',
      borderRadius: '50%',
      marginRight: '1em',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: tertiary[50],
      border: `1px solid ${tertiary[300]}`,
      '& > *': {
        lineHeight: '1em',
      },
    },
    stepIconCurrentBg: {
      backgroundColor: primary[50],
      border: `1px solid ${primary[700]}`,
    },
    stepIconLastBg: {
      backgroundColor: tertiary[100],
      border: 'none',
    },
    stepIconSuccessBg: {
      backgroundColor: primary[700],
    },
    checkIcon: {
      color: base,
    },
    checkIconBlack: {
      color: 'black',
    },
    stepTextPoint: {
      cursor: 'pointer',
    },
    stepText: {
      cursor: 'default',
    },
    documentStepsRight: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginLeft: '1em',
        width: '8em',
        '&:first-child': {
          marginLeft: '1em',
        },
      },
    },
    personalDataRoot: {
      padding: '0 2em 0',
      [down('md')]: {
        paddingBottom: '5em',
      },
    },
    personalDataContent: {
      paddingTop: '1.25em',
    },
    contractConfirmContent: {
      maxWidth: '31em',
      minWidth: '29em',
      paddingBottom: '2em',
    },
    personalDataFileItem: {
      width: '20.5em',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    personalDataFormItem: {
      marginBottom: '2em',
      width: '23em',
    },
    personalDataRadioContainer: {
      width: '13.75em',
      display: 'flex',
      justifyContent: 'space-between',
    },
    personalDataGenderContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    personalDataGenderControls: {
      display: 'block',
    },
    personalDataFormItems: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '2em',
      width: '23em',
    },
    personalDataFormCheckbox: {
      marginBottom: '2em',
      position: 'relative',
      width: 'auto',
      display: 'inline-block',
    },
    passportNumber: {
      width: '15.5em',
    },
    uploadButton: {
      width: '23em',
      marginRight: '1em',
    },
    personalDataErrorDialog: {
      width: '20em',
    },
    personalDataErrorCenterBlock: {
      paddingBottom: '1.5em',
    },
    fileUploadLabel: {
      paddingBottom: '0.75em',
      display: 'block',
    },
    tariffOptionsList: {
      display: 'flex',
      paddingTop: '2em',
    },
    tariffOption: {
      transition: createTransition(['background-color'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      cursor: 'pointer',
      backgroundColor: tertiary[50],
      width: '23em',
      padding: '1.5em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      border: `solid 1px ${tertiary[200]}`,
      borderRadius: '5px',
      marginRight: '1em',
      marginBottom: '2em',
      '&:hover': {
        backgroundColor: primary[50],
      },
    },
    tariffOptionSelected: {
      backgroundColor: primary[50],
      border: `1px solid ${primary[700]}`,
    },
    tariffOptionList: {
      paddingTop: '2em',
      paddingBottom: '0.5em',
    },
    tariffUl: {
      paddingLeft: 0,
      margin: 0,
    },
    tariffLI: {
      paddingTop: '0.25em',
      paddingBottom: '0.25em',
      display: 'flex',
      alignItems: 'center',
      listStyleType: 'none',
      '&:first-child': {
        paddingTop: 0,
      },
    },
    tariffButtons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    tariffPriceInfo: {
      display: 'flex',
    },
    tariffPriceNumber: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '0.25em',
      paddingLeft: '1em',
      '&:first-child': {
        paddingLeft: 0,
      },
    },
    tariffAccountSwitcher: {
      paddingTop: '0.5em',
      display: 'flex',
      alignItems: 'center',
    },
    tariffAdditionalAccounts: {
      marginRight: '0.5em',
      whiteSpace: 'nowrap',
      '&:last-child': {
        marginRight: '0',
        marginLeft: '0.5em',
      },
    },
    tariffAdditionalAccountsPrice: {
      marginLeft: '0.5em',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    amountTextStyle: {
      whiteSpace: 'nowrap',
    },
    summaryTextStyle: {
      marginRight: '1.5em',
      whiteSpace: 'nowrap',
    },
    oldPriceTextStyle: {
      whiteSpace: 'nowrap',
      textDecoration: 'line-through',
    },
    tariffAdditionalTextPadding: {
      paddingRight: '1.5em',
    },
    tariffDiscountTextPadding: {
      paddingLeft: '0.5em',
    },
    tariffAccountPrice: {
      borderTop: `solid 1px ${tertiary[200]}`,
      padding: '1em 0 1.5em',
    },
    tariffRadio: {
      backgroundColor: base,
      borderRadius: '3px',
      width: '8em',
      border: `solid 1px ${tertiary[200]}`,
    },
    tariffRadioSelected: {
      backgroundColor: primary[200],
    },
    tariffBottoms: {
      display: 'flex',
      flexDirection: 'column',
    },
    tariffAccounts: {
      backgroundColor: tertiary[100],
      padding: '0.5em',
      marginBottom: '2em',
      borderRadius: '3px',
      display: 'flex',
      flexDirection: 'column',
    },
    tariffAccountsSelected: {
      backgroundColor: primary[200],
    },
    tariffTitle: {
      paddingBottom: '1em',
    },
    tariffAdditionalFunctionalWrapper: {
      backgroundColor: tertiary[50],
      borderRadius: '0.75em',
      width: '71em',
    },
    tariffAdditionalOption: {
      padding: '1em 1.5em',
      borderTop: `solid 1px ${tertiary[300]}`,
      '&:first-child': {
        borderTop: 'none',
      },
    },
    tariffOptionSwitchCell: {
      minWidth: '3em',
      paddingTop: '0.125em',
      alignSelf: 'flex-start',
    },
    tariffOptionInfoCell: {
      width: '100%',
    },
    tariffOptionActionCell: {
      minWidth: '20em',
    },
    tariffOptionActionCellWrapper: {
      width: '17.5em',
    },
    tariffOptionPriceCell: {
      minWidth: '11em',
    },
    tariffAdditionalOptionDescription: {
      paddingTop: '0.125em',
    },
    tariffMultipleOption: {
      paddingRight: '1em',
    },
    tariffHiddenOption: {
      display: 'none',
    },

    scenarioHeadContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '1.5em',
    },
    scenarioTotals: {
      padding: '1em 2em',
      marginTop: '1.5em',
      borderRadius: '12px',
      backgroundColor: links[50],
      height: pxToEm(80),
    },

    tariffPlanContainer: {
      width: '100%',
      height: '3.25em',
      backgroundColor: tertiary[900],
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '1.5em',
      borderRadius: '0.75em 0.75em 0 0',
    },
    scenarioTotalsWarning: {
      display: 'flex',
      alignItems: 'flex-end',
      marginTop: pxToEm(4),
    },
    scenarioTotalsWarningIcon: {
      marginRight: '.5em',
      color: tertiary[600],
    },
    scenarioTotalsLine: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    scenarioTotalsLineColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    scenarioTotalsItem: {
      paddingLeft: '2em',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
    },
    scenarioTotalsAmount: {
      display: 'flex',
    },
    lineHeight1: {
      lineHeight: 1,
    },
    scenarioCardDescriptionText: {
      marginTop: '1em',
      width: '35em',
    },
    phoneNumberGrid: {
      display: 'flex',
      width: '52em',
      flexDirection: 'column',
    },
    phoneNumberRow: {
      display: 'flex',
      alignItems: 'center',
      padding: '1em 0',
    },
    confirmHeadingRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.5em 1em 0.5em 1.5em',
      backgroundColor: tertiary[100],
    },
    confirmHeadingRowTotal: {
      backgroundColor: primary[50],
      borderRadius: '0.75em',
      padding: '1.5em 1em 1.5em 1.5em',
    },
    flexBlock: {
      display: 'flex',
      justifyContent: 'end',
      paddingRight: '0.75em',
    },
    confirmGridColoredRow: {
      backgroundColor: base,
      padding: '1.5em 1em 1.5em 1.5em',
      borderBottom: `solid 1px ${tertiary[300]}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      '&:last-child': {
        border: 'none',
      },
    },
    phoneNumberTotalRow: {
      display: 'flex',
      alignItems: 'center',
      borderTop: `solid 1px ${tertiary[100]}`,
      position: 'relative',
    },
    phoneNumberAddBlock: {},
    phoneNumberPaddingCell: {
      padding: '0 1em',
      boxSizing: 'content-box',
    },
    phoneNumberWelcomeScreen: {
      width: '37em',
      paddingTop: '1em',
    },
    phoneNumberTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0.75em 0 0',
    },
    phoneNumberCancelButton: {
      padding: '0.65em',
    },
    btnWidth: {
      width: '17em',
      marginTop: '2em',
    },
    btnScenarioExampleWidth: {
      width: '7.5em',
      height: '2em',
    },
    list: {
      willChange: 'opacity !important',
    },
    phoneNumberRightCell: {
      padding: '1em 0 0 1em',
      boxSizing: 'border-box',
    },
    phoneNumberLeftCell: {
      padding: '1em 0 0 2em',
      boxSizing: 'border-box',
    },
    phoneNumberPreloader: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    phoneNumberCell: {
      border: `solid 1px ${tertiary[300]}`,
      borderRadius: '6px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      transition: createTransition(['background-color'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      backgroundColor: base,
      '&:hover': {
        backgroundColor: primary[100],
        border: `solid 1px ${primary[600]}`,
      },
    },
    phoneNumberCellDisabled: {
      backgroundColor: tertiary[50],
      cursor: 'default',
      '&:hover': {
        backgroundColor: tertiary[50],
      },
    },
    phoneNumberCellFocused: {
      backgroundColor: primary[50],
      border: `solid 1px ${primary[700]}`,
    },
    contractConfirmIndividualPrompt: {
      position: 'absolute',
      display: 'flex',
      backgroundColor: 'white',
      marginTop: '2em',
      left: '16em',
      padding: '1em',
      textAlign: 'left',
      alignItems: 'center',
      width: '20em',
      height: '5em',
      margin: 'auto',
      boxShadow: '0px 0.5em 1.25em rgba(0, 0, 0, 0.1)',
      borderRadius: '3px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    fieldTitle: {
      paddingBottom: '1em',
      paddingTop: '2em',
    },
    confirmTextBlock: {
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '5px',
    },
    confirmServiceGrid: {
      display: 'flex',
      width: '53em',
      flexDirection: 'column',
    },
    confirmServiceTotalRow: {
      backgroundColor: primary[50],
      borderTop: `1px solid ${tertiary[300]}`,
      borderRadius: '0 0 0.75em 0.75em',
    },
    confirmTariffRow: {
      display: 'flex',
      width: '53em',
      flexDirection: 'column',
      padding: '0.5em 0',
      backgroundColor: tertiary[100],
      '& > span': {
        paddingLeft: '1.75em',
      },
    },
    confirmPaddingBlock: {
      padding: '1em 0 2em',
    },
    confirmDataBlock: {
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '0.75em',
      maxWidth: '53.1em',
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: '2em',
    },
    confirmBottomContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      maxWidth: '53em',
      paddingTop: '1em',
      paddingBottom: '2em',
      alignItems: 'center',
    },
    confirmBtn: {
      width: '19em',
      marginLeft: '2em',
      height: '3em',
    },
    confirmPopupDialog: {
      width: '25em',
    },
    confirmPopupText: {
      paddingBottom: '1.5em',
      textAlign: 'left',
    },
    customOptionRender: {
      padding: '0.25em 0.75em',
      display: 'flex',
      flexDirection: 'column',
    },
    exampleScenarioDialogBasis: {
      width: '36.5em',
      height: '32.5em',
    },
    exampleScenarioDialogIndividual: {
      width: '36.5em',
      height: '21em',
    },
    messageDialogCellBlock: {
      width: '32,5em',
      height: '14em',
      backgroundColor: tertiary[50],
      padding: '1em 2em 2em',
      borderRadius: '0.3125em',
      '&:hover': {
        backgroundColor: tertiary[100],
      },
      '&:not(:first-child)': {
        marginTop: '1em',
      },
      '&:last-of-type': {
        marginBottom: '1em',
      },
    },
    messageDialogTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1em 0 0',
    },
    messageDialogBasisTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1em 0 0',
      maxWidth: '20em',
    },
    messageDialogControlsContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '1.5em',
    },
    messageDialogTitleIndent: {
      marginBottom: '0.5em',
    },
    messageDialogBlocksContainer: {
      marginTop: '1em',
      height: '22.5em',
      overflowY: 'auto',
    },
    messageDialogRowsContainer: {
      padding: '0.75em 0 1.125em',
    },
    messageDialogRowContent: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    messageDialogCheckIcon: {
      marginRight: '0.5em',
      color: primary[700],
    },
    messageDialogTextBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    messageDialogFeeContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '1.25em 1.75em 0 0',
    },
    messageDialogBasisButtonsBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '28.5em',
      height: '2em',
    },
    messageDialogFeeBlock: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '0.5em',
    },
    audioListenSmallControlButton: {
      alignItems: 'center',
      width: '2em',
      height: '2em',
      padding: '0.75em 0.5em',
    },
    audioListenExampleControlButton: {
      display: 'flex',
      alignItems: 'center',
      padding: '0.375em 1.125em',
      width: '12.5em',
      height: '2em',
    },
    scenarioTypeControlButton: {
      width: '50%',
      '&:first-of-type': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&:last-of-type': {
        borderLeft: 'none',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    scenarioTypeControlButtonActiveColor: {
      backgroundColor: primary[200],
      border: `solid 1px ${primary[700]}`,
    },
    schemaControlButton: {
      alignItems: 'center',
      padding: '0.325em 1.125em',
      width: '5em',
      height: '2em',
      marginLeft: '0.5em',
    },
    modalScenarioDialog: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '95vh',
      margin: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '5px',
    },
    modalScenarioImageOverlay: {
      overflow: 'auto',
      width: 'calc(100% - 3px)',
      marginRight: '3px',
    },
    audioControlButtonIcon: {
      marginRight: '0.5em',
    },
    closeIcon: {
      marginLeft: '1em',
      cursor: 'pointer',
      '&:hover': {
        color: primary[600],
      },
    },
    modalContentContainer: {
      margin: 'auto',
      paddingBottom: '0.5em',
      borderRadius: '5px',
      width: 'auto',
      backgroundColor: 'white',
      maxHeight: '96vh',
      height: 'fit-content',
    },
    modalPaper: {
      maxWidth: '75em',
    },
    messageDialogBtnWrapper: {
      display: 'flex',
    },
    contractCustomRoot: {
      bottom: '5em',
      [down('md')]: {
        bottom: 0,
      },
    },
    contractCustomOutlet: {
      position: 'static',
      height: '100%',
    },
    personalDataContainer: {
      border: `solid 1px ${tertiary[300]}`,
      borderRadius: '0.75em',
      padding: '1.5em 1.5em 0.5em',
      marginBottom: '1em',
    },
    personalDataContactTitle: {
      paddingBottom: '1em',
    },
    personalDataItem: {
      padding: '0.5em 1em',
      margin: '0.5em 0',
      borderRadius: '4px',
      backgroundColor: tertiary[50],
    },
    tooltip: {
      display: 'block',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
    },
    controlls: {
      textAlign: 'right',
    },
    fieldset: {
      border: 'none',
    },
    recordsStoringOptionLabelText: {
      marginLeft: '1.85em',
    },
    photoPlaceholderWrapper: {
      width: pxToEm(368),
      height: pxToEm(168),

      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',

      marginBottom: '1em',
      borderRadius: '12px',
      backgroundColor: secondary[50],
    },
    photoUploadAlert: {
      width: pxToEm(720),
    },
    hoverTooltipHelpCircleIconContainer: {
      color: tertiary[400],
      marginLeft: '.3em',
      transform: 'translateY(-1px)',
      alignItems: 'start',
    },
    hoverTooltip: {
      padding: '.75em 1em',
      fontSize: '1em',
      backgroundColor: secondary[50],
      border: `1px solid ${secondary[200]}`,
      borderRadius: '8px',
      boxShadow: '12px 9px 40px 6px rgba(0,0,0,0.08)',
      '-webkit-box-shadow': '12px 9px 40px 6px rgba(0,0,0,0.08)',
      '-moz-box-shadow': '12px 9px 40px 6px rgba(0,0,0,0.08)',
    },
    hoverTooltipContent: {
      textAlign: 'left',
    },
    hoverTooltipArrow: {
      color: secondary[50],
      '&::before': {
        border: `1px solid ${secondary[200]}`,
      },
    },
    hidden: {
      display: 'none',
    },
    scenarioCard: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1.5em',

      width: pxToEm(368),
      height: pxToEm(448),

      borderRadius: '16px',
      border: `1px solid ${tertiary[300]}`,
    },
    scenarioCardActive: {
      borderColor: primary[700],
    },
    scenarioCardIconWrapper: {
      color: primary[700],
    },
    scenarioCardBottomButtonsWrapper: {
      marginTop: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    afterSwitchingPBXToCommercialAlert: {
      margin: '1em 0',
    },
    ...styleConstants,
  }),
  {
    index: 10,
  }
);
