import { graphql } from '@/client/generated/gql';

export const RESET_PASSWORD_MUTATION = graphql(`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(data: { token: $token, password: $password }) {
      message
    }
  }
`);

export const GET_ACCESS_TO_DOMAIN_MUTATION = graphql(`
  mutation GetAccessToDomain($domainId: Int!) {
    getAccessToDomain(data: { domainId: $domainId }) {
      token
      refreshToken
    }
  }
`);

export const REMOVE_EMPLOYEE_REGISTRATIONS_MUTATION = graphql(`
  mutation RemoveEmployeeRegistrations($id: Int!) {
    removeEmployeeRegistrations(data: { id: $id }) {
      confirmed
    }
  }
`);

export const REMOVE_EMPLOYEE_MUTATION = graphql(`
  mutation RemoveEmployee($id: Int!) {
    removeEmployee(data: { id: $id }) {
      confirmed
    }
  }
`);

export const CREATE_EMPLOYEE_MUTATION = graphql(`
  mutation CreateEmployee($data: CreateEmployee!) {
    createEmployee(data: $data) {
      id
      role
      position
      createdAt
      updatedAt
      deletedAt
    }
  }
`);

export const UPDATE_EMPLOYEE_MUTATION = graphql(`
  mutation UpdateEmployee($data: UpdateEmployee!) {
    updateEmployee(data: $data) {
      id
      role
      position
      createdAt
      updatedAt
      deletedAt
    }
  }
`);

export const UPDATE_CLIENT_CONTACTS_MUTATION = graphql(`
  mutation UpdateClientContacts($data: UpdateClientContacts!) {
    updateClientContacts(data: $data) {
      id
      status
    }
  }
`);

export const INITIALIZE_EMPLOYEE_MUTATION = graphql(`
  mutation InitializeEmployee($data: InitializeEmployee!) {
    initializeEmployee(data: $data) {
      accessToken
      refreshToken
    }
  }
`);

export const UPDATE_PROFILE_MUTATION = graphql(`
  mutation UpdateProfile($data: UpdateUserProfile, $file: Upload) {
    updateProfile(data: $data, file: $file) {
      id
      name
      email
      phone
      position
      domains {
        id
        timezoneId
        domain
        current
        status
        settings {
          extLength
          blf
          displayNumberName
          holdMusic {
            url
            type
            hash
            name
            systemHoldURL
          }
          assistants {
            type
            count
          }
        }
        createdAt
        employees {
          id
          role
          position
          ext
          current
          callForwarding
          callForwardingTimeout
          phone
          departments {
            id
            name
          }
        }
        client {
          id
          name
          type
          contract
        }
      }
    }
  }
`);

export const CHANGE_PASSWORD_MUTATION = graphql(`
  mutation ChangePassword($data: ChangePassword!) {
    changePassword(data: $data) {
      accessToken
      refreshToken
    }
  }
`);

export const CHANGE_EMAIL_MUTATION = graphql(`
  mutation ChangeEmail($data: ChangeEmail!) {
    changeEmail(data: $data) {
      accessToken
      refreshToken
    }
  }
`);

export const REMOVE_DEPARTMENT_MUTATION = graphql(`
  mutation RemoveDepartment($data: RemoveDepartment!) {
    removeDepartment(data: $data) {
      confirmed
    }
  }
`);

export const CREATE_DEPARTMENT_MUTATION = graphql(`
  mutation CreateDepartment($data: CreateDepartment!) {
    createDepartment(data: $data) {
      id
      name
      ext
      employees {
        id
        isActive
        user {
          id
          name
        }
      }
    }
  }
`);

export const UPDATE_DEPARTMENT_MUTATION = graphql(`
  mutation UpdateDepartment($data: UpdateDepartment!) {
    updateDepartment(data: $data) {
      id
      name
      ext
      employees {
        id
        isActive
        user {
          id
          name
        }
      }
    }
  }
`);

export const UPDATE_INCOMING_NUMBER_MUTATION = graphql(`
  mutation UpdateIncomingNumber($data: UpdateNumber!) {
    updateNumber(data: $data) {
      __typename
      ... on NumbersModel {
        id
      }
      ... on FmcNumberModel {
        id
      }
    }
  }
`);

export const UPDATE_DOMAIN_MUTATION = graphql(`
  mutation UpdateDomain($data: UpdateDomain!) {
    updateDomain(data: $data) {
      id
    }
  }
`);

export const DELETE_OUTGOING_NUMBER_MUTATION = graphql(`
  mutation DeleteOutgoingNumber($data: DeleteOutgoingNumber!) {
    deleteOutgoingNumber(data: $data) {
      message
      confirmed
    }
  }
`);

export const ADD_OUTGOING_NUMBER_MUTATION = graphql(`
  mutation AddOutgoingNumber($data: AddOutgoingNumber!) {
    addOutgoingNumber(data: $data) {
      id
    }
  }
`);

export const UPDATE_OUTGOING_NUMBER_MUTATION = graphql(`
  mutation UpdateOutgoingNumber($data: UpdateOutgoingNumber!) {
    updateOutgoingNumber(data: $data) {
      id
    }
  }
`);

export const UPLOAD_FILE_MUTATION = graphql(`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      filename
      url
    }
  }
`);

export const UPLOAD_DOCUMENT_MUTATION = graphql(`
  mutation UploadDocument($data: UploadDocument!, $file: Upload!) {
    uploadDocument(data: $data, file: $file) {
      filename
      url
    }
  }
`);

export const UPDATE_COMMON_OUTGOING_NUMBER_MUTATION = graphql(`
  mutation UpdateCommonOutgoingNumber($data: UpdateCommonOutgoingNumber!) {
    updateCommonOutgoingNumber(data: $data) {
      numberId
    }
  }
`);

export const UPDATE_CALLS_RESTRICTIONS_MUTATION = graphql(`
  mutation UpdateCallsRestrictions($data: UpdateCallRestriction!) {
    updateCallRestriction(data: $data) {
      id
    }
  }
`);

export const ADD_CALLS_RESTRICTIONS_MUTATION = graphql(`
  mutation AddCallsRestrictions($data: AddCallRestriction!) {
    addCallRestriction(data: $data) {
      id
    }
  }
`);

export const UPDATE_COMMON_CALLS_RESTRICTIONS_MUTATION = graphql(`
  mutation UpdateCommonCallsRestrictions($data: UpdateCommonCallRestriction!) {
    updateCommonCallRestriction(data: $data) {
      id
    }
  }
`);

export const DELETE_CALLS_RESTRICTIONS_MUTATION = graphql(`
  mutation DeleteCallsRestrictions($data: DeleteCallRestriction!) {
    deleteCallRestriction(data: $data) {
      message
      confirmed
    }
  }
`);

export const SYNTHESIS_AUDIO_MUTATION = graphql(`
  mutation SynthesisAudio($text: String!) {
    synthesis(data: { text: $text }) {
      filename
      url
    }
  }
`);

export const PRESELECT_TARIFF_MUTATION = graphql(`
  mutation PreselectTariff($data: SelectedTariff!) {
    preselectTariff(data: $data) {
      id
      domain
      employeesNumber
      settings {
        extLength
        blf
        allowEmergencyServices
        displayNumberName
        holdMusic {
          url
          type
          hash
          name
          systemHoldURL
        }
        assistants {
          type
          count
        }
      }
      status
      tariff {
        id
        name
        active
        options {
          id
          name
          type
          remark
          setupFee
          monthlyFee
          category
        }
      }
    }
  }
`);

export const RESERVE_NUMBER_MUTATION = graphql(`
  mutation ReserveNumber($catalogNumberId: LongInt!, $numberType: NumberType!) {
    reserveNumber(data: { catalogNumberId: $catalogNumberId, numberType: $numberType }) {
      id
      domainId
      phone
      name
      hasSchedule
      prefix
      city
      type
      status
      statusTill
      periods
      destination
      scheduler
      tariff {
        setupFee
        monthlyFee
        setupDiscount
        monthlyDiscount
        isSetupDiscountAbsolute
        isMonthlyDiscountAbsolute
        baseSetupFee
        baseMonthlyFee
      }
      createdAt
      updatedAt
    }
  }
`);

export const REMOVE_RESERVED_NUMBER_MUTATION = graphql(`
  mutation RemoveReservedNumber($domainNumberId: Int!) {
    removeNumber(data: { domainNumberId: $domainNumberId }) {
      message
      confirmed
      errors
    }
  }
`);

export const BUY_NUMBER_MUTATION = graphql(`
  mutation BuyNumber($catalogNumberId: Int!) {
    buyNumber(data: { catalogNumberId: $catalogNumberId }) {
      id
      domainId
      phone
      name
      hasSchedule
      prefix
      city
      type
      status
      statusTill
      periods
      destination
      scheduler
      tariff {
        setupFee
        monthlyFee
        setupDiscount
        monthlyDiscount
        isSetupDiscountAbsolute
        isMonthlyDiscountAbsolute
        baseSetupFee
        baseMonthlyFee
      }
      createdAt
      updatedAt
    }
  }
`);

export const REFUSE_NUMBER_MUTATION = graphql(`
  mutation RefuseNumber($domainNumberId: Int!) {
    refuseNumberRequest(data: { domainNumberId: $domainNumberId }) {
      message
      confirmed
      errors
    }
  }
`);

export const ABORT_REFUSE_NUMBER_MUTATION = graphql(`
  mutation AbortRefuseNumber($domainNumberId: Int!) {
    abortRefuseNumberRequest(data: { domainNumberId: $domainNumberId }) {
      message
      confirmed
      errors
    }
  }
`);

export const REGISTER_ORDER_FL_MUTATION = graphql(`
  mutation RegisterOrderFl($amount: Int!, $callback: String) {
    registerOrderFL(data: { amount: $amount, callback: $callback })
  }
`);

export const REGISTER_ORDER_UL_MUTATION = graphql(`
  mutation RegisterOrderUl($amount: Int!) {
    registerOrderUL(data: { amount: $amount })
  }
`);

export const UPDATE_CLIENT_PASSPORT_MUTATION = graphql(`
  mutation UpdateClientPassport($data: UpdateClientPassport!) {
    updateClientPassport(data: $data) {
      id
    }
  }
`);

export const SELECT_NUMBERS_MUTATION = graphql(`
  mutation SelectNumbers {
    numbersChoosen {
      confirmed
      message
    }
  }
`);

export const MOVE_TO_MODERATING_MUTATION = graphql(`
  mutation MoveToModerating {
    moveToModerating {
      id
    }
  }
`);

export const CREATE_INTEGRATION_MUTATION = graphql(`
  mutation CreateIntegration($data: CreateIntegration!) {
    createIntegration(data: $data) {
      id
    }
  }
`);

export const CREATE_MISSED_CALL_CONFIG_MUTATION = graphql(`
  mutation CreateMissedCallConfig($data: MissedCallSource!) {
    createMissedCallConfig(data: $data) {
      id
      domainId
      allNumbers
      domainNumber {
        id
        phone
      }
      employee {
        id
        name
      }
      department {
        id
        name
      }
      receivers {
        id
        missedCallSourceId
        employee {
          id
          name
          phone
          email
        }
        department {
          id
          name
          numberCount
          emailCount
        }
        external
        notificationTypes
      }
    }
  }
`);

export const UPDATE_MISSED_CALL_CONFIG_MUTATION = graphql(`
  mutation UpdateMissedCallConfig($data: MissedCallUpdateSource!) {
    updateMissedCallConfig(data: $data) {
      id
      domainId
      allNumbers
      domainNumber {
        id
        phone
      }
      employee {
        id
        name
      }
      department {
        id
        name
      }
      receivers {
        id
        missedCallSourceId
        employee {
          id
          name
          phone
          email
        }
        department {
          id
          name
          numberCount
          emailCount
        }
        external
        notificationTypes
      }
    }
  }
`);

export const REMOVE_MISSED_CALL_CONFIG_MUTATION = graphql(`
  mutation RemoveMissedCallConfig($data: MissCallId!) {
    deleteMissedCallConfig(data: $data) {
      message
      confirmed
    }
  }
`);

export const UPDATE_INTEGRATION_MUTATION = graphql(`
  mutation UpdateIntegration($data: UpdateIntegration!) {
    updateIntegration(data: $data) {
      id
    }
  }
`);

export const UPDATE_INTEGRATION_ACTIVATION_MUTATION = graphql(`
  mutation UpdateIntegrationActivation($data: SetActivationIntegration!) {
    setIntegrationActivation(data: $data) {
      id
    }
  }
`);

export const DELETE_INTEGRATION_MUTATION = graphql(`
  mutation DeleteIntegration($data: DomainIntegrationId!) {
    deleteIntegration(data: $data) {
      message
      confirmed
      errors
    }
  }
`);

export const GET_BITRIX_IS_KNOWN_MUTATION = graphql(`
  mutation GetBitrixIsKnown($data: BitrixUrl!) {
    isBitrixAppInstalled(data: $data) {
      confirmed
      url
    }
  }
`);

export const CREATE_BUSINESS_CARD_MUTATION = graphql(`
  mutation CreateBusinessCard($data: BusinessCardSetting!) {
    createBusinessCardConfig(data: $data) {
      id
      domainId
      smsTemplate
      newClientDelay
      addContacts
      active
      sources {
        id
        domainId
        businessCardId
        allNumbers
        number {
          id
          phone
        }
      }
    }
  }
`);

export const UPDATE_BUSINESS_CARD_CONFIG_MUTATION = graphql(`
  mutation UpdateBusinessCardConfig($data: BusinessCardUpdateSetting!) {
    updateBusinessCardConfig(data: $data) {
      id
      domainId
      smsTemplate
      newClientDelay
      addContacts
      active
      sources {
        id
        domainId
        businessCardId
        allNumbers
        number {
          id
          phone
        }
      }
    }
  }
`);

export const DELETE_BUSINESS_CARD_MUTATION = graphql(`
  mutation DeleteBusinessCard($data: BusinessCardSettingId!) {
    deleteBusinessCardConfig(data: $data) {
      message
      confirmed
      errors
    }
  }
`);

export const REMOVE_APOLOGY_MESSAGES_SETTINGS_MUTATION = graphql(`
  mutation RemoveApologyMessagesSettings($id: Int!) {
    removeApologyMessagesSettings(id: $id) {
      message
      confirmed
      errors
    }
  }
`);

export const UPDATE_APOLOGY_MESSAGES_SETTINGS_MUTATION = graphql(`
  mutation UpdateApologyMessagesSettings($input: UpdateApologyMessagesSettings!) {
    updateApologyMessagesSettings(input: $input) {
      id
      messageTemplate
      active
      allNumbers
      triggerTime
      domainNumbers {
        id
        phone
      }
    }
  }
`);

export const CREATE_APOLOGY_MESSAGES_SETTINGS_MUTATION = graphql(`
  mutation CreateApologyMessagesSettings($input: CreateApologyMessagesSettings!) {
    createApologyMessagesSettings(input: $input) {
      id
      messageTemplate
      active
      allNumbers
      triggerTime
      domainNumbers {
        id
        phone
      }
    }
  }
`);

export const REMOVE_FROM_BLACKLIST_MUTATION = graphql(`
  mutation RemoveFromBlacklist($data: RemoveFromBlackList!) {
    removeFromBlackList(data: $data) {
      confirmed
      message
    }
  }
`);

export const ADD_TO_BLACKLIST_MUTATION = graphql(`
  mutation AddToBlacklist($data: AddToBlackList!) {
    addToBlackList(data: $data) {
      confirmed
      message
    }
  }
`);

export const CHOOSE_ASSISTANTS_IN_DEMO_MUTATION = graphql(`
  mutation ChooseAssistantsInDemo($data: ReserveAssistantsInDemo!) {
    chooseAssistantsInDemo(data: $data) {
      confirmed
      message
    }
  }
`);

export const ACTIVATE_BUSINESS_CARD_MUTATION = graphql(`
  mutation ActivateBusinessCard($data: BusinessCardActivation!) {
    activateBusinessCard(data: $data) {
      id
      smsTemplate
      newClientDelay
      addContacts
      active
      sources {
        id
        domainId
        businessCardId
        allNumbers
        number {
          id
          phone
        }
      }
    }
  }
`);

export const PRE_DELETE_ASSISTANT_MUTATION = graphql(`
  mutation PreDeleteAssistant($data: AssistantId!) {
    preDeleteAssistant(data: $data) {
      id
      domainId
      status
      type
      sum
      name
      ext
      minutesConsumption
      telephonyService {
        cost
        prepaid
        oneUnitCost
      }
      marketAssistant {
        id
        type
        name
      }
      statusDate
    }
  }
`);

export const BUY_MARKET_ASSISTANT_MUTATION = graphql(`
  mutation BuyMarketAssistant($data: BuyMarketAssistant!) {
    buyMarketAssistant(data: $data) {
      id
      domainId
      status
      type
      sum
      name
      ext
      minutesConsumption
      telephonyService {
        cost
        prepaid
        oneUnitCost
      }
      marketAssistant {
        id
        type
        name
      }
      statusDate
    }
  }
`);

export const BUY_INDIVIDUAL_ASSISTANT_MUTATION = graphql(`
  mutation BuyIndividualAssistant {
    createDealForIndividualAssistant {
      confirmed
      message
    }
  }
`);

export const CANCEL_ASSISTANT_DELETION_MUTATION = graphql(`
  mutation CancelAssistantDeletion($data: AssistantId!) {
    cancelAssistantDeletion(data: $data) {
      id
      domainId
      status
      type
      sum
      name
      ext
      minutesConsumption
      telephonyService {
        cost
        prepaid
        oneUnitCost
      }
      marketAssistant {
        id
        type
        name
      }
      statusDate
    }
  }
`);

export const PRE_FREEZE_ASSISTANT_MUTATION = graphql(`
  mutation PreFreezeAssistant($data: AssistantId!) {
    preFreezeAssistant(data: $data) {
      id
      domainId
      status
      type
      sum
      name
      ext
      minutesConsumption
      telephonyService {
        cost
        prepaid
        oneUnitCost
      }
      marketAssistant {
        id
        type
        name
      }
      statusDate
    }
  }
`);

export const UNFREEZE_ASSISTANT_MUTATION = graphql(`
  mutation UnfreezeAssistant($data: AssistantId!) {
    unfreezeAssistant(data: $data) {
      id
      domainId
      status
      type
      sum
      name
      ext
      minutesConsumption
      telephonyService {
        cost
        prepaid
        oneUnitCost
      }
      marketAssistant {
        id
        type
        name
      }
      statusDate
    }
  }
`);

export const CREATE_PROMISED_PAYMENT_MUTATION = graphql(`
  mutation CreatePromisedPayment {
    createPromisedPayment {
      message
      confirmed
      errors
    }
  }
`);

export const ADD_CHANGE_TARIFF_REQUEST_MUTATION = graphql(`
  mutation AddChangeTariffRequest($data: SelectedTariff!) {
    addChangeTariffRequest(data: $data) {
      id
      tariffId
      status
      options {
        tariffOptionId
        type
        count
      }
      createdAt
    }
  }
`);

export const CANCEL_CHANGE_TARIFF_REQUEST_MUTATION = graphql(`
  mutation CancelChangeTariffRequest {
    cancelChangeTariffRequest {
      confirmed
      message
    }
  }
`);

export const INSTALL_YCLIENTS_INTEGRATION_MUTATION = graphql(`
  mutation InstallYclientsIntegration($data: YclientsCrmToken!) {
    installYClientsIntegration(data: $data)
  }
`);

export const GENERATE_HISTORY_MUTATION = graphql(`
  mutation GenerateHistory {
    generateHistoryRecords {
      message
      createdCount
    }
  }
`);

export const MODIFY_TARIFF_MUTATION = graphql(`
  mutation ModifyTariff($data: SelectedTariff!) {
    modifyTariff(data: $data) {
      id
      domain
      employeesNumber
      settings {
        extLength
        blf
        allowEmergencyServices
        displayNumberName
        holdMusic {
          url
          type
          hash
          name
          systemHoldURL
        }
        assistants {
          type
          count
        }
      }
      status
      tariff {
        id
        name
        active
        options {
          id
          name
          type
          remark
          setupFee
          monthlyFee
          baseSetupFee
          baseMonthlyFee
          category
          params
          domainTariffOptionParams
          setupDiscount
          monthlyDiscount
          isSetupDiscountAbsolute
          isMonthlyDiscountAbsolute
        }
      }
      tariffChangeRequest {
        id
        tariffId
        status
        options {
          tariffOptionId
          type
          count
        }
        createdAt
      }
    }
  }
`);

export const GET_RETAILCRM_IS_KNOWN_MUTATION = graphql(`
  mutation GetRetailcrmIsKnownMutation($data: RetailCrmUrl!) {
    isRetailCrmAppInstalled(data: $data) {
      confirmed
      integrationId
    }
  }
`);

export const UPDATE_CLIENT_PASSPORT_SCANS_MUTATION = graphql(`
  mutation UpdateClientPassportScans($data: ClientPassportScans!) {
    updateClientPassportScans(data: $data) {
      confirmed
    }
  }
`);

export const ADD_WORK_CALENDAR_CUSTOM_DAY = graphql(`
  mutation AddWorkCalendarCustomDay($data: CreateWorkCalendarCustomDay!) {
    addWorkCalendarCustomDay(data: $data) {
      category
      date
      id
      note
      type
    }
  }
`);

export const UPDATE_CAROUSEL_EMPLOYEES_LIST_MUTATION = graphql(`
  mutation UpdateCarouselEmployeesList($input: UpdateCarouselEmployeesList!) {
    updateCarouselEmployeesList(input: $input) {
      message
      confirmed
    }
  }
`);

export const CREATE_END_USER_MUTATION = graphql(`
  mutation CreateEndUser($input: CreateEndUser!) {
    createEndUser(input: $input) {
      id
    }
  }
`);

export const UPDATE_WORK_CALENDAR_CUSTOM_DAY = graphql(`
  mutation UpdateWorkCalendarCustomDay($data: UpdateWorkCalendarCustomDay!) {
    updateWorkCalendarCustomDay(data: $data) {
      confirmed
    }
  }
`);

export const DELETE_WORK_CALENDAR_CUSTOM_DAY = graphql(`
  mutation DeleteWorkCalendarCustomDay($customDayId: Int!) {
    deleteWorkCalendarCustomDay(customDayId: $customDayId) {
      confirmed
    }
  }
`);

export const UPDATE_END_USER = graphql(`
  mutation UpdateEndUser($input: UpdateEndUser!) {
    updateEndUser(input: $input) {
      id
    }
  }
`);

export const REMOVE_END_USER_MUTATION = graphql(`
  mutation RemoveEndUser($id: Int!) {
    removeEndUser(id: $id) {
      confirmed
    }
  }
`);

export const SET_DOMAIN_NUMBERS_END_USER_MUTATION = graphql(`
  mutation SetDomainNumbersEndUser($input: SetDomainNumbersEndUser!) {
    setDomainNumbersEndUser(input: $input) {
      confirmed
    }
  }
`);
