import { makeStyles } from '@material-ui/core';
import MessageDialog from '@shared/components/MessageDialog';
import SelectField from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import React, { useState } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from '@legendapp/state/react';
import { formatPhone } from '@components/utils';
import { useMutation, useQuery } from '@apollo/client';
import {
  DOMAIN_TEL_NUMBERS_QUERY,
  GET_ALL_END_USERS_QUERY,
  IS_UNASSIGNED_END_USERS_QUERY,
} from '@/client/queries';
import { SET_DOMAIN_NUMBERS_END_USER_MUTATION } from '@/client/mutations';
import resultify from '@shared/utils/resultify';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import PreloaderPanel from '@shared/components/PreloaderPanel';
import clsx from 'clsx';
import client from '@components/client/client';
import Button from '@shared/components/Button';
import { useNavigate } from 'react-router-dom';
import { NumberType } from '@/client/generated/graphql';
import { phoneNumbersContext$, resetPhoneNumbersContext } from '../PhoneNumbers';

type FormFields = {
  endUserId: number | null;
};

const useStyles = makeStyles(({ color: { secondary } }) => ({
  selectField: {
    marginTop: '1em',
    width: '100%',
  },
  marginBottom05: {
    marginBottom: '.5em',
  },
  marginBottom1: {
    marginBottom: '1em',
  },
  marginTop1: {
    marginTop: '1em',
  },
  marginTop15: {
    marginTop: '1.5em',
  },
  additionalInfo: {
    padding: '.75em 1em',
    backgroundColor: secondary[50],
    border: `1px solid ${secondary[200]}`,
    borderRadius: '8px',
  },
  noResponsiblePersonsInfoWrapper: {
    marginTop: '.5em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItem: 'center',
  },
}));

const DialogSelectResponsible = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const formMethods = useFormContext<FormFields>();

  const [isLoading, setIsLoading] = useState(false);
  const {
    data: endUsersQueryData,
    loading: endUsersQueryLoading,
    error: endUsersQueryError,
  } = useQuery(GET_ALL_END_USERS_QUERY);
  const { data: isUnassignedEndUsersQuery } = useQuery(IS_UNASSIGNED_END_USERS_QUERY, {
    fetchPolicy: 'cache-only',
  });
  const [setDomainNumbersEndUser] = useMutation(SET_DOMAIN_NUMBERS_END_USER_MUTATION);

  const endUsersDataMapped =
    endUsersQueryData?.getAllEndUsers.map((endUserData) => {
      const nameArr = [];
      if (endUserData.surname) {
        nameArr.push(endUserData.surname);
      }
      if (endUserData.name) {
        nameArr.push(endUserData.name);
      }
      if (endUserData.patronymic) {
        nameArr.push(endUserData.patronymic);
      }
      return {
        name: nameArr.join(' '),
        id: endUserData.id,
      };
    }) || [];

  const isOpen = useSelector(phoneNumbersContext$.isDialogSelectResponsibleOpen);
  const phoneNumber = useSelector(phoneNumbersContext$.phoneNumber);
  const domainNumberId = useSelector(phoneNumbersContext$.domainNumberId);
  const additionalNotification = useSelector(phoneNumbersContext$.additionalNotification);

  const handleAddResponsiblePersonButtonClick = () => {
    // two function calls are intentional
    navigate('/numbers/responsible-persons');
    navigate('/numbers/add-responsible-person');
  };

  const renderContent = () => {
    if (endUsersQueryLoading) {
      return <PreloaderPanel />;
    }

    if (endUsersQueryError) {
      return <Typography>{translate('SOMETHING_WENT_WRONG')}</Typography>;
    }

    return (
      <FormProvider {...formMethods}>
        <div className={classes.marginBottom05}>
          <Typography pxToEmSize={12}>{translate('NUMBER')}</Typography>
        </div>

        <div className={classes.marginBottom1}>
          <Typography pxToEmSize={18} medium>
            {formatPhone(phoneNumber)}
          </Typography>
        </div>

        <div className={classes.marginBottom1}>
          <Typography>
            {translate('AN_EMPLOYEE_WITH_ENTERED_PASSPORT_DATA_MAY_BE_RESPONSIBLE')}
          </Typography>
        </div>

        {endUsersDataMapped.length ? (
          <SelectField
            className={classes.selectField}
            name="endUserId"
            data={endUsersDataMapped}
            required
            valueKey="id"
            titleKey="name"
            label={translate('SELECT_RESPONSIBLE_PERSON')}
          />
        ) : (
          <div className={classes.noResponsiblePersonsInfoWrapper}>
            <Typography color="tertiary400">
              {translate('NOT_A_SINGLE_RESPONSIBLE_PERSON_HAS_BEEN_CREATED')}
            </Typography>
            <Button
              className={classes.marginTop15}
              variant="secondaryFilled"
              onClick={handleAddResponsiblePersonButtonClick}
              smallHeight
            >
              <Typography>{translate('ADD_RESPONSIBLE')}</Typography>
            </Button>
          </div>
        )}

        {additionalNotification && endUsersDataMapped.length !== 0 && (
          <div className={clsx(classes.marginTop1, classes.additionalInfo)}>
            <Typography>{additionalNotification}</Typography>
          </div>
        )}
      </FormProvider>
    );
  };

  const handleClose = () => {
    phoneNumbersContext$.isDialogSelectResponsibleOpen.set(false);
    setTimeout(() => {
      // prevent ui blink
      resetPhoneNumbersContext();
      formMethods.reset();
    }, 250);
  };

  const handleSubmit = async (formFields: FormFields) => {
    setIsLoading(true);
    const result = await resultify(
      setDomainNumbersEndUser({
        variables: { input: { domainNumberId, endUserId: formFields.endUserId! } },
      })
    );

    if (result.type === 'error') {
      globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      setIsLoading(false);
      return;
    }

    const domainTelNumbersResult = await resultify(
      client.query({ query: DOMAIN_TEL_NUMBERS_QUERY })
    );

    // error is showed on page, so we can ignore it here
    if (domainTelNumbersResult.type === 'success') {
      if (
        isUnassignedEndUsersQuery?.isUnassignedEndUsers &&
        domainTelNumbersResult.data.data.getNumbers
          .filter((numberData) => numberData.type === NumberType.Runexis)
          .every(
            (numberData) =>
              numberData.endUser && typeof numberData.code === 'number' && numberData.code >= 900
          )
      ) {
        client.refetchQueries({ include: [IS_UNASSIGNED_END_USERS_QUERY] }).catch(() => {});
      }
    }

    globalNotification$.show('success', 'SUCCESS');
    setIsLoading(false);
    handleClose();
  };

  return (
    <MessageDialog
      title={translate('SELECT_RESPONSIBLE')}
      isOpen={isOpen}
      renderContent={renderContent()}
      onCancel={isLoading ? undefined : handleClose}
      buttonSuccessProps={
        endUsersDataMapped.length !== 0
          ? {
              titleCode: 'CONFIRM',
              loading: isLoading,
              onClick: formMethods.handleSubmit(handleSubmit),
            }
          : {
              titleCode: 'CLOSE',
              onClick: handleClose,
            }
      }
      buttonCancelProps={
        endUsersDataMapped.length !== 0
          ? {
              titleCode: 'CANCEL',
              disabled: isLoading,
              onClick: isLoading ? undefined : handleClose,
            }
          : undefined
      }
    />
  );
};

export default DialogSelectResponsible;
