import exifr from 'exifr';
import resultify from '@shared/utils/resultify';
import * as Sentry from '@sentry/react';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import { addHours, isValid } from 'date-fns';
import { heicToJpegSafe } from '@components/utils/images';
import { getLastModifiedDateFromExifrData } from '@components/utils/exif';

export const validateAndGetSelfieFileForUploading = async (file: File, skipValidation = false) => {
  let resultFile = file;

  const exifrParseResult = await resultify(exifr.parse(file));

  if (exifrParseResult.type === 'error') {
    Sentry.captureException(exifrParseResult.error, {
      extra: { message: 'Parsing data with exifr failed' },
    });
    globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
    return { type: 'error' } as const;
  }

  const lastModifiedDate = getLastModifiedDateFromExifrData(exifrParseResult.data);
  if (!skipValidation) {
    if (!lastModifiedDate || !isValid(lastModifiedDate)) {
      return {
        type: 'error',
        errorMessageCodeForHashField: 'CANNOT_DEFINE_PHOTO_TAKE_TIMING',
      } as const;
    }
    if (addHours(lastModifiedDate, 12) < new Date()) {
      return {
        type: 'error',
        errorMessageCodeForHashField: 'PHOTO_MUST_NOT_BE_OLDER_THAN_12_HOURS',
      } as const;
    }
  }
  if (file.name.toLowerCase().endsWith('.heic') || file.name.toLowerCase().endsWith('.heif')) {
    const heicToJpegResult = await heicToJpegSafe(file, lastModifiedDate || undefined);
    if (heicToJpegResult.type === 'error') {
      Sentry.captureException(heicToJpegResult.error, {
        extra: { message: heicToJpegResult.additionalMessage },
      });
      return { type: 'error' } as const;
    }

    resultFile = heicToJpegResult.file;
  }

  return { type: 'success', file: resultFile } as const;
};
