import React, { useState } from 'react';
import { WorkCalendarCategory } from '@/client/generated/graphql';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import Button from '@shared/components/Button';
import {
  getDaysStringWithCorrectCaseForRussianLanguage,
  shortDateStringToDDMMString,
} from '@shared/utils/dates';
import clsx from 'clsx';
import { ChevronDownIcon } from '@shared/assets/images/icons';
import { WorkCalendarEventsGroupProps } from './WorkCalendarEventsGroup.types';
import { useWorkCalendarEventsGroupStyles } from './WorkCalendarEventsGroup.styles';

const WorkCalendarEventsGroup = ({
  events,
  category,
  handleEventClick,
}: WorkCalendarEventsGroupProps) => {
  const classes = useWorkCalendarEventsGroupStyles();
  const [translate, i18n] = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandButtonClick = () => {
    setIsExpanded((prev) => !prev);
  };

  const areThereEvents = events.length !== 0;

  const getTitle = () => {
    switch (category) {
      case WorkCalendarCategory.Custom:
        return translate('EXCEPTION_DAYS');
      case WorkCalendarCategory.Holiday:
        return translate('WEEKENDS_AND_HOLIDAYS');
      case WorkCalendarCategory.Rescheduled:
        return translate('RESCHEDULED_DAYS');
      default:
        category satisfies never;
        return '';
    }
  };

  const getDaysCountString = () => {
    if (events.length !== 0) {
      if (i18n.language === 'ru') {
        return getDaysStringWithCorrectCaseForRussianLanguage(events.length);
      }
      return `${events.length} ${translate('DAYS')}`;
    }
    if (category === WorkCalendarCategory.Custom) {
      return translate('THERE_ARE_NO_EXCEPTIONS');
    }
    return translate('THEY_ARE_ABSENT');
  };
  const renderEvents = () => {
    if (!areThereEvents) {
      if (
        category === WorkCalendarCategory.Holiday ||
        category === WorkCalendarCategory.Rescheduled
      ) {
        return null;
      }

      return (
        <div className={classes.noExceptionsAddedBlock}>
          <Typography color="tertiary400">
            {translate('TO_SET_UP_EXCEPTIONS_CLICK_ON_DESIRED_DATE_IN_THE_CALENDAR')}
          </Typography>
        </div>
      );
    }

    return (
      <div className={classes.eventsList}>
        <div className={classes.eventsDates}>
          {events.map((event) => {
            const dateString = shortDateStringToDDMMString(event.date);

            if (!event.note && handleEventClick) {
              return (
                <Button
                  className={classes.eventNameButton}
                  key={event.id}
                  variant="text"
                  disableRipple
                  onClick={() => handleEventClick(event)}
                >
                  <Typography className={classes.eventNameButtonTypography} color="link600">
                    {dateString}
                  </Typography>
                </Button>
              );
            }

            return (
              <div className={classes.eventDateWrapper} key={event.id}>
                <Typography>{dateString}</Typography>
              </div>
            );
          })}
        </div>
        <div className={classes.eventsNamesButtons}>
          {events.map((event) => {
            if (!event.note) {
              return <div key={event.id} className={classes.eventNameButtonPlaceholder} />;
            }

            if (handleEventClick) {
              return (
                <Button
                  className={classes.eventNameButton}
                  key={event.id}
                  variant="text"
                  disableRipple
                  onClick={() => handleEventClick(event)}
                >
                  <Typography className={classes.eventNameButtonTypography} color="link600">
                    {event.note}
                  </Typography>
                </Button>
              );
            }

            return (
              <div className={classes.eventDateWrapper} key={event.id}>
                <Typography className={classes.eventNameButtonTypography}>{event.note}</Typography>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={clsx(classes.eventsGroup, isExpanded && classes.eventsGroupExpanded)}>
      <div className={classes.topBlock}>
        <div
          className={clsx(
            classes.topBlockColorMarker,
            category === WorkCalendarCategory.Custom &&
              classes.topBlockColorMarkerCustomDaysCategory,
            category === WorkCalendarCategory.Holiday &&
              classes.topBlockColorMarkerWeekendsAndHolidaysCategory,
            category === WorkCalendarCategory.Rescheduled &&
              classes.topBlockColorMarkerRescheduledDaysCategory
          )}
        />
        <div className={classes.topBlockInfo}>
          <Typography>{getTitle()}</Typography>
          <div className={classes.topBlockInfoDaysCountWrapper}>
            <Typography color="tertiary600" pxToEmSize={12}>
              {getDaysCountString()}
            </Typography>
          </div>
        </div>
        {(areThereEvents || category === WorkCalendarCategory.Custom) && (
          <Button
            className={classes.topBlockExpandButton}
            variant="icon"
            onClick={handleExpandButtonClick}
          >
            <ChevronDownIcon className={clsx(isExpanded && classes.rotateX180)} />
          </Button>
        )}
      </div>

      {isExpanded && <div className={classes.bottomBlock}>{renderEvents()}</div>}
    </div>
  );
};

export default WorkCalendarEventsGroup;
