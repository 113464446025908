import React, { useCallback, useState } from 'react';
import { FilterIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { OutgoingCallEntity } from '@components/typings/interfaces';
import CheckboxField from '@shared/components/CheckboxField';
import { useOutgoingCallsStyles } from '@/features/OutgoingCalls/OutgoingCalls.styles';
import ControlButtons from '@shared/components/ControlButtons';
import MessageDialog from '@shared/components/MessageDialog';

type Props = {
  disabled: boolean;
};

type OutgoingCallsFilterParams = {
  selectAll?: boolean;
  department?: boolean;
  employee?: boolean;
};

export const OutgoingCallTypeHeaderCell = ({ disabled }: Props) => {
  const classes = useOutgoingCallsStyles();
  const [translate] = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const entityFilter = searchParams.getAll('filter');
  const computedClass = clsx(classes.headFilterIcon, {
    [classes.primary]: entityFilter.length && !entityFilter.includes('all'),
  });

  const formMethods = useForm<OutgoingCallsFilterParams>({
    defaultValues: {
      selectAll: !entityFilter?.length || entityFilter.includes('all'),
      department: entityFilter.includes('department'),
      employee: entityFilter.includes('employee'),
    },
  });
  const { reset, handleSubmit, setValue } = formMethods;

  const setAllValuesToTrue = useCallback(() => {
    reset({
      selectAll: true,
      department: true,
      employee: true,
    });
  }, [reset]);

  const setAllValuesToFalse = () => {
    reset({
      selectAll: false,
      department: false,
      employee: false,
    });
  };

  const handleSelectAllCheckboxFieldClick = () => {
    const selectAllCurrentValue = !formMethods.getValues().selectAll;
    if (selectAllCurrentValue) {
      setAllValuesToTrue();
      return;
    }

    setAllValuesToFalse();
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleFilterClick = () => {
    if (disabled) {
      return;
    }

    setOpen(true);

    if (entityFilter.length === 0 || entityFilter.includes('all')) {
      setAllValuesToTrue();
      return;
    }

    const formParams: OutgoingCallsFilterParams = {
      selectAll: false,
      department: false,
      employee: false,
    };
    entityFilter.forEach((el: string) => {
      formParams[el as keyof OutgoingCallsFilterParams] = true;
    });
    reset(formParams);
  };

  const handleSubmitForm = useCallback(
    ({ department, employee, selectAll }: OutgoingCallsFilterParams) => {
      searchParams.delete('filter');
      if (selectAll) {
        searchParams.set('filter', 'all');
      } else {
        if (department) {
          searchParams.append('filter', OutgoingCallEntity.Department);
        }
        if (employee) {
          searchParams.append('filter', OutgoingCallEntity.Employee);
        }
      }
      setSearchParams(searchParams);
      setOpen(false);
    },
    [searchParams, setSearchParams]
  );

  const handleGetCheckboxFieldClickHandler =
    (fieldName: Exclude<keyof OutgoingCallsFilterParams, 'selectAll'>) => () => {
      const values: Omit<OutgoingCallsFilterParams, 'selectAll'> = {
        department: formMethods.getValues().department,
        employee: formMethods.getValues().employee,
      };

      switch (fieldName) {
        case 'department':
          values.department = !values.department;
          break;
        case 'employee':
          values.employee = !values.employee;
          break;
        default:
          fieldName satisfies never;
      }

      setValue(
        'selectAll',
        Object.values(values).every((value) => value)
      );
    };

  const renderIcon = () => (
    <FilterIcon
      width={16}
      height={16}
      style={!disabled ? { cursor: 'pointer' } : undefined}
      onClick={() => handleFilterClick()}
    />
  );

  return (
    <div className={computedClass}>
      {renderIcon()}
      <MessageDialog
        onCancel={handleFilterClose}
        isOpen={open}
        contentClass={classes.defaultElementWidth21}
        title={translate('FILTER_BY_TYPE')}
        renderContent={
          <FormProvider {...formMethods}>
            <form
              id={'outgoing-calls-filter'}
              onSubmit={handleSubmit(handleSubmitForm)}
              className={classes.form}
            >
              <CheckboxField
                className={classes.checkboxControl}
                name={'selectAll'}
                label={translate('SELECT_ALL')}
                onClick={handleSelectAllCheckboxFieldClick}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'department'}
                label={translate('DEPARTMENTS')}
                onClick={handleGetCheckboxFieldClickHandler('department')}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'employee'}
                label={translate('EMPLOYEES')}
                onClick={handleGetCheckboxFieldClickHandler('employee')}
              />
              <ControlButtons
                confirmTitle={'APPLY'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                form={'outgoing-calls-filter'}
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                rootClass={classes.dialogFilterActions}
                onCancelClick={() => setOpen(false)}
                small
              />
            </form>
          </FormProvider>
        }
      />
    </div>
  );
};
