import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Typography from '@shared/components/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '@shared/components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Drawer from '@components/Drawer';
import { AudioPlayer } from '@shared/components/AudioPlayer';
import { XIcon, PlayIcon, GitPullRequestIcon, SlidersIcon } from '@shared/assets/images/icons';
import {
  BUY_INDIVIDUAL_ASSISTANT_MUTATION,
  BUY_MARKET_ASSISTANT_MUTATION,
} from '@/client/mutations';
import { BALANCE_QUERY } from '@/client/queries';
import MessageDialog from '@shared/components/MessageDialog';
import { toPrecision } from '@components/utils';
import RadioGroup from '@shared/components/RadioGroup';
import Translate from '@shared/components/Translate';
import clsx from 'clsx';
import { getMonthRatio } from '@/utils/getMonthRatio';
import { BASIC_SCENARIOS, IScenarioContent } from '@/../typings/basicScenarios';
import { scenarioTypes } from '@components/typings/interfaces';
import ControlButtons from '@shared/components/ControlButtons';
import BottomButtons from '@/layouts/BottomButtons';
import BodyContainer from '@/layouts/BodyContainer';
import { VATPercent } from '@/common/constants';
import * as Sentry from '@sentry/react';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import Flex from '@shared/components/Flex';
import RadioButton from '@shared/components/RadioButton';
import { useScenariosStyle } from './Scenarios.styles';
import { individualScenarioData, marketScenarioData } from './Scenarios.constants';

type FormFields = {
  assistantType: scenarioTypes;
};

export const AddScenario = () => {
  const classes = useScenariosStyle();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const formMethods = useForm<FormFields>();
  const { watch } = formMethods;
  const assistantType = watch('assistantType');

  const [isExampleDrawerOpen, setIsExampleDrawerOpen] = useState(false);
  const [activePlayerIndex, setActivePlayerIndex] = useState<number | null>(null);
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [schemaIndex, setSchemaIndex] = useState<number | null>(null);

  const [getCanBoughtNumber, { data: balanceData, loading: loadingBalanceData }] = useLazyQuery(
    BALANCE_QUERY,
    {
      onCompleted: () => {
        setModalIsOpen(true);
      },
      onError: () => {
        globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      },
      fetchPolicy: 'network-only',
    }
  );
  const userBalance = balanceData?.getBalance?.balance;

  const [buyMarketAssistant, { loading: loadingBuyMarketAssistant }] = useMutation(
    BUY_MARKET_ASSISTANT_MUTATION
  );
  const [buyIndividualAssistant, { loading: loadingBuyIndividualAssistant }] = useMutation(
    BUY_INDIVIDUAL_ASSISTANT_MUTATION
  );

  const loading = loadingBalanceData || loadingBuyIndividualAssistant || loadingBuyMarketAssistant;

  const isMarketAssistant = assistantType === scenarioTypes.Market;
  const isIndividualAssistant = assistantType === scenarioTypes.Individual;

  const selectedScenarioPrice =
    assistantType === scenarioTypes.Market
      ? marketScenarioData.monthPrice +
        (marketScenarioData.installationPrice || 0) * getMonthRatio()
      : 0;

  const canPay = typeof userBalance === 'number' && userBalance >= selectedScenarioPrice;

  function handleExampleStartPlay(index: number) {
    setActivePlayerIndex(index);
  }

  function handleCloseDrawer() {
    if (activePlayerIndex !== null) {
      setActivePlayerIndex(null);
    }
    setIsExampleDrawerOpen(false);
  }

  function handleCloseSchemaDialog() {
    setSchemaIndex(null);
  }

  const handleBuyMarketScenarioButtonClick = () => {
    buyMarketAssistant({
      variables: {
        data: {
          marketAssistantId: 1,
        },
      },
    })
      .then(() => {
        navigate('/scenarios-list');
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: { message: 'Error executing buyMarketAssistant mutation' },
        });
        globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      });
  };

  const handleBuyIndividualScenario = () => {
    buyIndividualAssistant()
      .then(() => {
        setModalIsOpen(true);
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: { message: 'Error executing buyIndividualAssistant mutation' },
        });
        globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      });
  };

  function handleConfirmButtonClick() {
    if (isExampleDrawerOpen) {
      handleCloseDrawer();
    }

    if (isIndividualAssistant) {
      handleBuyIndividualScenario();
    } else {
      getCanBoughtNumber();
    }
  }

  function handleCloseDialog() {
    setModalIsOpen(false);
  }

  function getModalTitle() {
    if (isMarketAssistant && canPay) {
      return translate('PAY_FOR_SCENARIO');
    }
    if (isMarketAssistant && !canPay) {
      return translate('NUMBER_BOUGHT_TITLE_NO_MONEY');
    }
    if (isIndividualAssistant) {
      return translate('APPLICATION_SENT');
    }
    return '';
  }

  const renderBuyMarketScenarioDialogBody = () => {
    if (typeof userBalance !== 'number') {
      return null;
    }

    return (
      <>
        <Typography>{translate('TARIFF_PAYMENT_PRICE')}</Typography>
        <div className={classes.marginTop025}>
          <Typography color="primary700" pxToEmSize={18}>
            {toPrecision(selectedScenarioPrice)} ₽
          </Typography>
        </div>

        <div className={classes.marginTop1}>
          <Typography>{translate('TARIFF_PAYMENT_BALANCE_AFTER')}</Typography>
        </div>
        <div className={classes.marginTop025}>
          <Typography color="tertiary600" pxToEmSize={18}>
            {toPrecision(userBalance - selectedScenarioPrice)} ₽
          </Typography>
        </div>

        <div className={classes.marginTop1}>
          <Typography color="tertiary600">
            {translate('YOU_WILL_BE_ABLE_TO_USE_SCENARIO_AFTER_AGREEING_WITH_MANAGER')}
          </Typography>
        </div>

        <ControlButtons
          confirmTitle={'CONFIRM'}
          cancelTitle={'CANCEL'}
          cancelVariant="secondary"
          onConfirmClick={handleBuyMarketScenarioButtonClick}
          onCancelClick={handleCloseDialog}
          loading={loadingBuyMarketAssistant}
          rootClass={classes.actionsContainer}
          justifyContent="start"
          flexDirection="row-reverse"
          small
        />
      </>
    );
  };

  const renderNotEnoughFundsDialogBody = () => (
    <>
      <Translate
        i18nKey="BUY_MARKET_SCENARIO_ERROR"
        components={{
          t: <Typography type={'text3'} color={'tertiary900'} />,
          l: <Typography type={'text3'} color={'primary700'} />,
          b: <br />,
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
          n: <a rel={'noreferrer'} href={'/cabinet/balance'} className={classes.aFontSize} />,
        }}
      />
      <div className={classes.actionsContainer}>
        <Button title={translate('CLOSE')} onClick={() => setModalIsOpen(false)} smallHeight />
      </div>
    </>
  );

  const renderBuyIndividualScenarioDialogBody = () => (
    <>
      <Typography type={'text3'} color={'tertiary900'}>
        {translate('MANAGER_WILL_CONTACT')}
      </Typography>
      <div className={classes.actionsContainer}>
        <Button
          title={translate('CLOSE')}
          onClick={() => navigate('/scenarios-list')}
          smallHeight
        />
      </div>
    </>
  );

  const renderModalContent = () => {
    if (isMarketAssistant && canPay) {
      return renderBuyMarketScenarioDialogBody();
    }
    if (isMarketAssistant && !canPay) {
      return renderNotEnoughFundsDialogBody();
    }
    if (isIndividualAssistant) {
      return renderBuyIndividualScenarioDialogBody();
    }
    return null;
  };

  const renderExampleScenariosCard = (el: IScenarioContent, index: number) => (
    <div className={classes.scenarioExampleCard} key={`scenarioCard-${index}`}>
      <div className={classes.scenarioExampleCardBlock}>
        <div className={classes.scenarioExampleCardTextContainer}>
          <div className={classes.scenarioExampleCardTitle}>
            <Typography type={'text3'} color={'tertiary900'} bold>
              {translate(el.title)}
            </Typography>
          </div>
          <Typography
            className={classes.scenarioExampleCardDescription}
            type={'text4'}
            color={'tertiary900'}
          >
            {translate(el.description)}
          </Typography>
        </div>
      </div>
      <div className={classes.scenarioExampleCardControlsContainer}>
        {activePlayerIndex !== index ? (
          <div className={classes.scenarioExampleCardRowContent}>
            <div className={classes.scenarioExampleCardButtonsBlock}>
              <div className={classes.scenarioExampleCardBtnWrapper}>
                <Button
                  size={'small'}
                  variant={'secondary'}
                  className={classes.audioListenSmallControlButton}
                  onClick={() => handleExampleStartPlay(index)}
                >
                  <PlayIcon />
                </Button>
                <Button
                  variant={'secondary'}
                  title={translate('SCHEMA')}
                  className={classes.schemaControlButton}
                  onClick={() => {
                    setSchemaIndex(index);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.scenarioExampleCardRowContent}>
            <AudioPlayer
              source={el.audio}
              isPaused={false}
              isExpanded
              collapseOnEnd={false}
              side={'left'}
              autoPlay
            />
            <XIcon className={classes.closeIcon} onClick={() => setActivePlayerIndex(null)} />
          </div>
        )}
      </div>
    </div>
  );

  const renderMarketScenarioCard = () => {
    return (
      <div
        className={clsx(
          classes.scenarioCardNew,
          assistantType === scenarioTypes.Market && classes.scenarioCardNewActive
        )}
      >
        <div className={classes.scenarioCardNewIconWrapper}>
          <GitPullRequestIcon />
        </div>

        <div className={classes.marginTop15}>
          <Typography pxToEmSize={18} medium>
            {translate(marketScenarioData.title)}
          </Typography>
        </div>
        <div className={classes.marginTop025}>
          <Typography color="tertiary600">{translate(marketScenarioData.description)}</Typography>
        </div>

        <Flex className={classes.marginTop15} justifyContent="spaceBetween">
          <Typography>{translate('MONTH_PRICE')}</Typography>
          <Typography pxToEmSize={16} medium>
            {translate('RUB_PER_MONTH2', {
              amount: toPrecision(marketScenarioData.monthPrice, false),
            })}
          </Typography>
        </Flex>

        <Flex className={classes.marginTop025} justifyContent="spaceBetween">
          <Typography>{translate('INSTALLATION_WORK_SHORT')}</Typography>
          <Typography pxToEmSize={16} medium>
            {toPrecision(marketScenarioData.installationPrice, false)} ₽
          </Typography>
        </Flex>

        <div className={classes.marginTop025}>
          <Typography color="tertiary600">
            {translate('AMOUNTS_ARE_INCLUSIVE_OF_VAT', { percent: VATPercent })}
          </Typography>
        </div>

        <div className={classes.scenarioCardNewBottomButtonsWrapper}>
          <Button
            className={classes.marginRight1}
            variant="secondary"
            onClick={() => setIsExampleDrawerOpen(true)}
          >
            <Typography>{translate('EXAMPLES')}</Typography>
          </Button>
          <RadioButton
            name="assistantType"
            heightPx={40}
            value={scenarioTypes.Market}
            label={translate('CHOOSE')}
            onClick={() => isExampleDrawerOpen && handleCloseDrawer()}
          />
        </div>
      </div>
    );
  };

  const renderIndividualScenarioCard = () => {
    return (
      <div
        className={clsx(
          classes.scenarioCardNew,
          classes.marginLeft1,
          assistantType === scenarioTypes.Individual && classes.scenarioCardNewActive
        )}
      >
        <div className={classes.scenarioCardNewIconWrapper}>
          <SlidersIcon />
        </div>

        <div className={classes.marginTop15}>
          <Typography pxToEmSize={18} medium>
            {translate(individualScenarioData.title)}
          </Typography>
        </div>
        <div className={classes.marginTop025}>
          <Typography color="tertiary600">
            {translate(individualScenarioData.description)}
          </Typography>
        </div>

        <Flex className={classes.marginTop15} justifyContent="spaceBetween">
          <Typography>{translate('PRICE')}</Typography>
          <Typography pxToEmSize={16} medium>
            {translate('FROM_RUB', {
              amount: toPrecision(individualScenarioData.monthPrice, false),
            })}
          </Typography>
        </Flex>

        <div className={classes.scenarioCardNewBottomButtonsWrapper}>
          <RadioButton
            name="assistantType"
            heightPx={40}
            value={scenarioTypes.Individual}
            label={translate('SUBMIT_APPLICATION')}
            onClick={() => isExampleDrawerOpen && handleCloseDrawer()}
          />
        </div>
      </div>
    );
  };

  const renderScenarioCardsNew = () => {
    return (
      <Flex>
        {renderMarketScenarioCard()}
        {renderIndividualScenarioCard()}
      </Flex>
    );
  };

  const renderExampleDrawer = () => (
    <Drawer
      wrapperClass={classes.drawerWrapper}
      contentClass={classes.drawerInternalContent}
      footerClass={classes.drawerFooter}
      title={'EXAMPLES'}
      variant={'persistent'}
      open={isExampleDrawerOpen}
      size={'md'}
      primaryButton={{
        title: 'CLOSE',
        onClick: handleCloseDrawer,
      }}
      onClose={handleCloseDrawer}
    >
      {BASIC_SCENARIOS.map(renderExampleScenariosCard)}
    </Drawer>
  );

  const renderExamplesScenarioContent = () => (
    <div className={classes.modalScenarioDialog}>
      <div className={classes.modalScenarioImageOverlay}>
        <img
          src={BASIC_SCENARIOS[schemaIndex || 0].schema}
          alt={BASIC_SCENARIOS[schemaIndex || 0].title}
        />
      </div>
      <Button title={translate('CLOSE')} onClick={handleCloseSchemaDialog} />
    </div>
  );

  return (
    <BodyContainer disableOverflow>
      <div className={classes.root}>
        <FormProvider {...formMethods}>
          <div className={classes.contentContainer}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('CHOOSE_SCENARIO_TITLE')}
            </Typography>
            <RadioGroup defaultValue={'none'} name={'assistantType'}>
              <div className={classes.formContent}>{renderScenarioCardsNew()}</div>
            </RadioGroup>
            <div className={classes.addScenarioActions}>
              <BottomButtons>
                <ControlButtons
                  justifyContent={'start'}
                  confirmTitle={'CONFIRM'}
                  cancelTitle={'CANCEL'}
                  cancelVariant={'text'}
                  cancelUnderline
                  onConfirmClick={handleConfirmButtonClick}
                  loading={loading}
                  onCancelClick={() => navigate('/scenarios-list')}
                  confirmDisable={!isMarketAssistant && !isIndividualAssistant}
                />
              </BottomButtons>
            </div>
          </div>
        </FormProvider>
      </div>
      {renderExampleDrawer()}
      <MessageDialog
        isOpen={schemaIndex !== null}
        title={`${translate('SCENARIO_SCHEMA')}: ${translate(
          BASIC_SCENARIOS[schemaIndex || 0].title
        )}`}
        contentClass={classes.modalContentContainer}
        onCancel={handleCloseSchemaDialog}
        renderContent={renderExamplesScenarioContent()}
        disableEnforceFocus
        paperClass={classes.dialogPaper}
      />
      <MessageDialog
        isOpen={isModalOpen}
        contentClass={isMarketAssistant ? classes.marketDialog : classes.individualDialog}
        onCancel={handleCloseDialog}
        title={getModalTitle()}
        renderContent={<FormProvider {...formMethods}>{renderModalContent()}</FormProvider>}
      />
    </BodyContainer>
  );
};
