import React, { useCallback, useState } from 'react';
import { FilterIcon } from '@shared/assets/images/icons';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import MessageDialog from '@shared/components/MessageDialog';
import CheckboxField from '@shared/components/CheckboxField';
import clsx from 'clsx';
import ControlButtons from '@shared/components/ControlButtons';
import { useNotificationsStyles } from '../SettingsNotifications.styles';
import { allowedSearchParamsValues } from '../SettingsNotifications.utils';

type NotificationsFilterParams = {
  selectAll?: boolean;
  domainNumber?: boolean;
  department?: boolean;
  employee?: boolean;
};

export const NotificationsStatusHeaderCell = () => {
  const classes = useNotificationsStyles();
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const entityFilter = searchParams
    .getAll('filter')
    .filter((value) =>
      allowedSearchParamsValues.includes(value as (typeof allowedSearchParamsValues)[number])
    );

  const formMethods = useForm<NotificationsFilterParams>({
    defaultValues: {
      selectAll: entityFilter.length === 0 || entityFilter.includes('all'),
      department: entityFilter.includes('department'),
      employee: entityFilter.includes('employee'),
      domainNumber: entityFilter.includes('domainNumber'),
    },
  });
  const { reset, handleSubmit, setValue } = formMethods;

  const computedClass = clsx(classes.defaultIcon, {
    [classes.filterActive]: entityFilter.length !== 0 && !entityFilter.includes('all'),
  });

  const setAllValuesToTrue = useCallback(() => {
    reset({
      selectAll: true,
      department: true,
      employee: true,
      domainNumber: true,
    });
  }, [reset]);

  const setAllValuesToFalse = () => {
    reset({
      selectAll: false,
      department: false,
      employee: false,
      domainNumber: false,
    });
  };

  const handleSelectAllCheckboxFieldClick = () => {
    const selectAllCurrentValue = !formMethods.getValues().selectAll;
    if (selectAllCurrentValue) {
      setAllValuesToTrue();
      return;
    }

    setAllValuesToFalse();
  };

  const handleFilterClick = () => {
    setOpen(true);

    if (entityFilter.length === 0 || entityFilter.includes('all')) {
      setAllValuesToTrue();
      return;
    }

    const formParams: NotificationsFilterParams = {
      selectAll: false,
      domainNumber: false,
      department: false,
      employee: false,
    };
    entityFilter.forEach((el: string) => {
      formParams[el as keyof NotificationsFilterParams] = true;
    });
    reset(formParams);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleSubmitForm = useCallback(
    ({ domainNumber, department, employee, selectAll }: NotificationsFilterParams) => {
      searchParams.delete('filter');
      if (selectAll) {
        searchParams.set('filter', 'all');
      } else {
        if (domainNumber) {
          searchParams.append('filter', 'domainNumber');
        }
        if (department) {
          searchParams.append('filter', 'department');
        }
        if (employee) {
          searchParams.append('filter', 'employee');
        }
      }
      setSearchParams(searchParams);
      setOpen(false);
    },
    [searchParams, setSearchParams]
  );

  const handleGetCheckboxFieldClickHandler =
    (fieldName: Exclude<keyof NotificationsFilterParams, 'selectAll'>) => () => {
      const { department, employee, domainNumber } = formMethods.getValues();
      const values: Omit<NotificationsFilterParams, 'selectAll'> = {
        department,
        employee,
        domainNumber,
      };

      switch (fieldName) {
        case 'department':
          values.department = !values.department;
          break;
        case 'employee':
          values.employee = !values.employee;
          break;
        case 'domainNumber':
          values.domainNumber = !values.domainNumber;
          break;
        default:
          fieldName satisfies never;
      }

      setValue(
        'selectAll',
        Object.values(values).every((value) => value)
      );
    };

  const renderIcon = () => (
    <FilterIcon
      className={computedClass}
      width={16}
      height={16}
      onClick={() => handleFilterClick()}
    />
  );

  return (
    <>
      {renderIcon()}
      <MessageDialog
        isOpen={open}
        onCancel={handleFilterClose}
        contentClass={classes.defaultElementWidth20}
        title={translate('FILTER_BY_TYPE')}
        renderContent={
          <FormProvider {...formMethods}>
            <form
              id={'notifications-filter'}
              action={''}
              onSubmit={handleSubmit(handleSubmitForm)}
              className={classes.form}
            >
              <CheckboxField
                className={classes.checkboxControl}
                name={'selectAll'}
                label={translate('SELECT_ALL')}
                onClick={handleSelectAllCheckboxFieldClick}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'domainNumber'}
                label={translate('NUMBERS')}
                onClick={handleGetCheckboxFieldClickHandler('domainNumber')}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'department'}
                label={translate('DEPARTMENTS')}
                onClick={handleGetCheckboxFieldClickHandler('department')}
              />
              <CheckboxField
                className={classes.checkboxControl}
                name={'employee'}
                label={translate('EMPLOYEES')}
                onClick={handleGetCheckboxFieldClickHandler('employee')}
              />
              <ControlButtons
                confirmTitle={'APPLY'}
                cancelTitle={'CANCEL'}
                cancelVariant="secondary"
                justifyContent={'start'}
                flexDirection={'row-reverse'}
                form={'notifications-filter'}
                onCancelClick={() => setOpen(false)}
                rootClass={classes.marginTop1}
                small
              />
            </form>
          </FormProvider>
        }
      />
    </>
  );
};
