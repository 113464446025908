import React from 'react';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import { useSearchParams } from 'react-router-dom';
import { EndUserModel } from '@/client/generated/graphql';

export const NameCell = ({ item: { name, surname, patronymic } }: ICellProps<EndUserModel>) => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('searchQuery');
  const nameArr = [];
  if (surname) {
    nameArr.push(surname);
  }
  if (name) {
    nameArr.push(name);
  }
  if (patronymic) {
    nameArr.push(patronymic);
  }

  return <HighlightedText query={searchQuery} text={nameArr.join(' ') || ''} type="text" />;
};
