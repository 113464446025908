import { makeStyles } from '@material-ui/core/styles';

export const useTabStyles = makeStyles(
  ({ color: { primary, tertiary } }) => ({
    clientDetailsTab: {
      backgroundColor: 'transparent',
      marginRight: '1em',
      padding: '0 0 0.5em 0',
      border: 'none',
      borderRadius: 0,
      borderBottom: 'solid 2px transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        borderBottom: `solid 2px ${tertiary[300]}`,
      },
    },
    clientDetailsTabActive: {
      borderBottom: `solid 2px ${primary[600]}`,
      '&:hover': {
        borderBottom: `solid 2px ${primary[600]}`,
      },
    },
  }),
  {
    index: 1,
  }
);
