import React, { useState } from 'react';
import { CheckboxProps, FormControlLabel } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import Switch from '@shared/components/Switch';
import clsx from 'clsx';
import { useSwitchFieldStyles } from './SwitchField.styles';

export type SwitchFieldProps = Omit<CheckboxProps, 'defaultValue'> & {
  label?: string;
  defaultValue?: boolean;
  customLabel?: React.ReactNode;
  onChanges?: (checked: boolean) => void;
  readOnly?: boolean;
  classNameFieldRoot?: string;
};

export const SwitchField = ({
  name = '',
  label = '',
  customLabel,
  defaultValue = false,
  onChanges = undefined,
  readOnly,
  disabled,
  classNameFieldRoot,
  ...props
}: SwitchFieldProps) => {
  const classes = useSwitchFieldStyles();
  const { control, watch } = useFormContext() || {};
  const currentValue = watch ? watch(name) : undefined;
  const defaultControlValue = currentValue || defaultValue;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultControlValue}
      render={({ field: { value, onChange, ref } }) => (
        <FormControlLabel
          className={clsx(classes.root, classNameFieldRoot)}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          control={
            <Switch
              disabled={readOnly || disabled}
              color={'primary'}
              defaultValue={value || defaultControlValue}
              onChange={(e) => {
                onChange(e.target.checked);
                if (typeof onChanges === 'function') {
                  onChanges(e.target.checked);
                }
              }}
              inputRef={ref}
              checked={defaultControlValue ?? value}
              classes={{
                track: clsx(
                  isHovered && !disabled && !currentValue && classes.trackHovered,
                  isHovered && !disabled && currentValue && classes.checkedTrackHovered
                ),
              }}
              {...props}
            />
          }
          label={customLabel || label}
          classes={{ label: classes.label }}
          defaultValue={defaultControlValue}
        />
      )}
    />
  );
};

export default SwitchField;
