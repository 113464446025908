import React, { useEffect, useMemo, useState } from 'react';
import Tag from '@components/Tag';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import QuickSearch from '@components/QuickSearch';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { DOMAIN_TEL_NUMBERS_QUERY, USER_QUERY } from '@/client/queries';
import PagePreloader from '@components/PagePreloader';
import PageError from '@shared/components/PageError';
import { CommercialDomainStatuses } from '@components/typings/interfaces';
import DrawerReserveNumbers from '@/components/DrawerReserveNumbers/DrawerReserveNumbers';
import { useEnabledDomainStatus } from '@/common/hooks';
import { PhoneIcon } from '@shared/assets/images/icons';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import LazyTable, { CellPreloader, ContentHint, IColumn } from '@components/LazyTable';
import BodyContainer from '@/layouts/BodyContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Typography from '@shared/components/Typography';
import Translate from '@shared/components/Translate';
import { getCurrentClient } from '@/utils';
import { observable } from '@legendapp/state';
import { useNumbersStyle } from './PhoneNumbers.styles';
import {
  NumberStatusCell,
  NumberTypeCell,
  NumberActionsCell,
  NumberCostCell,
  NumberCell,
  NumberPerMonthCell,
} from './modules';
import {
  DomainNumber,
  PhoneNumberFormFields,
  PhoneNumbersContext,
} from './PhoneNumbers.interfaces';
import NumbersTabs from '../modules/NumbersTabs';
import DialogSelectResponsible from './DialogSelectResponsible';
import { NumberResponsibleCell } from './modules/NumberResponsibleCell';
import DialogShouldSelectResponsibleInfo from './DialogShouldSelectResponsibleInfo';

const getContextDefaultValues = () => ({
  isDialogSelectResponsibleOpen: false,
  isDialogShouldSelectResponsibleOpen: false,
  phoneNumber: '',
  domainNumberId: -1,
  additionalNotification: '',
});

export const phoneNumbersContext$ = observable<PhoneNumbersContext>(getContextDefaultValues());

export const resetPhoneNumbersContext = () => {
  phoneNumbersContext$.set(getContextDefaultValues());
};

const PhoneNumbers = () => {
  const classes = useNumbersStyle();
  const [translate] = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [openMenu, setMenuOpen] = useState(false);

  const sSearch = searchParams.get('search');
  const sGroup = searchParams.get('group');
  const sReserve = searchParams.get('reserve');

  const formMethods = useForm<PhoneNumberFormFields>({
    defaultValues: {
      search: sSearch,
      endUserId: null,
    },
  });
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { isModerated } = getCurrentClient(userData?.user) || {};

  const { loading = false, error = false, data } = useQuery(DOMAIN_TEL_NUMBERS_QUERY);
  const tableData = useMemo(() => {
    if (!data?.getNumbers) {
      return [];
    }

    let numbers = data.getNumbers;
    if (sSearch) {
      // реализован поиск только по номеру
      const searchString = sSearch.replace(/\W/g, '');
      numbers = numbers?.filter((number) => {
        const incomingString = number.phone.replace(/\W/g, '');
        return incomingString.includes(searchString);
      });
    }
    return numbers;
  }, [data, sSearch]);

  useEffect(() => {
    if (sReserve) {
      setMenuOpen(true);
    }
  }, [sReserve]);

  useEffect(() => {
    const cleanup = () => {
      resetPhoneNumbersContext();
    };
    return cleanup;
  }, []);

  useEnabledDomainStatus(CommercialDomainStatuses);

  const columns: IColumn<DomainNumber>[] = useMemo(
    () => [
      { id: 'phone', label: 'NUMBER', minWidth: '10.5em', Renderer: NumberCell },
      {
        id: 'type',
        label: 'TYPE',
        Renderer: NumberTypeCell,
      },
      {
        id: 'cost',
        label: 'COST',
        align: 'left',
        Renderer: NumberCostCell,
      },
      {
        id: 'price',
        label: 'MONTH_PRICE',
        align: 'right',
        Renderer: NumberPerMonthCell,
        className: classes.paddingRight2,
        classNameHeader: classes.paddingRight2,
        maxWidth: '7em',
      },
      {
        id: 'responsible',
        label: 'RESPONSIBLE',
        align: 'left',
        Renderer: NumberResponsibleCell,
      },
      {
        id: 'status',
        label: 'STATUS',
        Renderer: NumberStatusCell,
        maxWidth: '7em',
      },
      { id: 'actions', align: 'right', label: '', Renderer: NumberActionsCell },
    ],
    [classes]
  );

  function handleCloseTag() {
    searchParams.delete('search');
    searchParams.delete('group');
    setSearchParams(searchParams);
  }

  function handlePhoneNumberChooseMenuClose() {
    searchParams.delete('reserve');
    searchParams.delete('code');
    setSearchParams(searchParams);
    setMenuOpen(false);
  }

  function handleBookPhoneClick() {
    setMenuOpen(true);
  }

  const renderSearchLine = () => {
    if (sGroup && sSearch) {
      return (
        <Tag
          text={sGroup === 'phone' ? formatPhone(sSearch) : sSearch}
          onClose={() => handleCloseTag()}
        />
      );
    }

    return <QuickSearch parameterName={'search'} />;
  };

  const renderEmptyContent = () => {
    if (!sSearch && !tableData?.length) {
      return (
        <ContentHint isContentCenter>
          <PhoneIcon className={classes.phoneNumberIcon} />
          <Typography type={'text3'} color={'tertiary600'}>
            <Translate i18nKey={'NO_PBX_NUMBERS'} />
          </Typography>
        </ContentHint>
      );
    }
    return (
      <CellPreloader>
        <Typography color={'tertiary900'} type={'text3'}>
          {translate('NOTHING_FOUND')}
        </Typography>
      </CellPreloader>
    );
  };

  const renderNumbersContent = () => {
    if (loading) return <PagePreloader />;
    if (error) return <PageError />;
    return (
      <FormProvider {...formMethods}>
        <Flex direction={'column'} className={classes.root}>
          <div className={classes.head}>
            <NumbersTabs isPhoneNumbersTabActive />
            <div className={classes.headRight}>
              <Typography color={'tertiary600'} type={'text4'}>{`${translate('TOTAL_NUMBERS')}: ${
                tableData.length
              }`}</Typography>
              <div className={classes.lineItem}>{renderSearchLine()}</div>
              <div className={classes.lineItem}>
                <Button
                  variant={'primary'}
                  title={translate('BOOK_A_PHONE')}
                  className={classes.actionButton}
                  disabled={isModerated === false}
                  onClick={handleBookPhoneClick}
                />
              </div>
            </div>
          </div>
          <LazyTable<DomainNumber>
            columns={columns}
            data={tableData || []}
            emptyDataMessage={translate('NOTHING_FOUND')}
            renderEmptyDataMessage={renderEmptyContent()}
          />
          <DrawerReserveNumbers open={openMenu} onClose={handlePhoneNumberChooseMenuClose} />
          <DialogSelectResponsible />
          <DialogShouldSelectResponsibleInfo />
        </Flex>
      </FormProvider>
    );
  };

  return <BodyContainer disableOverflow>{renderNumbersContent()}</BodyContainer>;
};

export default PhoneNumbers;
