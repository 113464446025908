import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useNumbersStyle = makeStyles(
  ({ color: { primary, success } }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    lineItem: {
      paddingLeft: '1em',
    },
    numberDiscount: {
      paddingLeft: '0.3em',
      color: `${primary[700]}`,
    },
    numberSecondaryText: {
      marginTop: '0.5em',
    },
    numberActionsContent: {
      paddingTop: '0.1em',
      display: 'flex',
      alignItems: 'center',
      flexFlow: 'row-reverse nowrap',
      '& > :not(:last-child)': {
        marginLeft: '1em',
      },
    },
    numberActionsDialogPaper: {
      maxWidth: '24em',
    },
    numberActionsDialogContent: {
      textAlign: 'left',
      whiteSpace: 'pre-line',
    },
    numberActionsDialogActions: {
      paddingTop: '2em',
      textAlign: 'right',
      justifyContent: 'flex-end',
    },
    numberActionsActionButton: {
      height: '2em',
      width: '2em',
      padding: 'initial',
    },
    numberActionsActionButtonText: {
      height: '2em',
      minWidth: '6.25em',
    },
    actionButton: {
      width: 'initial',
      height: '2em',
    },
    noNumbers: {
      position: 'absolute',
      top: '12em',
      height: '15em',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    phoneNumberIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    head: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1em 2em 1em 2em',
    },
    headRight: {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'flex-end',
    },
    banner: {
      borderRadius: '1em',
      backgroundColor: `${success[100]}`,
      height: '3em',
      padding: '0.5em 1.5em 0.375em 1em',
    },
    gift: {
      color: `${primary[700]}`,
      marginRight: '1em',
      marginTop: '0.375em',
    },
    paddingRight2: {
      paddingRight: '2em',
    },
    ...styleConstants,
  }),
  {
    index: 5,
  }
);
