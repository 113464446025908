import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_CAROUSEL_EMPLOYEES_LIST_MUTATION } from '@/client/mutations';
import { GET_CAROUSEL_EMPLOYEES_LIST, GET_SELECTED_TARIFF_QUERY } from '@/client/queries';
import { useNavigate } from 'react-router-dom';
import EmployeesSelection from '@/components/EmployeesSelection/EmployeesSelection';
import { Employee } from '@/components/EmployeesSelection';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import SwitchField from '@shared/components/SwitchField';
import { CarouselIcon, TrashIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import PagePreloader from '@components/PagePreloader';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import { TariffOptionType } from '@/client/generated/graphql';
import { isOptionAvailable } from '@/common/hooks';
import { SettingsPageTemplate } from '../SettingsPageTemplate';
import { useSettingsPagesStyles } from '../SettingsPage.styles';

interface IFormFields {
  carousel?: boolean;
}

export const SettingsCarousel = () => {
  const [translate] = useTranslation();
  const classes = useSettingsPagesStyles();
  const formMethods = useForm<IFormFields>();
  const navigate = useNavigate();

  const { data: tariffData, loading: loadingOptions } = useQuery(GET_SELECTED_TARIFF_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const { handleSubmit } = formMethods;

  const [state, setState] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const options = tariffData?.getDomain.tariff?.options.map((option) => option.type) ?? [];
  const isCarouselActivated = isOptionAvailable(TariffOptionType.Carousel, options);

  const { loading: loadingCarouselEmployees, refetch } = useQuery(GET_CAROUSEL_EMPLOYEES_LIST, {
    onCompleted: (data) => {
      if (isCarouselActivated) {
        const carouselEmployees: Employee[] =
          data?.getCarouselEmployeesList.map((employee) => ({
            id: employee.employeeId,
            isActive: employee.active,
            ext: employee.ext,
            user: { name: employee.name },
          })) ?? [];
        setEmployees(carouselEmployees);
      }
    },
  });
  const [updateCarouselEmployees, { loading }] = useMutation(
    UPDATE_CAROUSEL_EMPLOYEES_LIST_MUTATION
  );

  const getBlockedPath = (path: string) => {
    setBlockedPath(path);
  };

  const handleCancelChanges = () => {
    setState({
      isBlocking: false,
      action: ConfirmAction.Cancel,
    });
  };

  const selectEmployee = (items: Employee[]) => {
    setEmployees([...employees, ...items.map((item) => ({ ...item, isActive: true }))]);
    setState({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  };

  const handleCarouselFormSubmit = () => {
    if (loading) return;
    setState({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    updateCarouselEmployees({
      variables: {
        input: {
          employees: employees.map((employee) => ({
            employeeId: employee.id,
            active: Boolean(employee.isActive),
          })),
        },
      },
    })
      .then(() => {
        navigate(blockedPath || '/settings');
      })
      .catch((error) => {
        const messageFromError = getErrorMessageFromGraphqlError(error);
        globalNotification$.show('danger', messageFromError ?? 'SOMETHING_WENT_WRONG');
        refetch();
      });
  };

  const deselectEmployee = useCallback(
    (id: number) => () => {
      setEmployees(employees.filter((employee) => employee.id !== id));
      setState({
        isBlocking: true,
        action: ConfirmAction.Edit,
      });
    },
    [employees]
  );

  const handleToggleActive = (id: number) => () => {
    setEmployees((prev) =>
      prev.map((item) => (item.id === id ? { ...item, isActive: !item.isActive } : item))
    );
    setState({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  };

  useEffect(() => {
    if (state.action === ConfirmAction.Cancel && !state.isBlocking) {
      navigate('/settings');
    }
  }, [state, navigate]);

  const renderEmployeeRow = () => {
    const sortedEmployees = employees.sort((a, b) => {
      if (!a.isActive && b.isActive) {
        return 1;
      }
      if (a.isActive && !b.isActive) {
        return -1;
      }
      return 0;
    });

    return sortedEmployees.map(({ id, isActive, user, ext }) => (
      <Flex key={id}>
        <SwitchField checked={Boolean(isActive)} onChange={handleToggleActive(id)} />
        <Flex className={classes.carouselNameRow}>
          <Typography type={'text3'} color={'tertiary900'}>
            {user.name ?? ''}
          </Typography>
          <Typography type={'text3'} color={'tertiary900'}>
            {ext ?? ''}
          </Typography>
        </Flex>
        <Button
          className={classes.carouselTrashButton}
          variant="secondary"
          color="error"
          onClick={deselectEmployee(id)}
        >
          <TrashIcon />
        </Button>
      </Flex>
    ));
  };

  const renderContent = () => {
    if (loadingCarouselEmployees || loadingOptions) {
      return <PagePreloader />;
    }
    if (!isCarouselActivated) {
      return (
        <Flex className={classes.carouselNotification}>
          <div className={classes.carouselIconBlue}>
            <CarouselIcon />
          </div>
          <Flex className={classes.carouselNotificationTextWrap}>
            <Typography medium>{translate('NUMBERS_CAROUSEL')}</Typography>
            <Typography>{translate('CAROUSEL_SELL_NOTIFICATION')}</Typography>
          </Flex>
        </Flex>
      );
    }
    return (
      <>
        <div className={classes.employeesListTitle}>
          <Typography type={'text3'} medium>
            {translate('CAROUSEL_EMPLOYEES')}
          </Typography>
        </div>
        <FormProvider {...formMethods}>
          <form
            className={classes.form}
            onSubmit={handleSubmit(handleCarouselFormSubmit)}
            id={'settings-carousel'}
          >
            <Flex className={classes.employeesWrap}>
              {renderEmployeeRow()}
              <Button
                title={translate('ADD_EMPLOYEES')}
                variant={'secondary'}
                fullWidth
                className={clsx(classes.addEmployee, {
                  [classes.addEmployeeOpen]: open,
                  [classes.addEmployeeEmptyList]: employees.length === 0,
                })}
                onClick={() => setOpen(true)}
              />
            </Flex>
          </form>
          <EmployeesSelection
            open={open}
            onClose={() => setOpen(false)}
            selected={employees}
            onSelect={selectEmployee}
          />
          <ConfirmDialog
            isBlocked={state.isBlocking}
            onNavigationBlocked={getBlockedPath}
            onSaveChanges={handleSubmit(handleCarouselFormSubmit)}
          />
        </FormProvider>
      </>
    );
  };

  return (
    <SettingsPageTemplate
      form={'settings-carousel'}
      title={'NUMBERS_CAROUSEL'}
      onCancel={handleCancelChanges}
      isLoading={loading}
    >
      <Typography color={'tertiary600'} type={'text3'}>
        {translate('DESCRIPTION_CAROUSEL')}
      </Typography>
      {renderContent()}
    </SettingsPageTemplate>
  );
};
