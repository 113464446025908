import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';
import { pxToEm } from '@shared/utils/styles';

export const useEditIncomingNumberStyles = makeStyles(
  ({ color: { base, primary, warning, tertiary, success } }) => ({
    root: {
      width: '100%',
      height: '100%',
      background: base,
    },
    actionsBtns: {
      paddingTop: '2em',
      textAlign: 'right',
    },
    actions: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: 'fit-content',
      '& button': {
        marginTop: '0.5em',
      },
      '& > :not(:first-child)': {
        marginLeft: '1em',
      },
    },
    collapseHeader: {
      display: 'flex',
      width: '100%',
      maxWidth: '69em',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      padding: '0 2.25em 0 0',
    },
    confirmButton: {
      width: '12em',
      height: '2.5em',
    },
    contentBottomSpace: {
      bottom: '5em',
    },
    copyBtn: {
      marginLeft: '0.5em',
      padding: '0.5em 2em',
    },
    copyDialog: {
      width: '28.5em',
    },
    copyDialogText: {
      textAlign: 'left',
      paddingBottom: '1em',
    },
    copyText: {
      marginRight: '0.5em',
    },
    customOption: {
      display: 'flex',
      flexFlow: 'column nowrap',
      pointerEvents: 'none',
      height: '3.25em',
      justifyContent: 'center',
      width: '100%',
      padding: '0.75em 2.5em 0.75em 1em',
    },
    customOptionFlexMarginLeft: {
      marginLeft: '1.5em',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
      margin: '0 0 0 2em',
      paddingRight: '1em',
      overflow: 'auto',
    },
    nameFieldWrapper: {
      width: '14em',
      marginBottom: '2em',
      marginTop: '1em',
    },
    atcSelectFieldWrapper: {
      width: '14em',
      margin: '1em 0',
    },
    formErrors: {
      margin: '2em 0',
    },
    glyph: {
      marginTop: '-0.25em',
      marginRight: '0.5em',
      color: primary[700],
    },
    integrationDisabledIcon: {
      position: 'absolute',
      width: '0.75em',
      height: '0.75em',
      bottom: 0,
      right: 0,
      color: warning[600],
      backgroundColor: base,
      borderRadius: '50%',

      '& svg': {
        width: '10px',
        height: '10px',
      },
    },
    integrationIcon: {
      borderRadius: '6px',
      height: '100%',
      width: '100%',

      '& > svg': {
        width: '1.5em',
        height: '1.5em',
      },
    },
    integrationIconDisabled: {
      opacity: 0.3,
    },
    integrationIconWrapper: {
      position: 'relative',
      width: '2em',
      height: '2em',
      marginRight: '1em',
      boxSizing: 'border-box',
    },
    integrationListDivider: {
      height: '2em',
      width: '1px',
      backgroundColor: tertiary[200],
      marginRight: '1em',
    },
    integrationResponsible: {
      paddingBottom: '2em',
    },
    integrationWrapper: {
      padding: '1em 0',
    },
    numberName: {
      maxWidth: '25em',
      marginTop: '0.375em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    responsibleSettingText: {
      whiteSpace: 'pre-wrap',
    },
    section: {
      display: 'flex',
      flexFlow: 'column',
      marginTop: '2em',
    },
    title: {
      marginTop: '1em',
    },
    workCalendarInfoAlert: {
      width: pxToEm(720),
      marginBottom: '2em',
    },
    workCalendarLinkButton: {
      maxWidth: 'unset',
      padding: 0,
    },
    success600: {
      color: success[600],
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
