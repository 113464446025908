import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowBackIcon,
  DownloadIcon,
  InfoIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  WalletIcon,
  XIcon,
} from '@shared/assets/images/icons';
import { createSearchParams, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { getPrivateRoutesMap } from '@/routes';
import { AUTHENTICATION_STATE_QUERY } from '@components/client/queries';
import {
  CLIENT_QUERY,
  BALANCE_QUERY,
  INCOMING_NUMBER_PHONE_QUERY,
  USER_QUERY,
} from '@/client/queries';
import { useMutation, useQuery } from '@apollo/client';
import Button from '@shared/components/Button';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { toPrecision, formatPhone } from '@components/utils';
import { useRoutes } from '@components/Routes';
import { isFeatureAvailable, useDomainFeatures } from '@/common/hooks';
import { DomainStatus, IDomain } from '@components/typings/interfaces';
import { getCurrentDomain, getRole, hasFinancialRole, hasSystemRole } from '@/utils';
import Preloader from '@shared/components/Preloader';
import Flex from '@shared/components/Flex';
import Avatar from '@shared/components/Avatar';
import { GET_ACCESS_TO_DOMAIN_MUTATION } from '@/client/mutations';
import {
  ContractIsCheckedImage,
  DataIsBeingCheckedImage,
  DataIsCheckedImage,
  FillingTheContractImage,
} from '@components/assets/images';
import * as Sentry from '@sentry/react';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import { useSelector } from '@legendapp/state/react';
import { format } from 'date-fns';
import { BalanceDialog } from '@/components/BalanceDialog';
import { Role } from '@/client/generated/graphql';
import { useDashboardHeaderStyles } from './DashboardHeader.styles';
import { menuItems, sysUserMenuNavItems, userMenuNavItems } from './DashboardHeader.constants';
import DashboardNotificationBlock from './DashboardNotificationBlock';
import layoutsContext from '../layoutsContext';

export const DashboardHeader = () => {
  const { data: userData, loading } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const docsFlags = userData?.user.domains.find((domain) => domain.current)?.params?.docs;

  const { data: clientData, loading: loadingClientData } = useQuery(CLIENT_QUERY, {
    fetchPolicy: 'cache-first',
  });
  const isModerated = clientData?.getClient.isModerated;

  const { data: dataAuthStateQuery } = useQuery<{
    role?: string;
  }>(AUTHENTICATION_STATE_QUERY);
  const role = getRole(dataAuthStateQuery?.role);
  const isSystemRoleOrOwner = role !== Role.User && role !== Role.Operator && role !== Role.Admin;

  const [openMenu, setMenuOpen] = useState(false);
  const {
    data: getBalanceData,
    loading: balanceLoading,
    refetch: refetchBalance,
    error: balanceError,
  } = useQuery(BALANCE_QUERY, { fetchPolicy: 'cache-and-network', pollInterval: 600000 }); // refetch every 10 minutes

  const balanceInfo = getBalanceData?.getBalance;
  const balance = balanceInfo?.balance || 0;
  const credit = balanceInfo?.credit || 0;
  const promisedPaymentAvailability = balanceInfo?.isPromisedPaymentAvailable;
  const burntDate = balanceInfo?.burntDate;
  const unknownBalance = balanceInfo?.balance === undefined;

  const currentDomain = useMemo(() => getCurrentDomain(userData?.user), [userData?.user]);
  const account = useMemo(() => currentDomain?.client?.account, [currentDomain]);
  const status = currentDomain?.status;
  const clientType = currentDomain?.client?.type;
  const [getAccessToDomain] = useMutation(GET_ACCESS_TO_DOMAIN_MUTATION);

  const [translate] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const dashboardHeaderHeight = useSelector(layoutsContext.dashboardHeaderHeight);

  const classes = useDashboardHeaderStyles({ rootHeight: dashboardHeaderHeight });

  const { route, path } = useRoutes(getPrivateRoutesMap({ clientType }));
  const [title, setTitle] = useState<string>(translate(route?.titleCode || ''));
  const [isProfileBalloonOpen, setIsProfileBalloonOpen] = useState(false);
  const [isFillBalanceDialogOpen, setFillBalanceDialogOpen] = useState(false);
  const [isPromisePaymentDialogOpen, setPromisePaymentDialogOpen] = useState(false);

  useQuery(INCOMING_NUMBER_PHONE_QUERY, {
    variables: { data: { id: parseInt(path.identifier || '0', 10) } },
    fetchPolicy: 'cache-and-network',
    skip: !(path.category === 'calls' && path.subcategory === 'incoming' && path.identifier),
    onCompleted: (data) => {
      if (data?.getNumber?.phone) {
        const titleSuffix = formatPhone(data?.getNumber?.phone);
        setTitle(`${translate(route?.titleCode || '')} ${titleSuffix}`);
      }
    },
  });

  const { features } = useDomainFeatures();

  const isDocumentsAvailable = useMemo(() => isFeatureAvailable('documents', features), [features]);

  const financialMenuAvailable = useMemo(() => hasFinancialRole(role), [role]);
  const isSystemUser = useMemo(() => hasSystemRole(role), [role]);

  useEffect(() => {
    setTitle(`${translate(route?.titleCode || '')}`);
  }, [route, translate]);

  useEffect(() => {
    if (openMenu) {
      refetchBalance();
    }
  }, [openMenu, refetchBalance]);

  function handleMenuOpen() {
    setMenuOpen(true);
  }

  function handleMenuClickOutside() {
    setMenuOpen(false);
  }

  function handleUserBalloonClickOutside() {
    setIsProfileBalloonOpen(false);
  }

  function handlePBXLogin(domain: IDomain) {
    getAccessToDomain({ variables: { domainId: domain.id } }).then(({ data }) => {
      const token = data?.getAccessToDomain?.token;

      if (!token) {
        Sentry.captureException(
          Error(
            `Cannot login to pbx because getAccessToDomain mutation has no token. Received: ${token}`
          )
        );
        globalNotification$.show('warn', 'SOMETHING_WENT_WRONG');
        return;
      }

      const params = createSearchParams({
        token,
      });

      const refreshToken = data.getAccessToDomain.refreshToken;
      if (refreshToken) {
        params.append('refreshToken', refreshToken);
      }

      window.open(
        `${
          process.env.NODE_ENV === 'production'
            ? `https://${domain.domain}`
            : `http://${process.env.HOST}:${process.env.PORT}`
        }?${params}`,
        '_blank',
        'noopener'
      );
    });
  }

  const getAvatarUrl = (avatar?: string | null): string | undefined => {
    if (avatar) {
      return `${process.env.API_URL}/files/avatar/${avatar}`;
    }
    return undefined;
  };

  const renderUserData = () => {
    if (loading || loadingClientData) {
      return <Preloader size={'medium'} />;
    }
    if (userData?.user) {
      const { name = '', email = '', avatar = '' } = userData?.user || {};
      return (
        <>
          <Flex direction={'column'} className={classes.userNameWrapper}>
            <Typography className={classes.textOverflow} type={'text3'} color={'tertiary900'}>
              {name || email || 'profile'}
            </Typography>
            <Typography
              type={'text4'}
              color={isSystemUser ? 'primary600' : 'tertiary600'}
              bold={isSystemUser}
            >
              {currentDomain?.domain || ''}
            </Typography>
          </Flex>
          {!isSystemUser && (
            <Avatar
              url={getAvatarUrl(avatar)}
              className={classes.userAvatar}
              name={name || undefined}
              showInitials
              size={'small'}
            />
          )}
          {!isProfileBalloonOpen ? (
            <ChevronDownIcon className={classes.marginLeft05} />
          ) : (
            <ChevronUpIcon className={classes.marginLeft05} />
          )}
        </>
      );
    }
    return null;
  };

  const renderMenuItems = () => {
    return menuItems
      .filter((item) => {
        if (!status) {
          return false;
        }
        if (
          item.availableIn &&
          (status === DomainStatus.PreDeleted && currentDomain?.lastStatus
            ? !item.availableIn.includes(currentDomain?.lastStatus)
            : !item.availableIn.includes(status))
        ) {
          return false;
        }
        if (item.feature && !isFeatureAvailable(item.feature, features)) {
          return false;
        }
        return true;
      })
      .map((item, index) => (
        <div className={classes.linkWrapper} key={index}>
          <NavLink
            onClick={() => setMenuOpen(false)}
            to={item.path}
            className={classes.link}
            key={index}
          >
            <div className={classes.iconWrapper}>{item.icon}</div>
            <Typography type={'text3'} color={'base'}>
              {translate(item.title)}
            </Typography>
          </NavLink>
        </div>
      ));
  };

  const renderUserMenuBalloon = () => {
    const { domains = [] } = userData?.user || {};

    if (!isProfileBalloonOpen) {
      return null;
    }

    return (
      <ClickAwayListener onClickAway={handleUserBalloonClickOutside}>
        <div
          className={clsx(classes.userMenuBalloonWrapper, {
            [classes.userMenuWithFinancialMenu]: financialMenuAvailable,
            [classes.userMenuWithoutFinancialMenu]: !financialMenuAvailable,
          })}
        >
          <Flex className={classes.userInfoContainer}>
            <Typography className={classes.userInfoText} type={'text4'} color={'tertiary600'}>
              {`${translate('CURRENT_PBX')}:`}
            </Typography>
            <Typography className={classes.userInfoText} type={'text3'} color={'link600'} medium>
              {userData?.user?.domains.map((el) => !!el.current && el.domain)}
            </Typography>
          </Flex>
          {!isSystemUser && domains.length > 1 && (
            <Flex className={classes.userAvailableAtcContainer} direction={'column'}>
              <Typography
                className={classes.userAvailableAtsTitle}
                type={'text4'}
                color={'tertiary600'}
              >
                {`${translate('AVAILABLE_PBX')}:`}
              </Typography>
              {domains
                .filter((domain) => domain.id !== currentDomain?.id)
                .map((domain, index) => (
                  <Button
                    clear
                    className={classes.userBalloonItemRow}
                    key={`available-ats-${index}`}
                    onClick={() => handlePBXLogin(domain)}
                  >
                    <DownloadIcon className={classes.userBalloonAvailableIcon} />
                    <Typography className={classes.userDomain} type={'text3'} color={'tertiary900'}>
                      {domain.domain}
                    </Typography>
                  </Button>
                ))}
              {/*
                //TODO Заготовка для кнопки добавить ATC
                <Typography className={classes.userAddAtsBtn}>{translate('ADD_PBX')}</Typography>
                */}
            </Flex>
          )}
          <Flex direction={'column'}>
            <Flex className={classes.userBalloonItemRows}>
              {(isSystemUser ? sysUserMenuNavItems : userMenuNavItems).map((item, i) => (
                <Button
                  clear
                  onClick={() => {
                    if (item.external) {
                      window.open(item.path);
                      return;
                    }
                    navigate(item.path);
                  }}
                  className={classes.userBalloonItemRow}
                  key={`user-menu-item-${i}`}
                >
                  <div
                    className={clsx(classes.userBalloonIcon, {
                      [classes.userBalloonLogoutIcon]: !!item.logOut,
                    })}
                  >
                    {item.icon}
                  </div>
                  <Typography type={'text3'} color={item.logOut ? 'danger600' : 'tertiary900'}>
                    {translate(item.title)}
                  </Typography>
                </Button>
              ))}
            </Flex>
          </Flex>
        </div>
      </ClickAwayListener>
    );
  };

  const getPathToCurrentContractFillStep = () => {
    if (!docsFlags || !docsFlags.passport) {
      return '/register/personal-data';
    }
    if (!docsFlags.tariff) {
      return '/register/select-tariff';
    }
    if (!docsFlags.numbers) {
      return '/register/phone-number';
    }
    if (!docsFlags.assistants) {
      return '/register/scenario';
    }
    return '/register/confirm';
  };

  const renderGoToContractDataButton = (buttonTitle: string) => {
    if (location.pathname.startsWith('/register')) {
      return null;
    }

    return (
      <Button
        title={buttonTitle}
        className={classes.button}
        onClick={() => {
          navigate(getPathToCurrentContractFillStep());
        }}
      />
    );
  };

  const renderDemoBlockContents = () => {
    switch (isModerated) {
      case null:
      case undefined: {
        return (
          <>
            <FillingTheContractImage className={classes.contractStatusIcon} />
            <Typography type={'text3'}>{translate('CONTRACT_DESCRIPTION')}</Typography>
          </>
        );
      }
      case false: {
        return (
          <>
            <DataIsBeingCheckedImage className={classes.contractStatusIcon} />
            <Typography type={'text3'}>{translate('DATA_IS_BEING_CHECKED_DESC')}</Typography>
          </>
        );
      }
      case true: {
        return (
          <>
            <DataIsCheckedImage className={classes.contractStatusIcon} />
            <Typography type={'text3'}>{translate('DATA_IS_CHECKED_DESC')}</Typography>
          </>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderMessageType = () => {
    if (!isDocumentsAvailable) {
      return <div />;
    }
    if (status === DomainStatus.Demo) {
      return (
        <div className={classes.menuBottomContainerDemoAndModerating}>
          {renderDemoBlockContents()}
          {renderGoToContractDataButton(translate('EXECUTE_CONTRACT'))}
          <a
            rel={'noreferrer'}
            target={'_blank'}
            href={'/Договор оферты.pdf'}
            className={classes.downloadTemplateLink}
          >
            <DownloadIcon style={{ fill: '#0073FA' }} />
            <Typography type={'text3'} color={'link600'}>
              {translate('DOWNLOAD_CONTRACT_TEMPLATE')}
            </Typography>
          </a>
        </div>
      );
    }
    if (status === DomainStatus.Moderating) {
      return (
        <div className={classes.menuBottomContainerDemoAndModerating}>
          <DataIsBeingCheckedImage className={classes.contractStatusIcon} />
          <Typography type={'text3'}>{translate('CONTRACT_DESCRIPTION_MODERATING')}</Typography>
          <Typography type={'text3'}>{translate('CONTRACT_DESCRIPTION_MODERATING_2')}</Typography>
        </div>
      );
    }
    if (status === DomainStatus.Confirmed) {
      return (
        <div className={classes.menuBottomContainer}>
          <ContractIsCheckedImage className={classes.contractStatusIcon} />
          <Typography type={'text3'}>{translate('CONTRACT_IS_CHECKED')}!</Typography>
          <Typography type={'text3'}>
            {translate('PBX_IS_AWAITING_TRANSITION_TO_COMMERCIAL_USE')}
          </Typography>
          <Typography type={'text3'}>
            {translate('ITS_A_GOOD_TIME_TO_REPLENISH_YOUR_ACCOUNT')}
          </Typography>
        </div>
      );
    }
    return <div />;
  };

  return (
    <header className={classes.root}>
      {isSystemRoleOrOwner && (
        <DashboardNotificationBlock
          loading={loading || loadingClientData}
          domainStatus={status}
          isModerated={isModerated}
        />
      )}
      <div className={classes.header}>
        <div className={classes.backTitleBlock}>
          <Button onClick={() => navigate(-1)} className={classes.backButton} variant="text">
            <ArrowBackIcon />
          </Button>
          <Typography color={'tertiary900'} type={'text2'}>
            {title}
          </Typography>
        </div>
        <Flex>
          {financialMenuAvailable && (
            <ClickAwayListener onClickAway={handleMenuClickOutside}>
              <div>
                <Button
                  clear
                  className={clsx(classes.buttonMenu, {
                    [classes.buttonMenuAlert]: balance < 0,
                  })}
                  onClick={handleMenuOpen}
                >
                  <Flex className={classes.buttonMenuContent}>
                    {unknownBalance && balanceLoading ? (
                      <Preloader size={'small'} />
                    ) : (
                      <Typography type={'text3'} medium>
                        {toPrecision(balance)} ₽
                      </Typography>
                    )}
                    <WalletIcon />
                  </Flex>
                </Button>
                <Drawer
                  variant={'temporary'}
                  anchor={'right'}
                  open={openMenu}
                  classes={{ paper: classes.rightMenu }}
                  onClose={() => setMenuOpen(false)}
                >
                  <div
                    className={clsx(classes.menuBackground, {
                      [classes.menuBackgroundDemo]:
                        status === DomainStatus.Demo || status === DomainStatus.Moderating,
                    })}
                  >
                    <div
                      className={clsx(classes.menuTopContainer, {
                        [classes.menuTopContainerAlert]: balance < 0,
                        [classes.menuTopContainerDanger]: balanceError,
                      })}
                    >
                      <Flex className={classes.menuTitleContainer}>
                        <Typography type={'text2'} color={'base'}>
                          {translate('FINANCIAL_CABINET_LABEL')}
                        </Typography>
                        <XIcon className={classes.closeIcon} onClick={() => setMenuOpen(false)} />
                      </Flex>
                      <div className={classes.menuBalanceContainer}>
                        <Typography type={'text3'} color={'tertiary200'}>
                          {`${translate('PBX_ACCOUNT')}: ${account}`}
                        </Typography>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {balanceError ? (
                          <Flex alignItems="center" justifyContent="spaceBetween">
                            <Typography type={'text3'} color={'base'}>
                              {translate('BALANCE_UNAVAILABLE')}
                            </Typography>
                            <InfoIcon className={classes.infoIcon} />
                          </Flex>
                        ) : balanceLoading ? (
                          <Preloader size={'medium'} />
                        ) : (
                          <>
                            <Flex justifyContent="spaceBetween" alignItems="center">
                              <Typography type={'text2'} color={'base'}>
                                {translate('BALANCE')}:
                              </Typography>
                              <Typography
                                type={'text1'}
                                color={balance < 0 ? 'danger500' : 'base'}
                                medium
                                className={classes.menuBalanceNumber}
                              >
                                {toPrecision(balance)} ₽
                              </Typography>
                            </Flex>
                            {credit !== 0 && (
                              <Flex justifyContent="spaceBetween">
                                <Typography type={'text3'} color={'base'}>
                                  {burntDate
                                    ? translate('CREDIT_AVAILABLE_UNTIL', {
                                        date: format(new Date(burntDate), 'dd.MM'),
                                      })
                                    : translate('PROMISED_PAYMENT')}
                                </Typography>
                                <Typography type={'text3'} color={'warning500'}>
                                  {toPrecision(credit)} ₽
                                </Typography>
                              </Flex>
                            )}
                            {promisedPaymentAvailability && (
                              <>
                                <Button
                                  title={translate('EXECUTE_PROMISED_PAYMENT')}
                                  className={classes.promisedPaymentButton}
                                  onClick={() => setPromisePaymentDialogOpen(true)}
                                />
                                <BalanceDialog
                                  isFillBalanceDialogOpen={isFillBalanceDialogOpen}
                                  isPromisePaymentDialogOpen={isPromisePaymentDialogOpen}
                                  onCloseBalanceDialog={() => setFillBalanceDialogOpen(false)}
                                  onClosePromiseDialog={() => setPromisePaymentDialogOpen(false)}
                                  onPromisePaymentSuccess={refetchBalance}
                                />
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className={classes.menuItems}>{renderMenuItems()}</div>
                    </div>
                    {renderMessageType()}
                  </div>
                </Drawer>
              </div>
            </ClickAwayListener>
          )}
          <Button
            clear
            className={classes.userDataBlockWrapper}
            onClick={() => setIsProfileBalloonOpen(true)}
          >
            {renderUserData()}
          </Button>
          {renderUserMenuBalloon()}
        </Flex>
      </div>
    </header>
  );
};

export default DashboardHeader;
