import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_MISSED_CALLS_QUERY } from '@/client/queries';
import PagePreloader from '@components/PagePreloader';
import PageError from '@shared/components/PageError';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import LazyTable, { CellPreloader, ContentHint, IColumn } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import { SmsIcon } from '@shared/assets/images/icons';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParamsValidator } from '@components/common';
import { NotificationsBreadcrumbs } from './NotificationsBreadcrumbs';
import { TableDataObject } from './SettingsNotifications.interface';
import { useNotificationsStyles } from './SettingsNotifications.styles';
import {
  NotificationsStatusHeaderCell,
  NotificationsActionsCell,
  NotificationsArrowCell,
  NotificationsMethodCell,
  NotificationsRecipientCell,
  NotificationsStatusCell,
  NotificationsMissedCallCell,
} from './modules';
import { allowedSearchParamsValues } from './SettingsNotifications.utils';

export const missedCallsColumns: IColumn<TableDataObject>[] = [
  {
    id: 'notificationsFilter',
    width: '1em',
    minWidth: '1em',
    align: 'center',
    label: '',
    HeaderRenderer: NotificationsStatusHeaderCell,
    Renderer: NotificationsStatusCell,
  },
  {
    id: 'missedCall',
    label: 'MISSED_CALL_ON',
    Renderer: NotificationsMissedCallCell,
  },
  {
    id: 'missedCall',
    label: '',
    Renderer: NotificationsArrowCell,
  },
  {
    id: 'notificationRecipient',
    label: 'NOTIFICATION_RECIPIENT',
    Renderer: NotificationsRecipientCell,
  },
  {
    id: 'method',
    label: 'NOTIFICATION_METHOD',
    Renderer: NotificationsMethodCell,
  },
  {
    id: 'actions',
    label: '',
    align: 'right',
    Renderer: NotificationsActionsCell,
  },
];

export const SettingsNotificationsMissedCalls = () => {
  const { data: missedCallsData, loading, error } = useQuery(GET_ALL_MISSED_CALLS_QUERY);
  const allMissedCallsData = missedCallsData?.getAllMissedCallConfigs;
  const [translate] = useTranslation();
  const classes = useNotificationsStyles();
  const { searchParams } = useSearchParamsValidator({ query: null, filter: null });

  const filterQuery = searchParams
    .getAll('filter')
    .filter((value) =>
      allowedSearchParamsValues.includes(value as (typeof allowedSearchParamsValues)[number])
    );
  const sQuery = searchParams.get('query')?.toUpperCase() || '';

  const formMethods = useForm({
    defaultValues: { query: sQuery },
  });

  const tableData = useMemo(() => {
    if (!allMissedCallsData) {
      return null;
    }

    if (!sQuery && (filterQuery.length === 0 || filterQuery.includes('all'))) {
      return allMissedCallsData;
    }

    const cleanedQuery = sQuery.replace(/[( )\s+-]/g, '');

    return allMissedCallsData?.filter((el) => {
      const isSearchMatch =
        sQuery === '' ||
        el.domainNumber?.phone.includes(cleanedQuery) ||
        el.department?.name?.toUpperCase().includes(sQuery) ||
        el.employee?.name?.toUpperCase().includes(sQuery) ||
        el.receivers?.reduce((acc, rec) => {
          if (
            rec.employee?.name?.toUpperCase().includes(sQuery) ||
            rec.department?.name?.toUpperCase().includes(sQuery) ||
            rec.external?.includes(cleanedQuery)
          ) {
            return true;
          }
          return acc;
        }, false);

      const isFilterMatch = Boolean(
        filterQuery.length === 0 ||
          filterQuery.includes('all') ||
          (el.domainNumber && filterQuery.includes('domainNumber')) ||
          (el.department && filterQuery.includes('department')) ||
          (el.employee && filterQuery.includes('employee'))
      );

      return isSearchMatch && isFilterMatch;
    });
  }, [allMissedCallsData, filterQuery, sQuery]);

  const renderEmptyContent = () => {
    if (loading || error) {
      return null;
    }

    if (!allMissedCallsData || !allMissedCallsData.length) {
      return (
        <ContentHint isContentCenter>
          <SmsIcon className={classes.SmsIcon} />
          <Typography color={'tertiary600'} type={'text3'}>
            {translate('HERE_YOU_CAN_ADD')}
          </Typography>
          <Typography color={'tertiary600'} type={'text3'}>
            {translate('NEW_NOTIFICATIONS')?.toLowerCase()}
          </Typography>
        </ContentHint>
      );
    }

    if (!tableData || !tableData.length) {
      return (
        <CellPreloader>
          <Typography color={'tertiary900'} type={'text3'}>
            {translate('NOTHING_FOUND')}
          </Typography>
        </CellPreloader>
      );
    }

    return null;
  };

  const renderSettingsNotificationsMissedCallsContent = () => {
    if (loading) return <PagePreloader />;
    if (error) return <PageError />;
    return (
      <FormProvider {...formMethods}>
        <Flex direction={'column'} className={classes.root}>
          <NotificationsBreadcrumbs />
          <LazyTable<TableDataObject>
            columns={missedCallsColumns}
            data={tableData || []}
            renderEmptyDataMessage={renderEmptyContent()}
          />
        </Flex>
      </FormProvider>
    );
  };

  return (
    <BodyContainer disableOverflow>{renderSettingsNotificationsMissedCallsContent()}</BodyContainer>
  );
};
