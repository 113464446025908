import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import Button from '@shared/components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import clsx from 'clsx';
import { SELECT_NUMBERS_MUTATION } from '@/client/mutations';
import { getNumbersPrices } from '@/utils/getNumbersPrices';
import { toPrecision } from '@components/utils/toPrecision';
import DrawerReserveNumbers from '@/components/DrawerReserveNumbers/DrawerReserveNumbers';
import { formType } from '@/features/Documents/Contract/Contract.constants';
import { useForm } from 'react-hook-form';
import { NumberType } from '@components/typings/interfaces';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ContractLayout } from '@/features/Documents';
import { ReservedNumber } from '@/components/ReservedNumber/ReservedNumber';
import { DOMAIN_TEL_NUMBERS_QUERY } from '@/client/queries';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import Alert from '@shared/components/Alert';
import { useContractStyle } from '../Contract.styles';
import { DomainTelNumbersQueryNumberModel } from '../Contract.interfaces';

export const ContractPhoneNumber = () => {
  const classes = useContractStyle();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const sReserve = searchParams.get('reserve');
  const { handleSubmit } = formMethods;

  const [confirmNumbersSelect, { loading: loadingSelectNumbers }] =
    useMutation(SELECT_NUMBERS_MUTATION);
  const {
    data: domainTelNumberQueryData,
    refetch: updateNumbersList,
    loading: loadingDomainTelNumbers,
  } = useQuery(DOMAIN_TEL_NUMBERS_QUERY);
  const loading = loadingSelectNumbers || loadingDomainTelNumbers;

  const reservedNumberList = domainTelNumberQueryData?.getNumbers || [];
  const isThereRunexisPhoneNumber = domainTelNumberQueryData?.getNumbers.some(
    (phoneNumberInfo) =>
      phoneNumberInfo.type === NumberType.Runexis &&
      typeof phoneNumberInfo.code === 'number' &&
      phoneNumberInfo.code >= 900
  );

  const [openMenu, setMenuOpen] = useState(false);

  const rootRef = useRef<HTMLDivElement | null>(null);
  const numbersList = reservedNumberList.filter(
    (e) => e.type !== NumberType.Demo
  ) as DomainTelNumbersQueryNumberModel[];

  const checkKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.code === 'Enter') event.preventDefault();
  };

  useEffect(() => {
    if (sReserve) {
      setMenuOpen(true);
    }
  }, [sReserve]);

  function handlePhoneNumberChooseMenuOpen() {
    setMenuOpen(true);
  }

  function handlePhoneNumberChooseMenuClose() {
    searchParams.delete('reserve');
    searchParams.delete('code');
    setSearchParams(searchParams);
    setMenuOpen(false);
  }

  function handleDeleteReservedNumber() {
    updateNumbersList();
  }

  function handlePhoneNumberSubmit() {
    confirmNumbersSelect()
      .then(() => {
        navigate('/register/scenario');
      })
      .catch(() => {
        globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      });
  }

  const renderReservedNumbersList = () => {
    if (numbersList.length) {
      const { fixedPrice, monthlyPrice } = getNumbersPrices(numbersList);

      return (
        <div className={classes.phoneNumberGrid}>
          <div className={classes.phoneNumberRow}>
            <div className={classes.defaultElementWidth12}>
              <Typography type={'text4'} color={'tertiary700'}>
                {translate('NUMBERS')}
              </Typography>
            </div>
            <div className={classes.defaultElementWidth12}>
              <Typography type={'text4'} color={'tertiary700'}>
                {translate('CITY')}
              </Typography>
            </div>
            <div className={classes.defaultElementWidth9}>
              <Typography type={'text4'} color={'tertiary700'}>
                {translate('TYPE')}
              </Typography>
            </div>
            <div className={clsx(classes.defaultElementWidth7, classes.textAlignRight)}>
              <Typography type={'text4'} color={'tertiary700'}>
                {translate('COST')}
              </Typography>
            </div>
            <div
              className={clsx(
                classes.defaultElementWidth7,
                classes.textAlignRight,
                classes.phoneNumberPaddingCell
              )}
            >
              <Typography type={'text4'} color={'tertiary700'}>
                {translate('MONTH_PRICE')}
              </Typography>
            </div>
          </div>
          {numbersList.map(({ id, city, phone, category, tariff }) => (
            <ReservedNumber
              key={id}
              id={id}
              phone={phone}
              city={city}
              category={category}
              setupFee={tariff?.setupFee}
              monthlyFee={tariff?.monthlyFee}
              onComplete={handleDeleteReservedNumber}
            />
          ))}
          {isThereRunexisPhoneNumber && (
            <Alert
              classes={{ root: classes.afterSwitchingPBXToCommercialAlert }}
              status="info"
              description={translate('AFTER_SWITCHING_PBX_TO_COMMERCIAL_INFO')}
            />
          )}
          <div className={classes.phoneNumberRow}>
            <div className={classes.defaultElementWidth24}>
              <Button
                title={translate('ADD_NUMBER')}
                variant={'secondary'}
                classes={{ root: classes.defaultElementWidth17 }}
                onClick={handlePhoneNumberChooseMenuOpen}
              />
            </div>
            <div className={classes.defaultElementWidth9}>
              <Typography type={'text3'} color={'tertiary900'} medium>
                {translate('TOTAL')}
              </Typography>
            </div>
            <div className={clsx(classes.defaultElementWidth7, classes.textAlignRight)}>
              <Typography type={'text3'} color={'tertiary900'} medium>
                {toPrecision(fixedPrice, false)} ₽
              </Typography>
            </div>
            <div
              className={clsx(
                classes.defaultElementWidth7,
                classes.textAlignRight,
                classes.phoneNumberPaddingCell
              )}
            >
              <Typography type={'text3'} color={'tertiary900'} medium>
                {translate('RUB_PER_MONTH_SHORT', { amount: toPrecision(monthlyPrice, false) })}
              </Typography>
            </div>
          </div>
        </div>
      );
    }

    return <div />;
  };

  return (
    <ContractLayout
      isLoading={loading}
      childrenHeight={rootRef?.current?.clientHeight}
      formId={formType.PhoneNumber}
    >
      <div ref={rootRef} className={classes.personalDataRoot}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form
          id={formType.PhoneNumber}
          onSubmit={handleSubmit(handlePhoneNumberSubmit)}
          onKeyDown={checkKeyDown}
        >
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('CHOOSE_NUMBERS')}
          </Typography>
          <div className={classes.phoneNumberAddBlock}>
            {numbersList.length ? (
              renderReservedNumbersList()
            ) : (
              <div className={classes.phoneNumberWelcomeScreen}>
                <div className={classes.phoneNumberTextContainer}>
                  <Typography type={'text3'} color={'tertiary900'}>
                    {translate('NUMBER_SELECT_NOTE_01')}
                  </Typography>
                  <Typography type={'text3'} color={'tertiary900'}>
                    {translate('NUMBER_SELECT_NOTE_02')}
                  </Typography>
                </div>
                <Button
                  title={translate('CHOOSE_NUMBER')}
                  variant={'secondary'}
                  classes={{ root: classes.btnWidth }}
                  onClick={handlePhoneNumberChooseMenuOpen}
                />
              </div>
            )}
          </div>
          <DrawerReserveNumbers open={openMenu} onClose={handlePhoneNumberChooseMenuClose} />
        </form>
      </div>
    </ContractLayout>
  );
};
