import {
  AMOIcon,
  AMOIconMed,
  Bitrix24Icon,
  Bitrix24IconMed,
  GravitelIconMed,
  MegaplanIcon,
  MegaplanIconMed,
  NewApiIcon as OtherIntegrationsIcon,
  OdinSIconMed,
  OldApiIcon as GravitelIcon,
  OmnideskIcon,
  OmnideskIconMed,
  PlanfixIcon,
  PlanfixIconMed,
  RESTIconMed,
  RetailCRM,
  RetailIconMed,
  RoistatIcon,
  RoistatIconMed,
  YClientsIcon,
  YclientsIconMed,
  KlienticsIconMed,
  SimpleCallsMed,
} from '@shared/assets/images/icons/integrations';

import {
  IIntegrationTypes,
  IntegrationCategory,
  IntegrationList,
} from '@components/typings/interfaces/integrations';
import { IIntegrationCard } from './Integrations.interfaces';
import { FeaturesType } from '../../../typings';

export const integrationsLogoList: IIntegrationTypes = {
  [IntegrationList.AmoCrm]: AMOIcon,
  [IntegrationList.API]: OtherIntegrationsIcon,
  [IntegrationList.Gravitel]: GravitelIcon,
  [IntegrationList.Bitrix24]: Bitrix24Icon,
  [IntegrationList.Megaplan]: MegaplanIcon,
  [IntegrationList.YClients]: YClientsIcon,
  [IntegrationList.Omnidesk]: OmnideskIcon,
  [IntegrationList.Planfix]: PlanfixIcon,
  [IntegrationList.Roistat]: RoistatIcon,
  [IntegrationList.RetailCRM]: RetailCRM,
  [IntegrationList.OdinS]: OdinSIconMed,
  [IntegrationList.Klientiks]: KlienticsIconMed,
  [IntegrationList.ProstieZvonki]: SimpleCallsMed,
};

export const integrationsMediumLogoList: IIntegrationTypes = {
  [IntegrationList.AmoCrm]: AMOIconMed,
  [IntegrationList.API]: RESTIconMed,
  [IntegrationList.Gravitel]: GravitelIconMed,
  [IntegrationList.Bitrix24]: Bitrix24IconMed,
  [IntegrationList.Megaplan]: MegaplanIconMed,
  [IntegrationList.YClients]: YclientsIconMed,
  [IntegrationList.Omnidesk]: OmnideskIconMed,
  [IntegrationList.Planfix]: PlanfixIconMed,
  [IntegrationList.Roistat]: RoistatIconMed,
  [IntegrationList.RetailCRM]: RetailIconMed,
  [IntegrationList.OdinS]: OdinSIconMed,
  [IntegrationList.Klientiks]: KlienticsIconMed,
  [IntegrationList.ProstieZvonki]: SimpleCallsMed,
};

export const integrationsCards: IIntegrationCard[] = [
  {
    type: IntegrationList.AmoCrm,
    Logo: integrationsLogoList[IntegrationList.AmoCrm],
    LogoMedium: integrationsMediumLogoList[IntegrationList.AmoCrm],
    titleCode: 'AMOCRM_API_INTEGRATION',
    descriptionCode: 'AMOCRM_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationAmo,
    paid: true,
    category: IntegrationCategory.CRM,
    visible: true,
  },
  {
    type: IntegrationList.Bitrix24,
    Logo: integrationsLogoList[IntegrationList.Bitrix24],
    LogoMedium: integrationsMediumLogoList[IntegrationList.Bitrix24],
    titleCode: 'BITRIX24_API_INTEGRATION',
    descriptionCode: 'BITRIX24_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationBitrix24,
    paid: true,
    category: IntegrationCategory.CRM,
    visible: true,
  },
  {
    type: IntegrationList.Megaplan,
    Logo: integrationsLogoList[IntegrationList.Megaplan],
    LogoMedium: integrationsMediumLogoList[IntegrationList.Megaplan],
    titleCode: 'MEGAPLAN_API_INTEGRATION',
    descriptionCode: 'MEGAPLAN_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationMegaPlan,
    paid: true,
    category: IntegrationCategory.CRM,
    visible: false,
  },
  {
    type: IntegrationList.RetailCRM,
    Logo: integrationsLogoList[IntegrationList.RetailCRM],
    LogoMedium: integrationsMediumLogoList[IntegrationList.RetailCRM],
    titleCode: 'RETAIL_API_INTEGRATION',
    descriptionCode: 'RETAIL_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationRetail,
    paid: true,
    category: IntegrationCategory.CRM,
    visible: true,
  },
  {
    type: IntegrationList.YClients,
    Logo: integrationsLogoList[IntegrationList.YClients],
    LogoMedium: integrationsMediumLogoList[IntegrationList.YClients],
    titleCode: 'YCLIENTS_API_INTEGRATION',
    descriptionCode: 'YCLIENTS_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationBasic,
    paid: true,
    category: IntegrationCategory.CRM,
    visible: true,
  },
  {
    type: IntegrationList.OdinS,
    Logo: integrationsLogoList[IntegrationList.OdinS],
    LogoMedium: integrationsMediumLogoList[IntegrationList.OdinS],
    titleCode: 'ODINS_API_INTEGRATION',
    descriptionCode: 'ODINS_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationOdinS,
    paid: true,
    category: IntegrationCategory.CRM,
    visible: true,
  },
  {
    type: IntegrationList.Omnidesk,
    Logo: integrationsLogoList[IntegrationList.Omnidesk],
    LogoMedium: integrationsMediumLogoList[IntegrationList.Omnidesk],
    titleCode: 'OMNIDESK_API_INTEGRATION',
    descriptionCode: 'OMNIDESK_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationBasic,
    paid: true,
    category: IntegrationCategory.CRM,
    visible: true,
  },
  {
    type: IntegrationList.Planfix,
    Logo: integrationsLogoList[IntegrationList.Planfix],
    LogoMedium: integrationsMediumLogoList[IntegrationList.Planfix],
    titleCode: 'PLANFIX_API_INTEGRATION',
    descriptionCode: 'PLANFIX_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationBasic,
    paid: true,
    category: IntegrationCategory.CRM,
    visible: true,
  },
  {
    type: IntegrationList.Klientiks,
    Logo: integrationsLogoList[IntegrationList.Klientiks],
    LogoMedium: integrationsMediumLogoList[IntegrationList.Klientiks],
    titleCode: 'KLIENTICS_API_INTEGRATION',
    descriptionCode: 'KLIENTICS_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationBasic,
    paid: true,
    category: IntegrationCategory.CRM,
    visible: true,
  },
  {
    type: IntegrationList.Roistat,
    Logo: integrationsLogoList[IntegrationList.Roistat],
    LogoMedium: integrationsMediumLogoList[IntegrationList.Roistat],
    titleCode: 'ROISTAT_API_INTEGRATION',
    descriptionCode: 'ROISTAT_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationBasic,
    paid: false,
    category: IntegrationCategory.CALLTRACKING,
    visible: true,
  },
  {
    type: IntegrationList.API,
    Logo: integrationsLogoList[IntegrationList.API],
    LogoMedium: integrationsMediumLogoList[IntegrationList.API],
    titleCode: 'REST_API_INTEGRATION',
    descriptionCode: 'REST_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationBasic,
    paid: true,
    category: IntegrationCategory.OTHER,
    visible: true,
  },
  {
    type: IntegrationList.Gravitel,
    Logo: integrationsLogoList[IntegrationList.Gravitel],
    LogoMedium: integrationsMediumLogoList[IntegrationList.Gravitel],
    titleCode: 'GRAVITEL_API_INTEGRATION',
    descriptionCode: 'GRAVITEL_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationBasic,
    paid: true,
    category: IntegrationCategory.OTHER,
    visible: true,
  },
  {
    type: IntegrationList.ProstieZvonki,
    Logo: integrationsLogoList[IntegrationList.ProstieZvonki],
    LogoMedium: integrationsMediumLogoList[IntegrationList.ProstieZvonki],
    titleCode: 'SIMPLE_CALLS_API_INTEGRATION',
    descriptionCode: 'SIMPLE_CALLS_API_INTEGRATION_DESCRIPTION',
    feature: FeaturesType.IntegrationBasic,
    paid: true,
    category: IntegrationCategory.OTHER,
    visible: true,
  },
];

export const integrationBitrixInstallOptions = [
  'INTEGRATION_INSTALL_NOTE_01',
  'INTEGRATION_INSTALL_NOTE_02',
];

export const FREE_INTEGRATIONS_TYPES_LIST = integrationsCards
  .filter((item) => !item.paid)
  .map((item) => item.type);

export const integrationsCardsFilteredCards = [
  {
    title: 'CRM',
    list: integrationsCards.filter((item) => item.category === IntegrationCategory.CRM),
  },
  {
    title: 'CALLTRACKING_TITLE',
    list: integrationsCards.filter((item) => item.category === IntegrationCategory.CALLTRACKING),
  },
  {
    title: 'EXTERNAL_SERVICES',
    list: integrationsCards.filter((item) => item.category === IntegrationCategory.OTHER),
  },
];
