import React, { useEffect, useState } from 'react';
import BodyContainer from '@/layouts/BodyContainer';
import BottomButtons from '@/layouts/BottomButtons';
import ControlButtons from '@shared/components/ControlButtons';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_END_USER_MUTATION } from '@/client/mutations';
import { dateToDateOnlyString } from '@shared/utils/dates';
import resultify from '@shared/utils/resultify';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { GET_ALL_END_USERS_QUERY } from '@/client/queries';
import PagePreloader from '@components/PagePreloader';
import PageError from '@shared/components/PageError';
import ResponsiblePersonDataForm, {
  ResponsiblePersonDataFormFields,
  getDefaultResponsiblePersonDataFormValues,
} from '../modules/ResponsiblePersonDataForm';
import ResponsibleAlreadyExistsDialog from '../modules/ResponsibleAlreadyExistsDialog';

type FieldsToSend = {
  [K in keyof ResponsiblePersonDataFormFields]:
    | ResponsiblePersonDataFormFields[K]
    | null
    | undefined;
};

const AddResponsiblePerson = () => {
  const navigate = useNavigate();
  const formMethods = useForm<ResponsiblePersonDataFormFields>({
    defaultValues: getDefaultResponsiblePersonDataFormValues(),
  });

  const [isResponsibleAlreadyExistsDialogOpen, setIsResponsibleAlreadyExistsDialogOpen] =
    useState(false);

  const {
    data: endUsersQueryData,
    loading: responsiblePersonsQueryDataLoading,
    error: endUsersQueryError,
  } = useQuery(GET_ALL_END_USERS_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const checkResponsibleDuplicate = () => {
    const responsibleWithTheSamePassportData = endUsersQueryData?.getAllEndUsers.find(
      (endUserData) =>
        endUserData.documentType === formMethods.getValues().documentType &&
        endUserData.series === formMethods.getValues().series &&
        endUserData.number === formMethods.getValues().number
    );

    if (responsibleWithTheSamePassportData) {
      setIsResponsibleAlreadyExistsDialogOpen(true);
      return false;
    }

    return true;
  };
  const [confirmState, setConfirmState] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const [blockedPath, setBlockedPath] = useState<string | null>(null);

  const [addResponsiblePerson, { loading }] = useMutation(CREATE_END_USER_MUTATION);

  useEffect(() => {
    if (confirmState.action === ConfirmAction.Finish) {
      navigate(blockedPath || '/numbers/responsible-persons');
    }
  }, [blockedPath, confirmState.action, navigate]);

  const handleFormChange = () => {
    setConfirmState({ action: ConfirmAction.Edit, isBlocking: true });
  };

  const handleSubmit = async (formFields: ResponsiblePersonDataFormFields) => {
    if (!checkResponsibleDuplicate()) {
      return;
    }

    const fieldsToSend: FieldsToSend = { ...formFields };
    const dateOfBirth = dateToDateOnlyString(formFields.dateOfBirth)!;
    const dateOfIssue = formFields.dateOfIssue
      ? dateToDateOnlyString(formFields.dateOfIssue)!
      : undefined;

    Object.keys(fieldsToSend).forEach((key) => {
      if (fieldsToSend[key as keyof typeof fieldsToSend] === '') {
        fieldsToSend[key as keyof typeof fieldsToSend] = null;
      }
    });

    const result = await resultify(
      addResponsiblePerson({
        variables: {
          input: {
            ...(fieldsToSend as ResponsiblePersonDataFormFields),
            dateOfBirth,
            dateOfIssue,
          },
        },
      })
    ); // TODO (responsibles) ask about passport type

    if (result.type === 'error') {
      globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      return;
    }

    setConfirmState({ isBlocking: false, action: ConfirmAction.Finish });
  };

  const goBack = () => {
    navigate(-1);
  };

  if (responsiblePersonsQueryDataLoading) {
    return <PagePreloader />;
  }

  if (endUsersQueryError) {
    return <PageError />;
  }

  return (
    <BodyContainer>
      <FormProvider {...formMethods}>
        <ResponsiblePersonDataForm
          handleSubmit={handleSubmit}
          handleFormChange={handleFormChange}
        />
        <BottomButtons>
          <ControlButtons
            confirmTitle="SAVE"
            cancelTitle="CANCEL"
            onConfirmClick={formMethods.handleSubmit(handleSubmit)}
            onCancelClick={goBack}
            cancelVariant="secondary"
            justifyContent="start"
            loading={loading}
          />
        </BottomButtons>
      </FormProvider>
      <ConfirmDialog
        isBlocked={confirmState.isBlocking}
        onNavigationBlocked={setBlockedPath}
        onSaveChanges={formMethods.handleSubmit(handleSubmit)}
      />
      <ResponsibleAlreadyExistsDialog
        isOpen={isResponsibleAlreadyExistsDialogOpen}
        onClose={() => setIsResponsibleAlreadyExistsDialogOpen(false)}
      />
    </BodyContainer>
  );
};

export default AddResponsiblePerson;
