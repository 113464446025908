import { FormControlLabel, Theme, makeStyles } from '@material-ui/core';
import { pxToEm } from '@shared/utils/styles';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import Radio from '../Radio/Radio';

// eslint-disable-next-line @typescript-eslint/ban-types
type Height = 32 | 40 | (number & {});

type RadioButtonProps = {
  label: string;
  value: any;
  name: string;

  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  heightPx?: Height;
};

const useRadioButtonStyles = makeStyles<Theme, { height?: Height }>(
  ({ color: { primary, tertiary } }) => ({
    formControlLabel: {
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '6px',
      cursor: 'pointer',
      marginLeft: 'unset',
      marginRight: 'unset',
      paddingRight: '1.125em',
      minHeight: pxToEm(32),
      height: (props) => pxToEm(props.height || 32),
    },
    checked: {
      backgroundColor: primary[50],
      borderColor: primary[700],
    },
    radio: {
      margin: '0 1em',
      padding: 0,
    },
  })
);

const RadioButton = ({
  name,
  label,
  className,
  value,
  disabled,
  heightPx: height,
  onClick,
}: RadioButtonProps) => {
  const classes = useRadioButtonStyles({ height });
  const formMethods = useFormContext();

  const currentValue = formMethods.watch(name);
  const isChecked = currentValue === value;

  return (
    <FormControlLabel
      className={clsx(classes.formControlLabel, isChecked && classes.checked, className)}
      onClick={onClick}
      disabled={disabled}
      label={label}
      value={value}
      control={<Radio className={classes.radio} />}
    />
  );
};

export default RadioButton;
