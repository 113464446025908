import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import { useSearchParams } from 'react-router-dom';
import HighlightedText from '@components/HighlightedText';
import { useNumbersStyle } from '../PhoneNumbers.styles';
import { DomainNumber } from '../PhoneNumbers.interfaces';

export const NumberCell: FunctionComponent<ICellProps<DomainNumber>> = ({ item }) => {
  const classes = useNumbersStyle();
  const [searchParams] = useSearchParams();
  const sSearch = searchParams.get('search');

  const { phone = '', city = '' } = item;

  return (
    <Flex direction={'column'}>
      <HighlightedText type={'phone'} text={phone} query={sSearch} />
      <Typography type={'text4'} color={'tertiary600'} className={classes.numberSecondaryText}>
        {city || ''}
      </Typography>
    </Flex>
  );
};
