import { scenarioTypes } from '@components/typings/interfaces';

export const marketScenarioData = {
  title: 'SCENARIOS_BASE_TITLE',
  description: 'SCENARIO_BASE_DESCRIPTION',
  monthPrice: 5000,
  installationPrice: 5250,
  type: scenarioTypes.Market,
};

export const individualScenarioData = {
  title: 'SCENARIO_INDIVIDUAL_TITLE',
  description: 'SCENARIO_INDIVIDUAL_DESCRIPTION',
  monthPrice: 10000,
  type: scenarioTypes.Individual,
};
