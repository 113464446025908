import { makeStyles, Theme } from '@material-ui/core';
import paddingStyleConstants from '@components/styles/padding.constants';
import textConstants from '@components/styles/text.constants';

type UseDashboardHeaderStylesProps = {
  rootHeight: string;
};

export const useDashboardHeaderStyles = makeStyles<Theme, UseDashboardHeaderStylesProps>(
  ({
    color: { base, tertiary, danger, links, primary, warning, success },
    breakpoints: { down },
  }) => ({
    '@keyframes open': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    root: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 'auto',
      height: (props) => props.rootHeight,
      maxHeight: (props) => props.rootHeight,
      backgroundColor: 'inherit',
      width: 'auto',
      position: 'absolute',
      [down('md')]: {
        width: '1200px',
      },
    },
    backButton: {
      color: tertiary[900],
      minWidth: 'auto',
      padding: '0.25em 2px 0.25em 0',
      border: 'none',
      marginRight: '1.5em',
      borderRadius: '2px',
      background: 'transparent',
      height: '2em',
      width: '2em',
      '&:hover': {
        backgroundColor: tertiary[100],
        borderRadius: '0.375em',
      },
    },
    backTitleBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonMenu: {
      backgroundColor: success[50],
      marginRight: '1em',
      width: 'fit-content',
      height: '2em',
      alignItems: 'center',
      borderRadius: '6px',
      color: tertiary[900],
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: tertiary[200],
        color: tertiary[900],
        transition: '0.3s ease',
      },
    },
    buttonMenuAlert: {
      backgroundColor: danger[50],
    },
    buttonMenuContent: {
      display: 'flex',
      gap: '0.5em',
      padding: '0 1em',
    },
    button: {
      width: '100%',
      marginTop: '2em',
      '&:hover': {
        color: tertiary[900],
      },
    },
    downloadTemplateLink: {
      marginTop: '0.5em',
      height: '2.5em',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: base,
      '& *': {
        lineHeight: '1em',
        margin: '0 0.25em',
      },
    },
    closeIcon: {
      color: base,
      cursor: 'pointer',
    },
    contractStatusIcon: {
      width: '5.5em',
      height: '5.5em',
      marginBottom: '1.5em',
    },
    header: {
      width: '100%',
      height: '5em',
      padding: '0 2em 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    iconWrapper: {
      height: '1.25em',
    },
    infoIcon: {
      width: '2em',
      height: '2em',
      marginLeft: '1em',
      color: warning[500],
    },
    link: {
      height: '2.5em',
      padding: '0 1.25em',
      color: base,
      display: 'flex',
      alignItems: 'center',
      gap: '0.75em',
      textDecoration: 'none',
    },
    linkWrapper: {
      '&:hover *': {
        backgroundColor: tertiary[700],
      },
    },
    menuBackground: {
      background: base,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    menuBackgroundDemo: {
      backgroundColor: tertiary[800],
    },
    menuTitleContainer: {
      height: '3em',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    menuTopContainer: {
      padding: ' 0 1.5em',
      background: 'radial-gradient(122.31% 120.08% at 115.06% -20.08%, #046163 0%, #161618 100%)',
    },
    menuTopContainerDanger: {
      background: 'radial-gradient(122.31% 120.08% at 115.06% -20.08%, #702128 0%, #161618 100%)',
    },
    menuTopContainerAlert: {
      background: 'radial-gradient(122.31% 120.08% at 115.06% -20.08%, #662500 0%, #161618 100%)',
    },
    menuBalanceContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75em',
      padding: '1em',
      borderRadius: '1em',
      background: 'rgba(255, 255, 255, 0.15)',
      margin: '0.5em 0 1em',
    },
    menuBottomContainer: {
      height: '100%',
      padding: '2em',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    menuBottomContainerDemoAndModerating: {
      height: '100%',
      padding: '3em 1.5em',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: base,
    },
    menuItems: {
      paddingBottom: '1.25em ',
    },
    menuBalanceNumber: {
      marginLeft: '0.5em',
      lineHeight: '1.25em',
    },
    menuArrowDown: {
      transform: 'rotate(180deg)',
      color: tertiary[900],
      margin: '1em 0 0.5em',
    },
    promisedPaymentButton: {
      border: 'none',
      background: 'rgba(255, 255, 255, 0.15)',

      '&:hover': {
        background: 'rgba(255, 255, 255, 0.25)',
      },
    },
    rightMenu: {
      width: '22em',
    },
    userMenuBalloonWrapper: {
      position: 'absolute',
      marginTop: '2.75em',
      borderRadius: '0.75em',
      flexDirection: 'column',
      width: '16.5em',
      backgroundColor: 'white',
      zIndex: 100,
      filter: `drop-shadow(0 0 0.25em ${tertiary[300]})`,
      animation: '$open linear 0.2s',
      top: '2em',
      paddingBottom: '0.5em',
      cursor: 'default',
    },
    userMenuWithFinancialMenu: {
      right: '4.75em',
    },
    userMenuWithoutFinancialMenu: {
      right: '1.75em',
    },
    userBalloonItemRows: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    userAvailableAtcContainer: {
      backgroundColor: tertiary[50],
    },
    userAvailableAtsTitle: {
      margin: '0.75em 0 0.75em 1em',
    },
    userBalloonItemRow: {
      height: '2.5em',
      width: '100%',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: tertiary[100],
      },
    },
    userBalloonIcon: {
      margin: '2px 0.75em 0 1em',
      color: 'black',
    },
    userBalloonAvailableIcon: {
      margin: '2px 0.75em 0 1em',
      minWidth: '1em',
      transform: 'rotate(270deg)',
      color: 'black',
    },
    userDomain: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginRight: '0.5em',
    },
    userAddAtsBtn: {
      margin: '1em',
      color: links[600],
      backgroundColor: 'inherit',
    },
    userBalloonLogoutIcon: {
      color: danger[600],
    },
    userInfoContainer: {
      paddingTop: '1em',
      paddingBottom: '1em',
      borderBottom: `1px solid ${tertiary[200]}`,
      flexDirection: 'column',
    },
    userInfoText: {
      marginLeft: '1em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '1.6',
    },
    userDataBlockWrapper: {
      display: 'flex',
      alignItems: 'center',
      color: tertiary[900],
      '&:hover': {
        cursor: 'pointer',
        color: tertiary[900],
      },
    },
    userNameWrapper: {
      cursor: 'pointer',
      textAlign: 'end',
    },
    userAvatar: {
      width: '2em',
      height: '2em',
      marginLeft: '0.75em',
      backgroundColor: danger[500],
    },
    ...paddingStyleConstants,
    ...textConstants,
  })
);
