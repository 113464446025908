import { isValid } from 'date-fns';

/**
 * @param dateOnlyString string in yyyy-mm-dd format.
 * @returns `Date` if conversion is successful, `null` otherwise.
 */
export const dateOnlyStringToDate = (dateOnlyString: string) => {
  const matches = dateOnlyString.match(/(\d{4})-(\d{2})-(\d{2})/);

  if (!matches || !matches[0] || !matches[1] || !matches[2] || !matches[3]) {
    return null;
  }

  return new Date(Number(matches[1]), Number(matches[2]) - 1, Number(matches[3]));
};

/**
 * Converts date to string representation in yyyy-mm-dd format.
 * @returns `yyyy-mm-dd` string. If date is invalid, returns `null`.
 */
export const dateToDateOnlyString = (date: Date | null | undefined) => {
  if (!date || !isValid(date)) {
    return null;
  }
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(
    -2
  )}`;
};

export const shortDateStringToDDMMString = (dateString: string) => {
  const match = dateString.match(/(\d{4})-(\d{2})-(\d{2})/);
  if (!match || !match[1] || !match[2] || !match[3]) {
    return null;
  }

  return `${match[3]}.${match[2]}`;
};

export const getDaysStringWithCorrectCaseForRussianLanguage = (daysCount: number) => {
  const num = Math.abs(Math.trunc(daysCount));

  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  let word: string;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    word = 'дней';
  } else {
    switch (lastDigit) {
      case 1:
        word = 'день';
        break;
      case 2:
      case 3:
      case 4:
        word = 'дня';
        break;
      default:
        word = 'дней';
        break;
    }
  }

  return `${daysCount} ${word}`;
};
