import { makeStyles } from '@material-ui/core';
import { pxToEm } from '@shared/utils/styles';

const eventElementsMargin = `${pxToEm(4)} 0`;

export const useWorkCalendarEventsGroupStyles = makeStyles(
  ({ color: { tertiary, danger, secondary, success } }) => ({
    eventsGroup: {
      marginTop: pxToEm(12),
      padding: `${pxToEm(12)} ${pxToEm(16)}`,
      height: pxToEm(64),
      border: `1px solid ${tertiary[200]}`,
      borderRadius: '12px',
    },
    eventsGroupExpanded: {
      height: 'auto',
    },
    topBlock: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    topBlockColorMarker: {
      width: pxToEm(14),
      height: pxToEm(14),
      marginRight: pxToEm(8),
      backgroundColor: danger[500],
      borderRadius: '5px',
    },
    topBlockColorMarkerCustomDaysCategory: {
      backgroundColor: success[500],
    },
    topBlockColorMarkerWeekendsAndHolidaysCategory: {
      backgroundColor: danger[500],
    },
    topBlockColorMarkerRescheduledDaysCategory: {
      backgroundColor: secondary[500],
    },
    topBlockInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    topBlockInfoDaysCountWrapper: {
      marginTop: pxToEm(2),
    },
    topBlockExpandButton: {
      marginLeft: 'auto',
      width: '2em',
      height: '2em',
    },

    bottomBlock: {},
    noExceptionsAddedBlock: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '1em',
      height: pxToEm(56),
      textAlign: 'center',
    },
    eventsList: {
      marginTop: '1em',

      display: 'flex',
    },
    eventsDates: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '.5em',
    },
    eventDateWrapper: {
      margin: eventElementsMargin,
      height: pxToEm(21),
    },
    eventsNamesButtons: {
      flex: '0 0 80%',
      overflow: 'hidden',

      display: 'flex',
      flexDirection: 'column',
    },
    eventNameButton: {
      margin: eventElementsMargin,
      height: pxToEm(21),
      padding: 0,
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
      minWidth: 0,
    },
    eventNameButtonTypography: {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    eventNameButtonPlaceholder: {
      margin: eventElementsMargin,
      height: pxToEm(21),
    },

    rotateX180: {
      transform: 'rotateX(180deg)',
    },
    marginRight05: {
      marginRight: '.5em',
    },
    addExceptionButton: {
      marginTop: '1em',
    },
  })
);
