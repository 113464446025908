import LazyTable, { IColumn } from '@components/LazyTable';
import React from 'react';
import Typography from '@shared/components/Typography';
import { makeStyles } from '@material-ui/core';
import QuickSearch from '@components/QuickSearch';
import { FormProvider, useForm } from 'react-hook-form';
import Button from '@shared/components/Button';
import { useTranslation } from 'react-i18next';
import BodyContainer from '@/layouts/BodyContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_END_USERS_QUERY } from '@/client/queries';
import { EndUserModel } from '@/client/generated/graphql';
import { observable } from '@legendapp/state';
import { useSelector } from '@legendapp/state/react';
import MessageDialog from '@shared/components/MessageDialog';
import { REMOVE_END_USER_MUTATION } from '@/client/mutations';
import resultify from '@shared/utils/resultify';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import { ActionsCell, NameCell, NumbersCell } from './modules';
import NumbersTabs from '../modules/NumbersTabs';

type ResponsiblePersonsContext = {
  isCannotRemoveInfoDialogOpen: boolean;
  responsiblePersonIdForDeletion: number | null;
};

export const responsiblePersonsContext$ = observable<ResponsiblePersonsContext>({
  isCannotRemoveInfoDialogOpen: false,
  responsiblePersonIdForDeletion: null,
});

const columns: IColumn<EndUserModel>[] = [
  {
    id: 'name',
    label: 'NAME',
    Renderer: NameCell,
    maxWidth: '30%',
  },
  // Will be implemented in future
  // {
  //   id: 'numbersCount',
  //   label: 'NUMBERS_COUNT',
  //   maxWidth: '6em',
  //   Renderer: ({ item }) => (
  //     <Typography color="tertiary400">{item.domainNumbers.length}</Typography>
  //   ),
  // },
  {
    id: 'numbers',
    label: 'NUMBERS',
    Renderer: NumbersCell,
  },
  {
    id: 'actions',
    label: '',
    maxWidth: '5em',
    Renderer: ActionsCell,
  },
];

type FormFields = {
  searchQuery: string;
};

const useStyles = makeStyles(() => ({
  topBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em 2em',
  },
  marginLeft1: {
    marginLeft: '1em',
  },
  fullHeight: {
    height: '100%',
  },
  height2: {
    height: '2em',
  },
}));

const ResponsiblePersons = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const isCannotRemoveInfoDialogOpen = useSelector(
    responsiblePersonsContext$.isCannotRemoveInfoDialogOpen
  );
  const responsiblePersonIdForDeletion = useSelector(
    responsiblePersonsContext$.responsiblePersonIdForDeletion
  );

  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('searchQuery');

  const formMethods = useForm<FormFields>({
    defaultValues: {
      searchQuery: '',
    },
  });

  const {
    data: responsiblePersonsQueryData,
    loading: responsiblePersonsQueryLoading,
    error,
    refetch: refetchResponsiblePersonsQuery,
  } = useQuery(GET_ALL_END_USERS_QUERY);
  const responsiblePersons = responsiblePersonsQueryData?.getAllEndUsers;

  const [removeEndUser, { loading: removeEndUserLoading }] = useMutation(REMOVE_END_USER_MUTATION);

  const filteredData = responsiblePersons?.filter(
    (element) =>
      !searchQuery ||
      element.domainNumbers.some((domainNumberData) =>
        domainNumberData.phone.replace(/\D/g, '').includes(searchQuery.replace(/\D/g, ''))
      ) ||
      element.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAddEmployeeButtonClick = () => {
    navigate('/numbers/add-responsible-person');
  };

  const renderEmptyDataMessage = () => {
    if (error) {
      return translate('SOMETHING_WENT_WRONG');
    }
    return translate('NOTHING_FOUND');
  };

  const handleRemoveResponsibleButtonClick = async () => {
    const result = await resultify(
      removeEndUser({ variables: { id: responsiblePersonIdForDeletion } })
    );
    if (result.type === 'error') {
      globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      return;
    }

    responsiblePersonsContext$.responsiblePersonIdForDeletion.set(null);
    refetchResponsiblePersonsQuery();
  };

  const handleResponsiblePersonDeletionDialogClose = () => {
    if (removeEndUserLoading) {
      return;
    }
    responsiblePersonsContext$.responsiblePersonIdForDeletion.set(null);
  };

  const handleCannotRemoveInfoDialogClose = () => {
    responsiblePersonsContext$.isCannotRemoveInfoDialogOpen.set(false);
  };

  const renderCannotRemoveInfoDialogContent = () => {
    return <Typography>{translate('EMPLOYEE_IS_RESPONSIBLE_FOR_THE_NUMBERS_IN_PBX')}</Typography>;
  };

  const renderResponsiblePersonDeletionDialogContent = () => {
    return <Typography>{translate('RESPONSIBLE_AND_HIS_DATA_WILL_BE_REMOVED')}</Typography>;
  };

  return (
    <BodyContainer disableOverflow>
      <FormProvider {...formMethods}>
        <div className={classes.topBlock}>
          <NumbersTabs isResponsiblePersonsTabActive />
          <div>
            <QuickSearch
              classNameInput={classes.height2}
              parameterName="searchQuery"
              loading={responsiblePersonsQueryLoading}
            />
            <Button
              className={classes.marginLeft1}
              smallHeight
              onClick={handleAddEmployeeButtonClick}
            >
              <Typography color="base">{translate('ADD_RESPONSIBLE')}</Typography>
            </Button>
          </div>
        </div>
        <LazyTable
          columns={columns}
          data={filteredData || []}
          rowLoading={responsiblePersonsQueryLoading}
          emptyDataMessage={renderEmptyDataMessage()}
        />
      </FormProvider>
      <MessageDialog
        isOpen={typeof responsiblePersonIdForDeletion === 'number'}
        title={translate('ARE_YOU_SURE')}
        renderContent={renderResponsiblePersonDeletionDialogContent()}
        onCancel={handleResponsiblePersonDeletionDialogClose}
        buttonSuccessProps={{
          titleCode: 'REMOVE',
          color: 'error',
          loading: removeEndUserLoading,
          onClick: handleRemoveResponsibleButtonClick,
        }}
        buttonCancelProps={{
          titleCode: 'CANCEL',
          disabled: removeEndUserLoading,
          onClick: handleResponsiblePersonDeletionDialogClose,
        }}
      />
      <MessageDialog
        isOpen={isCannotRemoveInfoDialogOpen}
        title={translate('UNABLE_TO_DELETE')}
        renderContent={renderCannotRemoveInfoDialogContent()}
        onCancel={handleCannotRemoveInfoDialogClose}
        buttonSuccessProps={{
          titleCode: 'CLOSE',
          onClick: handleCannotRemoveInfoDialogClose,
        }}
      />
    </BodyContainer>
  );
};

export default ResponsiblePersons;
