import { numberKeyValueType } from '@components/typings/interfaces';

export const getMonthTranslationCode = (monthNumber: number) => {
  const monthDays: numberKeyValueType = {
    0: 'MONTH_JANUARY',
    1: 'MONTH_FEBRUARY',
    2: 'MONTH_MART',
    3: 'MONTH_APRIL',
    4: 'MONTH_MAY',
    5: 'MONTH_JUNE',
    6: 'MONTH_JULY',
    7: 'MONTH_AUGUST',
    8: 'MONTH_SEPTEMBER',
    9: 'MONTH_OCTOBER',
    10: 'MONTH_NOVEMBER',
    11: 'MONTH_DECEMBER',
  };

  return monthDays[monthNumber];
};
