import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import Button from '@shared/components/Button';
import { XIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import { AudioPlayer } from '@shared/components/AudioPlayer';
import { ICellProps } from '@components/LazyTable';
import { useTranslation } from 'react-i18next';
import MessageDialog from '@shared/components/MessageDialog';
import ButtonDownload from '@shared/components/ButtonDownload';
import getBasicButtonDownloadHandleErrorCallback from '@components/utils/getBasicButtonDownloadHandleErrorCallback';
import { useHistoryStyle } from '../HistoryPage.styles';
import { callTypes, IHistoryList, IPlayerState } from '../HistoryPage.interfaces';

export const InternalActionsCell: FunctionComponent<ICellProps<IHistoryList>> = ({ item }) => {
  const classes = useHistoryStyle();
  const [translate] = useTranslation();

  const audioPanelRef = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<IPlayerState>({
    isPlayerExpanded: false,
    pause: undefined,
    isOpen: false,
  });
  const [showErrorDialog, setShowErrorDialog] = useState({
    isOpen: false,
    title: '',
    message: '',
  });

  function handleClickPlay() {
    if (item) {
      setState((prevState) => ({
        ...prevState,
        isPlayerExpanded: true,
        pause: false,
      }));
    }
  }

  function handleClosePlayer() {
    setState((prevState) => ({
      ...prevState,
      pause: true,
      isPlayerExpanded: false,
    }));
  }

  const handleCloseErrorDialog = useCallback(() => {
    setShowErrorDialog({
      isOpen: false,
      title: '',
      message: '',
    });
  }, []);

  useEffect(() => {
    const clickOutside = (event: MouseEvent) => {
      if (audioPanelRef && !audioPanelRef.current?.contains(event?.target as Node)) {
        if (state.isPlayerExpanded) {
          handleClosePlayer();
        }
      }
    };

    if (audioPanelRef.current) {
      document.addEventListener('mousedown', clickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [state.isPlayerExpanded]);

  const computedClasses = clsx(classes.audioWrap, {
    [classes.show]: state.isPlayerExpanded,
    [classes.showInternal]: state.isPlayerExpanded,
    [classes.hide]: !state.isPlayerExpanded,
  });
  if (!item) {
    return <div />;
  }

  const { call, record = '' } = item;
  const fax = item.fax || '';

  const isMissed = call === callTypes.IncomingMissed || call === callTypes.OutgoingMissed;

  return (
    <div ref={audioPanelRef} className={classes.audioPanel}>
      {isMissed && fax.length > 0 && (
        <ButtonDownload
          className={clsx(classes.button)}
          url={fax}
          onDownloadError={getBasicButtonDownloadHandleErrorCallback(translate)}
        />
      )}

      {!isMissed && (
        <div className={computedClasses}>
          <div className={state.isPlayerExpanded ? classes.playerWrapper : classes.button}>
            {record && (
              <AudioPlayer
                isPaused={state.pause}
                isExpanded={state.isPlayerExpanded}
                collapseOnEnd={false}
                onClick={handleClickPlay}
                onErrorButtonClick={handleClickPlay}
                source={record}
                withSpeed
              />
            )}
          </div>
          {record && (
            <ButtonDownload
              className={classes.button}
              url={record}
              onDownloadError={getBasicButtonDownloadHandleErrorCallback(translate)}
            />
          )}
          {state.isPlayerExpanded && (
            <div className={classes.button}>
              <Button
                variant={'secondary'}
                color={'error'}
                onClick={handleClosePlayer}
                className={classes.playerButton}
              >
                <XIcon />
              </Button>
            </div>
          )}
        </div>
      )}
      <MessageDialog
        isOpen={showErrorDialog.isOpen}
        title={translate(showErrorDialog.title)}
        message={translate(showErrorDialog.message)}
        onCancel={handleCloseErrorDialog}
        renderControls={
          <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
        }
      />
    </div>
  );
};
