import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { DomainNumber } from '../PhoneNumbers.interfaces';

export const NumberTypeCell: FunctionComponent<ICellProps<DomainNumber>> = ({ item }) => {
  const [translate] = useTranslation();

  return (
    <Typography type={'text3'} color={'tertiary900'}>
      {translate(`NUMBER_${item.category}`)}
    </Typography>
  );
};
