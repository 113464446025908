import React, { FunctionComponent } from 'react';
import { IDrawerProps } from '@components/Drawer/Drawer.interfaces';
import { Drawer as MuiDrawer } from '@material-ui/core';
import { useDrawerStyles } from '@components/Drawer/Drawer.styles';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/components/Button/Button';
import { XIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import { getDrawerWidthBySize } from './Drawer.functions';

export const Drawer: FunctionComponent<IDrawerProps> = ({
  width,
  size = 'sm',
  rootClass,
  wrapperClass,
  headerClass,
  contentClass,
  footerClass,
  variant = 'temporary',
  anchor = 'right',
  title,
  children,
  ModalProps,
  hasFooterDivider = true,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  isInvisible,
  onClose,
  ...props
}) => {
  const classes = useDrawerStyles({ width: width || getDrawerWidthBySize(size), hasFooterDivider });
  const [translate] = useTranslation();

  const handleCloseButtonClick = () => {
    onClose?.();
  };

  const shouldRenderFooter = primaryButton || secondaryButton || tertiaryButton;

  return (
    <MuiDrawer
      classes={{ root: clsx(classes.root, rootClass), paper: clsx(classes.wrapper, wrapperClass) }}
      ModalProps={{
        ...ModalProps,
        BackdropProps: { invisible: isInvisible, ...ModalProps?.BackdropProps },
      }}
      variant={variant}
      anchor={anchor}
      onClose={onClose}
      {...props}
    >
      <div className={clsx(classes.header, headerClass)}>
        <Typography className={classes.title} type={'text3'} color={'base'}>
          {translate(title)}
        </Typography>
        <Button
          variant={'tertiary'}
          className={classes.closeButton}
          onClick={handleCloseButtonClick}
        >
          <XIcon />
        </Button>
      </div>
      <div className={clsx(classes.content, contentClass)}>{children}</div>
      {shouldRenderFooter && (
        <div className={clsx(classes.footer, footerClass)}>
          {primaryButton && (
            <Button
              className={classes.primaryButton}
              classes={{ label: classes.buttonLabel }}
              onClick={() => primaryButton.onClick?.()}
              {...primaryButton.props}
            >
              {translate(primaryButton.title)}
            </Button>
          )}
          {secondaryButton && (
            <Button
              className={classes.secondaryButton}
              classes={{ label: classes.buttonLabel }}
              variant={'secondary'}
              onClick={() => secondaryButton.onClick?.()}
              {...secondaryButton.props}
            >
              {translate(secondaryButton.title)}
            </Button>
          )}
          {tertiaryButton && (
            <Button
              className={classes.tertiaryButton}
              classes={{ label: classes.buttonLabel }}
              variant={'tertiary'}
              onClick={() => tertiaryButton.onClick?.()}
              {...tertiaryButton.props}
            >
              {translate(tertiaryButton.title)}
            </Button>
          )}
        </div>
      )}
    </MuiDrawer>
  );
};
