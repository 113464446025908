import React, { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import HighlightedText from '@components/HighlightedText';
import Button from '@shared/components/Button';
import { IHistoryList } from '../HistoryPage.interfaces';
import { useHistoryStyle } from '../HistoryPage.styles';

export const ViaCell: FunctionComponent<ICellProps<IHistoryList>> = ({ item }) => {
  const classes = useHistoryStyle();
  const [searchParams, setSearchParams] = useSearchParams();
  const sViaPhone = searchParams.get('via');
  const sFilterName = searchParams.get('filterName');
  const sSearch = searchParams.get('search');

  function handleViaNumberClick() {
    searchParams.set('search', '');
    searchParams.set('employee', '');
    searchParams.set('department', '');
    searchParams.set('other', '');
    searchParams.set('filterName', '');
    searchParams.set('ext', '');
    searchParams.set('phone', '');
    searchParams.set('via', item?.via || '');
    setSearchParams(searchParams);
  }

  if (item) {
    const { via } = item;
    return (
      <Button variant="text" className={classes.textHover} onClick={handleViaNumberClick}>
        <HighlightedText
          type={'phone'}
          text={via}
          query={sViaPhone || sFilterName || sSearch}
          typographyColor={'tertiary400'}
        />
      </Button>
    );
  }

  return <div />;
};
