import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useHistoryStyle = makeStyles(
  ({
    color: { base, tertiary, primary, success, links, danger, warning },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    blacklistDialog: {
      width: '24em',
    },
    contextItemBlock: {
      padding: '0.675em 1em',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: primary[50],
      },
    },
    contextItemBlockDisabled: {
      opacity: 0.5,
    },
    clientTag: {
      gap: '0.25em',
      marginTop: '0.25em',

      '& svg': {
        maxWidth: '0.75em',
        maxHeight: '0.75em',
      },
    },
    head: {
      padding: '0 2em 0.875em 1.5em',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headTitle: {
      padding: '0 1.5em 1.5em',
    },
    headRight: {
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'flex-end',
    },
    lineItem: {
      paddingLeft: '1em',
    },
    body: {
      position: 'relative',
      height: '100%',
      '& > div': {
        width: '100% !important',
      },
    },
    textHover: {
      fontSize: '1em',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      userSelect: 'text',
      borderRadius: 0,
      padding: 0,

      '&:hover *': {
        textDecoration: 'underline',
        color: primary['800'],
      },
    },
    playerButton: {
      width: '2em',
      height: '2em',
      padding: 0,
    },
    button: {
      marginLeft: '1em',
      width: '2em',
      minWidth: '2em',
      padding: 0,
      // display: 'inline-block',
      '&:first-child': {
        marginLeft: 0,
      },
    },
    audioPanel: {
      position: 'relative',
      height: '2em',
      width: '8.75em',
    },
    audioWrap: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cellCloseIcon: {
      marginLeft: '0.25em',
      color: primary[700],
      opacity: 0.6,
    },
    tooltip: {
      border: `1px solid ${tertiary[100]}`,
      background: base,
    },
    arrow: {
      color: base,
      '&::before': {
        border: `1px solid ${tertiary[100]}`,
      },
    },
    hide: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    show: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: base,
      width: '37em',
      right: '-3.75em',
      left: 'auto',
      top: '-0.75em',
      bottom: '-0.75em',
      padding: '0.75em',
      borderRadius: '0.75em',
      boxShadow: '0px 0.5em 0.625em rgba(0, 0, 0, 0.05)',
    },
    showInternal: {
      width: '37em',
    },
    headLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '1em',
      height: '2em',
      minHeight: '2em',
      backgroundColor: tertiary['50'],
      borderBottom: `solid 1px${base}`,
      '& div': {
        boxSizing: 'content-box',
      },
    },
    dateLine: {
      paddingRight: '1em',
      height: '2em',
      backgroundColor: tertiary['50'],
      borderTop: `solid 1px${base}`,
      borderBottom: `solid 1px${base}`,
      '& div': {
        boxSizing: 'content-box',
      },
    },
    cellDirection: {
      paddingLeft: '2em',
    },
    cellNumber: {
      paddingLeft: '1.5em',
    },
    cellHighlightOnParentHover: {},
    cellLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
      '& div': {
        boxSizing: 'content-box',
      },
      '&:hover': {
        backgroundColor: tertiary['50'],
        '&:last-child': {
          borderBottom: 'solid 1px white',
        },
      },
      '&:hover $cellHighlightOnParentHover *': {
        textDecoration: 'underline',
        color: primary['700'],
      },
    },
    cellAction: {
      paddingRight: '2.5em',
      paddingLeft: '0.5em',
      overflow: 'visible',
    },
    message: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
    },
    xls: {
      transition: createTransition(['color'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      color: tertiary['900'],
      '&:hover': {
        backgroundColor: tertiary[100],
        borderRadius: '0.325em',
      },
    },
    success: {
      color: success[600],
    },
    warning: {
      color: warning[600],
    },
    links: {
      color: links[600],
    },
    danger: {
      color: danger[600],
    },
    tertiary: {
      color: tertiary[600],
    },
    list: {
      willChange: 'opacity !important',
      width: '100% !important',
    },
    emptyBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '14em',
    },
    ClockIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    playerWrapper: {
      width: '100%',
    },
    ...styleConstants,
  }),
  {
    index: 5,
    name: 'History',
  }
);
