import { DocumentModel } from '@/client/generated/graphql';
import { ICellProps } from '@components/LazyTable';
import Typography from '@shared/components/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentsStyle } from '../DocumentsList.styles';

export const DocumentsTypeCell = ({ item }: ICellProps<DocumentModel>) => {
  const classes = useDocumentsStyle();
  const [translate] = useTranslation();

  if (item) {
    const isOldContract = item.type === 'CONTRACT' && item.terminatedAt;
    return (
      <div className={classes.documentTypeBlock}>
        <Typography color={'tertiary900'} type={'text3'}>
          {translate(isOldContract ? 'OLD_CONTRACT' : item.type)}
        </Typography>
        {!!item.comment && (
          <Typography color={'tertiary400'} type={'text3'}>
            {item.comment}
          </Typography>
        )}
        {isOldContract && (
          <Typography color={'tertiary400'} type={'text3'}>
            {translate('CONTRACT_TERMINATED')}
          </Typography>
        )}
      </div>
    );
  }

  return <div />;
};
