import { makeStyles } from '@material-ui/core';
import { pxToEm } from '@shared/utils/styles';

export const useSettingsPagesStyles = makeStyles(
  ({
    color: { primary, tertiary, base, links },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    cardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0 1em 0 2em',
      boxSizing: 'border-box',
    },
    insideContainer: {
      minWidth: '600px',
      paddingTop: '1em',
      paddingLeft: '2em',
    },
    cardBlock: {
      margin: '0.5em 1em 0.5em 0',
    },
    container: {
      maxWidth: '32em',
      paddingTop: '1em',
    },
    customContainer: {
      maxWidth: '41em',
    },
    description: {
      paddingBottom: '1em',
    },
    controls: {
      width: '20em',
    },
    confirmButton: {
      minWidth: '12em',
      height: '2.5em',
    },
    form: {
      minHeight: '20em',
      '& label': {
        marginLeft: 0,
      },
    },
    selectRoot: {
      padding: 0,
      height: '2.5em',
      '& li:hover': {
        backgroundColor: 'transparent',
      },
      '& svg': {
        display: 'none',
      },
      '& *': {
        color: tertiary[900],
      },
    },
    selectText: {
      padding: '0.5em 1em',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    selectTime: {
      paddingRight: '1em',
      display: 'flex',
      alignItems: 'center',
    },
    selectIcon: {
      marginRight: '0.5em',
      color: primary[700],
    },
    selectIconAbsenceMarginLeft: {
      marginLeft: '1.5em',
    },
    selectLabel: {
      marginRight: 'auto',
    },
    selectTimeZone: {
      paddingLeft: '1em',
    },
    message: {
      display: 'flex',
      justifyContent: 'center',
    },
    toneWrapper: {
      width: '46em',
      paddingTop: '1em',
      paddingLeft: '2em',
    },
    accordion: {
      boxShadow: 'none',
      border: `1px solid ${tertiary[200]}`,
      borderRadius: '0.75em',
      '&:before': {
        display: 'none',
      },
      marginBottom: '1em',
    },
    accordionSumRoot: {
      borderRadius: '0.75em',
      padding: '0 1em 0 2em',
      transition: createTransition(['backgroundColor'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      backgroundColor: tertiary[50],
      '&:hover': {
        backgroundColor: tertiary[100],
      },
    },
    accordionTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0.75em 0',
    },
    accordionTitleExpanded: {
      backgroundColor: tertiary[100],
      '&.Mui-expanded': {
        minHeight: 'auto',
      },
      '& .Mui-expanded': {
        margin: '0.75em 0',
      },
    },
    accordionBody: {
      padding: '1.5em 2em 2em',
      display: 'block',
    },
    accordionItem: {
      paddingTop: '1em',
      '&:first-child': {
        paddingTop: 0,
      },
    },
    radioGroup: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '1em',
      '& label': {
        marginRight: '2em',
      },
    },
    radio: {
      paddingLeft: 0,
    },
    playerContainer: {
      width: '32em',
      boxSizing: 'border-box',
      padding: '1.5em 2em 2em',
      borderRadius: '5px',
      position: 'relative',
      backgroundColor: tertiary[50],
      border: `1px solid ${tertiary[300]}`,
    },
    player: {
      padding: '1em',
      border: `1px solid ${tertiary[300]}`,
      borderRadius: '0.75em',
      backgroundColor: base,
      marginBottom: '1em',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    preloader: {
      padding: '1em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    settingList: {
      paddingTop: '2.375em',
    },
    settingWrapper: {
      borderRadius: '0.75em',
      backgroundColor: links[50],
      marginBottom: '1em',
      padding: '0.75em 1em',
    },
    settingIconText: {
      paddingLeft: '0.75em',
      gap: '0.25em',
    },
    settingIcon: {
      color: links[600],
      width: '1.25em',
      height: '1.25em',
    },
    marginTop16: {
      marginTop: pxToEm(16),
    },
    carouselForm: {
      width: '26.5em',
    },
    carouselNameRow: {
      width: '21.5em',
      height: '2.5em',
      backgroundColor: tertiary[50],
      padding: '0 1em',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '0.375em',
    },
    carouselTrashButton: {
      width: '2.5em',
      minWidth: 'unset',
      marginLeft: '.5em',
      padding: 0,

      '& svg': {
        color: tertiary[900],
      },
    },
    addEmployee: {
      margin: '1em 2.5em',
      width: '21.5em',
    },
    addEmployeeOpen: {
      backgroundColor: primary[200],
      border: `1px solid ${primary[600]}`,
      color: primary[700],
    },
    addEmployeeEmptyList: {
      margin: '0em',
      width: '24em',
    },
    employeesListTitle: {
      margin: '1.25em 0 1em',
    },
    employeesWrap: {
      flexDirection: 'column',
      gap: '0.5em',
    },
    carouselNotification: {
      width: '41.25em',
      padding: '1em',
      backgroundColor: links[50],
      borderRadius: '0.75em',
      marginTop: '2em',
      gap: '0.75em',
    },
    carouselIconBlue: {
      '& svg': {
        color: links[600],
        width: '1.25em',
        height: '1.25em',
      },
    },
    carouselNotificationTextWrap: {
      flexDirection: 'column',
      gap: '0.25em',
    },
    contentBottomSpace: {
      bottom: '6em',
    },
  }),
  {
    index: 2,
  }
);
