import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface ISearchParamRelevantValues {
  [key: string]: { validate: (value: string) => boolean; defaultValue?: string } | null;
}

export const useSearchParamsValidator = (validators: ISearchParamRelevantValues) => {
  const [isSearchParamsChecked, setIsSearchParamsChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.forEach((value, key) => {
      if (key in validators) {
        const validator = validators[key];

        if (validator) {
          const isValid = validator.validate(value);
          if (!isValid) {
            if (validator.defaultValue) {
              searchParams.set(key, validator.defaultValue);
            } else {
              searchParams.delete(key);
            }
            setSearchParams(searchParams);
          }
        }
      } else {
        searchParams.delete(key);
        setSearchParams(searchParams);
      }
    });
    setIsSearchParamsChecked(true);
  }, [searchParams, validators, setSearchParams]);

  return { searchParams, setSearchParams, isSearchParamsChecked };
};

export const validateSearchParamsEnum = (type: { [key: string]: string }) => (value: string) =>
  Object.values(type)
    .map((item) => item.toString().toLowerCase())
    .includes(value.toLowerCase());

export const validateSearchParamsDate = (value: string) => {
  return new Date(value) instanceof Date && !Number.isNaN(new Date(value).valueOf());
};

export const validateSearchParamsTime = (value: string) =>
  /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value);
