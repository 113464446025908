import { makeStyles } from '@material-ui/core';

export const useDateFieldStyles = makeStyles(
  ({ color: { tertiary, primary, base } }) => ({
    datePicker: {
      '& .MuiPickersBasePicker-pickerView': {
        width: '17.5em',
      },
      '& .MuiPickersCalendar-root': {
        minHeight: '13.25em',
      },
      '& .MuiPickersCalendarHeader-root': {
        paddingLeft: '0.875em',
        paddingRight: '0.875em',
      },
      '& .MuiPickersYear-yearButton': {
        width: '3.875em',
        borderRadius: '3px',
      },
      '& .MuiIconButton-root': {
        borderRadius: '3px',
      },
      '& .MuiPickersArrowSwitcher-root': {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      },
      '& .MuiPickersCalendarHeader-monthTitleContainer': {
        position: 'absolute',
        left: '50%',
        margin: 0,
        transform: 'translateX(-50%)',
      },
      '& .MuiPickersDay-root': {
        width: '2em',
        height: '2em',
        borderRadius: '3px',
        fontSize: '1em',
        color: tertiary[900],
        '&.MuiPickersDay-dayOutsideMonth': {
          color: tertiary[300],
        },
        '&.Mui-selected': {
          color: base,
          backgroundColor: primary[700],
          '&:hover': {
            backgroundColor: primary[700],
          },
          '&:focus': {
            backgroundColor: primary[700],
          },
          '&.MuiPickersDay-dayOutsideMonth': {
            border: 'none',
            backgroundColor: 'transparent',
            color: tertiary[300],
          },
        },
        '&.Mui-disabled': {
          color: tertiary[300],
          backgroundColor: tertiary[50],
        },
      },
      '& .MuiPickersCalendar-weekDayLabel': {
        width: '2.285em',
        height: '2.285em',
        fontSize: '0.875em',
        margin: '0 2px',
      },
      '& .MuiPickersDateRangeDay-day': {
        transform: 'scale(1)',
        '& > *': {
          transform: 'scale(1)',
        },
      },
      '& .MuiPickersDay-dayLabel': {
        fontSize: '0.875em',
      },
      '& .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
        borderColor: 'transparent',
        border: 'none',
      },
      '& .MuiPickersDateRangeDay-rangeIntervalPreview': {
        border: 'none',
      },
      '& .MuiPickersDateRangeDay-root': {
        width: '2em',
        height: '2em',
        '&:first-child': {
          '& .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
            borderColor: 'transparent',
            border: 'none',
          },
        },
        '&:last-child': {
          '& .MuiPickersDateRangeDay-rangeIntervalDayPreview': {
            borderColor: 'transparent',
            border: 'none',
          },
        },
      },
      '& .MuiPickersDateRangeDay-rangeIntervalDayHighlight': {
        backgroundColor: primary[600],
        '&:first-child': {
          borderRadius: '3px 0 0 3px',
        },
        '&:last-child': {
          borderRadius: '0 3px 3px 0',
        },
      },
      '& .MuiPickersDateRangeDay-rangeIntervalDayHighlightEnd': {
        borderRadius: '0 3px 3px 0',
        marginRight: 0,
      },
      '& .MuiPickersDateRangeDay-rangeIntervalDayHighlightStart': {
        borderRadius: '3px 0 0 3px',
        marginLeft: 0,
      },
      '& .MuiPickersDesktopDateRangeCalendar-calendar': {
        minWidth: '15.875em',
        minHeight: '14.875em',
      },
    },
  }),
  {
    index: 5,
  }
);
