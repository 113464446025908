import React from 'react';
import { Button as ButtonMUI } from '@material-ui/core';
import {
  AlertCircleIcon,
  AlertTriangleIcon,
  CheckCircleIcon,
  XIcon,
} from '@shared/assets/images/icons';
import clsx from 'clsx';
import { useAlertStyles } from './Alert.styles';
import { AlertProps } from './Alert.types';
import Button from '../Button';
import Typography from '../Typography';

const Alert = ({
  buttonActionProps,
  classes: classesFromProps,
  customContent,
  description,
  onClose,
  status,
  title,
  variant = 'standard',
  icon,
  rootRef,
}: AlertProps) => {
  const classes = useAlertStyles();

  const getTextColor = () => {
    switch (variant) {
      case 'filled':
        return 'base';
      case 'outlined':
        return 'tertiary900';
      case 'standard':
        return 'tertiary900';
      default:
        variant satisfies never;
        return 'tertiary900';
    }
  };

  const getIconColorNested = () => {
    switch (status) {
      case 'success':
        return classes.iconColorSuccess;
      case 'info':
        return classes.iconColorInfo;
      case 'warn':
        return classes.iconColorWarning;
      case 'danger':
        return classes.iconColorError;
      default:
        status satisfies never;
        return classes.iconColorInfo;
    }
  };

  const getIconColor = () => {
    switch (variant) {
      case 'filled':
        return classes.iconColorBase;
      case 'outlined':
      case 'standard':
      default:
        return getIconColorNested();
    }
  };

  const getIcon = () => {
    let iconToRender: React.ReactNode;

    if (icon) {
      iconToRender = icon;
    } else {
      switch (status) {
        case 'success':
          iconToRender = <CheckCircleIcon viewBox="0 0 16 16" />;
          break;
        case 'warn':
          iconToRender = <AlertTriangleIcon viewBox="0 0 16 16" />;
          break;
        case 'info':
        case 'danger':
          iconToRender = <AlertCircleIcon viewBox="0 0 16 16" />;
          break;
        default:
          status satisfies never;
          iconToRender = <AlertCircleIcon viewBox="0 0 16 16" />;
          break;
      }
    }

    return <div className={clsx(classes.iconWrapper, getIconColor())}>{iconToRender}</div>;
  };

  const getRootStyles = () => {
    switch (status) {
      case 'success':
        return (function getForSuccess() {
          switch (variant) {
            case 'filled':
              return classes.rootFilledSuccess;
            case 'outlined':
              return classes.rootOutlinedSuccess;
            case 'standard':
              return classes.rootStandardSuccess;
            default:
              return variant satisfies never;
          }
        })();
      case 'info':
        return (function getForInfo() {
          switch (variant) {
            case 'filled':
              return classes.rootFilledInfo;
            case 'outlined':
              return classes.rootOutlinedInfo;
            case 'standard':
              return classes.rootStandardInfo;
            default:
              return variant satisfies never;
          }
        })();
      case 'warn':
        return (function getForWarn() {
          switch (variant) {
            case 'filled':
              return classes.rootFilledWarning;
            case 'outlined':
              return classes.rootOutlinedWarning;
            case 'standard':
              return classes.rootStandardWarning;
            default:
              return variant satisfies never;
          }
        })();
      case 'danger':
        return (function getForError() {
          switch (variant) {
            case 'filled':
              return classes.rootFilledError;
            case 'outlined':
              return classes.rootOutlinedError;
            case 'standard':
              return classes.rootStandardError;
            default:
              return variant satisfies never;
          }
        })();
      default:
        return status satisfies never;
    }
  };

  const renderDescription = () => {
    if (!description) {
      return null;
    }
    if (typeof description === 'string') {
      return (
        <Typography className={classes.description} color={getTextColor()}>
          {description}
        </Typography>
      );
    }
    return description;
  };

  const isThereCloseButton = !!onClose;
  const isThereActionButton = !!buttonActionProps;
  const isThereButtonsBlock = isThereCloseButton || isThereActionButton;

  return (
    <div className={clsx(classes.root, getRootStyles(), classesFromProps?.root)} ref={rootRef}>
      <div className={clsx(classes.leftBlock, classesFromProps?.leftBlock)}>{getIcon()}</div>
      <div className={clsx(classes.rightBlock, classesFromProps?.rightBlock)}>
        {isThereButtonsBlock && (
          <div className={classes.rightBlockTopButtons}>
            {isThereActionButton && (
              <Button
                variant="secondary"
                smallHeight
                {...buttonActionProps}
                className={clsx(classes.rightBlockTopButtonAction, buttonActionProps.className)}
                classNameTypography={clsx(
                  classes.rightBlockButtonActionTypography,
                  buttonActionProps.classNameTypography
                )}
              />
            )}
            {isThereCloseButton && (
              <ButtonMUI className={classes.rightBlockTopButtonClose} onClick={onClose}>
                <XIcon className={clsx(classes.xIcon, classes[getTextColor()])} />
              </ButtonMUI>
            )}
          </div>
        )}
        <div className={classes.spaceDividerMarginTop} />
        {customContent || (
          <>
            <Typography className={classes.title} medium color={getTextColor()}>
              {title}
            </Typography>
            <div className={clsx(classes.descriptionWrapper, classesFromProps?.descriptionWrapper)}>
              {renderDescription()}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Alert;
