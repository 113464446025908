import merge from 'lodash.merge';
import React, { useMemo } from 'react';
import { useFormContext, RegisterOptions, Path } from 'react-hook-form';
import { mergeMultipleRefs } from '@shared/utils/react';
import FormFieldBase from '../FormFieldBase';
import { FormFieldRhfUncontrolledProps } from './FormFieldRhfUncontrolled.interfaces';

/** This component relies on react-hook-form context provider. Use it only when you have one above, otherwise it won't work. */
function FormFieldRhfUncontrolled<
  FormFields extends Record<string, any> = Record<string, any>,
  FieldName extends Path<FormFields> = Path<FormFields>,
>({
  name,
  error,
  onChange,
  onBlur,
  required,
  validate,
  type = 'text',
  disabled,
  rules,
  inputRef,
  ...props
}: FormFieldRhfUncontrolledProps<FormFields, FieldName>) {
  const formMethods = useFormContext<FormFields>();

  const rulesComputed: RegisterOptions<FormFields, FieldName> = useMemo(() => {
    const registerOptionsFromProps = { required, onChange, onBlur, validate };
    const additionalRules: RegisterOptions<FormFields, FieldName> = {};
    if (type === 'number') {
      additionalRules.valueAsNumber = true;
    }
    if (type === 'date') {
      additionalRules.valueAsDate = true;
    }
    return merge(registerOptionsFromProps, rules, additionalRules);
  }, [required, onChange, onBlur, type, rules, validate]);

  if (formMethods === null) {
    console.error('Try to render FormFieldRhf without FormProvider above! Form methods is null!');
    return null;
  }

  const { ref, ...restRegisterProps } = formMethods.register(name, rulesComputed);

  return (
    <FormFieldBase
      {...props}
      {...restRegisterProps}
      disabled={disabled}
      error={Boolean(error)}
      inputRef={mergeMultipleRefs(inputRef, ref)}
      type={type}
    />
  );
}

export default FormFieldRhfUncontrolled;
