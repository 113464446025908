import React from 'react';
import { ICellProps } from '@components/LazyTable';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { NumberType } from '@/client/generated/graphql';
import clsx from 'clsx';
import { DomainNumber, PhoneNumberFormFields } from '../PhoneNumbers.interfaces';
import { phoneNumbersContext$ } from '../PhoneNumbers';

const useStyles = makeStyles(({ color: { tertiary, links } }) => ({
  responsibleButton: {
    padding: 0,
    border: 0,
    color: links[600],
  },
  responsibleButtonWithEndUser: {
    color: tertiary[900],
    textDecoration: 'underline',
    '&:hover': {
      color: links[600],
      textDecoration: 'underline',
    },
  },
}));

export const NumberResponsibleCell = ({ item }: ICellProps<DomainNumber>) => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const formMethods = useFormContext<PhoneNumberFormFields>();

  const handleButtonClick = () => {
    formMethods.setValue('endUserId', item.endUser?.id || null);

    let additionalNotification = '';
    if (item.type === NumberType.Runexis && typeof item.code === 'number' && item.code >= 900) {
      if (typeof item.endUser?.id === 'number') {
        additionalNotification = translate('RUNEXIS_NUMBER_RESPONSIBLE_PERSON_CHANGE_INFO');
      } else {
        additionalNotification = translate('RUNEXIS_NUMBER_REGISTRATION_PERIOD_INFO');
      }
    }

    phoneNumbersContext$.domainNumberId.set(item.id);
    phoneNumbersContext$.phoneNumber.set(item.phone);
    phoneNumbersContext$.isDialogSelectResponsibleOpen.set(true);
    phoneNumbersContext$.additionalNotification.set(additionalNotification);
  };

  const nameArr = [];
  if (item.endUser?.surname) {
    nameArr.push(item.endUser?.surname);
  }
  if (item.endUser?.name) {
    nameArr.push(item.endUser?.name);
  }
  if (item.endUser?.patronymic) {
    nameArr.push(item.endUser?.patronymic);
  }

  return (
    <Button
      className={clsx(
        classes.responsibleButton,
        typeof item.endUser?.id === 'number' && classes.responsibleButtonWithEndUser
      )}
      variant="text"
      onClick={handleButtonClick}
    >
      <Typography color="inherit">{nameArr.join(' ') || translate('CHOOSE')}</Typography>
    </Button>
  );
};
