import React, { useCallback, useMemo, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Drawer from '@components/Drawer';
import { EMPLOYEES_QUERY } from '@/client/queries';
import { SearchIcon, XIcon } from '@shared/assets/images/icons';
import { CheckboxField } from '@shared/components/CheckboxField/CheckboxField';
import Preloader from '@shared/components/Preloader';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import { useEmployeesSelectionStyles } from './EmployeesSelection.styles';
import { Employee, IEmployeesSelectionProps } from './EmployeesSelection.interfaces';

export const EmployeesSelection = ({
  selected,
  onSelect,
  open,
  onClose,
  modalProps = {},
  width,
}: IEmployeesSelectionProps) => {
  const classes = useEmployeesSelectionStyles({ width });
  const { data: { employees: items = [] } = { employees: [] }, loading: loadingEmployees } =
    useQuery(EMPLOYEES_QUERY);
  const [translate] = useTranslation();
  const [filterText, changeFilterText] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [isChanged, setIsChanged] = useState(false);

  const handleClose = useCallback(() => {
    changeFilterText('');
    setSelectedEmployees([]);
    setIsChanged(false);
    onClose();
  }, [onClose]);

  const handleSelection = useCallback(() => {
    onSelect(selectedEmployees);
    handleClose();
  }, [handleClose, onSelect, selectedEmployees]);

  const handleSelectEmployee = useCallback(
    (item: Employee) => {
      const foundEmployeeIndex = selectedEmployees.findIndex(({ id }) => item.id === id);
      const newSelection = [...selectedEmployees];
      if (foundEmployeeIndex >= 0) {
        newSelection.splice(foundEmployeeIndex, 1);
      } else {
        newSelection.push(item);
      }
      setSelectedEmployees(newSelection);

      if (!isChanged) {
        setIsChanged(true);
      }
    },
    [setIsChanged, isChanged, selectedEmployees]
  );

  const renderItem = ({ id, ext, user, ...rest }: Employee) => {
    const isSelected = selectedEmployees.some(({ id: selectedId }) => selectedId === id);
    const name = user.name || '';

    return (
      <div
        key={id}
        className={clsx(classes.listItem, {
          [classes.listItemSelected]: isSelected,
        })}
        onClick={() => handleSelectEmployee({ id, user, ext, ...rest })}
      >
        <Flex className={classes.listItemWrapper} justifyContent={'spaceBetween'}>
          <div className={classes.title}>
            <CheckboxField checked={isSelected} color={'primary'} />
            {name || ''}
          </div>
          <Typography className={classes.listItemExtWrapper} type={'text3'} color={'tertiary900'}>
            {ext || ''}
          </Typography>
        </Flex>
        <div />
      </div>
    );
  };

  const filteredItems = useMemo(
    () =>
      items.filter(
        ({ id, ext, user }) =>
          !selected.some(({ id: selectedId }) => selectedId === id) &&
          (selectedEmployees.some(({ id: selectedId }) => selectedId === id) ||
            !filterText ||
            (filterText && (user.name || '').toUpperCase().includes(filterText.toUpperCase())) ||
            (filterText && ext?.includes(filterText)))
      ),
    [filterText, items, selected, selectedEmployees]
  );

  const renderDrawerItems = () => {
    if (loadingEmployees) return <Preloader size={'medium'} />;
    if (filterText) return translate('NOTHING_FOUND');
    if (!filterText && !loadingEmployees) return translate('ALL_ADDED_EMPLOYEES');
    return '';
  };

  return (
    <Drawer
      title={'CHOOSE_DEPARTMENT_EMPLOYEES'}
      ModalProps={{
        BackdropProps: { ...(modalProps?.BackdropProps || {}) },
        disableBackdropClick: isChanged,
      }}
      elevation={4}
      open={open}
      primaryButton={{
        title: 'ADD_EMPLOYEES',
        onClick: handleSelection,
        props: {
          disabled: !filteredItems.length,
        },
      }}
      secondaryButton={{
        title: 'CANCEL',
        onClick: handleClose,
      }}
      onClose={handleClose}
    >
      <TextField
        type={'text'}
        placeholder={translate('SEARCH_BY_NAME')}
        InputProps={{
          classes: { root: classes.searchInputRoot },
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position={'end'}>
              {filterText.length ? (
                <div className={classes.clearIcon}>
                  <XIcon onClick={() => changeFilterText('')} />
                </div>
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
        }}
        value={filterText}
        onChange={({ target: { value = '' } = {} }) => changeFilterText(value)}
      />
      <div className={classes.drawerContent}>
        {filteredItems.length ? (
          filteredItems.map(renderItem)
        ) : (
          <div className={classes.listItemEmpty}>{renderDrawerItems()}</div>
        )}
      </div>
    </Drawer>
  );
};

export default EmployeesSelection;
