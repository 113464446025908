import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import Translate from '@shared/components/Translate';
import Flex from '@shared/components/Flex';
import { FormProvider, useForm } from 'react-hook-form';
import { DomainStatus, TariffInitStateType } from '@components/typings/interfaces';
import clsx from 'clsx';
import { NavLink, useNavigate } from 'react-router-dom';
import { AlertCircleIcon, MinusIcon, PlusIcon } from '@shared/assets/images/icons';
import { toPrecision } from '@components/utils';
import { getCurrentDomain, getMonthRatio } from '@/utils';
import { Button } from '@shared/components/Button/Button';
import MessageDialog from '@shared/components/MessageDialog';
import { FormArrowSwitcher } from '@components/FormArrowSwitcher/FormArrowSwitcher';
import { MAX_ACCOUNT_AMOUNT } from '@/features/Documents/Contract/Contract.constants';
import BodyContainer from '@/layouts/BodyContainer';
import {
  getAccountsPrices,
  getComputedMinEmployeesTooltipText,
  getComputedMinIntegrationsTooltipText,
  getTariffExactOptions,
  useTariffProps,
} from '@/utils/tariff';
import {
  TariffDiscount,
  TariffIncludedOptionsList,
  TariffPriceDiscount,
} from '@components/TariffForm';
import SwitchField from '@shared/components/SwitchField';
import RadioGroup from '@shared/components/RadioGroup';
import { FormControlLabel } from '@material-ui/core';
import Radio from '@shared/components/Radio';
import { PreselectTariffType } from '@/features/Documents/Contract/Contract.interfaces';
import ControlButtons from '@shared/components/ControlButtons';
import TooltipHover from '@shared/components/Popover/TooltipHover';
import { GET_SELECTED_TARIFF_QUERY, USER_QUERY } from '@/client/queries';
import { useQuery } from '@apollo/client';
import Preloader from '@shared/components/Preloader';
import {
  TariffOptionType,
  TariffOptionCategory,
  TariffOptionModel,
} from '@/client/generated/graphql';
import BottomButtons from '@/layouts/BottomButtons';
import PreloaderPanel from '@shared/components/PreloaderPanel';
import Alert from '@shared/components/Alert';
import useThrottledCallback from '@shared/utils/hooks/useThrottledCallback';
import * as Sentry from '@sentry/react';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import { useTariffsStyle } from './Tariffs.styles';
import { useChangeTariffMethodsHooks } from './hooks/useChangeTariffMethods.hooks';
import {
  getCorrectRussianTranslationForEmployeeMinimumByAmount,
  getOptionChangeMessageText,
} from './Tariffs.utils';

type FormFields = {
  /**
   * Defines what options are selected now.
   * For example, if we have 4 options and one of them is selected, this value must be `[true,false,false,false]`.
   * Note that accounts option is not included here, because by business logic our client always has some employees accounts.
   * */
  additionalOptions: boolean[];
  accountAmount: number;
  integrationAmount: number;
  /** Despite of the name, this is an id of our records option, but represented as string. */
  records: string;
};

type TariffChangeResult = {
  [key in TariffOptionType]: {
    isPure: boolean;
    isDowngrade: boolean;
    isPromote: boolean;
    isSelected: boolean;
    domainDifference: number;
  };
};

export const Tariffs = () => {
  const classes = useTariffsStyle();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const formMethods = useForm<FormFields>();
  const { watch, setValue } = formMethods;
  const accountAmount = Number(watch('accountAmount') || 0);
  const integrationsAmount = Number(watch('integrationAmount') || 0);
  const selectedOptions = watch('additionalOptions');
  const storeRecordsId = Number(watch('records') || 0);
  const [isTariffEdit, setTariffEdit] = useState(false);

  // These two states are used for individual tariff option change modal.
  const [isTariffStructureChangeMessageDialogOpen, setIsTariffStructureChangeMessageDialogOpen] =
    useState(false);
  /** Index defines what is the tariff option we want to modify. Is should be null when
  individual tariff option change modal is closed. */
  const [individualTariffChangeIndex, setIndividualTariffChangeIndex] = useState<number | null>(
    null
  );

  const [isTariffChangeDialogShow, setTariffChangeDialogShow] = useState(false);
  const [isBalanceLowDialogShow, setBalanceLowDialogShow] = useState(false);
  const [isReset, setFormReset] = useState(false);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [tariffInitState, setTariffInitState] = useState<TariffInitStateType | null>(null);

  /**
   * This value is used to display initial current month price
   *  when we are in tariff edit mode
   */
  const [initialPrice, setInitialPrice] = useState<number>(0);

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { status } = getCurrentDomain(userData?.user) || {};

  const monthRatio = getMonthRatio();

  const sentryCaptureExceptionThrottled = useThrottledCallback((error: Error) => {
    Sentry.captureException(error);
  }, 60_000);

  const {
    accountOption,
    additionalAccountOption,
    additionalOptionsKeyList,
    additionalTariffOptions,
    optionsResultList,
    optionsComputedPrices,
    employeeComputedPrices,
    currentTariff,
    currentEmployeeCount,
    currentTariffEmployeesCount,
    currentMonthPrice,
    updateTariffData,
    isTariffRequested,
    tariffRequestData,
    tariffRequest,
    installedIntegrationsCount,
    nextMonthPrice,
    isTariffActive,
    isDataLoading,
    loadingSelectedTariff,
  } = useTariffProps(
    selectedOptions,
    accountAmount,
    integrationsAmount,
    storeRecordsId,
    tariffInitState?.map((el) => el.isSelected)
  );

  const areRecordsStorageSettingsIndividual = additionalTariffOptions[
    TariffOptionType.Records
  ]?.some((option) => typeof option?.domainTariffOptionParams?.days === 'number');

  const {
    modifyTariff,
    loadingModifyTariff,
    createTariffChangeRequest,
    loadingCreateRequest,
    cancelTariffChangeRequest,
    loadingCancelRequest,
    balance,
    getBalanceData,
  } = useChangeTariffMethodsHooks();

  const getChangedOptionList = () => {
    if (tariffInitState && selectedOptions) {
      const { totalEmployeeCount } = getTariffExactOptions(currentTariff?.options || []);
      const { accounts, options } = tariffRequestData;
      const computedAccountAmount = accounts || accountAmount;
      const isAccountUnchanged = computedAccountAmount === totalEmployeeCount;
      const isDomainsLower = computedAccountAmount < totalEmployeeCount;
      const accountDomainDifference = isDomainsLower
        ? totalEmployeeCount - computedAccountAmount
        : computedAccountAmount - totalEmployeeCount;

      const opt = additionalOptionsKeyList.reduce(
        (result: TariffChangeResult, optionName, optionIndex) => {
          const { isRequested, amount } = options[optionName] || {
            amount: 0,
            isRequested: undefined,
          };
          const optionInitCount = tariffInitState[optionIndex].amount || 0;
          const isOptionBeenEnabled = tariffInitState[optionIndex].isSelected || false;
          const isOptionNowEnabled = isRequested ?? selectedOptions[optionIndex];
          const isOptionDowngrade = isOptionBeenEnabled && !isOptionNowEnabled;
          const isOptionPromote = !isOptionBeenEnabled && isOptionNowEnabled;
          let domainDifference = 0;
          let isDomainsChanged = false;
          let isOptionDomainsCountLess = false;
          let isOptionDomainsCountMore = false;

          if (optionName === TariffOptionType.Integrations) {
            const amountInRequest = isRequested ? amount / accounts : null;
            const computedIntegrations =
              amountInRequest || (selectedOptions[optionIndex] ? integrationsAmount : 0);
            isOptionDomainsCountLess = computedIntegrations < optionInitCount;
            isOptionDomainsCountMore = computedIntegrations > optionInitCount;
            isDomainsChanged = computedIntegrations !== optionInitCount;
            if (isOptionDowngrade) {
              domainDifference = optionInitCount;
            } else if (isOptionPromote) {
              domainDifference = computedIntegrations;
            } else if (isDomainsChanged) {
              domainDifference = isOptionDomainsCountLess
                ? optionInitCount - computedIntegrations
                : computedIntegrations - optionInitCount;
            }
          }
          if (
            optionName === TariffOptionType.Records &&
            !areRecordsStorageSettingsIndividual &&
            (isOptionNowEnabled || isOptionBeenEnabled)
          ) {
            const sOptions = additionalTariffOptions[TariffOptionType.Records] || [];
            const sDaysCount =
              (tariffRequest
                ? sOptions.find(
                    (sOpt) => tariffRequest?.options?.some((req) => req.tariffOptionId === sOpt.id)
                  )?.params?.days
                : sOptions.find((sOpt) => sOpt.id === storeRecordsId)?.params?.days) || 0;
            isDomainsChanged = (optionInitCount || 0) !== (sDaysCount || 0);
            isOptionDomainsCountLess = sDaysCount < optionInitCount;
            isOptionDomainsCountMore = sDaysCount > optionInitCount;
          }
          return {
            ...result,
            [optionName]: {
              isPure: !isOptionDowngrade && !isOptionPromote && !isDomainsChanged,
              isDowngrade: isOptionDomainsCountLess || isOptionDowngrade,
              isPromote: isOptionDomainsCountMore || isOptionPromote,
              isSelected: isOptionNowEnabled,
              domainDifference,
            },
          };
        },
        {} as TariffChangeResult
      );
      return {
        ...opt,
        [TariffOptionType.Accounts]: {
          isPure: isAccountUnchanged,
          isDowngrade: isDomainsLower,
          isPromote: !isAccountUnchanged && !isDomainsLower,
          isSelected: true,
          domainDifference: accountDomainDifference,
        },
      } as TariffChangeResult;
    }
    return {} as TariffChangeResult;
  };
  const changedOptionList = getChangedOptionList();
  const changedOptionListKeys = Object.keys(changedOptionList) as TariffOptionType[];
  const { downgradeList, promoteList } = changedOptionListKeys.reduce(
    (
      res: { downgradeList: Array<TariffOptionType>; promoteList: Array<TariffOptionType> },
      optionName
    ) => {
      if (changedOptionList[optionName].isDowngrade) {
        res.downgradeList.push(optionName);
      }
      if (changedOptionList[optionName].isPromote) {
        res.promoteList.push(optionName);
      }
      return res;
    },
    { downgradeList: [], promoteList: [] }
  );

  useEffect(() => {
    if (optionsResultList && !isReset) {
      const { accountMin } = optionsResultList[0] || {};

      if (currentTariff && currentTariff?.active) {
        const cTariffOptions = currentTariff?.options || [];
        const { baseEmployeeCount, additionalEmployeeCount, integrationCount } =
          getTariffExactOptions(cTariffOptions);
        const selectedAccountAmount = baseEmployeeCount + additionalEmployeeCount;
        const baseDomainsCount = Math.max(selectedAccountAmount, currentEmployeeCount);

        const integrationItemsCount = integrationCount / selectedAccountAmount;
        const { selectedTariffOptions, tariffInitStateValue } = additionalOptionsKeyList.reduce(
          (
            result: {
              selectedTariffOptions: Array<boolean>;
              tariffInitStateValue: TariffInitStateType;
            },
            optionKey
          ) => {
            let isSelected = false;
            let optionAmount = 0;
            if (optionKey === TariffOptionType.Records && !areRecordsStorageSettingsIndividual) {
              const recordsOption = cTariffOptions.find(
                (opt) =>
                  additionalTariffOptions[TariffOptionType.Records]?.find((o) => o.id === opt.id)
              );
              if (recordsOption) {
                result.selectedTariffOptions.push(true);
                result.tariffInitStateValue.push({
                  isSelected: !isSelected,
                  amount: recordsOption?.params?.days || 0,
                });
                setValue('records', String(recordsOption.id));
                return result;
              }
            }
            if (
              currentTariff?.options.find(
                (opt) => opt.type === optionKey && opt.category === TariffOptionCategory.Additional
              )
            ) {
              isSelected = true;
              optionAmount = 1;
              if (optionKey === TariffOptionType.Integrations) {
                optionAmount = integrationItemsCount;
              }
            }
            result.tariffInitStateValue.push({
              isSelected,
              amount: optionAmount,
            });
            result.selectedTariffOptions.push(isSelected);
            return result;
          },
          { selectedTariffOptions: [], tariffInitStateValue: [] }
        );
        setValue('accountAmount', baseDomainsCount);
        setValue('additionalOptions', selectedTariffOptions);
        setValue('integrationAmount', integrationItemsCount);
        setTariffInitState(tariffInitStateValue);
        setFormReset(true);
      } else {
        setValue(
          'accountAmount',
          accountMin > currentEmployeeCount ? accountMin : currentEmployeeCount
        );
      }
    }
  }, [
    additionalOptionsKeyList,
    currentEmployeeCount,
    optionsResultList,
    setValue,
    currentTariff,
    isReset,
    installedIntegrationsCount,
    additionalTariffOptions,
    areRecordsStorageSettingsIndividual,
  ]);

  useEffect(() => {
    if (isTariffEdit && !storeRecordsId && !areRecordsStorageSettingsIndividual) {
      setValue('records', String(additionalTariffOptions[TariffOptionType.Records]?.[0].id));
    }
  }, [
    additionalTariffOptions,
    areRecordsStorageSettingsIndividual,
    isTariffEdit,
    setValue,
    storeRecordsId,
  ]);

  function handleTariffChangeReset() {
    setFormReset(false);
    setTariffEdit(false);
  }

  const handleOperationFinish = () => {
    setTariffChangeDialogShow(false);
    setTariffEdit(false);
    setSuccess(true);
    updateTariffData();

    getBalanceData().then(() => {
      handleTariffChangeReset();
    });
  };

  function handleCloseTariffChange() {
    setTariffChangeDialogShow(false);
  }

  function getDetails(optionName: TariffOptionType, forceToPickCheapestRecordsOption?: boolean) {
    const isAccounts = optionName === TariffOptionType.Accounts;
    const option = additionalTariffOptions[optionName];
    if (optionName === TariffOptionType.Records) {
      if (forceToPickCheapestRecordsOption) {
        return [...(option || [])].sort(
          (a, b) => (a.baseMonthlyFee || 0) - (b.baseMonthlyFee || 0)
        )[0];
      }
      return (option || []).find((i) => i.id === storeRecordsId);
    }
    return isAccounts ? accountOption : option?.[0];
  }

  function handleResetChangedOption(optionName: string, optionIndex: number) {
    if (optionName === TariffOptionType.Accounts) {
      const { baseEmployeeCount, additionalEmployeeCount } = getTariffExactOptions(
        currentTariff?.options || []
      );
      setValue('accountAmount', baseEmployeeCount + additionalEmployeeCount);
    } else {
      const restoredSelectedValues = [...selectedOptions];
      const { isSelected = false, amount = 0 } = tariffInitState?.[optionIndex] || {};
      restoredSelectedValues[optionIndex] = isSelected;
      if (optionName === TariffOptionType.Integrations) {
        setValue('integrationAmount', amount);
      }
      if (optionName === TariffOptionType.Records && !areRecordsStorageSettingsIndividual) {
        const sOptions = additionalTariffOptions[TariffOptionType.Records] || [];
        const recordsId = sOptions.find((sOpt) => sOpt.params.days === amount)?.id || 0;
        setValue('records', String(recordsId));
      }
      setValue('additionalOptions', restoredSelectedValues);
    }
  }

  function getRelativeOptionPrice(
    optionName: TariffOptionType,
    amount: number,
    calculateOnlyPerEmployee?: boolean,
    withoutMonthRatio?: boolean,
    forceToPickCheapestRecordsOption?: boolean,
    useAllIntegrationsAmount?: boolean
  ) {
    const details = getDetails(optionName, forceToPickCheapestRecordsOption);

    const perEmployee = Boolean(details?.params?.perEmployee || false);
    const monthlyFee = details?.monthlyFee || 0;
    const monthRatioLocal = withoutMonthRatio ? 1 : monthRatio;

    if (perEmployee) {
      let multiplier = 1;
      if (optionName === TariffOptionType.Integrations) {
        const { domainDifference, isPure, isDowngrade } = changedOptionList[optionName] || {};
        multiplier = isPure || useAllIntegrationsAmount ? integrationsAmount : domainDifference;
        if (isDowngrade) {
          multiplier = integrationsAmount + domainDifference;
        }
      }
      multiplier = multiplier || 1;
      return monthlyFee * amount * multiplier * monthRatioLocal;
    }
    if (calculateOnlyPerEmployee) {
      return 0;
    }
    return monthlyFee * monthRatioLocal;
  }

  function getPriceForAccounts(withoutMonthRatio?: boolean) {
    const { domainDifference } = changedOptionList[TariffOptionType.Accounts] || {};
    const { accountMax = 0 } = optionsResultList?.[0] || {};
    const { accountsOption, additionalAccountsOption } = getTariffExactOptions(
      currentTariff?.options || []
    );
    const { accountPrice, additionalAccountPrice } = getAccountsPrices(
      accountsOption,
      additionalAccountsOption,
      optionsResultList?.[0]
    );
    const isOnlyBase = accountAmount <= accountMax;
    const isOnlyAdditional = accountAmount - domainDifference > accountMax;

    const newAdditionalAccounts = Math.abs(accountMax - accountAmount);
    const newBaseAccounts = domainDifference - newAdditionalAccounts;

    const newDomainsTotalPrice = () => {
      if (isOnlyBase) return accountPrice * domainDifference;
      if (isOnlyAdditional) return additionalAccountPrice * domainDifference;

      return newBaseAccounts * accountPrice + newAdditionalAccounts * additionalAccountPrice;
    };

    return additionalOptionsKeyList.reduce(
      (res: number, optionName: TariffOptionType, optionIndex: number) => {
        const { isSelected } = tariffInitState?.[optionIndex] || {};
        // const { isPure, isDowngrade } = changedOptionList[optionName] || {}; // TODO try to understand why it was there and if everything still works appropriately.
        if (isSelected) {
          const price = getRelativeOptionPrice(
            optionName,
            domainDifference,
            true,
            withoutMonthRatio,
            false,
            true
          );
          return res + price;
        }
        return res;
      },
      newDomainsTotalPrice() * (withoutMonthRatio ? 1 : monthRatio)
    );
  }

  const getDataForTariffModifyRequest = (isModify: boolean, onlyPromoted?: boolean) => {
    const {
      id = 0,
      accountId = 0,
      additionalAccountId = 0,
      accountMax = 0,
    } = optionsResultList?.[0] || {};
    const accounts = onlyPromoted
      ? accountAmount + (changedOptionList[TariffOptionType.Accounts]?.domainDifference || 0)
      : accountAmount;
    const baseAccounts = accounts <= accountMax ? accounts : accountMax;
    const additionalAccounts = accounts <= accountMax ? 0 : accounts - accountMax;

    const data: PreselectTariffType = {
      tariffId: id,
      options: [
        {
          tariffOptionId: accountId,
          count: baseAccounts,
        },
      ],
    };
    if (additionalAccounts > 0) {
      data.options.push({
        tariffOptionId: additionalAccountId,
        count: additionalAccounts,
      });
    }

    additionalOptionsKeyList.forEach((optionKey: TariffOptionType, optionIndex: number) => {
      const { isDowngrade: isAccountDowngrade, domainDifference: accountDomainDifference = 0 } =
        changedOptionList[TariffOptionType.Accounts] || {};
      const { isSelected, amount = 1 } = tariffInitState?.[optionIndex] || {};
      const { isDowngrade } = changedOptionList[optionKey] || {};
      const isIntegrationOption = optionKey === TariffOptionType.Integrations;
      const sIntegrationsAmount = isIntegrationOption ? accounts * integrationsAmount || 1 : 1;
      const sOption = additionalTariffOptions[optionKey]!;
      const optionCount = isIntegrationOption ? Number(sIntegrationsAmount) : accounts;
      let sOptionId = sOption[0].id;
      let isPerEmployee = sOption[0].params?.perEmployee || false;
      if (
        sOption.length > 1 &&
        optionKey === TariffOptionType.Records &&
        !areRecordsStorageSettingsIndividual
      ) {
        const subOption = sOption.find((item) => item.id === storeRecordsId);
        sOptionId = subOption?.id || 0;
        isPerEmployee = subOption?.params?.perEmployee || false;
      }
      if (isModify && isSelected && isDowngrade) {
        const accountDomains = isAccountDowngrade ? accounts + accountDomainDifference : accounts;
        const optionAmount = isPerEmployee ? accountDomains : amount;
        data.options.push({
          tariffOptionId: sOptionId,
          count: isIntegrationOption ? amount * accountDomains : optionAmount,
        });
      } else if (selectedOptions[optionIndex]) {
        data.options.push({
          tariffOptionId: sOptionId,
          count: isPerEmployee ? optionCount : 1,
        });
      }
    });
    return {
      data,
    };
  };

  const toggleOptionSelectionByIndex = (index: number, isSelected: boolean) => {
    setValue(
      'additionalOptions',
      [...formMethods.getValues().additionalOptions].map((value, i) => {
        if (i !== index) {
          return value;
        }
        return isSelected;
      })
    );
  };

  const handleTariffChangeError = () => {
    setTariffChangeDialogShow(false);
    setError(true);
  };

  const handleTariffChange = (isTariffModify: boolean, isTariffRequest: boolean) => {
    if (loadingCreateRequest || loadingCancelRequest || loadingModifyTariff) {
      return;
    }

    if (isTariffModify && isTariffRequest) {
      modifyTariff({
        variables: { ...getDataForTariffModifyRequest(true, true) },
        refetchQueries: [
          {
            query: GET_SELECTED_TARIFF_QUERY,
          },
        ],
      })
        .then(() => {
          createTariffChangeRequest({ variables: getDataForTariffModifyRequest(false) })
            .then(() => {
              handleOperationFinish();
            })
            .catch(() => {
              handleTariffChangeError();
            });
        })
        .catch(() => {
          handleTariffChangeError();
        });
    } else if (isTariffModify) {
      modifyTariff({
        variables: getDataForTariffModifyRequest(true),
        refetchQueries: [
          {
            query: GET_SELECTED_TARIFF_QUERY,
          },
        ],
      })
        .then(() => {
          handleOperationFinish();
        })
        .catch(() => {
          handleTariffChangeError();
        });
    } else if (isTariffRequest) {
      createTariffChangeRequest({ variables: getDataForTariffModifyRequest(false) })
        .then(() => {
          handleOperationFinish();
        })
        .catch(() => {
          handleTariffChangeError();
        });
    }
  };

  function handleCloseTariffBalance() {
    setBalanceLowDialogShow(false);
    if (individualTariffChangeIndex !== null) {
      toggleOptionSelectionByIndex(individualTariffChangeIndex, false);
    }
  }

  function getPriceToPay(arr: Array<TariffOptionType>) {
    if (!arr.length) return { priceToBuy: 0, balanceAfter: -1, isBalanceLow: false };
    const priceToBuy = arr.reduce((res: number, optionName: TariffOptionType) => {
      if (optionName === TariffOptionType.Accounts) {
        const priceForAccounts = getPriceForAccounts();
        return res + priceForAccounts;
      }
      const price = getRelativeOptionPrice(
        optionName,
        Math.max(accountAmount, currentTariffEmployeesCount)
      );
      return res + price;
    }, 0);
    const isBalanceLow = balance - priceToBuy < 0;
    return {
      priceToBuy,
      balanceAfter: balance - priceToBuy,
      isBalanceLow,
    };
  }

  const getAdditionalOptionsTotalPrice = () => {
    if (individualTariffChangeIndex !== null) {
      const singleChangingOptionFactualPrice =
        optionsComputedPrices.discountPrice[individualTariffChangeIndex] ||
        optionsComputedPrices.price[individualTariffChangeIndex];
      return nextMonthPrice - singleChangingOptionFactualPrice;
    }

    return nextMonthPrice;
  };

  const { priceToBuy, balanceAfter, isBalanceLow } = getPriceToPay(promoteList);

  function handleTariffConfirmClick() {
    const isTariffPromoted = promoteList.length !== 0;
    const isTariffDowngraded = downgradeList.length !== 0;
    if (!isTariffPromoted && !isTariffDowngraded) {
      setTariffEdit(false);
      return;
    }
    if (isBalanceLow) {
      setBalanceLowDialogShow(true);
      return;
    }
    if (!isTariffChangeDialogShow && isTariffPromoted) {
      setTariffChangeDialogShow(true);
      return;
    }
    handleTariffChange(isTariffPromoted, isTariffDowngraded);
  }

  function handleTariffRequestCancel() {
    cancelTariffChangeRequest().then(() => {
      updateTariffData();
      getBalanceData();
    });
  }

  const getPromotedPrice = (
    optionName: TariffOptionType,
    withoutMonthRatio?: boolean,
    forceToPickCheapestRecordsOption?: boolean
  ) => {
    if (optionName === TariffOptionType.Accounts) {
      return getPriceForAccounts(withoutMonthRatio);
    }
    return getRelativeOptionPrice(
      optionName,
      Math.max(accountAmount, currentTariffEmployeesCount),
      false,
      withoutMonthRatio,
      forceToPickCheapestRecordsOption
    );
  };

  const renderRecordsOptionRadioGroup = (
    keyIndex: number,
    isOptionDisplaysInAvailableOptionsList?: boolean
  ) => {
    const optionsList = additionalTariffOptions[TariffOptionType.Records]!;
    const isOptionSelected = selectedOptions?.[keyIndex];
    let currentTariffOption = currentTariff?.options.find(
      (opt) =>
        opt.type === TariffOptionType.Records && opt.category === TariffOptionCategory.Additional
    );

    return (
      <RadioGroup
        className={clsx(
          individualTariffChangeIndex === keyIndex &&
            !isOptionDisplaysInAvailableOptionsList &&
            classes.recordsOptionRadioGroupInTariffStructureChangeModal
        )}
        defaultValue={isTariffEdit ? String(optionsList[0].id) : ''}
        name={'records'}
      >
        <Flex direction={'row'}>
          {optionsList.map((option, index) => {
            const getShouldRenderRadio = () => {
              if (isOptionDisplaysInAvailableOptionsList && individualTariffChangeIndex !== null) {
                return false;
              }
              return (
                option.id === currentTariffOption?.id ||
                isTariffEdit ||
                individualTariffChangeIndex === keyIndex
              );
            };
            const shouldRenderRadio = getShouldRenderRadio();

            if (shouldRenderRadio && !formMethods.getValues().records) {
              setValue('records', String(option.id));
            }

            currentTariffOption = currentTariff?.options.find((opt) => opt.id === option.id);
            return (
              <Flex
                className={classes.tariffMultipleOption}
                direction={'column'}
                key={`${option.type}-${index}`}
              >
                {shouldRenderRadio ? (
                  <FormControlLabel
                    value={String(option.id)}
                    control={
                      <Radio
                        disabled={
                          !isOptionSelected ||
                          loadingModifyTariff ||
                          (!isTariffEdit && individualTariffChangeIndex !== keyIndex)
                        }
                        color={'secondary'}
                      />
                    }
                    label={translate(`RECORDS_${option.params.days}`)}
                  />
                ) : (
                  <Typography
                    type={'text3'}
                    color={'tertiary600'}
                    className={classes.paddingUpDown025}
                  >
                    {translate(`RECORDS_${option?.params?.days}`)}
                  </Typography>
                )}
                <Typography
                  className={clsx(
                    classes.tariffAdditionalTextPadding,
                    individualTariffChangeIndex === keyIndex &&
                      !isOptionDisplaysInAvailableOptionsList &&
                      classes.recordsTariffOptionLabel,
                    isTariffEdit && classes.recordsStoringOptionLabelText
                  )}
                  type={'text4'}
                  color={'tertiary600'}
                >
                  {`${translate('COST_PER_EMPLOYEE_OPTION', {
                    amount: option.baseMonthlyFee,
                  })}`}
                  <TariffDiscount discountData={currentTariffOption || option} />
                </Typography>
              </Flex>
            );
          })}
        </Flex>
      </RadioGroup>
    );
  };

  const renderChangedOptionItem = (arr: Array<TariffOptionType>) => {
    if (!arr.length) return null;
    return arr.map((optionName) => {
      const { isDowngrade, isPromote, domainDifference } = changedOptionList[optionName] || {};
      const OptionIcon = isPromote ? (
        <PlusIcon className={classes.successIcon} />
      ) : (
        <MinusIcon className={classes.warningIcon} />
      );
      const optionChangeKey = isDowngrade ? 'OPTION_DOWNGRADE' : 'OPTION_PROMOTE';
      const details = getDetails(optionName);

      let price = 0;
      const indexOfOption = additionalOptionsKeyList.findIndex((optName) => optName === optionName);
      if (indexOfOption === -1) {
        if (optionName === 'Accounts') {
          price = getPriceForAccounts(true);
        } else {
          sentryCaptureExceptionThrottled(Error('Cannot define option price in Tariffs.tsx'));
          globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
          setTariffChangeDialogShow(false);
          return null;
        }
      } else if (optionName === 'Integrations') {
        price =
          domainDifference *
          accountAmount *
          additionalTariffOptions.Integrations![0].baseMonthlyFee!;
      } else {
        price = optionsComputedPrices.price[indexOfOption];
      }

      return (
        <Flex
          key={`${optionName}`}
          justifyContent={'spaceBetween'}
          className={classes.tariffChangedOptionLine}
        >
          <Flex alignItems={'center'}>
            {OptionIcon}
            <Typography type={'text3'} color={'tertiary900'}>
              {`${translate(optionChangeKey)} "${details?.name}"`}
            </Typography>
          </Flex>
          {isPromote ? (
            <Typography bold type={'text3'} color={'tertiary900'}>
              {translate('RUB_PER_MONTH2', {
                amount: toPrecision(price, false),
              })}
            </Typography>
          ) : null}
        </Flex>
      );
    });
  };

  const renderBalanceDetails = (arr: Array<string>) => {
    if (!arr.length) return null;

    return (
      <Flex direction={'column'} className={classes.tariffBalanceInformationLine}>
        <Flex justifyContent={'spaceBetween'} className={classes.tariffBalanceInformation}>
          <Typography type={'text3'} color={'tertiary900'}>
            {translate('TARIFF_PAYMENT_PRICE')}
          </Typography>
          <Typography type={'text3'} color={'primary700'}>
            {`${toPrecision(priceToBuy, false)} ₽`}
          </Typography>
        </Flex>
        <Flex justifyContent={'spaceBetween'} className={classes.tariffBalanceInformation}>
          <Typography type={'text3'} color={'tertiary500'}>
            {translate('TARIFF_PAYMENT_BALANCE_AFTER')}
          </Typography>
          <Typography type={'text3'} color={'tertiary500'}>
            {`${toPrecision(balanceAfter, false)} ₽`}
          </Typography>
        </Flex>
        <Flex className={classes.tariffBalanceInformationDescription}>
          <AlertCircleIcon className={classes.infoIcon} />
          <Typography type={'text4'} color={'tertiary500'}>
            {translate('TARIFF_PAYMENT_BALANCE_NOTIFICATION')}
          </Typography>
        </Flex>
      </Flex>
    );
  };

  const renderTariffDialogContent = () => {
    if (currentTariff && optionsResultList) {
      return (
        <Flex direction={'column'} alignItems={'center'} justifyContent={'spaceBetween'}>
          <div className={clsx(classes.defaultElementWidthFull, classes.marginBottom2)}>
            {renderChangedOptionItem(downgradeList)}
            {renderChangedOptionItem(promoteList)}
            {renderBalanceDetails(promoteList)}
          </div>
          <ControlButtons
            confirmTitle={'CONFIRM'}
            cancelTitle={'CANCEL'}
            cancelVariant="secondary"
            loading={loadingModifyTariff}
            confirmDisable={loadingModifyTariff}
            onConfirmClick={handleTariffConfirmClick}
            onCancelClick={() => setTariffChangeDialogShow(false)}
            justifyContent={'start'}
            flexDirection={'row-reverse'}
            small
          />
        </Flex>
      );
    }
    return null;
  };

  const renderTariffBalanceDialogContent = () => (
    <>
      <div className={classes.tariffMessageContent}>
        <Translate
          i18nKey={'TARIFF_LOW_BALANCE_MESSAGE'}
          components={{
            t: <Typography type={'text3'} color={'tertiary900'} />,
            a: <NavLink to={'/cabinet/balance'} />,
          }}
        />
      </div>
      <Flex justifyContent={'flexEnd'} alignItems={'center'}>
        <Button
          title={translate('CLOSE')}
          onClick={handleCloseTariffBalance}
          variant={'primary'}
          smallHeight
        />
      </Flex>
    </>
  );

  const handleTariffStructureChangeDialogNotEnoughMoneyOnBalanceApplyButtonClick = () => {
    setBalanceLowDialogShow(true);
  };

  const handleTariffStructureChangeDialogApplyButtonClick = () => {
    const data = getDataForTariffModifyRequest(true);
    modifyTariff({
      variables: { ...data },
      refetchQueries: [
        {
          query: GET_SELECTED_TARIFF_QUERY,
        },
      ],
    })
      .then(() => {
        handleOperationFinish();

        setIsTariffStructureChangeMessageDialogOpen(false);
        setTimeout(() => {
          setIndividualTariffChangeIndex(null);
        }, 200);
      })
      .catch(() => {
        handleTariffChangeError();
      });
  };

  const handleTariffStructureChangeDialogClose = () => {
    if (loadingModifyTariff) {
      return;
    }
    setIsTariffStructureChangeMessageDialogOpen(false);
    // setTimeout prevents UI blink
    setTimeout(() => {
      setIndividualTariffChangeIndex(null);
      if (individualTariffChangeIndex !== null) {
        toggleOptionSelectionByIndex(individualTariffChangeIndex, false);
      }
    }, 200);
  };

  const balanceValuesAfterIndividualOptionsConnection = additionalOptionsKeyList.map(
    (optionKey) => {
      const factualPrice = Math.round(getPromotedPrice(optionKey, false, true));
      const afterPaymentPrice = balance - factualPrice;
      return afterPaymentPrice;
    }
  );

  const renderConnectIndividualOptionMessageDialogContent = () => {
    if (typeof individualTariffChangeIndex !== 'number') {
      return <PreloaderPanel />;
    }

    const optionKey = additionalOptionsKeyList.find(
      (option, index) => index === individualTariffChangeIndex
    );
    if (!optionKey) {
      return translate('SOMETHING_WENT_WRONG');
    }

    const option = additionalTariffOptions[optionKey]!;

    const priceForWholeMonth = Math.round(getPromotedPrice(optionKey, true));
    const factualPrice = Math.round(getPromotedPrice(optionKey));
    const afterPaymentPrice = balance - factualPrice;

    const renderTopBlock = () => {
      if (optionKey === TariffOptionType.Records) {
        return (
          <>
            <Flex className={classes.tariffStructureChangeMessageDialogPlusElementWrapper}>
              <Typography
                className={classes.tariffStructureChangeMessageDialogPlusTypographyWrapper}
                pxToEmSize={26}
                color="primary700"
              >
                +
              </Typography>
              <Typography>{`${translate('OPTION_PROMOTE')} "${translate(
                'RECORDS_STORE'
              )}"`}</Typography>
              <Typography className={classes.marginLeftAuto} bold>
                {translate('RUB_PER_MONTH2', { amount: toPrecision(priceForWholeMonth, false) })}
              </Typography>
            </Flex>
            {renderRecordsOptionRadioGroup(individualTariffChangeIndex)}
          </>
        );
      }

      if (optionKey === TariffOptionType.Integrations) {
        const { integrationMinMessage, computedMinIntegrations } =
          getComputedMinIntegrationsTooltipText(
            integrationsAmount,
            installedIntegrationsCount,
            translate
          );

        return (
          <Flex className={classes.tariffStructureChangeMessageDialogPlusElementWrapper}>
            <Typography
              className={classes.tariffStructureChangeMessageDialogPlusTypographyWrapper}
              pxToEmSize={26}
              color="primary700"
            >
              +
            </Typography>
            <Typography>{`${translate('OPTION_PROMOTE')} "${option[0].name}"`}</Typography>
            <div className={classes.tariffStructureChangeMessageDialogFormArrowSwitcherBlockHolder}>
              <FormArrowSwitcher
                rootClass={classes.tariffStructureChangeMessageDialogFormArrowSwitcher}
                minTooltip={integrationMinMessage}
                disabled={loadingModifyTariff}
                min={computedMinIntegrations || 1}
                max={MAX_ACCOUNT_AMOUNT}
                name={'integrationAmount'}
                defaultValue={1}
              />
              <Typography className={classes.marginLeftAuto} bold>
                {translate('RUB_PER_MONTH2', { amount: toPrecision(priceForWholeMonth, false) })}
              </Typography>
            </div>
          </Flex>
        );
      }

      return (
        <Flex className={classes.tariffStructureChangeMessageDialogPlusElementWrapper}>
          <Typography
            className={classes.tariffStructureChangeMessageDialogPlusTypographyWrapper}
            pxToEmSize={26}
            color="primary700"
          >
            +
          </Typography>
          <Typography>{`${translate('OPTION_PROMOTE')} "${option[0].name}"`}</Typography>
          <Typography className={classes.marginLeftAuto} bold>
            {translate('RUB_PER_MONTH2', { amount: toPrecision(priceForWholeMonth, false) })}
          </Typography>
        </Flex>
      );
    };

    return (
      <>
        {renderTopBlock()}

        <div className={classes.tariffStructureChangeMessageDialogSeparator} />

        <Flex>
          <Typography>{translate('TARIFF_PAYMENT_PRICE')}</Typography>
          <Typography className={classes.marginLeftAuto} bold color="primary700">
            {toPrecision(factualPrice, false)} ₽
          </Typography>
        </Flex>
        <Flex className={classes.marginTop05}>
          <Typography>{translate('TARIFF_PAYMENT_BALANCE_AFTER')}</Typography>
          <Typography className={classes.marginLeftAuto} bold color="tertiary600">
            {toPrecision(afterPaymentPrice, false)} ₽
          </Typography>
        </Flex>
        <div className={classes.marginTop1}>
          <Typography pxToEmSize={12} color="tertiary500">
            {translate('TARIFF_PAYMENT_BALANCE_NOTIFICATION')}
          </Typography>
        </div>
      </>
    );
  };

  const renderMessageDialogs = () => (
    <>
      <MessageDialog
        title={translate('TARIFF_STRUCTURE_CHANGE')}
        isOpen={isTariffStructureChangeMessageDialogOpen}
        contentClass={classes.tariffStructureChangeMessageDialogContent}
        renderContent={renderConnectIndividualOptionMessageDialogContent()}
        onCancel={handleTariffStructureChangeDialogClose}
        disableClose={loadingModifyTariff}
        buttonSuccessProps={{
          titleCode: 'APPLY',
          onClick:
            balanceAfter < 0
              ? handleTariffStructureChangeDialogNotEnoughMoneyOnBalanceApplyButtonClick
              : handleTariffStructureChangeDialogApplyButtonClick,
          loading: loadingModifyTariff,
        }}
        buttonCancelProps={{
          titleCode: 'CANCEL',
          onClick: handleTariffStructureChangeDialogClose,
          disabled: loadingModifyTariff,
        }}
      />
      <MessageDialog
        isOpen={isTariffChangeDialogShow}
        title={translate('TARIFF_CHANGE_TITLE')}
        onCancel={handleCloseTariffChange}
        contentClass={classes.defaultElementWidth32}
        bodyWrapperClass={classes.tariffDialogBodyWrapper}
        renderContent={renderTariffDialogContent()}
      />
      {isBalanceLowDialogShow ? (
        <MessageDialog
          isOpen={isBalanceLowDialogShow}
          title={translate('NUMBER_BOUGHT_TITLE_NO_MONEY')}
          onCancel={handleCloseTariffBalance}
          contentClass={classes.defaultElementWidth24}
          renderContent={renderTariffBalanceDialogContent()}
        />
      ) : null}
      {isError ? (
        <MessageDialog
          isOpen={isError}
          title={translate('SOMETHING_WENT_WRONG')}
          message={translate('TARIFF_CHANGE_UNKNOWN_ERROR')}
          onCancel={() => setError(false)}
          contentClass={classes.defaultElementWidth24}
          renderControls={
            <Button
              title={translate('CLOSE')}
              onClick={() => setError(false)}
              variant={'primary'}
              smallHeight
            />
          }
        />
      ) : null}
      {isSuccess ? (
        <MessageDialog
          isOpen={isSuccess}
          title={translate('TARIFF_CHANGE_TITLE_SIMPLE')}
          message={translate('TARIFF_CHANGE_SUCCESS')}
          onCancel={() => setSuccess(false)}
          contentClass={classes.defaultElementWidth24}
          renderControls={
            <Button
              title={translate('CLOSE')}
              onClick={() => setSuccess(false)}
              variant={'primary'}
              smallHeight
            />
          }
        />
      ) : null}
    </>
  );

  const renderOptionChangeMessage = (optionName: TariffOptionType, optionIndex: number) => {
    if (!tariffInitState) {
      return null;
    }

    let warningMessage = '';

    if (optionName === TariffOptionType.Accounts) {
      const { isPure, isDowngrade, domainDifference } = changedOptionList?.[optionName] || {};
      if (isPure) {
        return null;
      }

      warningMessage = getOptionChangeMessageText(
        translate,
        domainDifference,
        optionName,
        isDowngrade
      );
    } else {
      const { isPure, isDowngrade, isPromote, domainDifference, isSelected } =
        changedOptionList?.[optionName] || {};

      if (isPure) return null;

      if (optionName === TariffOptionType.Integrations && isSelected) {
        warningMessage = getOptionChangeMessageText(
          translate,
          domainDifference,
          optionName,
          isDowngrade
        );
      } else {
        if (isDowngrade) {
          if (
            optionName === TariffOptionType.Records &&
            !areRecordsStorageSettingsIndividual &&
            isSelected
          ) {
            warningMessage = translate('OPTION_CHANGE_SIMPLE');
          } else {
            warningMessage = translate('OPTION_UNINSTALL_SIMPLE');
          }
        }
        if (isPromote) {
          warningMessage = translate('OPTION_INSTALL_SIMPLE');
        }
      }
    }

    return (
      <Flex className={classes.tariffOptionWarningWrapper}>
        <Alert
          classes={{ root: classes.warningAlertRoot }}
          status="warn"
          description={<Typography>{warningMessage}</Typography>}
          buttonActionProps={
            isTariffRequested
              ? undefined
              : {
                  title: translate('CANCEL'),
                  onClick: () => handleResetChangedOption(optionName, optionIndex),
                }
          }
        />
      </Flex>
    );
  };

  const renderDiscountLossWarning = () => {
    const { isDowngrade, domainDifference } = changedOptionList?.[TariffOptionType.Accounts] || {};
    const { baseEmployeeCount, additionalEmployeeCount, discountLimit } = getTariffExactOptions(
      currentTariff?.options || []
    );

    const computedAccountAmount = baseEmployeeCount + additionalEmployeeCount - domainDifference;

    if (computedAccountAmount < discountLimit && isDowngrade) {
      return (
        <Flex className={classes.tariffOptionWarningWrapper}>
          <Alert
            classes={{ root: classes.warningAlertRoot }}
            status="warn"
            description={translate('OPTION_DISCOUNT_LOSS_MESSAGE', { amount: discountLimit })}
          />
        </Flex>
      );
    }
    return null;
  };

  const renderOptionDescriptionCost = (optionKey: string, option: TariffOptionModel) => {
    if (optionKey === TariffOptionType.Carousel) {
      return translate('RUB_PER_MONTH_AND_FACT_OF_CALL', {
        amount: option.baseMonthlyFee,
        callCost: 0.8,
      });
    }

    const baseText = option.params?.perEmployee ? 'COST_PER_EMPLOYEE_OPTION' : 'RUB_PER_MONTH';
    const additionalText = optionKey === TariffOptionType.Integrations ? translate('PER_CRM') : '';

    return translate(baseText, { amount: option.baseMonthlyFee, additional: additionalText });
  };

  const renderOptionDescription = (
    optionKey: TariffOptionType,
    keyIndex: number,
    isOptionDisplaysInAvailableOptionsList?: boolean
  ) => {
    const oList = additionalTariffOptions[optionKey];
    const currentTariffOption = currentTariff?.options.find(
      (opt) => opt.type === optionKey && opt.category === TariffOptionCategory.Additional
    );
    if (oList && oList.length > 1 && optionKey === TariffOptionType.Records) {
      if (areRecordsStorageSettingsIndividual) {
        return (
          <TooltipHover
            tooltipAdditionalClass={classes.tooltipRecordRetentionInfo}
            contentClass={classes.textAlignLeft}
            title={
              <Typography pxToEmSize={12}>
                {translate(
                  'RECORD_RETENTION_PERIOD_IS_SPECIFIED_IN_THE_SETTINGS_IN_THE_CONVERSATION_RECORDING_SECTION'
                )}
              </Typography>
            }
            placement="right"
          >
            <Flex direction="column">
              <Typography type={'text3'} color={'tertiary900'} bold>
                {translate(optionKey)}
              </Typography>
              <Typography className={classes.marginTop025} pxToEmSize={12} color={'tertiary600'}>
                {translate('INDIVIDUAL')}
              </Typography>
            </Flex>
          </TooltipHover>
        );
      }
      return (
        <>
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate(optionKey)}
          </Typography>
          {renderRecordsOptionRadioGroup(keyIndex, isOptionDisplaysInAvailableOptionsList)}
        </>
      );
    }
    if (!oList) {
      return null;
    }
    return (
      <>
        <Typography type={'text3'} color={'tertiary900'} bold>
          {oList[0].name}
        </Typography>
        <div className={classes.tariffAdditionalOptionDescription}>
          <Typography
            className={classes.tariffAdditionalTextPadding}
            type={'text4'}
            color={'tertiary600'}
          >
            {renderOptionDescriptionCost(optionKey, oList[0])}
            <TariffDiscount discountData={currentTariffOption || oList[0]} />
          </Typography>
        </div>
      </>
    );
  };

  const renderActionCell = (
    optionKey: TariffOptionType,
    keyIndex: number,
    isOptionDisplaysInAvailableOptionsList?: boolean
  ) => {
    const isOptionSelected = selectedOptions?.[keyIndex];
    const isPerEmployee = additionalTariffOptions[optionKey]![0]?.params?.perEmployee || false;
    if (optionKey === TariffOptionType.Integrations) {
      const { integrationMinMessage, computedMinIntegrations } =
        getComputedMinIntegrationsTooltipText(
          integrationsAmount,
          installedIntegrationsCount,
          translate
        );

      const getShouldShowIntegrationsArrowSwitcher = () => {
        return isOptionSelected && isTariffEdit;
      };

      const getShouldShowIntegrationsAmount = () => {
        if (getShouldShowIntegrationsArrowSwitcher()) {
          return false;
        }
        if (isOptionDisplaysInAvailableOptionsList) {
          return isTariffEdit;
        }
        return isTariffEdit && integrationsAmount;
      };

      return (
        <>
          <Flex
            fullWidth
            justifyContent={'spaceBetween'}
            alignItems={'center'}
            className={clsx(
              !getShouldShowIntegrationsArrowSwitcher() && classes.tariffHiddenOption
            )}
          >
            <Typography type={'text4'} color={'tertiary600'}>
              {translate('INTEGRATIONS_COUNT')}
            </Typography>
            <FormArrowSwitcher
              minTooltip={integrationMinMessage}
              disabled={!isOptionSelected}
              min={
                isTariffEdit && isOptionSelected
                  ? computedMinIntegrations || 1
                  : computedMinIntegrations
              }
              max={MAX_ACCOUNT_AMOUNT}
              name={'integrationAmount'}
              defaultValue={1}
              rootClass={classes.defaultElementWidth7}
            />
          </Flex>
          <Typography
            className={clsx(!getShouldShowIntegrationsAmount() && classes.tariffHiddenOption)}
            type={'text4'}
            color={'tertiary600'}
          >
            {translate('INTEGRATIONS_AMOUNT', { amount: integrationsAmount })}
          </Typography>
        </>
      );
    }
    if (isOptionSelected && !isOptionDisplaysInAvailableOptionsList && isPerEmployee) {
      return (
        <Typography type={'text4'} color={'tertiary600'}>
          {translate('PER_EMPLOYEE', { amount: accountAmount })}
        </Typography>
      );
    }
    return null;
  };

  const renderPrice = (
    optionKey: string,
    keyIndex: number,
    isOptionDisplaysInAvailableOptionsList?: boolean
  ) => {
    const isOptionSelected = selectedOptions?.[keyIndex];

    const handleConnectSingleOptionButtonClick = () => {
      toggleOptionSelectionByIndex(keyIndex, true);
      setIndividualTariffChangeIndex(keyIndex);
      const balanceAfterOptionConnection = balanceValuesAfterIndividualOptionsConnection[keyIndex];
      if (balanceAfterOptionConnection < 0) {
        setBalanceLowDialogShow(true);
        return;
      }

      setIsTariffStructureChangeMessageDialogOpen(true);
    };

    if (
      !isTariffEdit &&
      !isTariffRequested &&
      isOptionDisplaysInAvailableOptionsList &&
      !(optionKey === TariffOptionType.Records && areRecordsStorageSettingsIndividual)
    ) {
      return (
        <Button variant="secondary" onClick={handleConnectSingleOptionButtonClick} smallHeight>
          <Typography>{translate('CONNECT')}</Typography>
        </Button>
      );
    }

    if (!isOptionSelected) return null;

    const price = optionsComputedPrices.price[keyIndex];
    const discountPrice = optionsComputedPrices.discountPrice[keyIndex];

    return <TariffPriceDiscount price={price} discountPrice={discountPrice} />;
  };

  const renderSwitchField = (optionKey: string, keyIndex: number) => {
    if (optionKey === TariffOptionType.Integrations && installedIntegrationsCount) {
      return (
        <TooltipHover
          placement={'bottom'}
          title={
            <Typography className={classes.tooltip} color={'tertiary900'} type={'text4'}>
              {translate('TARIFF_INTEGRATION_SWITCH_OFF_DISABLED')}
            </Typography>
          }
        >
          <SwitchField disabled name={`additionalOptions[${keyIndex}]`} />
        </TooltipHover>
      );
    }
    if (optionKey === TariffOptionType.Records && areRecordsStorageSettingsIndividual) {
      return <SwitchField disabled checked />;
    }
    return <SwitchField name={`additionalOptions[${keyIndex}]`} />;
  };

  const renderAccountsTariffOption = () => {
    if (!optionsResultList) return null;

    const { accountMin, accountPrice, additionalAccountPrice, accountMax } =
      optionsResultList[0] || {};
    const { price: employeePrice, discountPrice: employeeDiscountPrice } = employeeComputedPrices;

    const { accountsOption: cAccountOption, additionalAccountsOption: cAdditionalAccountsOption } =
      getTariffExactOptions(currentTariff?.options || []);

    const { accountMinMessage, computedMinAccount } = getComputedMinEmployeesTooltipText({
      accountAmount,
      currentEmployeeCount,
      accountOption: cAccountOption,
      translate,
      skipDiscount: true,
    });

    return (
      <Flex alignItems={'flexStart'} className={classes.tariffAdditionalOption}>
        <div
          className={clsx(classes.tariffOptionSwitchCell, {
            [classes.tariffHiddenOption]: !isTariffEdit,
          })}
        />
        <Flex
          direction={'column'}
          alignItems={'flexStart'}
          className={classes.tariffOptionInfoCell}
        >
          <Typography type={'text3'} color={'tertiary900'} bold>
            {translate('EMPLOYEES')}
          </Typography>
          <div className={classes.tariffAdditionalOptionDescription}>
            <Typography
              className={classes.tariffAdditionalTextPadding}
              type={'text4'}
              color={'tertiary600'}
            >
              {`${translate('COST_PER_EMPLOYEE', {
                amount: accountPrice,
              })}`}
              <TariffDiscount discountData={cAccountOption || accountOption} />
            </Typography>
            <Typography
              className={classes.tariffAdditionalTextPadding}
              type={'text4'}
              color={'tertiary600'}
            >
              {`${translate('DISCOUNT_COST_PER_EMPLOYEE', {
                amount: accountMax + 1,
                price: additionalAccountPrice,
              })}`}
              <TariffDiscount discountData={cAdditionalAccountsOption || additionalAccountOption} />
            </Typography>
          </div>
          {renderOptionChangeMessage(TariffOptionType.Accounts, -1)}
          {renderDiscountLossWarning()}
        </Flex>
        <Flex
          justifyContent={'flexEnd'}
          alignItems={'center'}
          className={classes.tariffOptionActionCell}
        >
          <Flex
            justifyContent={'spaceBetween'}
            alignItems={'center'}
            className={clsx(classes.tariffOptionActionCellWrapper)}
          >
            {!isTariffEdit ? (
              <Typography type={'text4'} color={'tertiary600'}>
                {translate('PER_EMPLOYEE', { amount: accountAmount })}
              </Typography>
            ) : (
              <Typography type={'text4'} color={'tertiary600'}>
                {getCorrectRussianTranslationForEmployeeMinimumByAmount(
                  translate,
                  computedMinAccount
                )}
              </Typography>
            )}
            <FormArrowSwitcher
              minTooltip={accountMinMessage}
              min={computedMinAccount}
              max={MAX_ACCOUNT_AMOUNT}
              name={'accountAmount'}
              defaultValue={accountMin}
              rootClass={clsx(classes.defaultElementWidth7, {
                [classes.tariffHiddenOption]: !isTariffEdit,
              })}
            />
          </Flex>
        </Flex>
        <Flex
          justifyContent={'flexEnd'}
          alignItems={'center'}
          className={classes.tariffOptionPriceCell}
        >
          <TariffPriceDiscount price={employeePrice} discountPrice={employeeDiscountPrice} />
        </Flex>
      </Flex>
    );
  };

  const renderAdditionalTariffOptionsTotalPrice = () => {
    return (
      <Flex className={classes.tariffMonthlyTotalPriceBlock} justifyContent="spaceBetween">
        <Typography bold>{translate('TOTAL_MONTH_COST')}</Typography>
        <Typography bold>
          {translate('RUB_PER_MONTH', {
            amount: toPrecision((isTariffEdit ? initialPrice : currentMonthPrice) || 0, false),
          })}
        </Typography>
      </Flex>
    );
  };

  const renderAdditionalTariffOptions = () => {
    return (
      <>
        <div className={classes.tariffTitle}>
          <Typography pxToEmSize={18} color={'tertiary900'} bold>
            {translate('CONNECTED_OPTIONS')}
          </Typography>
        </div>
        <Flex direction={'column'} className={classes.tariffAdditionalFunctionalWrapper}>
          {/* Accounts option is always there by design */ renderAccountsTariffOption()}
          {additionalOptionsKeyList.map((optionKey, keyIndex) => {
            if (!tariffInitState || tariffInitState[keyIndex].isSelected === false) {
              return null;
            }
            return (
              <Flex
                key={`${optionKey}-${keyIndex}`}
                alignItems={'flexStart'}
                className={classes.tariffAdditionalOption}
              >
                <Flex
                  alignItems={'flexStart'}
                  className={clsx(classes.tariffOptionSwitchCell, {
                    [classes.tariffHiddenOption]: !isTariffEdit,
                  })}
                >
                  {renderSwitchField(optionKey, keyIndex)}
                </Flex>
                <Flex
                  direction={'column'}
                  alignItems={'flexStart'}
                  className={classes.tariffOptionInfoCell}
                >
                  {renderOptionDescription(optionKey, keyIndex)}
                  {renderOptionChangeMessage(optionKey, keyIndex)}
                </Flex>
                <Flex
                  justifyContent={'flexEnd'}
                  alignItems={'center'}
                  className={classes.tariffOptionActionCell}
                >
                  <Flex
                    justifyContent={'spaceBetween'}
                    className={classes.tariffOptionActionCellWrapper}
                  >
                    {renderActionCell(optionKey, keyIndex)}
                  </Flex>
                </Flex>
                <Flex
                  justifyContent={'flexEnd'}
                  alignItems={'center'}
                  className={classes.tariffOptionPriceCell}
                >
                  {renderPrice(optionKey, keyIndex)}
                </Flex>
              </Flex>
            );
          })}
          {renderAdditionalTariffOptionsTotalPrice()}
        </Flex>
      </>
    );
  };

  const renderNewMonthTotalPrice = () => {
    const price = getAdditionalOptionsTotalPrice();

    const shouldDisplay = isTariffEdit && price !== initialPrice;

    if (!shouldDisplay) {
      return null;
    }

    return (
      <Flex
        className={classes.tariffNewMonthTotalPriceBlock}
        justifyContent="spaceBetween"
        alignItems="center"
      >
        <Typography bold pxToEmSize={18}>
          {translate('TOTAL_FROM_NEW_MONTH_COST')}
        </Typography>
        <Typography bold pxToEmSize={18}>
          {translate('RUB_PER_MONTH', {
            amount: toPrecision(price, false),
          })}
        </Typography>
      </Flex>
    );
  };

  const renderAvailableTariffOptions = () => {
    if (!tariffInitState) {
      return null;
    }
    let thereAreOnlySelectedOptions = true;

    const availableTariffOptionsKeys: (TariffOptionType | null)[] = additionalOptionsKeyList.map(
      (key, keyIndex) => {
        if (!tariffInitState[keyIndex].isSelected) {
          thereAreOnlySelectedOptions = false;
          return key;
        }
        return null;
      }
    );

    if (thereAreOnlySelectedOptions) {
      return null;
    }

    return (
      <>
        <div className={clsx(classes.tariffTitle, classes.marginTop15)}>
          <Typography pxToEmSize={18} color={'tertiary900'} bold>
            {translate('AVAILABLE_OPTIONS')}
          </Typography>
        </div>
        <Flex direction={'column'} className={classes.tariffAdditionalFunctionalWrapper}>
          {availableTariffOptionsKeys.map((key, keyIndex) => {
            if (key === null) {
              return null;
            }
            return (
              <Flex
                key={`${key}-${keyIndex}`}
                alignItems={'flexStart'}
                className={classes.tariffAdditionalOption}
              >
                <Flex
                  alignItems={'flexStart'}
                  className={clsx(classes.tariffOptionSwitchCell, {
                    [classes.tariffHiddenOption]: !isTariffEdit,
                  })}
                >
                  {renderSwitchField(key, keyIndex)}
                </Flex>
                <Flex
                  direction={'column'}
                  alignItems={'flexStart'}
                  className={classes.tariffOptionInfoCell}
                >
                  {renderOptionDescription(key, keyIndex, true)}
                  {renderOptionChangeMessage(key, keyIndex)}
                </Flex>
                <Flex
                  justifyContent={'flexEnd'}
                  alignItems={'center'}
                  className={classes.tariffOptionActionCell}
                >
                  <Flex
                    justifyContent={'spaceBetween'}
                    className={classes.tariffOptionActionCellWrapper}
                  >
                    {renderActionCell(key, keyIndex, true)}
                  </Flex>
                </Flex>
                <Flex
                  justifyContent={'flexEnd'}
                  alignItems={'center'}
                  className={classes.tariffOptionPriceCell}
                >
                  {renderPrice(key, keyIndex, true)}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </>
    );
  };

  const renderTariffActions = () => {
    if (isTariffRequested) {
      return (
        <Button
          className={clsx(classes.tariffButton, classes.defaultElementWidth10)}
          title={translate('NUMBER_UNRESERVED_BUTTON')}
          onClick={handleTariffRequestCancel}
          variant={'primary'}
        />
      );
    }
    if (isTariffEdit) {
      return (
        <>
          <Button
            className={clsx(classes.tariffButton, classes.defaultElementWidth10)}
            title={translate('CONFIRM')}
            onClick={handleTariffConfirmClick}
            variant={'primary'}
          />
          <Button
            className={classes.tariffButtonCancel}
            onClick={handleTariffChangeReset}
            title={translate('CANCEL')}
            variant={'secondary'}
          />
        </>
      );
    }
    if (status !== DomainStatus.Commercial) {
      return null;
    }
    if (!isTariffActive) {
      return null;
    }
    return (
      <>
        <Button
          className={clsx(classes.tariffButton, classes.defaultElementWidth10)}
          onClick={() => {
            setTariffEdit(true);
            setInitialPrice(currentMonthPrice);
            setIndividualTariffChangeIndex(null);
          }}
          title={translate('CHANGE')}
          variant={'primary'}
        />
        <Button
          className={classes.tariffButtonCancel}
          title={translate('CLOSE')}
          variant="secondary"
          onClick={() => {
            navigate('/dashboard');
          }}
        />
      </>
    );
  };

  return (
    <BodyContainer disableOverflow>
      <div className={classes.tariffContent}>
        <FormProvider {...formMethods}>
          <form className={clsx(classes.form, classes.paddingBottom2)}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {loadingSelectedTariff ? (
              <Flex className={classes.marginTop3} justifyContent={'center'}>
                <Preloader size={'large'} />
              </Flex>
            ) : !isTariffActive && (currentTariff?.id || 0) <= 10 && !isDataLoading ? (
              <div className={classes.tariffArchiveWrapper}>
                <AlertCircleIcon className={classes.alertIcon} />
                <Typography
                  className={classes.marginTop2}
                  type={'text3'}
                  color={'tertiary900'}
                  medium
                >
                  {translate('ARCHIVE_TARIFF_WARNING_01')}
                </Typography>
                <Typography className={classes.marginTop2} type={'text3'} color={'tertiary900'}>
                  {translate('ARCHIVE_TARIFF_WARNING_02')}
                </Typography>
              </div>
            ) : (
              <>
                {!isTariffActive && (
                  <div className={classes.tariffArchiveWarningWrapper}>
                    <Flex direction={'row'}>
                      <AlertCircleIcon className={classes.alertIconSmall} />
                      <Typography
                        className={clsx(classes.marginTop05, classes.marginLeft1)}
                        type={'text3'}
                        color={'tertiary900'}
                        medium
                      >
                        {translate('ARCHIVE_TARIFF_WARNING_01')}
                      </Typography>
                    </Flex>
                    <Typography
                      className={clsx(classes.marginTop05, classes.marginBottom1)}
                      type={'text3'}
                      color={'tertiary900'}
                    >
                      {translate('ARCHIVE_TARIFF_WARNING_03')}
                    </Typography>
                  </div>
                )}
                <TariffIncludedOptionsList optionsList={optionsResultList} />
                {renderAdditionalTariffOptions()}
                {renderAvailableTariffOptions()}
                {renderNewMonthTotalPrice()}

                {/** // TODO remove later  */}
                {/* <TariffTotalPrice
                  comparePrice={isTariffEdit}
                  optionsList={optionsResultList}
                  calcEmployeePrice={employeeComputedPrices}
                  optionsComputedPrices={optionsComputedPrices}
                  nextMonthPrice={nextMonthPrice}
                /> */}

                {renderMessageDialogs()}
              </>
            )}
          </form>
        </FormProvider>
        <BottomButtons className={classes.bottomButtonsWrapper}>
          {renderTariffActions()}
        </BottomButtons>
      </div>
    </BodyContainer>
  );
};

export default Tariffs;
