import Button from '@shared/components/Button/Button';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import React, { memo } from 'react';
import { ITabProps } from './Tab.interfaces';
import { useTabStyles } from './Tab.styles';

export const Tab = ({ active, onClick, id, tabTitle, className }: ITabProps) => {
  const classes = useTabStyles();

  return (
    <Button
      disableElevation
      disableRipple
      className={clsx(
        classes.clientDetailsTab,
        { [classes.clientDetailsTabActive]: active },
        className
      )}
      onClick={() => onClick(id)}
    >
      <Typography type={'text3'} color={active ? 'tertiary900' : 'tertiary600'}>
        {tabTitle}
      </Typography>
    </Button>
  );
};

export default memo(Tab);
