/* eslint-disable no-param-reassign */
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import FormFieldRhfControlled from '../FormFieldRhfControlled';
import { NumberFieldProps } from './NumberField.interfaces';
import {
  formatArbitraryNumberString,
  formatIntegerNumberString,
  formatIntegerPositiveNumberString,
  formatSumNumberString,
} from './NumberField.utils';

/** Поле ввода для чисел. Для работы компонент требует FormProvider в дереве компонентов выше. Если поле заполнено, значением (value) является корректное число в виде строки. (возможно преобразовать в `number`) */
export default function NumberField({
  onChange,
  validate: validateFromProps,
  required,
  rules,
  numberFormat = 'integer',
  ...props
}: NumberFieldProps) {
  const [translate] = useTranslation();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    switch (numberFormat) {
      case 'integer':
        event.target.value = formatIntegerNumberString(value);
        break;
      case 'integer-positive':
        event.target.value = formatIntegerPositiveNumberString(value);
        break;
      case 'sum':
        event.target.value = formatSumNumberString(value);
        break;
      case 'arbitrary':
        event.target.value = formatArbitraryNumberString(value);
        break;
      default:
        console.error(`Unknown number format to deal with in NumberField: ${numberFormat}`);
    }

    onChange?.(event);
  };

  const validate = (value: string) => {
    if (typeof value !== 'number' && !value) {
      if (!required) {
        return true;
      }
      return translate('ENTER_NUMBER');
    }
    const valueAsNumber = Number(value);
    if (Number.isNaN(valueAsNumber)) {
      return translate('INCORRECT_NUMBER');
    }
    if (validateFromProps) {
      return validateFromProps(value);
    }
    return true;
  };

  return (
    <FormFieldRhfControlled
      rules={{
        validate,
        ...rules,
      }}
      {...props}
      onChange={handleChange}
    />
  );
}
