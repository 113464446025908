import { makeStyles } from '@material-ui/core';

export const TypographyStyle = makeStyles((theme) => ({
  root: {
    fontFamily: 'Onest-Regular, sans-serif',
    fontWeight: 'normal',
    fontSize: '1em',
    lineHeight: '1.5',
  },
  default: {
    fontSize: '1em', // font-size: 16 px
  },
  text1: {
    fontSize: '1.5em', // font-size: 24 px
  },
  text2: {
    fontSize: '1.125em', // font-size: 18 px
  },
  text3: {
    fontSize: '0.875em', // font-size: 14 px
    lineHeight: 1.4,
  },
  text4: {
    fontSize: '0.75em', // font-size: 12 px
    lineHeight: 1.3,
  },
  text5: {
    fontSize: '0.625em', // font-size: 10 px
    lineHeight: 1.4,
  },
  text6: {
    fontSize: '2em', // font-size: 32px
  },
  text7: {
    fontSize: '1.25em', // font-size: 20px
  },

  inherit: {
    color: 'inherit',
  },
  base: {
    color: theme.color.base,
  },
  primary800: {
    color: theme.color.primary['800'],
  },
  primary700: {
    color: theme.color.primary['700'],
  },
  primary500: {
    color: theme.color.primary['500'],
  },
  primary600: {
    color: theme.color.primary['600'],
  },
  secondary600: {
    color: theme.color.secondary['600'],
  },
  tertiary900: {
    color: theme.color.tertiary['900'],
  },
  tertiary800: {
    color: theme.color.tertiary['800'],
  },
  tertiary700: {
    color: theme.color.tertiary['700'],
  },
  tertiary600: {
    color: theme.color.tertiary['600'],
  },
  tertiary500: {
    color: theme.color.tertiary['500'],
  },
  tertiary400: {
    color: theme.color.tertiary['400'],
  },
  tertiary300: {
    color: theme.color.tertiary['300'],
  },
  tertiary200: {
    color: theme.color.tertiary['200'],
  },
  danger400: {
    color: theme.color.danger['400'],
  },
  danger500: {
    color: theme.color.danger['500'],
  },
  danger600: {
    color: theme.color.danger['600'],
  },
  success600: {
    color: theme.color.success['600'],
  },
  warning500: {
    color: theme.color.warning['500'],
  },
  warning600: {
    color: theme.color.warning['600'],
  },
  link500: {
    color: theme.color.links['500'],
  },
  link600: {
    color: theme.color.links['600'],
  },
  underline: {
    textDecoration: 'underline',
  },
  medium: {
    fontFamily: 'Onest-Medium, sans-serif',
  },
  bold: {
    fontFamily: 'Onest-Medium, sans-serif',
  },
}));
