export enum AssistantType {
  Market = 'Market',
  Individual = 'Individual',
}

export enum AssistantStatus {
  Reserved = 'RESERVED',
  Developing = 'DEVELOPING',
  Bought = 'BOUGHT',
  PreFrozen = 'PRE_FROZEN',
  Frozen = 'FROZEN',
  PreDeletedBought = 'PRE_DELETED_BOUGHT',
  PreDeletedFrozen = 'PRE_DELETED_FROZEN',
}

export enum scenarioTypes {
  Market = 'MARKET',
  Individual = 'INDIVIDUAL',
}

export interface IAssistant {
  id: number;
  domainId: number;
  type: string;
  status: string;
  sum: number;
  name: string;
  marketAssistant?: {
    id: number;
    type?: string;
    name?: string;
  };
  onceService: IAssistantService;
  telephonyService: IAssistantService;
  minutesConsumption: number;
  statusDate?: string;
  createdAt?: string;
  updatedAt?: string;
  document?: {
    document: string;
  };
}

export interface IAssistantService {
  id: number;
  assistantType: string;
  name: string;
  cost: number;
  prepaid: number;
  oneUnitCost: number;
  isBaseCost: boolean;
  discountValue: number;
  isDiscountAbsolute: boolean;
  comment: string;
}
