import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';
import styleConstants from '@components/styles';
import { pxToEm } from '@shared/utils/styles';

export const useScenariosStyle = makeStyles(
  ({ color: { tertiary, primary } }: Theme & IThemeOptions) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    tableRoot: {
      tableLayout: 'fixed',
    },
    drawerInternalContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      paddingTop: 0,
      paddingBottom: 0,
    },
    drawerWrapper: {
      backgroundColor: tertiary[50],
    },
    drawerFooter: {
      paddingLeft: '2.250em',
    },
    message: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: '5em',
      borderBottom: `solid 1px ${tertiary['50']}`,
    },
    addScenarioBtn: {
      width: '14em',
      height: '2em',
    },
    actionSmallButton: {
      padding: '0.5em',
      marginRight: '1.25em',
      '&:last-of-type': {
        marginRight: '0',
      },
    },
    bigCancelApplication: {
      width: '30%',
      height: '2em',
      margin: '1.5em 0 1em',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '93%',
      padding: '2em 2em',
    },
    formContent: {
      marginTop: '1em',
    },
    addScenarioActions: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '14.5em',
    },
    drawerContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flexFlow: 'column',
      overflow: 'auto',
      width: '100%',
      height: '100%',
      backgroundColor: tertiary['50'],
    },
    scenarioExampleCardBlock: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    scenarioExampleCard: {
      flex: '0 1 10.5em',
      backgroundColor: 'white',
      borderRadius: '0.75em',
      border: `1px solid ${tertiary[300]}`,
      padding: '1.5em',
      marginBottom: '1em',
      '&:first-child': {
        marginTop: '2em',
      },
    },
    scenarioExampleCardTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '24em',
    },
    scenarioExampleCardTitle: {
      marginBottom: '0.5em',
      maxWidth: '20em',
    },
    scenarioExampleCardDescription: {
      whiteSpace: 'pre-wrap',
    },
    scenarioExampleCardControlsContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '1em',
    },
    scenarioExampleCardRowContent: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    scenarioExampleCardButtonsBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '28.5em',
      height: '2em',
      alignItems: 'end',
    },
    scenarioExampleCardBtnWrapper: {
      display: 'flex',
    },
    audioListenSmallControlButton: {
      alignItems: 'center',
      width: '2em',
      height: '2em',
      padding: '0.75em 0.5em',
    },
    schemaControlButton: {
      alignItems: 'center',
      fontSize: '1em',
      padding: '0.325em 1.125em',
      width: '5em',
      height: '2em',
      marginLeft: '0.5em',
    },
    closeIcon: {
      marginLeft: '1em',
      cursor: 'pointer',
      '&:hover': {
        color: primary[600],
      },
    },

    scenarioCardNew: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1.5em',

      width: pxToEm(368),
      height: pxToEm(448),

      borderRadius: '16px',
      border: `1px solid ${tertiary[300]}`,
    },
    scenarioCardNewActive: {
      borderColor: primary[700],
    },
    scenarioCardNewIconWrapper: {
      color: primary[700],
    },

    scenarioCardNewBottomButtonsWrapper: {
      marginTop: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
    },

    marginTopAuto: {
      marginTop: 'auto',
    },

    marginLeftAuto: {
      marginLeft: 'auto',
    },
    modalContentContainer: {
      paddingBottom: '0.5em',
      backgroundColor: 'white',
      height: '100%',
      width: '100%',
      scrollbarGutter: 'stable',
    },
    modalScenarioDialog: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 'max-content',
    },
    dialogPaper: {
      maxWidth: 'max-content',
    },
    modalScenarioImageOverlay: {
      overflow: 'auto',
      maxWidth: '100%',
      height: 'auto',
    },
    actionsContainer: {
      paddingTop: '1.5em',
      textAlign: 'right',
    },
    dialogContentCenter: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    buttonsContainerSmall: {
      paddingTop: '2em',
      textAlign: 'right',
    },
    marketDialog: {
      width: '24em',
      textAlign: 'left',
      whiteSpace: 'pre-line',
    },
    individualDialog: {
      width: '21em',
      textAlign: 'left',
      whiteSpace: 'pre-line',
    },
    aFontSize: {
      fontSize: '1.125em',
    },
    ...styleConstants,
  }),
  {
    index: 15,
  }
);
