import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { DOMAIN_ALL_NUMBERS_QUERY, USER_QUERY } from '@/client/queries';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { Skeleton } from '@material-ui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { INITIALIZE_EMPLOYEE_MUTATION } from '@/client/mutations';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography/Typography';
import { isFeatureAvailable, useDomainFeatures } from '@/common/hooks';
import Translate from '@shared/components/Translate';
import { NumberStatus, Role } from '@/client/generated/graphql';
import PasswordField from '@shared/components/PasswordField';
import { AUTHENTICATION_STATE_QUERY } from '@components/client/queries';
import MessageDialog from '@shared/components/MessageDialog';
import Flex from '@shared/components/Flex';
import clsx from 'clsx';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import { getRole } from '@/utils';
import * as Sentry from '@sentry/react';
import { setStorageItem } from '@components/storage/storage';
import client, { refreshAuthenticationState } from '@components/client/client';
import { useWelcomeDialogStyles } from './WelcomeDialog.styles';

type FormFields = {
  password: string;
  passwordConfirm: string;
};

type CurrentDialogStep = 1 | 2;

export const WelcomeDialog = () => {
  const classes = useWelcomeDialogStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const [step, setStep] = useState<CurrentDialogStep>(1);

  const { data: dataUser, loading } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const user = dataUser?.user;
  const isPasswordRequired = dataUser?.user.isPasswordRequired;

  const { data: dataAuthStateQuery } = useQuery<{ isAuthenticated?: boolean; role?: string }>(
    AUTHENTICATION_STATE_QUERY,
    {
      fetchPolicy: 'cache-only',
    }
  );
  const role = getRole(dataAuthStateQuery?.role);

  const formMethods = useForm<FormFields>();
  const { handleSubmit, setError } = formMethods;
  const { features } = useDomainFeatures();
  const nextRoute = useMemo(
    () => (isFeatureAvailable('dashboard', features) ? '/dashboard/' : '/history/'),
    [features]
  );
  const [initializeEmployee, { loading: initializeLoading }] = useMutation(
    INITIALIZE_EMPLOYEE_MUTATION
  );
  const { data: numbersData, loading: loadingPhone } = useQuery(DOMAIN_ALL_NUMBERS_QUERY);

  const { domainNumbers = [] } = numbersData || {};

  const phone = domainNumbers.find((number) => {
    if (number.__typename === 'NumbersModel') {
      return number.numberStatus === NumberStatus.Bought;
    }
    return false;
  })?.phone;
  const reservedPhone = domainNumbers.find(
    (number) =>
      number.__typename === 'NumbersModel' && number.numberStatus === NumberStatus.Reserved
  )?.phone;

  useEffect(() => {
    if ((role !== Role.Owner || !isPasswordRequired) && !loading) {
      navigate('/dashboard');
    }
  }, [isPasswordRequired, navigate, role, loading]);

  const handleFormSubmit = useCallback(
    async (formData: FormFields) => {
      const currentEmployeeId = user?.domains?.find(({ current }) => current)?.employees?.[0]?.id;
      const { password, passwordConfirm } = formData;
      if (password !== passwordConfirm) {
        setError('passwordConfirm', {
          type: 'error',
          message: translate('PASSWORD_CONFIRMATION_DOES_NOT_MATCH'),
        });
        return;
      }
      if (currentEmployeeId) {
        initializeEmployee({
          variables: {
            data: {
              id: currentEmployeeId,
              password,
            },
          },
        })
          .then((data) => {
            if (!data.data) {
              globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
              Sentry.captureException(
                Error('initializeEmployee mutation succeeded, but received data is null/undefined')
              );
              return;
            }

            const { accessToken, refreshToken } = data.data.initializeEmployee;
            setStorageItem('token', accessToken);
            setStorageItem('refreshToken', refreshToken);
            refreshAuthenticationState();

            setStep(2);
          })
          .catch((error) => {
            const message = getErrorMessageFromGraphqlError(error);
            let messageCodeToShow = 'SOMETHING_WENT_WRONG';
            let translatedMessage = '';

            if (message) {
              translatedMessage = translate(message);
              if (translatedMessage) {
                messageCodeToShow = message;
              }
            }

            globalNotification$.show('danger', messageCodeToShow);
          });
      }
    },
    [user, setError, translate, initializeEmployee]
  );

  const handleLetsGoButtonClick = () => {
    navigate(nextRoute);
    client.refetchQueries({ include: [USER_QUERY] }).catch(() => {
      globalNotification$.show('danger', 'CANNOT_GET_UPDATED_USER_DATA_PLEASE_RELOAD_THE_PAGE'); // TODO use runWithRetriesAndDelay function here, when implemented
    });
  };

  const renderStatusCircles = () => {
    return (
      <div className={classes.statusCirclesWrapper}>
        <div className={clsx(classes.statusCircle, step === 1 && classes.statusCircleActive)} />
        <div className={clsx(classes.statusCircle, step === 2 && classes.statusCircleActive)} />
      </div>
    );
  };

  const renderInfoBlockText = () => {
    if (reservedPhone) {
      return (
        <Typography className={classes.reservedPhoneWrapperTypography}>
          <Translate
            i18nKey={'WELCOME_DIALOG_INFO_BLOCK_TEXT_VARIANT_2'}
            values={{ number: formatPhone(reservedPhone) }}
            components={{
              typography: (
                <Typography className={classes.reservedPhoneTypography} bold pxToEmSize={16} />
              ),
            }}
          />
        </Typography>
      );
    }

    return <Typography>{translate('WELCOME_DIALOG_INFO_BLOCK_TEXT_VARIANT_1')}</Typography>;
  };

  const renderContent = () => {
    if (loading || loadingPhone) {
      return <Skeleton />;
    }

    if (step === 1) {
      return (
        <>
          <div className={classes.marginBottom1}>
            <Typography pxToEmSize={16}>{translate('ENTER_PASSWORD_TO_ACCESS_PBX')}</Typography>
          </div>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handleFormSubmit)} className={classes.form}>
              <div className={classes.resetPasswordWrapper}>
                <PasswordField
                  scoring
                  generation
                  required
                  name={'password'}
                  label={translate('NEW_PASSWORD')}
                />
              </div>
              <div className={classes.resetPasswordWrapper}>
                <PasswordField
                  scoring={false}
                  generation={false}
                  required
                  name={'passwordConfirm'}
                  label={translate('CONFIRM_NEW_PASSWORD')}
                />
              </div>
              <Flex justifyContent="spaceBetween">
                {renderStatusCircles()}
                <Button
                  title={translate('CONTINUE')}
                  type={'submit'}
                  loading={initializeLoading}
                  smallHeight
                />
              </Flex>
            </form>
          </FormProvider>
        </>
      );
    }

    return (
      <>
        <div className={classes.marginBottom1}>
          <Typography pxToEmSize={16}>{translate('FOR_CALLS_TESTING_YOU_ARE_GIVEN')}:</Typography>
        </div>
        <div className={classes.marginBottom15}>
          <Flex>
            <Typography color="tertiary600">{translate('DEDICATED_NUMBER')}:</Typography>
            <Typography className={classes.marginLeft025} bold>
              {formatPhone(phone || '')}
            </Typography>
          </Flex>
          <Flex>
            <Typography color="tertiary600">{translate('CREDIT')}:</Typography>
            <Typography className={classes.marginLeft025} bold>
              30 ₽
            </Typography>
          </Flex>
        </div>
        <div className={classes.infoBlock}>{renderInfoBlockText()}</div>
        <Flex justifyContent="spaceBetween">
          {renderStatusCircles()}
          <Button
            title={translate('LETS_GO')}
            loading={initializeLoading}
            smallHeight
            onClick={handleLetsGoButtonClick}
          />
        </Flex>
      </>
    );
  };

  return (
    <MessageDialog
      title={translate('SOFT_SWITCH_SUCCESSFULLY_CREATED')}
      isShowCloseBtn={false}
      isOpen
      disableBackdropClick
      disableClose
      renderContent={renderContent()}
    />
  );
};

export default WelcomeDialog;
