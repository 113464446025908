import { makeStyles } from '@material-ui/core/styles';

export const useSettingsCardStyles = makeStyles(
  ({
    color: { primary, tertiary },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { leavingScreen },
    },
  }) => ({
    root: {
      transition: createTransition(['background'], {
        easing: sharp,
        duration: leavingScreen,
      }),
      boxShadow: 'none',
      border: `1px solid ${tertiary[200]}`,
      position: 'relative',
      width: '23em',
      height: '10em',
      flexDirection: 'column',
      padding: '1.75em',
      borderRadius: '0.75em',
      cursor: 'pointer',
      '&:hover': {
        background: tertiary[50],
        border: `1px solid ${tertiary[300]}`,
        '& > $backgroundIcon': {
          color: tertiary[100],
        },
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
    titleIcon: {
      color: primary[700],
      width: '1.5em',
      height: '1.5em',
    },
    titleText: {
      paddingLeft: '1em',
    },
    description: {
      paddingTop: '1em',
    },
    body: {
      position: 'relative',
      zIndex: 1,
    },
  })
);
