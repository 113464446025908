import { useMutation, useQuery } from '@apollo/client';
import { BALANCE_QUERY } from '@/client/queries';
import {
  CANCEL_CHANGE_TARIFF_REQUEST_MUTATION,
  ADD_CHANGE_TARIFF_REQUEST_MUTATION,
  MODIFY_TARIFF_MUTATION,
} from '@/client/mutations';

export function useChangeTariffMethodsHooks() {
  const { data: balanceData, refetch: getBalanceData } = useQuery(BALANCE_QUERY);
  const balance = balanceData?.getBalance.balance || 0;
  const [createTariffChangeRequest, { loading: loadingCreateRequest }] = useMutation(
    ADD_CHANGE_TARIFF_REQUEST_MUTATION
  );
  const [cancelTariffChangeRequest, { loading: loadingCancelRequest }] = useMutation(
    CANCEL_CHANGE_TARIFF_REQUEST_MUTATION
  );
  const [modifyTariff, { loading: loadingModifyTariff }] = useMutation(MODIFY_TARIFF_MUTATION);

  return {
    modifyTariff,
    loadingModifyTariff,
    createTariffChangeRequest,
    loadingCreateRequest,
    cancelTariffChangeRequest,
    loadingCancelRequest,
    balance,
    getBalanceData,
  };
}
