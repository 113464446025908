import { makeStyles } from '@material-ui/core/styles';

export const useTariffFormStyle = makeStyles(
  ({ color: { success } }) => ({
    tariffTitle: {
      marginBottom: '1.5em',
    },
    tariffIncludedOptionWrapper: {
      width: '71em',
      flexWrap: 'wrap',
      paddingBottom: '1.5em',
      height: '9em',
    },
    tariffIncludedLine: {
      minWidth: '33%',
      paddingBottom: '0.5em',
    },
    tariffLIIcon: {
      width: '1em',
      minWidth: '1em',
      color: success[600],
      marginRight: '0.75em',
    },
    tariffTotals: {
      width: '71em',
      padding: '1em 1.5em 1em 0',
    },
    amountTextStyle: {
      whiteSpace: 'nowrap',
    },
    tariffDiscountTextPadding: {
      paddingLeft: '0.5em',
    },
    oldPriceTextStyle: {
      whiteSpace: 'nowrap',
      textDecoration: 'line-through',
    },
  }),
  {
    index: 10,
  }
);
