import React from 'react';
import { ICellProps } from '@components/LazyTable';
import Button from '@shared/components/Button';
import { EditIcon, TrashIcon } from '@shared/assets/images/icons';
import { makeStyles } from '@material-ui/core';
import { EndUserModel } from '@/client/generated/graphql';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_END_USERS_QUERY } from '@/client/queries';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import clsx from 'clsx';
import { responsiblePersonsContext$ } from '../ResponsiblePersons';

const useStyles = makeStyles(() => ({
  button: {
    width: '2em',
    height: '2em',
    minWidth: 'unset',
    padding: 0,
  },
  marginLeft1: {
    marginLeft: '1em',
  },
}));

export const ActionsCell = ({ item }: ICellProps<EndUserModel>) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { data: responsiblePersonsQueryData } = useQuery(GET_ALL_END_USERS_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const handleEditButtonClick = () => {
    navigate(`/numbers/edit-responsible-person/${item.id}`);
  };

  const handleRemoveButtonClick = () => {
    const responsiblePersonData = responsiblePersonsQueryData?.getAllEndUsers.find(
      (person) => person.id === item.id
    );
    if (!responsiblePersonData) {
      globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      return;
    }

    const responsibleHasAssignedNumbers = responsiblePersonData.domainNumbers.length > 0;
    if (responsibleHasAssignedNumbers) {
      responsiblePersonsContext$.isCannotRemoveInfoDialogOpen.set(true);
      return;
    }

    responsiblePersonsContext$.responsiblePersonIdForDeletion.set(item.id);
  };

  return (
    <>
      <Button className={classes.button} variant="secondary" onClick={handleEditButtonClick}>
        <EditIcon />
      </Button>
      <Button
        className={clsx(classes.button, classes.marginLeft1)}
        variant="secondary"
        color="error"
        onClick={handleRemoveButtonClick}
      >
        <TrashIcon />
      </Button>
    </>
  );
};
