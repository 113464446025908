import { WorkCalendarCategory, WorkCalendarModel } from '@/client/generated/graphql';
import { dateOnlyStringToDate } from '@shared/utils/dates';
import { TFunction } from 'i18next';

const sortByDateString = (a: WorkCalendarModel, b: WorkCalendarModel) => {
  return a.date.localeCompare(b.date);
};

export const getCorrectEventsShape = (data: WorkCalendarModel[]) => {
  const customDaysEvents = [];
  const holidayDaysEvents = [];
  const rescheduledDaysEvents = [];
  const currentYear = new Date().getFullYear();

  for (let i = 0; i < data.length; i += 1) {
    if (dateOnlyStringToDate(data[i].date)?.getFullYear() !== currentYear) {
      continue;
    }

    const category = data[i].category;

    switch (category) {
      case WorkCalendarCategory.Custom:
        customDaysEvents.push(data[i]);
        break;
      case WorkCalendarCategory.Holiday:
        holidayDaysEvents.push(data[i]);
        break;
      case WorkCalendarCategory.Rescheduled:
        rescheduledDaysEvents.push(data[i]);
        break;
      default:
        category satisfies never;
    }
  }

  return {
    customDaysEvents: customDaysEvents.sort(sortByDateString),
    holidayDaysEvents: holidayDaysEvents.sort(sortByDateString),
    rescheduledDaysEvents: rescheduledDaysEvents.sort(sortByDateString),
  };
};

export const getWeekDaysData = (translate: TFunction) => [
  { titleCode: translate('MONDAY'), value: 0 },
  { titleCode: translate('TUESDAY'), value: 1 },
  { titleCode: translate('WEDNESDAY'), value: 2 },
  { titleCode: translate('THURSDAY'), value: 3 },
  { titleCode: translate('FRIDAY'), value: 4 },
  { titleCode: translate('SATURDAY'), value: 5 },
  { titleCode: translate('SUNDAY'), value: 6 },
];

export const getWeekDaysDataWithNonWorkingDays = (translate: TFunction) => [
  ...getWeekDaysData(translate),
  { titleCode: translate('NON_WORKING_HOURS'), value: -1 },
];
