import { useSelector } from '@legendapp/state/react';
import MessageDialog from '@shared/components/MessageDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { phoneNumbersContext$ } from '../PhoneNumbers';

const DialogShouldSelectResponsibleInfo = () => {
  const [translate] = useTranslation();
  const isDialogShouldSelectResponsibleOpen = useSelector(
    phoneNumbersContext$.isDialogShouldSelectResponsibleOpen
  );

  const handleSelectResponsibleButtonClick = () => {
    phoneNumbersContext$.isDialogShouldSelectResponsibleOpen.set(false);
    phoneNumbersContext$.isDialogSelectResponsibleOpen.set(true);
  };

  const handleClose = () => {
    phoneNumbersContext$.isDialogShouldSelectResponsibleOpen.set(false);
  };

  return (
    <MessageDialog
      isOpen={isDialogShouldSelectResponsibleOpen}
      title={translate('ERROR')}
      renderContent={
        <Typography>{translate('SHOULD_SELECT_NUMBER_RESPONSIBLE_MANAGER')}</Typography>
      }
      onCancel={handleClose}
      buttonSuccessProps={{
        titleCode: 'SELECT_RESPONSIBLE',
        onClick: handleSelectResponsibleButtonClick,
      }}
      buttonCancelProps={{
        titleCode: 'CANCEL',
        onClick: handleClose,
      }}
    />
  );
};

export default DialogShouldSelectResponsibleInfo;
