import { makeStyles, Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';

type UsePageBodyStylesProps = {
  bodyRootHeight: string;
};

export const usePageBodyStyles = makeStyles<Theme & IThemeOptions, UsePageBodyStylesProps>(
  ({ breakpoints: { down } }) => ({
    content: {
      flexGrow: 1,
      position: 'absolute',
      right: 0,
      bottom: 0,
      left: 0,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      [down('md')]: {
        width: '1200px',
      },
    },
    outlet: {
      flexDirection: 'column',
      width: '100%',
      position: 'relative',
    },
    outletFullHeight: {
      height: '100%',
    },
    bodyRoot: {
      position: 'absolute',
      top: (props) => props.bodyRootHeight || '5em',
      left: 0,
      right: 0,
      bottom: 0,
      [down('md')]: {
        width: '1200px',
      },
    },
  }),
  {
    name: 'PageBody',
    index: 1,
  }
);
