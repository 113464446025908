import { TariffOptionType } from '@/client/generated/graphql';
import { TFunction } from 'i18next';

export const getCorrectTypeForEmployeeWordByAmount = (amount: number) => {
  const lastDigit = amount % 10;
  const lastTwoDigits = amount % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return 'EMPLOYEE_AMOUNT_5';
  }
  if (lastDigit === 1) {
    return 'EMPLOYEE_AMOUNT_1';
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return 'EMPLOYEE_AMOUNT_2';
  }
  return 'EMPLOYEE_AMOUNT_5';
};

export const getCorrectRussianTranslationForEmployeeMinimumByAmount = (
  translate: TFunction,
  amount: number
) => {
  const lastDigit = amount % 10;
  const lastTwoDigits = amount % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return `${translate('MINIMUM')} ${amount} ${translate('EMPLOYEE_AMOUNT_5')}`;
  }
  if (lastDigit === 1) {
    return `${translate('MINIMUM')} ${amount} ${translate('EMPLOYEE_AMOUNT_1')}`;
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return `${translate('MINIMUM')} ${amount} ${translate('EMPLOYEE_AMOUNT_2')}`;
  }
  return `${translate('MINIMUM')} ${amount} ${translate('EMPLOYEE_AMOUNT_5')}`;
};

export const getCorrectTypeForIntegrationWordByAmount = (amount: number) => {
  const lastDigit = amount % 10;
  const lastTwoDigits = amount % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return 'LOT_OF_INTEGRATIONS';
  }
  if (lastDigit === 1) {
    return 'INTEGRATION';
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return 'INTEGRATIONS';
  }
  return 'LOT_OF_INTEGRATIONS';
};

export const getOptionChangeMessageText = (
  translate: TFunction,
  amount: number,
  optionName: string,
  isDowngrade: boolean
): string => {
  const lastDigit = amount % 10;
  const lastTwoDigits = amount % 100;

  let verbWord = '';
  let typeWord = '';

  switch (optionName) {
    case TariffOptionType.Accounts: {
      // Define verb
      if (lastDigit === 1 && lastTwoDigits !== 11) {
        if (isDowngrade) {
          verbWord = translate('DISABLED_MALE_GENDER');
        } else {
          verbWord = translate('ENABLED_MALE_GENDER');
        }
      } else if (isDowngrade) {
        verbWord = translate('DISABLED_NEUTRAL_GENDER');
      } else {
        verbWord = translate('ENABLED_NEUTRAL_GENDER');
      }

      // Define type
      if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        typeWord = translate('EMPLOYEE_AMOUNT_5');
      } else if (lastDigit === 1) {
        typeWord = translate('EMPLOYEE_AMOUNT_1');
      } else if (lastDigit >= 2 && lastDigit <= 4) {
        typeWord = translate('EMPLOYEE_AMOUNT_2');
      } else {
        typeWord = translate('EMPLOYEE_AMOUNT_5');
      }
      break;
    }
    case TariffOptionType.Integrations: {
      // Define verb
      if (lastDigit === 1 && lastTwoDigits !== 11) {
        if (isDowngrade) {
          verbWord = translate('DISABLED_FEMALE_GENDER');
        } else {
          verbWord = translate('ENABLED_FEMALE_GENDER');
        }
      } else if (isDowngrade) {
        verbWord = translate('DISABLED_NEUTRAL_GENDER');
      } else {
        verbWord = translate('ENABLED_NEUTRAL_GENDER');
      }

      // Define type
      if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        typeWord = translate('LOT_OF_INTEGRATIONS');
      } else if (lastDigit === 1) {
        typeWord = translate('INTEGRATION');
      } else if (lastDigit >= 2 && lastDigit <= 4) {
        typeWord = translate('INTEGRATIONS');
      } else {
        typeWord = translate('LOT_OF_INTEGRATIONS');
      }
      break;
    }
    default: {
      return '';
    }
  }

  return translate(isDowngrade ? 'OPTION_UNINSTALL_MESSAGE' : 'OPTION_INSTALL_MESSAGE', {
    verb: verbWord.toLowerCase(),
    type: typeWord.toLowerCase(),
    amount,
  });
};
