import {
  TariffOptionType,
  TariffOptionCategory,
  TariffOptionModel,
} from '@/client/generated/graphql';
import {
  GET_ACTIVE_TARIFF_QUERY,
  GET_INTEGRATIONS_QUERY,
  GET_SELECTED_TARIFF_QUERY,
} from '@/client/queries';
import { FREE_INTEGRATIONS_TYPES_LIST } from '@/features/Integrations/Integrations.constants';
import {
  getTariffComputedPrices,
  getTariffEmployeePrices,
  getTariffExactOptions,
} from '@/utils/tariff';
import { useQuery } from '@apollo/client';
import { IntegrationList } from '@components/typings/interfaces/integrations';
import { useMemo } from 'react';
import { AdditionalTariffOptions } from 'typings/tariffs';
import getTariffOptionsList from '../getTariffOptionList';

export type TariffRequestOption = { isRequested: boolean; amount: number };

export type TariffRequestOptions = { [key in TariffOptionType]?: TariffRequestOption };

export type TariffRequestData = { accounts: number; options: TariffRequestOptions };

export function useTariffProps(
  selectedOptions: Array<boolean | undefined>,
  accountAmount?: number,
  integrationsAmount?: number,
  storeRecordsId?: number,
  initiallySelectedOptions?: boolean[]
) {
  const {
    data: activeTariffQueryData,
    refetch: getTariffListData,
    loading: loadingActiveTariff,
  } = useQuery(GET_ACTIVE_TARIFF_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const {
    data: selectedTariffQueryData,
    refetch: getCurrentTariffData,
    loading: loadingSelectedTariff,
  } = useQuery(GET_SELECTED_TARIFF_QUERY);

  const { data: integrationsListData } = useQuery(GET_INTEGRATIONS_QUERY);

  const activeTariffsList = activeTariffQueryData?.getActiveTariffs;
  /** Now we are assuming that we always have only one active tariff.
  In future there may be many. */
  const activeTariff = activeTariffsList?.[0];

  const currentTariff = selectedTariffQueryData?.getDomain?.tariff;
  const currentEmployeeCount: number =
    selectedTariffQueryData?.getDomain?.currentEmployeesCount || 0;
  const currentTariffEmployeesCount: number =
    selectedTariffQueryData?.getDomain?.employeesNumber || -1;
  const tariffRequest = selectedTariffQueryData?.getDomain?.tariffChangeRequest;
  const isDataLoading = loadingActiveTariff || loadingSelectedTariff;

  const installedIntegrationsCount: number = useMemo(() => {
    if (!integrationsListData) return 0;
    return (
      integrationsListData?.getIntegrations.filter(
        (i) => !FREE_INTEGRATIONS_TYPES_LIST.includes(i.type as IntegrationList)
      ).length || 0
    );
  }, [integrationsListData]);

  const optionsResultList = useMemo(
    () => getTariffOptionsList(activeTariffsList),
    [activeTariffsList]
  );

  const accountOption = useMemo(
    () => activeTariff?.options.find((tOption) => tOption.type === TariffOptionType.Accounts),
    [activeTariff]
  );

  const isTariffActive = useMemo(() => currentTariff?.active, [currentTariff]);

  const additionalAccountOption = useMemo(
    () =>
      activeTariff?.options.find((option) => option.type === TariffOptionType.AdditionalAccounts),
    [activeTariff]
  );

  const additionalTariffOptions: AdditionalTariffOptions = useMemo(() => {
    if (!activeTariff) return {};
    const additionalOptions = activeTariff.options.filter(
      (option) =>
        option.category === TariffOptionCategory.Additional &&
        option.type !== TariffOptionType.AdditionalAccounts &&
        option.type !== TariffOptionType.Accounts
    );
    return additionalOptions.reduce(
      (result: AdditionalTariffOptions, aOption: TariffOptionModel) => {
        const currentOption =
          currentTariff?.options.find((opt: TariffOptionModel) => opt.id === aOption.id) || aOption;
        if (currentOption.type === TariffOptionType.Records) {
          const isOptionCreated = !!result[TariffOptionType.Records];
          if (isOptionCreated) {
            result[TariffOptionType.Records]?.push(currentOption);
            return result;
          }
          return {
            ...result,
            [TariffOptionType.Records]: [currentOption],
          };
        }
        return {
          ...result,
          [currentOption.type]: [currentOption],
        };
      },
      {}
    );
  }, [activeTariff, currentTariff?.options]);

  const additionalOptionsKeyList = useMemo(
    () => Object.keys(additionalTariffOptions) as TariffOptionType[],
    [additionalTariffOptions]
  );

  const { accountsOption, discountLimit, additionalAccountsOption } = useMemo(
    () => getTariffExactOptions(currentTariff?.options || []),
    [currentTariff?.options]
  );

  const newTariffOptions = useMemo(() => {
    if (!currentTariff?.options) {
      return [];
    }

    const tariffOptions = [...currentTariff.options].filter(
      (opt) => tariffRequest?.options.some((req) => req.tariffOptionId === opt.id)
    );

    tariffRequest?.options.forEach((req) => {
      if (!currentTariff.options.some((opt) => req.tariffOptionId === opt.id)) {
        const foundOption = activeTariff?.options.find((opt) => opt.id === req.tariffOptionId);
        if (foundOption) {
          tariffOptions.push(foundOption);
        }
      }
    });
    return tariffOptions;
  }, [currentTariff?.options, activeTariff, tariffRequest?.options]);

  const {
    accountsOption: newAccountsOption,
    additionalAccountsOption: newAdditionalAccountsOption,
  } = useMemo(() => getTariffExactOptions(currentTariff?.options || []), [currentTariff?.options]);

  const isDiscountLoss = useMemo(
    () => (accountAmount || 1) < (discountLimit || 0),
    [accountAmount, discountLimit]
  );
  const tariffRequestData: TariffRequestData = useMemo(() => {
    const defaultData = { accounts: 0, options: {} };
    if (!tariffRequest || !additionalTariffOptions || !optionsResultList) return defaultData;
    const additionalAccountId = additionalAccountOption?.id;
    const accountId = accountOption?.id;

    const requestAccounts: number = tariffRequest.options.reduce((res, opt) => {
      if (opt.tariffOptionId === accountId || opt.tariffOptionId === additionalAccountId) {
        return res + (opt.count || 0);
      }
      return res;
    }, 0);
    const requestOptions = additionalOptionsKeyList.reduce((res, optionKey) => {
      const optionList = additionalTariffOptions[optionKey];
      if (!optionList) {
        return res;
      }

      let isThereRequestItem = false;
      let count = 0;

      if (optionList.length > 1) {
        isThereRequestItem = !!optionList.find(
          (opt) => tariffRequest?.options?.some((req) => req.tariffOptionId === opt.id)
        );
      } else {
        const optionId = optionList[0].id;
        const option = tariffRequest?.options?.find((reqOpt) => reqOpt.tariffOptionId === optionId);
        count = option?.count || 0;
        isThereRequestItem = !!option;
      }
      return {
        ...res,
        [optionKey]: {
          isRequested: isThereRequestItem,
          amount: count,
        },
      };
    }, {} as TariffRequestOptions);
    return {
      accounts: requestAccounts,
      options: requestOptions,
    };
  }, [
    tariffRequest,
    additionalTariffOptions,
    optionsResultList,
    additionalAccountOption,
    accountOption,
    additionalOptionsKeyList,
  ]);

  const isTariffRequested =
    tariffRequestData.accounts !== 0 || Object.keys(tariffRequestData.options).length > 0;

  const isFutureDiscountLoss = useMemo(
    () =>
      tariffRequestData?.accounts
        ? (tariffRequestData?.accounts || 1) < (discountLimit || 0)
        : isDiscountLoss,
    [tariffRequestData?.accounts, discountLimit, isDiscountLoss]
  );

  const employeeComputedPrices = useMemo(
    () =>
      getTariffEmployeePrices(
        optionsResultList?.[0],
        currentTariff,
        accountAmount,
        accountsOption,
        additionalAccountsOption,
        isDiscountLoss
      ),
    [
      accountAmount,
      accountsOption,
      additionalAccountsOption,
      currentTariff,
      isDiscountLoss,
      optionsResultList,
    ]
  );
  const nextMonthEmployeeComputedPrices = useMemo(
    () =>
      !isTariffRequested
        ? employeeComputedPrices
        : getTariffEmployeePrices(
            optionsResultList?.[0],
            currentTariff,
            tariffRequestData.accounts,
            newAccountsOption,
            newAdditionalAccountsOption,
            isFutureDiscountLoss
          ),
    [
      currentTariff,
      employeeComputedPrices,
      isFutureDiscountLoss,
      isTariffRequested,
      newAccountsOption,
      newAdditionalAccountsOption,
      optionsResultList,
      tariffRequestData.accounts,
    ]
  );

  const currentOptionsComputedPrices = getTariffComputedPrices(
    additionalTariffOptions,
    initiallySelectedOptions || [],
    currentTariff?.options,
    accountAmount,
    integrationsAmount,
    storeRecordsId,
    isDiscountLoss
  );

  const optionsComputedPrices = getTariffComputedPrices(
    additionalTariffOptions,
    selectedOptions,
    currentTariff?.options,
    accountAmount,
    integrationsAmount,
    storeRecordsId,
    isDiscountLoss
  );
  const requestStoreRecordsId = additionalTariffOptions[TariffOptionType.Records]?.find(
    (opt) => tariffRequest?.options?.some((req) => req.tariffOptionId === opt.id)
  )?.id;
  let newIntegrations = integrationsAmount;
  if (isTariffRequested && tariffRequestData?.options[TariffOptionType.Integrations]?.isRequested) {
    newIntegrations =
      (tariffRequest?.options.find((o) => o.type === TariffOptionType.Integrations)?.count || 0) /
      (tariffRequestData?.accounts || accountAmount || 1);
  }
  const newSelectedOptions = !isTariffRequested
    ? selectedOptions
    : additionalOptionsKeyList.map((key) => !!tariffRequestData?.options[key]?.isRequested);

  const nextMonthOptionsComputedPrices = useMemo(
    () =>
      !isTariffRequested
        ? optionsComputedPrices
        : getTariffComputedPrices(
            additionalTariffOptions,
            newSelectedOptions,
            newTariffOptions,
            tariffRequestData?.accounts || accountAmount,
            newIntegrations,
            requestStoreRecordsId || storeRecordsId,
            isFutureDiscountLoss
          ),
    [
      accountAmount,
      additionalTariffOptions,
      isFutureDiscountLoss,
      isTariffRequested,
      newIntegrations,
      newTariffOptions,
      newSelectedOptions,
      optionsComputedPrices,
      requestStoreRecordsId,
      storeRecordsId,
      tariffRequestData?.accounts,
    ]
  );

  function updateTariffData() {
    return Promise.allSettled([getTariffListData(), getCurrentTariffData()]);
  }

  const currentMonthPrice = currentOptionsComputedPrices.price.reduce(
    (res: number, price: number, index: number) => {
      let resultPrice = price;
      const discountPrice = currentOptionsComputedPrices.discountPrice[index];
      if (discountPrice) {
        resultPrice = discountPrice;
      }
      return res + resultPrice;
    },
    employeeComputedPrices.discountPrice || employeeComputedPrices.price
  );

  const nextMonthPrice = isFutureDiscountLoss
    ? nextMonthOptionsComputedPrices.price.reduce(
        (sum: number, price: number) => sum + price,
        nextMonthEmployeeComputedPrices.price
      )
    : nextMonthOptionsComputedPrices.discountPrice.reduce(
        (sum: number, discount: number, index: number) =>
          sum + (discount || nextMonthOptionsComputedPrices.price[index]),
        nextMonthEmployeeComputedPrices.discountPrice || nextMonthEmployeeComputedPrices.price
      );

  return {
    accountOption,
    additionalAccountOption,
    additionalOptionsKeyList,
    additionalTariffOptions,
    optionsResultList,
    employeeComputedPrices,
    optionsComputedPrices,
    currentTariff,
    currentEmployeeCount,
    currentTariffEmployeesCount,
    currentOptionsComputedPrices,
    currentMonthPrice,
    installedIntegrationsCount,
    tariffRequest,
    tariffRequestData,
    isTariffRequested,
    updateTariffData,
    getTariffListData,
    getCurrentTariffData,
    isDiscountLoss,
    nextMonthPrice,
    isTariffActive,
    isDataLoading,
    loadingSelectedTariff,
  };
}
