export enum periodType {
  today = 'today',
  yesterday = 'yesterday',
  week = 'week',
  pastWeek = 'pastWeek',
  month = 'month',
  pastMonth = 'pastMonth',
  year = 'year',
  dayHour = 'dayHour',
}

export enum periodShort {
  hour = 'Hour',
  day = 'Day',
  week = 'Week',
  month = 'Month',
}

export enum callType {
  allCalls = 'all',
  incoming = 'incoming',
  outgoing = 'outgoing',
  missed = 'missed',
  unsuccessful = 'unsuccessful',
}

export enum ChartType {
  bar = 'bar',
  line = 'line',
}
