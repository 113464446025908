import { SortDirection } from '@/client/generated/graphql';
import { validateSearchParamsEnum } from '@components/common';
import { callType, periodType } from '../Statistics.interfaces';

export const searchParamsValidators = {
  callType: { validate: validateSearchParamsEnum(callType) },
  filterPeriod: { validate: validateSearchParamsEnum(periodType) },
  avgDuration: { validate: validateSearchParamsEnum(SortDirection) },
  sumDuration: { validate: validateSearchParamsEnum(SortDirection) },
  totalCalls: { validate: validateSearchParamsEnum(SortDirection) },
};
