import React, { useState, useMemo } from 'react';
import { DomainStatus } from '@/client/generated/graphql';
import { CLIENT_QUERY, USER_QUERY } from '@/client/queries';
import { getCurrentDomain } from '@/utils';
import { useQuery } from '@apollo/client';
import {
  ContractIsCheckedImage,
  DataIsBeingCheckedImage,
  DataIsCheckedImage,
  FillingTheContractImage,
} from '@components/assets/images';
import { makeStyles } from '@material-ui/core';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { pxToEm } from '@shared/utils/styles';
import downloadFileSafe from '@shared/utils/downloadFileSafe';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import clsx from 'clsx';
import { BalanceDialog } from '@/components/BalanceDialog';
import { useNavigate } from 'react-router-dom';
import { getPathToCurrentContractFillStep } from '@/utils/getPathToCurrentContractFillStep';

const useStyles = makeStyles(({ color: { success, tertiary } }) => ({
  root: {
    flex: '1 1 100%',
    display: 'flex',
    padding: '1.5em',
    borderRadius: '1em',
    backgroundColor: tertiary[50],
    marginBottom: '2em',
    height: pxToEm(168),
  },
  rootSuccessBackground: {
    backgroundColor: success[50],
  },
  leftPart: {
    flex: '0 1 60%',
    display: 'flex',
    flexDirection: 'column',
  },
  rightPart: {
    flex: '0 1 40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    display: 'block',
  },
  descriptionWrapper: {
    marginTop: '.5em',
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    marginTop: 'auto',
    display: 'flex',
  },
  mainButton: {
    marginRight: '.5em',
  },
  downloadOfferAgreementButton: {
    minWidth: pxToEm(216),
  },
}));

const ContractStatusInfoBlock = () => {
  const classes = useStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const [isContractDownloading, setIsContractDownloading] = useState(false);
  const [isFillBalanceDialogOpen, setIsFillBalanceDialogOpen] = useState(false);

  const { data: userData, loading: loadingUserData } = useQuery(USER_QUERY, {
    fetchPolicy: 'cache-first',
  });
  const { data: clientData, loading: loadingClientData } = useQuery(CLIENT_QUERY, {
    fetchPolicy: 'cache-first',
  });
  const isModerated = clientData?.getClient.isModerated;
  const currentDomain = useMemo(() => getCurrentDomain(userData?.user), [userData?.user]);
  const status = currentDomain?.status;
  const docsFlags = userData?.user.domains.find((domain) => domain.current)?.params?.docs;

  if (loadingUserData || loadingClientData) {
    return null;
  }

  const handleButtonDownloadContractClick = async () => {
    if (isContractDownloading) {
      return;
    }

    setIsContractDownloading(true);

    const result = await downloadFileSafe('/Договор оферты.pdf', 'Договор оферты.pdf');
    if (result.type === 'error') {
      globalNotification$.show('danger', translate('COULD_NOT_DOWNLOAD_FILE'));
    }

    setIsContractDownloading(false);
  };

  const handleFillContractButtonClick = () => {
    navigate('/register/personal-data');
  };

  const handleReplenishAccountButtonClick = () => {
    setIsFillBalanceDialogOpen(true);
  };

  const handleCloseBalanceDialog = () => {
    setIsFillBalanceDialogOpen(false);
  };

  const handleContinueToFillContractButtonClick = () => {
    navigate(getPathToCurrentContractFillStep(docsFlags));
  };

  const renderContent = () => {
    switch (status) {
      case DomainStatus.Demo: {
        if (isModerated === true) {
          return (
            <div className={classes.root}>
              <div className={classes.leftPart}>
                <Typography className={classes.title} pxToEmSize={20} bold>
                  {translate('DATA_HAS_BEEN_CHECKED')}
                </Typography>
                <div className={classes.descriptionWrapper}>
                  <Typography>
                    {translate(
                      'YOU_MAY_CONTINUE_TO_FILL_THE_CONTRACT_FROM_THE_MOMENT_YOU_LEFT_OFF'
                    )}
                  </Typography>
                </div>

                <div className={classes.buttons}>
                  <Button
                    className={classes.mainButton}
                    smallHeight
                    onClick={handleContinueToFillContractButtonClick}
                  >
                    <Typography color="base">{translate('EXECUTE_CONTRACT')}</Typography>
                  </Button>
                  <Button
                    className={classes.downloadOfferAgreementButton}
                    variant="text"
                    loading={isContractDownloading}
                    onClick={handleButtonDownloadContractClick}
                    smallHeight
                  >
                    <Typography color="link600">
                      {translate('DOWNLOAD_CONTRACT_TEMPLATE')}
                    </Typography>
                  </Button>
                </div>
              </div>
              <div className={classes.rightPart}>
                <DataIsCheckedImage />
              </div>
            </div>
          );
        }
        if (isModerated === false) {
          return (
            <div className={classes.root}>
              <div className={classes.leftPart}>
                <Typography className={classes.title} pxToEmSize={20} bold>
                  {translate('DATA_IS_BEING_CHECKED')}
                </Typography>
                <div className={classes.descriptionWrapper}>
                  <Typography>
                    {translate(
                      'YOU_MAY_CONTINUE_TO_FILL_THE_CONTRACT_FROM_THE_MOMENT_YOU_LEFT_OFF'
                    )}
                  </Typography>
                </div>

                <div className={classes.buttons}>
                  <Button
                    className={classes.mainButton}
                    smallHeight
                    onClick={handleContinueToFillContractButtonClick}
                  >
                    <Typography color="base">{translate('CONTINUE_CONTRACT')}</Typography>
                  </Button>
                  <Button
                    className={classes.downloadOfferAgreementButton}
                    variant="text"
                    loading={isContractDownloading}
                    onClick={handleButtonDownloadContractClick}
                    smallHeight
                  >
                    <Typography color="link600">
                      {translate('DOWNLOAD_CONTRACT_TEMPLATE')}
                    </Typography>
                  </Button>
                </div>
              </div>
              <div className={classes.rightPart}>
                <DataIsBeingCheckedImage />
              </div>
            </div>
          );
        }
        return (
          <div className={classes.root}>
            <div className={classes.leftPart}>
              <Typography className={classes.title} pxToEmSize={20} bold>
                {translate('DOCUMENTS_FORM')}
              </Typography>
              <div className={classes.descriptionWrapper}>
                <Typography>{translate('CONTRACT_DESCRIPTION')}</Typography>
              </div>

              <div className={classes.buttons}>
                <Button
                  className={classes.mainButton}
                  smallHeight
                  onClick={handleFillContractButtonClick}
                >
                  <Typography color="base">{translate('EXECUTE_CONTRACT')}</Typography>
                </Button>
                <Button
                  className={classes.downloadOfferAgreementButton}
                  variant="text"
                  loading={isContractDownloading}
                  onClick={handleButtonDownloadContractClick}
                  smallHeight
                >
                  <Typography color="link600">{translate('DOWNLOAD_CONTRACT_TEMPLATE')}</Typography>
                </Button>
              </div>
            </div>
            <div className={classes.rightPart}>
              <FillingTheContractImage />
            </div>
          </div>
        );
      }
      case DomainStatus.Moderating: {
        return (
          <div className={classes.root}>
            <div className={classes.leftPart}>
              <Typography className={classes.title} pxToEmSize={20} bold>
                {translate('CONTRACT_IS_BEING_CHECKED')}
              </Typography>
              <div className={classes.descriptionWrapper}>
                <Typography>{translate('CONTRACT_DESCRIPTION_MODERATING_2')}</Typography>
              </div>
            </div>
            <div className={classes.rightPart}>
              <DataIsBeingCheckedImage />
            </div>
          </div>
        );
      }
      case DomainStatus.Confirmed: {
        return (
          <div className={clsx(classes.root, classes.rootSuccessBackground)}>
            <div className={classes.leftPart}>
              <Typography className={classes.title} pxToEmSize={20} bold>
                {translate('CONTRACT_IS_CHECKED')}
              </Typography>
              <div className={classes.descriptionWrapper}>
                <Typography>{translate('PBX_IS_AWAITING_TRANSITION_TO_COMMERCIAL_USE')}</Typography>
                <Typography>{translate('ITS_A_GOOD_TIME_TO_REPLENISH_YOUR_ACCOUNT')}</Typography>
              </div>
              <div className={classes.buttons}>
                <Button
                  className={classes.mainButton}
                  smallHeight
                  onClick={handleReplenishAccountButtonClick}
                >
                  <Typography color="base">{translate('REPLENISH_ACCOUNT')}</Typography>
                </Button>
              </div>
            </div>
            <div className={classes.rightPart}>
              <ContractIsCheckedImage />
            </div>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <>
      {renderContent()}
      <BalanceDialog
        isFillBalanceDialogOpen={isFillBalanceDialogOpen}
        onCloseBalanceDialog={handleCloseBalanceDialog}
      />
    </>
  );
};

export default ContractStatusInfoBlock;
