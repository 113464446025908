import { makeStyles } from '@material-ui/core';

export const useSwitchStyles = makeStyles(({ color: { base, tertiary, primary } }) => ({
  wrapper: {
    lineHeight: '1em',
  },
  root: {
    width: '2em',
    height: '1em',
    padding: 0,
    overflow: 'unset',
    '& .MuiIconButton-root': {
      fontSize: '1em',
    },
  },
  switchBase: {
    padding: '3px 0 0 4px',
    '&$disabled': {
      '& + $track': {
        backgroundColor: tertiary[300],
        opacity: 1,
      },
      '& $thumb': {
        backgroundColor: base,
      },
    },
    '&$checked': {
      transform: 'translateX(0.875em)',
      color: base,
      '& + $track': {
        opacity: 1,
        backgroundColor: primary[700],
      },
      '& $thumb': {
        backgroundColor: base,
      },
      '&$disabled': {
        '& + $track': {
          backgroundColor: tertiary[200],
        },
        '& $thumb': {
          backgroundColor: base,
        },
      },
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '&:not($checked) + $track': {
        backgroundColor: tertiary[500],
      },
      '&$checked + $track': {
        backgroundColor: primary[600],
      },
    },
  },
  thumb: {
    fontSize: '1em',
    width: '0.625em',
    height: '0.625em',
    boxShadow: 'none',
    backgroundColor: base,
    '&:hover': {
      boxShadow: 'none',
    },
  },
  track: {
    height: 'unset',
    borderRadius: '6.25em',
    opacity: 1,
    backgroundColor: tertiary[400],
  },
  checked: {},
  disabled: {},
}));
