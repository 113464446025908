import { ClientPassportModel, ClientType } from '@/client/generated/graphql';
import { dateOnlyStringToDate } from '@shared/utils/dates';
import { TFunction } from 'i18next';
import { UseFormReturn } from 'react-hook-form';

// Make sure that default values are set as string, not with `null | undefined`.
// This is important because fields validation functions always expect strings, not other types.

export const setDefaultFlValues = (
  formMethods: UseFormReturn<any>,
  passport: ClientPassportModel | null | undefined,
  translate: TFunction,
  phone?: string | null | undefined,
  email?: string | null | undefined
) => {
  if (!passport || passport.type !== ClientType.Fl) {
    return;
  }

  const setValue = formMethods.setValue;
  setValue('type', ClientType.Fl);

  setValue('IsPostalAddressDuplicate', passport.postalAddress === passport.legalAddress);

  setValue('person', passport.person);
  setValue('genitiveCasePerson', passport.genitiveCasePerson || '');
  if (passport.dateOfBirth) {
    setValue('dateOfBirth', dateOnlyStringToDate(passport.dateOfBirth));
  }
  setValue('gender', passport.gender);

  setValue('series', passport.series);
  setValue('number', passport.number);
  setValue('departmentCode', passport.departmentCode);
  setValue('placeOfIssue', passport.placeOfIssue);
  if (passport.dateOfIssue) {
    setValue('dateOfIssue', dateOnlyStringToDate(passport.dateOfIssue));
  }

  setValue('inn', passport.inn || '');
  setValue('postalAddress', passport.postalAddress);
  setValue('legalAddress', passport.legalAddress);

  setValue('phone', phone);
  setValue('email', email);

  setValue('passportFrontFileHash', passport?.scans?.passport || '');
  setValue(
    'passportFrontFileName',
    passport?.scans?.passport ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('passportAddressFileHash', passport?.scans?.registration || '');
  setValue(
    'passportAddressFileName',
    passport?.scans?.registration ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('innFileHash', passport?.scans?.inn || '');
  setValue(
    'innFileName',
    passport?.scans?.inn ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('kndFileHash', passport?.scans?.knd || '');
  setValue(
    'kndFileName',
    passport?.scans?.knd ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('selfieFileHash', passport?.scans?.selfie || '');
  setValue(
    'selfieFileName',
    passport?.scans?.selfie ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('selfieContractFileHash', passport?.scans?.selfieContract || '');
  setValue(
    'selfieContractFileName',
    passport?.scans?.selfieContract ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('personalDataAgreement', true);
  setValue('IsPostalAddressDuplicate', passport.postalAddress === passport.legalAddress);
};

export const setDefaultIpValues = (
  formMethods: UseFormReturn<any>,
  passport: ClientPassportModel | null | undefined,
  translate: TFunction,
  phone?: string | null | undefined,
  email?: string | null | undefined
) => {
  if (!passport || passport.type !== ClientType.Ip) {
    return;
  }

  const setValue = formMethods.setValue;
  setValue('type', ClientType.Ip);

  setValue('companyName', passport.companyName);
  setValue('person', passport.person);
  setValue('genitiveCasePerson', passport.genitiveCasePerson || '');
  if (passport.dateOfBirth) {
    setValue('dateOfBirth', dateOnlyStringToDate(passport.dateOfBirth));
  }
  setValue('gender', passport.gender);

  setValue('series', passport.series);
  setValue('number', passport.number);
  setValue('departmentCode', passport.departmentCode);
  setValue('placeOfIssue', passport.placeOfIssue);
  if (passport.dateOfIssue) {
    setValue('dateOfIssue', dateOnlyStringToDate(passport.dateOfIssue));
  }

  setValue('inn', passport.inn || '');
  setValue('ogrn', passport.ogrn);
  setValue('edo', passport.edo || '');

  setValue('legalAddress', passport.legalAddress);
  setValue('postalAddress', passport.postalAddress);

  setValue('bankName', passport.bankName);
  setValue('bik', passport.bik);
  setValue('account', passport.account);
  setValue('correspondentAccount', passport.correspondentAccount);
  setValue('bankAddress', passport.bankAddress);

  setValue('phone', phone);
  setValue('email', email);

  setValue('passportFrontFileHash', passport?.scans?.passport || '');
  setValue(
    'passportFrontFileName',
    passport?.scans?.passport ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('passportAddressFileHash', passport?.scans?.registration || '');
  setValue(
    'passportAddressFileName',
    passport?.scans?.registration ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('innFileHash', passport?.scans?.inn || '');
  setValue(
    'innFileName',
    passport?.scans?.inn ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('egripFileHash', passport?.scans?.egrip || '');
  setValue(
    'egripFileName',
    passport?.scans?.egrip ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('cardFileHash', passport?.scans?.card || '');
  setValue(
    'cardFileName',
    passport?.scans?.card ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('selfieFileHash', passport?.scans?.selfie || '');
  setValue(
    'selfieFileName',
    passport?.scans?.selfie ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('selfieContractFileHash', passport?.scans?.selfieContract || '');
  setValue(
    'selfieContractFileName',
    passport?.scans?.selfieContract ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('personalDataAgreement', true);
  setValue('IsPostalAddressDuplicate', passport.postalAddress === passport.legalAddress);
};

export const setDefaultUlValues = (
  formMethods: UseFormReturn<any>,
  passport: ClientPassportModel | null | undefined,
  translate: TFunction,
  phone?: string | null | undefined,
  email?: string | null | undefined
) => {
  if (!passport || passport.type !== ClientType.Ul) {
    return;
  }

  const setValue = formMethods.setValue;

  setValue('type', ClientType.Ul);

  setValue('companyName', passport.companyName);

  setValue('inn', passport.inn || '');
  setValue('ogrn', passport.ogrn);
  setValue('edo', passport.edo || '');
  setValue('kpp', passport.kpp);

  setValue('legalAddress', passport.legalAddress);
  setValue('postalAddress', passport.postalAddress);

  setValue('bankName', passport.bankName);
  setValue('bik', passport.bik);
  setValue('account', passport.account);
  setValue('correspondentAccount', passport.correspondentAccount);
  setValue('bankAddress', passport.bankAddress);

  setValue('person', passport.person);
  setValue('genitiveCasePerson', passport.genitiveCasePerson || '');
  setValue('position', passport.position);
  setValue('genitiveCasePosition', passport.genitiveCasePosition);
  setValue('actBasis', passport.actBasis);

  setValue('phone', phone);
  setValue('email', email);

  setValue('egrulFileHash', passport?.scans?.egrul || '');
  setValue(
    'egrulFileName',
    passport?.scans?.egrul ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('innFileHash', passport?.scans?.inn || '');
  setValue(
    'innFileName',
    passport?.scans?.inn ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('authorityFileHash', passport?.scans?.authority || '');
  setValue(
    'authorityFileName',
    passport?.scans?.authority ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('cardFileHash', passport?.scans?.card || '');
  setValue(
    'cardFileName',
    passport?.scans?.card ? translate('DOCUMENT_LOADED') : translate('FILE_IS_MISSING')
  );

  setValue('personalDataAgreement', true);
  setValue('IsPostalAddressDuplicate', passport.postalAddress === passport.legalAddress);
};
