import { makeStyles } from '@material-ui/core/styles';

export const useFormArrowSwitcher = makeStyles(
  ({ color: { tertiary, base } }) => ({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    wrapperRoot: {
      margin: 0,
      minWidth: 'auto',
      '& > div': {
        minWidth: 'auto',
        height: '100%',
      },
      height: '100%',
    },
    inputRoot: {
      borderRadius: 0,
      '& input': {
        textAlign: 'center',
      },
    },
    arrowButton: {
      padding: '0.5em 0.25em',
      backgroundColor: base,
      color: 'black',
      border: `solid 1px ${tertiary[300]}`,
      alignSelf: 'normal',
      justifySelf: 'normal',
    },
    decrease: {
      borderRadius: '3px 0 0 3px',
      borderRight: 'none',
    },
    increase: {
      borderRadius: '0 3px 3px 0',
      borderLeft: 'none',
    },
    tooltip: {
      display: 'block',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
    },
  }),
  {
    index: 3,
  }
);
