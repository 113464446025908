import { makeStyles, Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';
import { pxToEm } from '@shared/utils/styles';

export const useDashboardLayoutStyles = makeStyles<Theme & IThemeOptions>(
  ({ color: { base } }) => ({
    layoutRoot: {
      minWidth: '1200px',
      minHeight: '400px',
    },
    wrapper: {
      top: 0,
      bottom: 0,
      right: 0,
      overflowX: 'auto',
      overflowY: 'hidden',
      position: 'absolute',
      backgroundColor: base,
    },
    wrapperLeftShort: {
      left: '5em',
    },
    wrapperLeftLong: {
      left: '15em',
    },
    pageErrorBody: {
      marginTop: pxToEm(250),
    },
  }),
  {
    index: 1,
  }
);
