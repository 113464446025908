import React from 'react';
import Tab from '@components/Tab/Tab';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

type Props = {
  isPhoneNumbersTabActive?: boolean;
  isResponsiblePersonsTabActive?: boolean;
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  firstTab: {
    marginRight: '1em',
  },
}));

const NumbersTabs = ({
  isPhoneNumbersTabActive = false,
  isResponsiblePersonsTabActive = false,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [translate] = useTranslation();

  return (
    <div className={classes.container}>
      <Tab
        id={1}
        className={classes.firstTab}
        onClick={() => navigate('/numbers/numbers')}
        active={isPhoneNumbersTabActive}
        tabTitle={translate('PHONE_NUMBERS')}
      />
      <Tab
        id={2}
        onClick={() => navigate('/numbers/responsible-persons')}
        active={isResponsiblePersonsTabActive}
        tabTitle={translate('RESPONSIBLE_PERSONS')}
      />
    </div>
  );
};

export default NumbersTabs;
