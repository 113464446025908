import { makeStyles } from '@material-ui/core/styles';

export const useQuickSearchStyles = makeStyles(
  ({ color: { tertiary } }) => ({
    inputMarginDense: {
      padding: '0.47em 3em 0.47em 2.9em',
    },
    root: {
      width: 'auto',
    },
    startAdornment: {
      position: 'absolute',
      left: '1em',

      width: '1em',
      height: '1em',
    },
    tertiary500: {
      color: tertiary[500],
    },
  }),
  {
    index: 2,
  }
);
