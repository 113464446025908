import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';
import styleConstants from '@components/styles';
import { pxToEm } from '@shared/utils/styles';

export const useDepartmentStyles = makeStyles(
  ({ color: { tertiary, primary, danger, base } }: IThemeOptions & Theme) => ({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      background: base,
      flexDirection: 'column',
    },
    contentBottomSpace: {
      bottom: '5em',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
      padding: '0 0 0 2em',
      paddingRight: '1em',
      overflow: 'auto',
      height: '100%',
    },
    formContent: {
      width: '23em',
      marginBottom: '2em',
      marginTop: '0',
      '& > .MuiFormControl-root, & > .MuiAutocomplete-root': {
        marginTop: '1.5em',
      },
    },
    formListSection: {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    actions: {
      display: 'flex',
      width: '24em',
    },
    confirmButton: {
      width: '60%',
    },
    listItemLabel: {
      background: tertiary[50],
      width: '2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      borderRadius: '6px',
      marginRight: '1em',
    },
    listItemContent: {
      display: 'flex',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%',
      borderRadius: '6px',
      background: tertiary[50],
    },
    listEmployeeWrapper: {
      width: '90%',
    },
    listEmployeeExt: {
      paddingLeft: '1em',
      marginTop: '2px',
    },
    listItemContentDragging: {
      width: '24em',
      color: tertiary[900],
      background: tertiary[100],
      boxShadow: '0px 16px 30px 0px rgba(0, 0, 0, 0.14)',
    },
    listItemContentDanger: {
      background: danger[50],
    },
    listItemTitle: {
      marginLeft: '1em',
      wordBreak: 'break-all',
      overflow: 'hidden',
      maxHeight: '1.375em',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
    },
    listItemIcon: {
      display: 'flex',
      alignItems: 'center',
      margin: '0.5em',
    },
    listItemAction: {
      flex: `0 0 ${pxToEm(40)}`,
      height: pxToEm(40),
      padding: 0,
      marginLeft: '.5em',
      display: 'flex',
      cursor: 'pointer',
      border: 0,
      borderRadius: '6px',
      backgroundColor: 'transparent',
      color: tertiary[500],
      '&:hover': {
        backgroundColor: danger[50],
        border: `1px solid ${danger[600]}`,
        color: tertiary[900],
      },
    },
    addEmployee: {
      marginTop: '0',
      width: '24em',
    },
    addEmployeeOpen: {
      backgroundColor: primary[200],
      border: `1px solid ${primary[600]}`,
      color: primary[700],
    },
    ...styleConstants,
  }),
  {
    index: 1,
  }
);

export const useSortableListClasses = makeStyles(
  ({ color: { primary, tertiary, danger, base } }: IThemeOptions & Theme) => ({
    list: {
      width: '27.1em',
      marginTop: '1em',
      marginRight: '3.5em',
    },
    listTitle: {
      marginBottom: '1.25em',
    },
    listDraggingOver: {
      background: base,
    },
    listItem: {
      width: '100%',
      userSelect: 'none',
      borderRadius: '3px',
      border: '1px solid transparent',
      outline: 'none',
      background: 'transparent',
      display: 'flex',
      margin: '0 0 0.25em 0',
      flexFlow: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '2.625em',
      '&:last-child': {
        marginBottom: '16px',
      },
      '&:hover': {
        '& > :not(:last-child)': {
          background: tertiary[100],
        },
      },
    },
    listItemDanger: {
      '&:hover': {
        '& > :not(:last-child)': {
          background: danger[100],
        },
      },
    },
    listItemDragging: {
      opacity: '0.8',
      width: '24em',
      background: 'transparent',
      '& > :not(:last-child)': {
        color: tertiary[900],
        background: tertiary[100],
      },
    },
    dropListPlaceholder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '2.5em',
      width: '21.5em',
      borderRadius: '6px',
      border: `1px dashed ${tertiary[400]}`,
      color: tertiary[500],
    },
    dropListPlaceholderDraggingOver: {
      border: `1px dashed ${primary[500]}`,
      color: primary[700],
    },
  }),
  {
    index: 1,
  }
);
