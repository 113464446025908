import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CLIENT_QUERY, USER_QUERY } from '@/client/queries';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '@/components/Breadcrumbs';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import Flex from '@shared/components/Flex';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import DateField from '@shared/components/DateField';
import PhoneField from '@shared/components/PhoneField';
import EmailField from '@shared/components/EmailField';
import UploadField, { uploadFieldOnChangeData } from '@components/UploadField';
import { FormProvider, useForm } from 'react-hook-form';
import BodyContainer from '@/layouts/BodyContainer';
import { DomainStatus, genderOptions } from '@components/typings/interfaces';
import Button from '@shared/components/Button';
import MessageDialog from '@shared/components/MessageDialog';
import { TrashIcon } from '@shared/assets/images/icons';
import ControlButtons from '@shared/components/ControlButtons';
import {
  UPDATE_CLIENT_CONTACTS_MUTATION,
  UPLOAD_DOCUMENT_MUTATION,
  UPDATE_CLIENT_PASSPORT_SCANS_MUTATION,
} from '@/client/mutations';
import { ClientType } from '@/client/generated/graphql';
import Alert from '@shared/components/Alert';
import clsx from 'clsx';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import RadioGroup from '@shared/components/RadioGroup';
import FormControlLabelForRadio from '@shared/components/FormControlLabelForRadio';
import { Radio } from '@material-ui/core';
import { PreviewPhoto } from '@components/assets/images';
import { SelfiesMIMETypes, setUploadFieldProgress } from '@components/utils';
import PagePreloader from '@components/PagePreloader';
import resultify from '@shared/utils/resultify';
import { validateAndGetSelfieFileForUploading } from '@components/utils/documents';
import { AUTHENTICATION_STATE_QUERY } from '@components/client/queries';
import { getRole, hasSystemRole } from '@/utils';
import { ClientPassportFieldsExtended } from './PayerData.interfaces';
import { setDefaultFlValues, setDefaultIpValues, setDefaultUlValues } from '../documents.utils';
import { usePayerDataStyle, usePhotoBlockStyles } from './PayerData.styles';

const removeAllNonNumbers = (str?: string) => {
  if (!str) {
    return str;
  }
  return str.replace(/[^\d]/g, '');
};

type PhotoBlockProps = {
  clientType: ClientType | null;
};

type PhotoBlockFields = {
  selfieContractFileHash?: string | null;
  selfieContractFileName?: string | null;
  selfieContractFileUrl?: string | null;
  selfieContractFileProgressName?: string | null;
};

const PhotoBlock = ({ clientType }: PhotoBlockProps) => {
  const [translate] = useTranslation();
  const classes = usePhotoBlockStyles();

  const formMethods = useForm<PhotoBlockFields>();
  const { data: dataAuthStateQuery } = useQuery<{
    role?: string;
  }>(AUTHENTICATION_STATE_QUERY);
  const role = getRole(dataAuthStateQuery?.role);
  const isSystemUser = useMemo(() => hasSystemRole(role), [role]);

  useQuery(CLIENT_QUERY, {
    fetchPolicy: 'cache-only',
    onCompleted(data) {
      const selfieContract = data?.getClient.passport?.scans?.selfieContract;
      if (!selfieContract) {
        return;
      }

      formMethods.setValue('selfieContractFileHash', selfieContract);
      formMethods.setValue('selfieContractFileName', translate('DOCUMENT_LOADED'));
    },
  });
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const status = getCurrentDomain(userData?.user)?.status;

  const [uploadFile] = useMutation(UPLOAD_DOCUMENT_MUTATION);
  const [updateClientPassportScans] = useMutation(UPDATE_CLIENT_PASSPORT_SCANS_MUTATION);

  if (
    clientType === null ||
    (status !== DomainStatus.Demo && status !== DomainStatus.Moderating) ||
    (clientType !== ClientType.Fl && clientType !== ClientType.Ip)
  ) {
    return null;
  }

  const handlePhotoUpload = async ({ progressName, file }: uploadFieldOnChangeData) => {
    if (!file) {
      return;
    }

    const result = await validateAndGetSelfieFileForUploading(file, isSystemUser);
    if (result.type === 'error') {
      if (result.errorMessageCodeForHashField) {
        formMethods.setError('selfieContractFileHash', {
          message: translate(result.errorMessageCodeForHashField),
        });
        return;
      }
      globalNotification$.show('warn', 'SOMETHING_WENT_WRONG');
      return;
    }

    const resultFile = result.file;

    const comment = translate('PHOTO_WITH_CONTRACT');
    const documentType = 'SCAN';

    const uploadFileResult = await resultify(
      uploadFile({
        variables: {
          data: {
            type: documentType,
            comment,
          },
          file: resultFile,
        },
        context: {
          fetchOptions: {
            onProgress: ({ loaded, total }: ProgressEvent) =>
              setUploadFieldProgress(loaded, total, formMethods.setValue, progressName),
          },
        },
      })
    );

    if (uploadFileResult.type === 'error') {
      globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      return;
    }

    const filename = uploadFileResult.data.data?.uploadDocument.filename;
    if (!filename) {
      globalNotification$.show('warn', 'UPLOAD_FILE_ERROR');
      return;
    }

    const updateClientPassportScansResult = await resultify(
      updateClientPassportScans({ variables: { data: { selfieContract: filename } } })
    );

    if (updateClientPassportScansResult.type === 'error') {
      globalNotification$.show('warn', 'UPLOAD_FILE_ERROR');
      return;
    }

    formMethods.setValue('selfieContractFileHash', filename);
    globalNotification$.show('success', 'SUCCESS');
  };

  const handleRemovePhoto = () => {
    updateClientPassportScans({ variables: { data: { selfieContract: null } } })
      .then(() => {
        formMethods.setValue('selfieContractFileHash', null);
      })
      .catch(() => {
        globalNotification$.show('warn', 'COULD_NOT_REMOVE_FILE');
      });
  };

  return (
    <Flex className={classes.photoBlock}>
      <Flex className={classes.photoBlockFirstColumn} direction="column">
        <div className={classes.photoPlaceholder}>
          <PreviewPhoto />
        </div>

        <FormProvider {...formMethods}>
          <UploadField
            className={classes.photoField}
            label={translate('UPLOAD_PHOTO')}
            requiredText={translate('FILE_NOT_CHOSEN')}
            fileHashFieldName={'selfieContractFileHash'}
            fileNameFieldName={'selfieContractFileName'}
            fileUrlFieldName={'selfieContractFileUrl'}
            fileProgressName={'selfieContractFileProgressName'}
            onChange={handlePhotoUpload}
            accept={SelfiesMIMETypes}
            onCancel={handleRemovePhoto}
            showViewButton
          />
        </FormProvider>
      </Flex>

      <Flex
        className={classes.photoBlockSecondColumn}
        direction="column"
        justifyContent="spaceBetween"
      >
        <Alert
          status="info"
          description={
            <div className={classes.photoBlockAlert}>
              <Typography className={classes.photoBlockAlertItem}>
                {translate('UPLOAD_PHOTO_WITH_CONTRACT_ALERT_DESC_1')}
              </Typography>
              <Typography className={classes.photoBlockAlertItem}>
                {translate('UPLOAD_PHOTO_WITH_CONTRACT_ALERT_DESC_2')}
              </Typography>
              <Typography className={classes.photoBlockAlertItem}>
                {translate('UPLOAD_PHOTO_WITH_CONTRACT_ALERT_DESC_3')}
              </Typography>
              <Typography className={classes.photoBlockAlertItem}>
                {translate('UPLOAD_PHOTO_WITH_CONTRACT_ALERT_DESC_4')}
              </Typography>
            </div>
          }
        />
      </Flex>
    </Flex>
  );
};

export const PayerData = () => {
  const classes = usePayerDataStyle();
  const [translate] = useTranslation();

  const { data, loading: loadingClientData } = useQuery(CLIENT_QUERY);

  const passport = data?.getClient.passport;
  const type = data?.getClient.type || null;

  const params = useMemo(() => {
    return data?.getClient?.params || null;
  }, [data?.getClient.params]);

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const [updateClientContacts, { loading: loadingUpdateClientContacts }] = useMutation(
    UPDATE_CLIENT_CONTACTS_MUTATION,
    {
      refetchQueries: [
        {
          query: CLIENT_QUERY,
        },
      ],
    }
  );

  const [isEditPayerContactsDialogOpen, setIsEditPayerContactsDialogOpen] = useState(false);

  const status = getCurrentDomain(userData?.user)?.status;
  const formMethods = useForm<ClientPassportFieldsExtended>({
    defaultValues: {
      emailsNew: [],
      phonesNew: [],
    },
  });
  const { setValue, watch, clearErrors, handleSubmit } = formMethods;
  const isClientFl = useMemo(() => type === ClientType.Fl, [type]);
  const isClientUl = useMemo(() => type === ClientType.Ul, [type]);
  const isClientIp = useMemo(() => type === ClientType.Ip, [type]);

  const clientPhones = watch('phones') || [];
  const clientEmails = watch('emails') || [];
  const clientPhonesNew = watch('phonesNew') || [];
  const clientEmailsNew = watch('emailsNew') || [];

  const moreThanOneEmail = clientEmails.length > 1;
  const moreThanOnePhone = clientPhones.length > 1;

  const areThereLessOrEqualToOneClientPhonesNew = clientPhonesNew?.length <= 1;
  const areThereLessOrEqualToOneClientEmailsNew = clientEmailsNew?.length <= 1;

  const setDefaultFormValues = useCallback(
    (typeOfClient: ClientType) => {
      if (!typeOfClient) {
        return;
      }

      const phone = params?.contacts?.phones?.[0];
      const email = params?.contacts?.emails?.[0];

      switch (typeOfClient) {
        case ClientType.Fl:
          setDefaultFlValues(formMethods, passport, translate, phone, email);
          return;

        case ClientType.Ip:
          setDefaultIpValues(formMethods, passport, translate, phone, email);
          return;

        case ClientType.Ul:
          setDefaultUlValues(formMethods, passport, translate, phone, email);
          return;

        default: {
          typeOfClient satisfies never;
        }
      }
    },
    [formMethods, params?.contacts?.emails, params?.contacts?.phones, passport, translate]
  );

  // This useEffect sets default form values once when all
  // necessary request variables are received
  const refHaveDefaultValuesBeenSetOnce = useRef(false);
  useEffect(() => {
    if (refHaveDefaultValuesBeenSetOnce.current || !passport || !params) {
      return;
    }

    refHaveDefaultValuesBeenSetOnce.current = true;

    setValue('emails', params?.contacts?.emails);
    setValue('phones', params?.contacts?.phones);
    setValue('emailsNew', params?.contacts?.emails || []);
    setValue('phonesNew', params?.contacts?.phones || []);

    setDefaultFormValues(passport.type);
  }, [params, passport, setDefaultFormValues, setValue]);

  function handleEditContactsModalCancelClick() {
    setIsEditPayerContactsDialogOpen(false);
    clearErrors();

    setTimeout(() => {
      setValue('phonesNew', formMethods.getValues().phones || []);
      setValue('emailsNew', formMethods.getValues().emails || []);
    }, 200); // prevents UI blink
  }

  function handleAddClientPhone() {
    const newClientPhonesList = [...clientPhonesNew];
    newClientPhonesList.push('');
    setValue('phonesNew', newClientPhonesList);
  }

  function handleAddClientEmail() {
    const newClientEmailsList = [...clientEmailsNew];
    newClientEmailsList.push('');
    setValue('emailsNew', newClientEmailsList);
  }

  function handleRemoveClientPhone(i: number) {
    const updatedPhonesList = [...clientPhonesNew];
    updatedPhonesList.splice(i, 1);
    setValue('phonesNew', updatedPhonesList);
  }

  function handleRemoveClientEmail(i: number) {
    const updatedEmailsList = [...clientEmailsNew];
    updatedEmailsList.splice(i, 1);
    setValue('emailsNew', updatedEmailsList);
  }

  const getAreThereDifferencesBetweenOldAndNewPhones = () => {
    const maxLength = Math.max(clientPhones.length, clientPhonesNew.length);

    for (let i = 0; i < maxLength; i += 1) {
      if (removeAllNonNumbers(clientPhones[i]) !== removeAllNonNumbers(clientPhonesNew[i])) {
        return true;
      }
    }

    return false;
  };

  const getAreThereDifferencesBetweenOldAndNewEmails = () => {
    const maxLength = Math.max(clientEmails.length, clientEmailsNew.length);

    for (let i = 0; i < maxLength; i += 1) {
      if (clientEmails[i] !== clientEmailsNew[i]) {
        return true;
      }
    }

    return false;
  };

  function handleUpdateClientContacts() {
    if (clientPhonesNew.length === 0 || clientEmailsNew.length === 0) {
      console.warn('clientPhonesNew or clientEmailsNew length is zero');
      return;
    }

    updateClientContacts({
      variables: {
        data: {
          emails: clientEmailsNew,
          phones: clientPhonesNew,
        },
      },
    })
      .then(() => {
        setValue('phones', clientPhonesNew);
        setValue('emails', clientEmailsNew);

        globalNotification$.show('success', 'YOUR_CONTACTS_HAVE_BEEN_CHANGED');

        setIsEditPayerContactsDialogOpen(false);
      })
      .catch(() => {
        globalNotification$.show('warn', 'SOMETHING_WENT_WRONG');
      });
  }

  const renderDocumentClientContacts = () => (
    <>
      <Flex justifyContent={'spaceBetween'}>
        <div className={classes.personalDataTitle}>
          <Typography type={'text2'} color={'tertiary900'}>
            {translate('CONTACTS')}
          </Typography>
        </div>
        <Button
          className={classes.editContactsBtn}
          variant={'secondary'}
          disabled={status !== DomainStatus.Commercial}
          onClick={() => setIsEditPayerContactsDialogOpen(true)}
          title={translate('EDITING')}
        />
      </Flex>
      <div className={classes.personalDataContainer}>
        {params?.contacts ? (
          <>
            {moreThanOnePhone && (
              <div className={classes.personalDataContactTitle}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('NUMBER')}
                </Typography>
              </div>
            )}
            {clientPhones.map((phone, index) => (
              <div className={classes.personalDataItem} key={`client-phone-${phone}`}>
                <PhoneField
                  name={`phones.${index}`}
                  InputProps={{ readOnly: true }}
                  label={moreThanOnePhone ? undefined : translate('PHONE')}
                />
              </div>
            ))}
            {moreThanOneEmail && (
              <div className={classes.personalDataContactTitle}>
                <Typography type={'text3'} color={'tertiary900'}>
                  {translate('EMAIL')}
                </Typography>
              </div>
            )}
            {clientEmails.map((email, index) => (
              <div className={classes.personalDataItem} key={`client-email-${email}`}>
                <EmailField
                  name={`emails.${index}`}
                  InputProps={{ readOnly: true }}
                  label={moreThanOneEmail ? undefined : translate('EMAIL')}
                />
              </div>
            ))}
          </>
        ) : (
          <Typography type={'text3'} color={'tertiary900'}>
            {translate('CONTACTS_NOT_SPECIFIED')}
          </Typography>
        )}
      </div>
    </>
  );

  const renderEditContactsModalContent = () => {
    const isConfirmButtonDisabled =
      !getAreThereDifferencesBetweenOldAndNewPhones() &&
      !getAreThereDifferencesBetweenOldAndNewEmails();

    return (
      <form onSubmit={handleSubmit(handleUpdateClientContacts)} id={'edit-contacts'}>
        <div className={classes.personalDataContactTitle}>
          <Typography type={'text3'} color={'tertiary900'} medium>
            {translate('NUMBER')}
          </Typography>
        </div>
        <div className={classes.personalDataItem}>
          {clientPhonesNew.map((_, index: number) => (
            <div className={classes.editContactsItem} key={`client-phone-${index}`}>
              <div className={classes.editContactsContainer}>
                <PhoneField
                  name={`phonesNew.${index}`}
                  required
                  validate={(phone) => {
                    let samePhonesCount = 0;

                    for (let i = 0; i < clientPhonesNew.length; i += 1) {
                      if (removeAllNonNumbers(phone) === removeAllNonNumbers(clientPhonesNew[i])) {
                        samePhonesCount += 1;
                      }
                      if (i === index && samePhonesCount > 1) {
                        return translate('NUMBER_IS_ALREADY_PRESENT');
                      }
                    }

                    return true;
                  }}
                />
                <Button
                  color={'error'}
                  variant="secondary"
                  className={classes.personalDataRemoveBtn}
                  disabled={areThereLessOrEqualToOneClientPhonesNew}
                  onClick={() => handleRemoveClientPhone(index)}
                >
                  <TrashIcon />
                </Button>
              </div>
            </div>
          ))}
          <div className={classes.editContactsItem}>
            <Button
              className={classes.addContactBtn}
              variant={'secondary'}
              onClick={handleAddClientPhone}
              title={translate('ADD_NUMBER')}
              disabled={clientPhonesNew.some(
                (phone) => !phone || removeAllNonNumbers(phone)!.length < 8
              )}
            />
          </div>
        </div>
        <div className={classes.personalDataContactTitle}>
          <Typography type={'text3'} color={'tertiary900'} medium>
            {translate('EMAIL')}
          </Typography>
        </div>
        {clientEmailsNew.map((_, index: number) => (
          <div className={classes.editContactsItem} key={`client-email-${index}`}>
            <div className={classes.editContactsContainer}>
              <EmailField name={`emailsNew.${index}`} required />
              <Button
                className={classes.editContactsRemoveBtn}
                variant={'secondary'}
                color={'error'}
                disabled={areThereLessOrEqualToOneClientEmailsNew}
                onClick={() => handleRemoveClientEmail(index)}
              >
                <TrashIcon />
              </Button>
            </div>
          </div>
        ))}
        <Button
          className={classes.addContactBtn}
          variant={'secondary'}
          onClick={handleAddClientEmail}
          title={translate('ADD_EMAIL')}
          disabled={clientEmailsNew.some((email) => email === '')}
        />
        <ControlButtons
          rootClass={classes.marginTop2}
          confirmTitle={'SAVE'}
          cancelTitle={'CANCEL'}
          cancelVariant="secondary"
          form={'edit-contacts'}
          onCancelClick={handleEditContactsModalCancelClick}
          confirmDisable={isConfirmButtonDisabled}
          loading={loadingUpdateClientContacts}
          justifyContent={'start'}
          flexDirection={'row-reverse'}
          small
        />
      </form>
    );
  };

  const renderFlFields = () => {
    return (
      <Flex>
        <Flex
          className={clsx(classes.personalDataColumn, classes.marginRight2)}
          direction={'column'}
        >
          <div className={classes.personalDataTitle}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('CLIENT')}
            </Typography>
          </div>
          <div className={classes.personalDataContainer}>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'type'}
                label={translate('LEGAL_STATUS')}
                value={translate('INDIVIDUAL_TYPE')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'person'}
                label={translate('FULL_NAME')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'genitiveCasePerson'}
                label={translate('GENITIVE_NAME')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <DateField
                name={'dateOfBirth'}
                label={translate('DATE_OF_BIRTH')}
                disableFuture
                readonly
              />
            </div>
            <div className={classes.personalDataItem}>
              <RadioGroup name={'gender'}>
                <div className={classes.personalDataRadioContainer}>
                  <div className={classes.personalDataGenderContainer}>
                    <Typography type={'text4'} color={'tertiary700'}>
                      {translate('GENDER')}
                    </Typography>
                    <div className={classes.personalDataGenderControls}>
                      {genderOptions.map(({ titleCode, value }) => (
                        <FormControlLabelForRadio
                          className={classes.radioFormControlLabel}
                          key={`gender-${titleCode}`}
                          value={value}
                          control={<Radio color={'secondary'} />}
                          label={translate(titleCode)}
                          disabled
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </RadioGroup>
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'inn'}
                label={translate('INN')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <Flex justifyContent={'spaceBetween'} className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'series'}
                label={translate('SERIES')}
                InputProps={{ readOnly: true }}
                className={classes.defaultElementWidth6}
              />
              <FormFieldRhfUncontrolled
                name={'number'}
                label={translate('PAS_NUMBER')}
                InputProps={{ readOnly: true }}
                className={classes.defaultElementWidth19}
              />
            </Flex>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'departmentCode'}
                label={translate('CODE')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'placeOfIssue'}
                multiline
                rows={3}
                label={translate('WHO_GIVEN')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <DateField
                name={'dateOfIssue'}
                label={translate('GIVEN_DATE')}
                disableFuture
                readonly
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'legalAddress'}
                multiline
                rows={3}
                label={translate('REGISTRATION_ADDRESS')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'postalAddress'}
                multiline
                rows={3}
                label={translate('LIVING_ADDRESS')}
                InputProps={{ readOnly: true }}
              />
            </div>
          </div>
        </Flex>
        <Flex direction={'column'} className={classes.personalDataColumn}>
          {renderDocumentClientContacts()}
          <div className={classes.personalDataTitle}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('DOCUMENTS')}
            </Typography>
          </div>
          <div className={classes.personalDataContainer}>
            <div className={classes.personalDataItem}>
              <Typography
                type={'text4'}
                color={'tertiary600'}
                className={classes.personalDataUploadLabel}
              >
                {`${translate('PASSPORT')}. ${translate('PASSPORT_DATA_PAGE')}`}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'passportFrontFileHash'}
                fileNameFieldName={'passportFrontFileName'}
                fileUrlFieldName={'passportFrontFileUrl'}
                fileProgressName={'passportDataPage'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography
                type={'text4'}
                color={'tertiary600'}
                className={classes.personalDataUploadLabel}
              >
                {`${translate('PASSPORT')}. ${translate('PASSPORT_ADDRESS_PAGE')}`}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'passportAddressFileHash'}
                fileNameFieldName={'passportAddressFileName'}
                fileUrlFieldName={'passportAddressFileUrl'}
                fileProgressName={'passportAddressPage'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography type={'text4'} color={'tertiary600'}>
                {translate('INN_UPLOAD')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'innFileHash'}
                fileNameFieldName={'innFileName'}
                fileUrlFieldName={'innFileUrl'}
                fileProgressName={'innPage'}
                readonly
                showViewButton
                required
              />
            </div>

            <div className={classes.personalDataItem}>
              <Typography type={'text4'} color={'tertiary600'}>
                {translate('KND_UPLOAD')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'kndFileHash'}
                fileNameFieldName={'kndFileName'}
                fileUrlFieldName={'kndFileUrl'}
                fileProgressName={'kndPage'}
                readonly
                showViewButton
                required
              />
            </div>
          </div>
          <div className={classes.personalDataTitle}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('PHOTO')}
            </Typography>
          </div>
          <div className={classes.personalDataContainer}>
            <div className={classes.personalDataItem}>
              <Typography
                type={'text4'}
                color={'tertiary600'}
                className={classes.personalDataUploadLabel}
              >
                {translate('PHOTO_WITH_PASSPORT')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'selfieFileHash'}
                fileNameFieldName={'selfieFileName'}
                fileUrlFieldName={'selfieFileUrl'}
                fileProgressName={'selfieFileProgressName'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography
                type={'text4'}
                color={'tertiary600'}
                className={classes.personalDataUploadLabel}
              >
                {translate('PHOTO_WITH_CONTRACT')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'selfieContractFileHash'}
                fileNameFieldName={'selfieContractFileName'}
                fileUrlFieldName={'selfieContractFileUrl'}
                fileProgressName={'selfieContractFileProgressName'}
                readonly
                showViewButton
              />
            </div>
          </div>
        </Flex>
      </Flex>
    );
  };

  const renderIpFields = () => {
    return (
      <Flex>
        <Flex
          className={clsx(classes.personalDataColumn, classes.marginRight2)}
          direction={'column'}
        >
          <div className={classes.personalDataTitle}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('PAYER')}
            </Typography>
          </div>
          <div className={classes.personalDataContainer}>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'type'}
                label={translate('LEGAL_STATUS')}
                value={translate('BUSINESSMAN_TYPE')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'companyName'}
                label={translate('COMPANY_NAME')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'person'}
                label={translate('FULL_NAME')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'genitiveCasePerson'}
                label={translate('GENITIVE_NAME')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <DateField
                name={'dateOfBirth'}
                label={translate('DATE_OF_BIRTH')}
                readonly
                disableFuture
              />
            </div>
            <div className={classes.personalDataItem}>
              <RadioGroup name={'gender'}>
                <div className={classes.personalDataRadioContainer}>
                  <div className={classes.personalDataGenderContainer}>
                    <Typography type={'text4'} color={'tertiary700'}>
                      {translate('GENDER')}
                    </Typography>
                    <div className={classes.personalDataGenderControls}>
                      {genderOptions.map(({ titleCode, value }) => (
                        <FormControlLabelForRadio
                          className={classes.radioFormControlLabel}
                          key={`gender-${titleCode}`}
                          value={value}
                          control={<Radio color={'secondary'} />}
                          label={translate(titleCode)}
                          disabled
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </RadioGroup>
            </div>
            <Flex justifyContent={'spaceBetween'} className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'series'}
                label={translate('SERIES')}
                InputProps={{ readOnly: true }}
                className={classes.defaultElementWidth6}
              />
              <FormFieldRhfUncontrolled
                name={'number'}
                label={translate('PAS_NUMBER')}
                InputProps={{ readOnly: true }}
                className={classes.defaultElementWidth19}
              />
            </Flex>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'departmentCode'}
                label={translate('CODE')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'placeOfIssue'}
                multiline
                rows={3}
                label={translate('WHO_GIVEN')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <DateField
                name={'dateOfIssue'}
                label={translate('GIVEN_DATE')}
                disableFuture
                readonly
              />
            </div>

            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'inn'}
                label={translate('INN')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'ogrn'}
                label={translate('OGRNIP')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'edo'}
                label={translate('EDO_IDENTIFIER')}
                InputProps={{ readOnly: true }}
              />
            </div>

            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'legalAddress'}
                multiline
                rows={3}
                label={translate('LEGAL_ADDRESS')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'postalAddress'}
                multiline
                rows={3}
                label={translate('POSTAL_ADDRESS')}
                InputProps={{ readOnly: true }}
              />
            </div>
          </div>
        </Flex>
        <Flex direction={'column'} className={classes.personalDataColumn}>
          {renderDocumentClientContacts()}
          <div className={classes.personalDataTitle}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('BANK_DETAILS')}
            </Typography>
          </div>

          <div className={classes.personalDataContainer}>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'bankName'}
                label={translate('BANK_NAME')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'bik'}
                label={translate('BIK')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'account'}
                label={translate('CHECKING_ACCOUNT')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'correspondentAccount'}
                label={translate('CORRESPONDENT_ACCOUNT')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'bankAddress'}
                label={translate('BANK_ADDRESS')}
                InputProps={{ readOnly: true }}
              />
            </div>
          </div>

          <div className={classes.personalDataTitle}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('DOCUMENTS')}
            </Typography>
          </div>
          <div className={classes.personalDataContainer}>
            <div className={classes.personalDataItem}>
              <Typography
                type={'text4'}
                color={'tertiary600'}
                className={classes.personalDataUploadLabel}
              >
                {`${translate('PASSPORT')}. ${translate('PASSPORT_DATA_PAGE')}`}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'passportFrontFileHash'}
                fileNameFieldName={'passportFrontFileName'}
                fileUrlFieldName={'passportFrontFileUrl'}
                fileProgressName={'passportDataPage'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography
                type={'text4'}
                color={'tertiary600'}
                className={classes.personalDataUploadLabel}
              >
                {`${translate('PASSPORT')}. ${translate('PASSPORT_ADDRESS_PAGE')}`}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'passportAddressFileHash'}
                fileNameFieldName={'passportAddressFileName'}
                fileUrlFieldName={'passportAddressFileUrl'}
                fileProgressName={'passportAddressPage'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography type={'text4'} color={'tertiary600'}>
                {translate('INN_UPLOAD')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'innFileHash'}
                fileNameFieldName={'innFileName'}
                fileUrlFieldName={'innFileUrl'}
                fileProgressName={'innPage'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography type={'text4'} color={'tertiary600'}>
                {translate('EGRIP')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'egripFileHash'}
                fileNameFieldName={'egripFileName'}
                fileUrlFieldName={'egripFileUrl'}
                fileProgressName={'egripPage'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography type={'text4'} color={'tertiary600'}>
                {translate('IP_DETAILS_CARD')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'cardFileHash'}
                fileNameFieldName={'cardFileName'}
                fileUrlFieldName={'cardFileUrl'}
                fileProgressName={'cardFileProgressName'}
                readonly
                showViewButton
                required
              />
            </div>
          </div>
          <div className={classes.personalDataTitle}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('PHOTO')}
            </Typography>
          </div>
          <div className={classes.personalDataContainer}>
            <div className={classes.personalDataItem}>
              <Typography
                type={'text4'}
                color={'tertiary600'}
                className={classes.personalDataUploadLabel}
              >
                {translate('PHOTO_WITH_PASSPORT')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'selfieFileHash'}
                fileNameFieldName={'selfieFileName'}
                fileUrlFieldName={'selfieFileUrl'}
                fileProgressName={'selfieFileProgressName'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography
                type={'text4'}
                color={'tertiary600'}
                className={classes.personalDataUploadLabel}
              >
                {translate('PHOTO_WITH_CONTRACT')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'selfieContractFileHash'}
                fileNameFieldName={'selfieContractFileName'}
                fileUrlFieldName={'selfieContractFileUrl'}
                fileProgressName={'selfieContractFileProgressName'}
                readonly
                showViewButton
              />
            </div>
          </div>
        </Flex>
      </Flex>
    );
  };

  const renderUlFields = () => {
    return (
      <Flex>
        <Flex
          direction={'column'}
          className={clsx(classes.personalDataColumn, classes.marginRight2)}
        >
          <Flex direction={'column'}>
            <div className={classes.personalDataTitle}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('PAYER')}
              </Typography>
            </div>
            <div className={classes.personalDataContainer}>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'type'}
                  label={translate('LEGAL_STATUS')}
                  value={translate('LEGAL_TYPE')}
                  InputProps={{ readOnly: true }}
                />
              </div>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'companyName'}
                  label={translate('COMPANY_NAME')}
                  InputProps={{ readOnly: true }}
                />
              </div>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'inn'}
                  label={translate('INN')}
                  InputProps={{ readOnly: true }}
                />
              </div>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'ogrn'}
                  label={translate('OGRN')}
                  InputProps={{ readOnly: true }}
                />
              </div>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'edo'}
                  label={translate('EDO_IDENTIFIER')}
                  InputProps={{ readOnly: true }}
                />
              </div>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'kpp'}
                  label={translate('KPP')}
                  InputProps={{ readOnly: true }}
                />
              </div>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'legalAddress'}
                  multiline
                  rows={3}
                  label={translate('LEGAL_ADDRESS')}
                  InputProps={{ readOnly: true }}
                />
              </div>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'postalAddress'}
                  multiline
                  rows={3}
                  label={translate('POSTAL_ADDRESS')}
                  InputProps={{ readOnly: true }}
                />
              </div>
            </div>
          </Flex>
          <Flex className={classes.personalDataColumn} direction={'column'}>
            <div className={classes.personalDataTitle}>
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('SIGNATORY')}
              </Typography>
            </div>
            <div className={classes.personalDataContainer}>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'person'}
                  label={translate('FULL_NAME')}
                  InputProps={{ readOnly: true }}
                />
              </div>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'genitiveCasePerson'}
                  label={translate('GENITIVE_NAME')}
                  InputProps={{ readOnly: true }}
                />
              </div>
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'position'}
                  label={translate('POSITION')}
                  InputProps={{ readOnly: true }}
                />
              </div>
              {passport?.genitiveCasePosition && (
                <div className={classes.personalDataItem}>
                  <FormFieldRhfUncontrolled
                    name={'genitiveCasePosition'}
                    label={translate('POSITION_GENITIVE')}
                    InputProps={{ readOnly: true }}
                  />
                </div>
              )}
              <div className={classes.personalDataItem}>
                <FormFieldRhfUncontrolled
                  name={'actBasis'}
                  label={translate('BASIC_CONTRACT_GENITIVE')}
                  InputProps={{ readOnly: true }}
                />
              </div>
            </div>
          </Flex>
        </Flex>
        <Flex direction={'column'} className={classes.personalDataColumn}>
          {renderDocumentClientContacts()}

          <div className={classes.personalDataTitle}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('BANK_DETAILS')}
            </Typography>
          </div>
          <div className={classes.personalDataContainer}>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'bankName'}
                label={translate('BANK_NAME')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'bik'}
                label={translate('BIK')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'account'}
                label={translate('CHECKING_ACCOUNT')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'correspondentAccount'}
                label={translate('CORRESPONDENT_ACCOUNT')}
                InputProps={{ readOnly: true }}
              />
            </div>
            <div className={classes.personalDataItem}>
              <FormFieldRhfUncontrolled
                name={'bankAddress'}
                label={translate('BANK_ADDRESS')}
                InputProps={{ readOnly: true }}
              />
            </div>
          </div>

          <div className={classes.personalDataTitle}>
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('DOCUMENTS')}
            </Typography>
          </div>
          <div className={classes.personalDataContainer}>
            <div className={classes.personalDataItem}>
              <Typography type={'text4'} color={'tertiary600'}>
                {translate('DOCUMENT_ON_STATE_REGISTRATION')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'egrulFileHash'}
                fileNameFieldName={'egrulFileName'}
                fileUrlFieldName={'egrulFileUrl'}
                fileProgressName={'egrulFileProgressName'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography type={'text4'} color={'tertiary600'}>
                {translate('CERTIFICATE_OF_TAX_REGISTRATION')}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'innFileHash'}
                fileNameFieldName={'innFileName'}
                fileUrlFieldName={'innFileUrl'}
                fileProgressName={'innFileProgressName'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography type={'text4'} color={'tertiary600'}>
                {`${translate('DOCUMENT_CONFIRMING_THE_AUTHORITY_OF_A_LEGAL_ENTITY')}`}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'authorityFileHash'}
                fileNameFieldName={'authorityFileName'}
                fileUrlFieldName={'authorityFileUrl'}
                fileProgressName={'authorityFileProgressName'}
                readonly
                showViewButton
                required
              />
            </div>
            <div className={classes.personalDataItem}>
              <Typography type={'text4'} color={'tertiary600'}>
                {`${translate('CARD_WITH_COMPANY_DETAILS')}`}
              </Typography>
              <UploadField
                label={translate('FILE_IS_MISSING')}
                requiredText={translate('FILE_NOT_CHOSEN')}
                fileHashFieldName={'cardFileHash'}
                fileNameFieldName={'cardFileName'}
                fileUrlFieldName={'cardFileUrl'}
                fileProgressName={'cardFileProgressName'}
                readonly
                showViewButton
                required
              />
            </div>
          </div>
        </Flex>
      </Flex>
    );
  };

  const renderDocumentPersonalData = () => {
    if (!type) {
      return null;
    }
    if (isClientFl) {
      return renderFlFields();
    }
    if (isClientIp) {
      return renderIpFields();
    }
    if (isClientUl) {
      return renderUlFields();
    }
    return <Alert status="danger" description={translate('CANNOT_DEFINE_CLIENT_TYPE')} />;
  };

  const renderContent = () => {
    if (loadingClientData) {
      return <PagePreloader />;
    }

    return (
      <div className={classes.root}>
        <div className={classes.body}>
          <div className={classes.payerDataRoot}>
            <PhotoBlock clientType={type} />
            <FormProvider {...formMethods}>
              <form>
                <div className={classes.documentsContentContainer}>
                  <div className={classes.documentsContent}>{renderDocumentPersonalData()}</div>
                </div>
                <MessageDialog
                  isOpen={isEditPayerContactsDialogOpen}
                  contentClass={classes.defaultElementWidth26}
                  title={translate('CONTACTS')}
                  onCancel={handleEditContactsModalCancelClick}
                  renderContent={renderEditContactsModalContent()}
                />
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    );
  };

  return <BodyContainer breadCrumbs={<Breadcrumbs />}>{renderContent()}</BodyContainer>;
};
