import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { useSearchParams } from 'react-router-dom';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import HighlightedText from '@components/HighlightedText';
import { useNotificationsStyles } from '../SettingsNotifications.styles';
import { TableDataObject } from '../SettingsNotifications.interface';

export const NotificationsMissedCallCell = ({ item }: ICellProps<TableDataObject>) => {
  const { allNumbers, domainNumber, department, employee } = item || {};
  const [translate] = useTranslation();
  const classes = useNotificationsStyles();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get('query') || null;

  return (
    <Flex direction={'column'}>
      {allNumbers && (
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('ALL_NUMBERS')}
        </Typography>
      )}
      <div>
        {domainNumber?.phone && (
          <HighlightedText type={'phone'} text={domainNumber?.phone} query={searchText} />
        )}
        {employee?.name && (
          <HighlightedText type={'text'} text={employee?.name} query={searchText} />
        )}
        {department?.name && (
          <HighlightedText
            className={classes.span}
            type={'text'}
            text={department?.name}
            query={searchText}
          />
        )}
      </div>
    </Flex>
  );
};
