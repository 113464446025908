import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import Button from '@shared/components/Button/Button';
import { MinusIcon, PlusIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import { useFormContext } from 'react-hook-form';
import Typography from '@shared/components/Typography';
import TooltipHover from '@shared/components/Popover/TooltipHover';
import { useFormArrowSwitcher } from './FormArrowSwitcher.styles';
import { FormArrowSwitchType, IFormArrowSwitcher } from './FormArrowSwitcher.interfaces';

export const FormArrowSwitcher: FunctionComponent<IFormArrowSwitcher> = ({
  min = 0,
  max,
  minTooltip,
  maxTooltip,
  name = 'ArrowSwitchField',
  rootClass,
  disabled,
  value,
  defaultValue,
  step = 1,
  onChange,
}: IFormArrowSwitcher) => {
  const classes = useFormArrowSwitcher();
  const { watch, setValue } = useFormContext();
  const pattern = useMemo(() => /\D/gi, []);
  const fieldValue = value || watch(name) || 0;
  const filteredValue = fieldValue !== 0 ? Number(fieldValue.toString().replace(pattern, '')) : 0;
  const [isFocused, setFocused] = useState(false);

  const isButtonDisabled = (borderValue?: number) => {
    if (borderValue === undefined) {
      return false;
    }
    return filteredValue === borderValue;
  };

  const disableListeners = (directionName?: string) => ({
    disableHoverListener: directionName === undefined,
    disableFocusListener: directionName === undefined,
    disableTouchListener: directionName === undefined,
  });

  useEffect(() => {
    if (!isFocused) {
      if (min && filteredValue < min) {
        setValue(name, min);
      }
      if (max && filteredValue > max) {
        setValue(name, max);
      }
    }
    if (pattern.test(fieldValue)) {
      setValue(name, fieldValue.toString().replace(pattern, ''));
    }
  }, [fieldValue, min, max, name, setValue, pattern, filteredValue, isFocused]);

  function handleArrowClick(direction: FormArrowSwitchType) {
    if (direction === FormArrowSwitchType.Increase) {
      setValue(name, filteredValue + step);
    }
    if (direction === FormArrowSwitchType.Decrease) {
      setValue(name, filteredValue - step);
    }
    onChange?.();
  }

  function handleChange(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const inputValue = e.target.value;
    if (inputValue.length > 1 && inputValue[0] === '0') {
      const formattedValue = inputValue.slice(1);
      setValue(name, formattedValue);
    }
    if (!inputValue) setValue(name, min);
    onChange?.();
  }

  return (
    <div className={clsx(classes.root, rootClass)}>
      <TooltipHover
        placement={'bottom'}
        {...disableListeners(minTooltip)}
        title={
          <Typography className={classes.tooltip} color={'tertiary900'} type={'text4'}>
            {minTooltip}
          </Typography>
        }
      >
        <Button
          disabled={isButtonDisabled(min) || disabled}
          className={clsx(classes.arrowButton, classes.decrease)}
          onClick={() => handleArrowClick(FormArrowSwitchType.Decrease)}
          variant={'secondary'}
        >
          <MinusIcon />
        </Button>
      </TooltipHover>
      <div className={classes.wrapperRoot}>
        <FormFieldRhfUncontrolled
          value={value}
          name={name}
          disabled={disabled}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          defaultValue={defaultValue || 0}
          onChange={(e) => handleChange(e)}
          InputProps={{
            classes: {
              root: classes.inputRoot,
              marginDense: classes.wrapperRoot,
            },
            inputProps: {
              maxLength: max ? String(max).length : 99,
            },
          }}
        />
      </div>
      <TooltipHover
        placement={'bottom'}
        {...disableListeners(maxTooltip)}
        title={
          <Typography className={classes.tooltip} color={'tertiary900'} type={'text4'}>
            {maxTooltip}
          </Typography>
        }
      >
        <Button
          disabled={isButtonDisabled(max) || disabled}
          className={clsx(classes.arrowButton, classes.increase)}
          onClick={() => handleArrowClick(FormArrowSwitchType.Increase)}
          variant={'secondary'}
        >
          <PlusIcon />
        </Button>
      </TooltipHover>
    </div>
  );
};
