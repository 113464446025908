import React, { FunctionComponent, useEffect, useState } from 'react';
import { ButtonFilterGroupProps } from './ButtonFilterGroup.interfaces';
import { ButtonFilter } from '../ButtonFilter/ButtonFilter';
import { IButtonFilterProps } from '../ButtonFilter/ButtonFilter.interfaces';

export const ButtonFilterGroup: FunctionComponent<ButtonFilterGroupProps> = (props) => {
  const { data, onClick } = props;
  const [localData, setLocalData] = useState<IButtonFilterProps[]>(data);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  function handleItemClick(id: number | string) {
    if (onClick) {
      onClick(id);
    }
  }

  return (
    <div>
      {localData.map((item: IButtonFilterProps) => {
        const { title, count, icon, selected, id, disabled, border } = item;

        return (
          <ButtonFilter
            key={id}
            id={id}
            title={title}
            count={count}
            icon={icon}
            disabled={disabled}
            selected={selected}
            border={border}
            onClick={handleItemClick}
          />
        );
      })}
    </div>
  );
};
