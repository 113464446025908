import {
  ArrowRepeatIcon,
  BellIcon,
  CalendarIcon,
  CarouselIcon,
  DomainsIcon,
  MapInIcon,
  MelodyIcon,
  MicIcon,
  SipIcon,
  SmsIcon,
  UserXIcon,
} from '@shared/assets/images/icons';

import { TariffOptionType } from '@/client/generated/graphql';
import { FeaturesType } from '../../../typings';

export const settingCardList = [
  {
    title: 'RECORD_TALKS',
    link: '/settings/record-talks',
    icon: MicIcon,
    description: 'DESCRIPTION_RECORD_TALKS',
    feature: FeaturesType.BasicSettings,
    option: TariffOptionType.Records,
  },
  {
    title: 'TIMEZONE',
    link: '/settings/timezone',
    icon: MapInIcon,
    description: 'DESCRIPTION_TIMEZONE',
    feature: FeaturesType.BasicSettings,
    option: TariffOptionType.BasicPbx,
  },
  {
    title: 'TUNE',
    link: '/settings/tune',
    icon: MelodyIcon,
    description: 'DESCRIPTION_TUNE',
    feature: 'basic',
    option: TariffOptionType.BasicPbx,
  },
  {
    title: 'BLF',
    link: '/settings/blf',
    icon: ArrowRepeatIcon,
    description: 'DESCRIPTION_BLF',
    feature: FeaturesType.BasicSettings,
    option: TariffOptionType.BasicPbx,
  },
  {
    title: 'BLACK_LIST',
    link: '/settings/black-list',
    icon: UserXIcon,
    description: 'DESCRIPTION_BLACKLIST',
    feature: FeaturesType.BlackList,
    option: TariffOptionType.BasicPbx,
  },
  {
    title: 'TONE',
    link: '/settings/tone',
    icon: DomainsIcon,
    description: 'DESCRIPTION_TONE',
    feature: FeaturesType.BasicSettings,
    option: TariffOptionType.BasicPbx,
  },
  {
    title: 'SIP',
    link: '/settings/sip',
    icon: SipIcon,
    description: 'DESCRIPTION_SIP',
    feature: FeaturesType.OwnNumbers,
    option: TariffOptionType.OwnNumbersIn,
  },
  {
    title: 'NOTIFICATIONS',
    link: '/settings/notifications',
    icon: BellIcon,
    description: 'DESCRIPTION_NOTIFICATIONS',
    feature: FeaturesType.Notifications,
    option: TariffOptionType.Missed,
  },
  {
    title: 'BUSINESS_CARDS',
    link: '/business-cards/greetings',
    icon: SmsIcon,
    description: 'DESCRIPTION_SMS_CARDS',
    feature: FeaturesType.BusinessCards,
    option: TariffOptionType.BusinessCards,
  },
  {
    title: 'WORK_CALENDAR',
    link: '/settings/work-calendar',
    icon: CalendarIcon,
    description: 'DESCRIPTION_WORK_CALENDAR',
    // TODO (calendar) place correct icon
    backgroundIcon: CalendarIcon,
    feature: FeaturesType.BasicSettings,
    option: TariffOptionType.BasicPbx,
  },
  {
    title: 'NUMBERS_CAROUSEL',
    link: '/settings/carousel',
    icon: CarouselIcon,
    description: 'DESCRIPTION_CAROUSEL',
    feature: FeaturesType.BusinessCards,
    option: TariffOptionType.BusinessCards,
  },
];
