import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { DEPARTMENTS_QUERY } from '@/client/queries';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@shared/components/Button';
import QuickSearch from '@components/QuickSearch';
import Breadcrumbs from '@/components/Breadcrumbs';
import PagePreloader from '@components/PagePreloader';
import PageError from '@shared/components/PageError';
import LazyTable, { CellPreloader, ContentHint, IColumn } from '@components/LazyTable';
import { UsersIcon } from '@shared/assets/images/icons';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import { FormProvider, useForm } from 'react-hook-form';
import Typography from '@shared/components/Typography';
import { DepartmentsQuery } from '@/client/generated/graphql';
import { useSearchParamsValidator } from '@components/common';
import { useDepartmentsStyles } from './Departments.styles';
import {
  DepartmentNameCell,
  DepartmentExtCell,
  DepartmentCountCell,
  DepartmentActionsCell,
} from './modules';

export type Department = DepartmentsQuery['departments'][number];

export const columns: IColumn<Department>[] = [
  {
    id: 'name',
    label: 'DEPARTMENT_NAME',
    verticalAlign: 'center',
    minWidth: '25em',
    Renderer: DepartmentNameCell,
  },
  {
    id: 'ext',
    label: 'EXTENSION_NUM',
    minWidth: '4em',
    Renderer: DepartmentExtCell,
  },
  {
    id: 'employeesCount',
    label: 'EMPLOYEES_COUNT',
    minWidth: '4em',
    Renderer: DepartmentCountCell,
  },
  {
    id: 'activeEmployeesCount',
    label: 'RECEIVE_CALLS',
    minWidth: '4em',
    Renderer: DepartmentCountCell,
  },
  {
    id: 'actions',
    verticalAlign: 'center',
    label: '',
    Renderer: DepartmentActionsCell,
    align: 'right',
  },
];

export const Departments = () => {
  const classes = useDepartmentsStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(DEPARTMENTS_QUERY);
  const { searchParams } = useSearchParamsValidator({ query: null });
  const query = searchParams.get('query')?.toUpperCase();
  const formMethods = useForm({
    defaultValues: { query },
  });
  const departments = data?.departments || null;

  const tableData = useMemo(
    () =>
      departments?.filter(
        (dep) =>
          !query ||
          dep.name?.toUpperCase().includes(query) ||
          dep.ext?.includes(query) ||
          dep.employees?.some(
            (employee) =>
              employee.user.name?.toUpperCase().includes(query) || employee.ext?.includes(query)
          )
      ),
    [departments, query]
  );

  const renderDepartmentsContent = () => {
    if (loading) return <PagePreloader />;
    if (error) return <PageError />;

    const renderEmptyContent = () => {
      if (!query && !tableData?.length) {
        return (
          <ContentHint isContentCenter contentWidth={14}>
            <UsersIcon className={classes.usersIcon} />
            <Typography color={'tertiary600'} type={'text3'}>
              {translate('CREATE_DEPARTMENTS')}
            </Typography>
          </ContentHint>
        );
      }
      return (
        <CellPreloader>
          <Typography color={'tertiary900'} type={'text3'}>
            {translate('NOTHING_FOUND')}
          </Typography>
        </CellPreloader>
      );
    };

    return (
      <FormProvider {...formMethods}>
        <Flex direction={'column'} className={classes.root}>
          <Breadcrumbs>
            <div className={classes.action}>
              <QuickSearch parameterName={'query'} />
            </div>
            <Button
              title={translate('ADD_DEPARTMENT')}
              className={classes.addButton}
              onClick={() => navigate('/employee/departments/add')}
            />
          </Breadcrumbs>
          <LazyTable<Department>
            columns={columns}
            data={tableData || []}
            renderEmptyDataMessage={renderEmptyContent()}
          />
        </Flex>
      </FormProvider>
    );
  };

  return <BodyContainer disableOverflow>{renderDepartmentsContent()}</BodyContainer>;
};

export default Departments;
