import { HistoryRowModel } from '@/client/generated/graphql';
import {
  validateSearchParamsDate,
  validateSearchParamsEnum,
  validateSearchParamsTime,
} from '@components/common';
import { findWeekDay, formatDate } from '@components/utils';
import { format } from 'date-fns';
import { PeriodValues } from '@/utils';
import { getCallType, formatTime } from '../HistoryPage.constants';
import { callTypes, HistoryStatus, IHistoryList } from '../HistoryPage.interfaces';

export const getRowsData = (data?: HistoryRowModel[] | null): IHistoryList[] =>
  data
    ? data.map((item) => {
        const start = new Date(item.date);
        const itemDay = start.getDay();
        const week = findWeekDay(itemDay);
        const { day, month, monthShort } = formatDate(start);

        return {
          ...item,
          call: callTypes[getCallType(item.direction, item.status !== HistoryStatus.Success)],
          start,
          date: {
            week,
            month,
            day,
            monthShort,
          },
          other: item.other,
          time: format(new Date(item.date), formatTime),
          wait: item.provision,
          isInBlackList: false,
          blackListComment: null,
        };
      })
    : [];

export const searchParamsValidators = {
  search: null,
  employee: null,
  department: null,
  filterName: null,
  from: { validate: validateSearchParamsDate },
  to: { validate: validateSearchParamsDate },
  fromTime: { validate: validateSearchParamsTime },
  toTime: { validate: validateSearchParamsTime },
  period: { validate: validateSearchParamsEnum(PeriodValues) },
  status: { validate: validateSearchParamsEnum(HistoryStatus) },
  ext: null,
};
