import { DomainDocsModel } from '@/client/generated/graphql';

export const getPathToCurrentContractFillStep = (docsFlags: DomainDocsModel | null | undefined) => {
  if (!docsFlags || !docsFlags.passport) {
    return '/register/personal-data';
  }
  if (!docsFlags.tariff) {
    return '/register/select-tariff';
  }
  if (!docsFlags.numbers) {
    return '/register/phone-number';
  }
  if (!docsFlags.assistants) {
    return '/register/scenario';
  }
  return '/register/confirm';
};
