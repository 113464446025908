import { makeStyles } from '@material-ui/core';

export const useSwitchFieldStyles = makeStyles(
  ({ color: { primary, tertiary } }) => ({
    root: {
      marginRight: 0,
      minHeight: '1.5em',
    },
    label: {
      fontSize: '0.875em',
      color: tertiary[900],
      lineHeight: '1em',
      userSelect: 'none',
      paddingTop: '0.25em',
      marginLeft: '0.75em',
    },
    trackHovered: {
      backgroundColor: tertiary[500],
    },
    checkedTrackHovered: {
      backgroundColor: `${primary[600]} !important`,
    },
  }),
  {
    index: 5,
  }
);
