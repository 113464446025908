import React, { FunctionComponent } from 'react';
import { Switch as MuiSwitch, SwitchProps } from '@material-ui/core';
import clsx from 'clsx';
import { useSwitchStyles } from './Switch.styles';

type TSwitchProps = SwitchProps & {
  classes?: { wrapper?: string };
  readonly?: boolean;
};

export const Switch: FunctionComponent<TSwitchProps> = ({
  className,
  classes: propsClasses,
  disabled,
  readOnly,
  ...props
}) => {
  const classes = useSwitchStyles();

  return (
    <span className={clsx(propsClasses?.wrapper, classes.wrapper)}>
      <MuiSwitch
        className={clsx(className)}
        disabled={disabled || readOnly}
        classes={{
          ...propsClasses,
          root: clsx(classes.root, propsClasses?.root),
          switchBase: clsx(classes.switchBase, propsClasses?.switchBase),
          thumb: clsx(classes.thumb, propsClasses?.thumb),
          track: clsx(classes.track, propsClasses?.track),
          checked: clsx(classes.checked, propsClasses?.checked),
          disabled: clsx(classes.disabled, propsClasses?.disabled),
        }}
        {...props}
      />
    </span>
  );
};

export default Switch;
