import { useCallback, useMemo, useState } from 'react';
import { IFilterChangeValues } from '@/components/FilterByNumbers/FilterByNumbers.interfaces';
import {
  IColoredNumbersValues,
  INumberDiagramData,
  INumbersHistoryData,
} from '@/features/Statistics/StatisticsByNumbers/StatisticsByNumbers.interfaces';
import { LINE_DIAGRAM_TENSION } from '@/features/Statistics/StatisticsByCalls/StatisticsByCalls.constants';
import { formatDate, formatPhone, formatTimeHour } from '@components/utils';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { IColorVariants } from '@components/theme';
import { IThemeColorOptions } from '@components/typings/interfaces/theme';
import { useTheme } from '@material-ui/core/styles';
import { IHistoryStatistics } from '@/features/Statistics/StatisticsByCalls/StatisticsByCalls.interfaces';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@/client/queries';
import { isObjectEqual } from '@/utils';
import { callType, periodShort, periodType } from '../../Statistics.interfaces';

export function useStatisticsByNumbersProps(
  sType?: periodType,
  filterShortValue?: string,
  filterCallTypeValue?: string
) {
  const { data: { user = {} } = {} } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const [translate] = useTranslation();
  const [selectedNumbersList, setNumbersList] = useState<IColoredNumbersValues | null>(null);
  const [showDayPicker, setShowDayPicker] = useState(false);
  const [chartSwitch, setChartSwitch] = useState(true);
  const [selectedDayValue, setSelectedDayValue] = useState<Date | null>(null);
  const [hasSearchParams, setHasSearchParams] = useState(false);
  const [diagramDataState, setDiagramDataState] = useState<{
    prevRaw: INumbersHistoryData[] | null;
    prev: INumberDiagramData | null;
    data: INumberDiagramData;
  }>({
    prevRaw: null,
    prev: null,
    data: {
      labels: [],
      datasets: [],
    },
  });

  const theme: IThemeColorOptions = useTheme();
  const minDate = getCurrentDomain(user)?.createdAt;

  const createdAt = useMemo(() => {
    const currentDomain = getCurrentDomain(user);
    const now = new Date();
    return currentDomain?.createdAt ? new Date(currentDomain?.createdAt) : now;
  }, [user]);

  const colorsList: { [key: string]: IColorVariants } = useMemo(() => {
    const { links, success, primary, danger, warning } = theme?.color || {};
    return {
      danger,
      primary,
      links,
      success,
      warning,
    };
  }, [theme?.color]);

  const setNumberTypeData = useCallback(
    (data: INumbersHistoryData) => {
      if (data) {
        const formattedData = data.stat.reduce(
          (result: Array<number | null>, item) => {
            const start = new Date(item.timePeriod);
            const detailType = filterShortValue || periodShort.day;
            const now = new Date();
            const { dayEnd, weekEnd, monthEnd } = formatDate(start);
            let end = dayEnd;
            if (detailType === periodShort.week) {
              end = weekEnd;
            }
            if (detailType === periodShort.month) {
              end = monthEnd;
            }
            if (end < createdAt) {
              result.push(null);
              return result;
            }
            if (start < now) {
              switch (filterCallTypeValue) {
                case callType.incoming:
                  result.push(item.inSuccess + item.inNotSuccess);
                  break;
                case callType.outgoing:
                  result.push(item.outSuccess + item.outNotSuccess);
                  break;
                case callType.missed:
                  result.push(item.inNotSuccess);
                  break;
                case callType.unsuccessful:
                  result.push(item.outNotSuccess);
                  break;
                default:
                  result.push(
                    item.inSuccess + item.outSuccess + item.outNotSuccess + item.inNotSuccess
                  );
                  break;
              }
              return result;
            }
            return result;
          },
          [null]
        );
        formattedData.push(null);
        return {
          numberId: data.numberId,
          data: formattedData,
        };
      }
      return null;
    },
    [createdAt, filterCallTypeValue, filterShortValue]
  );

  const setLabel = useCallback(
    (data: IHistoryStatistics[]) => {
      if (data) {
        const detailType = filterShortValue || periodShort.day;
        return data.map((item, index) => {
          const start = new Date(item?.timePeriod);
          const { day, weekEndDay, month, monthShort, nextMonthShort } = formatDate(start);
          if (detailType === periodShort.hour) {
            return formatTimeHour(index + 1);
          }
          if (detailType === periodShort.day) {
            return `${day} ${translate(monthShort)}`;
          }
          if (detailType === periodShort.week) {
            return `${day} ${
              monthShort !== nextMonthShort ? translate(monthShort) : ''
            } - ${weekEndDay} ${translate(nextMonthShort)}`;
          }
          if (detailType === periodShort.month) {
            return translate(`${month}_NOM`);
          }
          return null;
        });
      }
      return null;
    },
    [filterShortValue, translate]
  );

  const computeDiagramData = useCallback(
    (
      isHide: boolean,
      hiddenIndex?: number | null,
      data?: INumbersHistoryData[],
      prevData?: INumberDiagramData,
      hiddenIdList?: Array<number>
    ) => {
      if (data) {
        const formattedData = data?.reduce(
          (result: INumberDiagramData, item: INumbersHistoryData, index: number) => {
            const numberList: Array<{
              phone: string;
              color: string;
            }> = Object.values(selectedNumbersList || {});
            const numberColor = colorsList[numberList[index].color];
            const itemColor =
              numberList[index].color !== 'primary'
                ? numberColor[600] || ''
                : numberColor[400] || '';
            const numberBgColor = itemColor;
            let labels = null;
            if (index === 0) {
              labels = setLabel(item?.stat);
            }
            const lineData = setNumberTypeData(item);

            const isItemHidden = () => {
              if (hiddenIdList && hiddenIdList?.includes(item.numberId)) {
                return true;
              }
              if (hiddenIndex === index) return isHide;
              return prevData?.datasets?.[index]?.hidden || false;
            };

            const computedData = {
              label: formatPhone(numberList[index].phone),
              data: lineData?.data || [],
              backgroundColor: numberBgColor,
              hoverBackgroundColor: numberBgColor,
              borderColor: itemColor,
              pointBackgroundColor: itemColor,
              pointBorderWidth: 0,
              tension: LINE_DIAGRAM_TENSION,
              hidden: isItemHidden(),
              numberId: item.numberId,
            };
            return {
              labels: labels || result.labels,
              datasets: [...result.datasets, computedData],
            };
          },
          {
            labels: [],
            datasets: [],
          }
        );
        formattedData.labels.unshift('');
        formattedData.labels.push('');
        return formattedData;
      }
      return {
        labels: [],
        datasets: [],
      };
    },
    [colorsList, selectedNumbersList, setLabel, setNumberTypeData]
  );

  const setDiagramData = useCallback(
    (options: {
      isDataUpdated?: boolean;
      hiddenIndex?: number;
      isHide?: boolean;
      data?: INumbersHistoryData[];
      prevData?: INumberDiagramData;
      hiddenIdList?: Array<number>;
    }) => {
      const {
        isDataUpdated = false,
        hiddenIndex,
        isHide = false,
        data,
        prevData,
        hiddenIdList,
      } = options;
      if (isDataUpdated && isObjectEqual(diagramDataState.prevRaw, data)) {
        return;
      }
      const newData = computeDiagramData(isHide, hiddenIndex, data, prevData, hiddenIdList);
      if (!isObjectEqual(diagramDataState.prev, newData)) {
        setDiagramDataState({ prevRaw: data || null, prev: newData, data: newData });
      }
    },
    [computeDiagramData, diagramDataState.prev, diagramDataState.prevRaw]
  );

  const disabledFilterShortList = useMemo(() => {
    if (sType === periodType.dayHour) {
      return [periodShort.day, periodShort.week, periodShort.month];
    }
    if (sType === periodType.year) {
      return [periodShort.day, periodShort.hour];
    }
    if (sType === periodType.month) {
      return [periodShort.month, periodShort.hour];
    }
    if (sType === periodType.week) {
      return [periodShort.week, periodShort.month, periodShort.hour];
    }
    return [periodShort.hour];
  }, [sType]);

  const [prevSelectedFilter, setPrevSelectedFilter] = useState<{
    from?: string | null;
    to?: string | null;
    period?: string | null;
    short?: string | null;
  } | null>(null);

  const [errorState, setErrorState] = useState({
    isOpen: false,
    title: 'SOMETHING_WENT_WRONG',
    message: 'SOMETHING_WRONG_MESSAGE',
  });

  const setSelectedValuesList = useCallback((numbersListData: IFilterChangeValues | null) => {
    const rawColorsList = ['danger', 'primary', 'links', 'success', 'warning'];
    setNumbersList((prevState) => {
      if (!numbersListData) return null;
      const prevNumbers = Object.keys(prevState || {});
      const newNumbers = Object.keys(numbersListData || {});
      const newNumbersList = { ...numbersListData };
      const prevMatchedNumbers = prevNumbers.reduce((res: IColoredNumbersValues, preNumberKey) => {
        if (newNumbers.includes(preNumberKey) && prevState !== null) {
          delete newNumbersList[Number(preNumberKey)];
          const userColor = prevState[Number(preNumberKey)].color || '';
          rawColorsList.splice(rawColorsList.indexOf(userColor), 1);
          return {
            ...res,
            [preNumberKey]: prevState[Number(preNumberKey)],
          };
        }
        return res;
      }, {});

      const newFilteredNumbers = Object.keys(newNumbersList || {});
      const newSelectedNumbers = newFilteredNumbers.reduce((res: IColoredNumbersValues, number) => {
        const newColor = rawColorsList[0];
        rawColorsList.splice(0, 1);
        return {
          ...res,
          [number]: {
            phone: numbersListData[Number(number)],
            color: newColor,
          },
        };
      }, {});
      return {
        ...prevMatchedNumbers,
        ...newSelectedNumbers,
      };
    });
  }, []);

  const defaultNumbersFilterValue = useMemo(() => {
    if (!selectedNumbersList) return null;
    return Object.keys(selectedNumbersList).reduce(
      (r: IFilterChangeValues, i) => ({
        ...r,
        [i]: selectedNumbersList[Number(i)].phone,
      }),
      {}
    );
  }, [selectedNumbersList]);

  return {
    selectedNumbersList,
    setSelectedValuesList,
    showDayPicker,
    setShowDayPicker,
    chartSwitch,
    setChartSwitch,
    selectedDayValue,
    setSelectedDayValue,
    hasSearchParams,
    setHasSearchParams,
    errorState,
    setErrorState,
    prevSelectedFilter,
    setPrevSelectedFilter,
    defaultNumbersFilterValue,
    disabledFilterShortList,
    theme,
    minDate,
    diagramData: diagramDataState.data,
    setDiagramData,
  };
}
