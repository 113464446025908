import { SizeUnits } from './interfaces';

export const sizeUnitsArray = [
  SizeUnits.Bytes,
  SizeUnits.Kilobytes,
  SizeUnits.Megabytes,
  SizeUnits.Gigabytes,
  SizeUnits.TeraBytes,
  SizeUnits.Petabytes,
  SizeUnits.Exabytes,
  SizeUnits.Zettabytes,
  SizeUnits.Yottabytes,
];

export const weekDays = [
  {
    titleCode: 'MONDAY',
    abbreviationCode: 'MON',
  },
  {
    titleCode: 'TUESDAY',
    abbreviationCode: 'TUE',
  },
  {
    titleCode: 'WEDNESDAY',
    abbreviationCode: 'WED',
  },
  {
    titleCode: 'THURSDAY',
    abbreviationCode: 'THU',
  },
  {
    titleCode: 'FRIDAY',
    abbreviationCode: 'FRI',
  },
  {
    titleCode: 'SATURDAY',
    abbreviationCode: 'SAT',
  },
  {
    titleCode: 'SUNDAY',
    abbreviationCode: 'SUN',
  },
];

export const weekDaysFromSunday = [
  {
    titleCode: 'SUNDAY',
    abbreviationCode: 'SUN',
  },
  {
    titleCode: 'MONDAY',
    abbreviationCode: 'MON',
  },
  {
    titleCode: 'TUESDAY',
    abbreviationCode: 'TUE',
  },
  {
    titleCode: 'WEDNESDAY',
    abbreviationCode: 'WED',
  },
  {
    titleCode: 'THURSDAY',
    abbreviationCode: 'THU',
  },
  {
    titleCode: 'FRIDAY',
    abbreviationCode: 'FRI',
  },
  {
    titleCode: 'SATURDAY',
    abbreviationCode: 'SAT',
  },
];

export const monthsNamesInUppercase = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export const VATPercent = 5;

// eslint-disable-next-line prefer-destructuring
const FILE_UPLOAD_MAX_SIZE = process.env.FILE_UPLOAD_MAX_SIZE;
const CommonConstants = {
  FileUploadMaxSize:
    FILE_UPLOAD_MAX_SIZE && Number.isFinite(Number(FILE_UPLOAD_MAX_SIZE))
      ? Number(FILE_UPLOAD_MAX_SIZE)
      : 1024 * 1024 * 20,
};

export default CommonConstants;
