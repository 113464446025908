import { BannerModel } from '@/client/generated/graphql';

export const sortBanners = (banners: BannerModel[]) =>
  banners.sort((a, b) => {
    if (!a.dateFrom && b.dateFrom) {
      return -1;
    }
    if (a.dateFrom && !b.dateFrom) {
      return 1;
    }
    if (a.dateFrom && b.dateFrom && a.dateFrom < b.dateFrom) {
      return -1;
    }
    if (a.dateFrom && b.dateFrom && a.dateFrom > b.dateFrom) {
      return 1;
    }
    if (!a.dateTo && b.dateTo) {
      return 1;
    }
    if (a.dateTo && !b.dateTo) {
      return -1;
    }
    if (a.dateTo && b.dateTo && a.dateTo < b.dateTo) {
      return -1;
    }
    if (a.dateTo && b.dateTo && a.dateTo > b.dateTo) {
      return 1;
    }
    return 0;
  });
