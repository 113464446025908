import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { format } from 'date-fns';
import { ICellProps } from '@components/LazyTable';
import { NumberStatus } from '@/client/generated/graphql';
import { DomainNumber } from '../PhoneNumbers.interfaces';

type TextColorCellVariants = 'inherit' | 'warning600' | 'danger600';

export const NumberStatusCell = ({ item }: ICellProps<DomainNumber>) => {
  const [translate] = useTranslation();

  const { numberStatus } = item;

  function getStatusObject(): {
    translate: string;
    date?: string;
    textColor: TextColorCellVariants;
  } {
    switch (numberStatus) {
      case NumberStatus.Reserved:
        /*
        Для Забронирован - должно приходить поле statusTill с датой, до которой номер будет находиться в этом статусе
         */
        return {
          translate: 'NUMBER_STATUS_Reserved',
          date: item?.statusTill ? format(new Date(item.statusTill), 'dd.MM.yyyy') : '',
          textColor: 'warning600',
        };
      case NumberStatus.WillBeDeleted:
        return {
          translate: 'NUMBER_STATUS_WillBeDeleted',
          date: item?.statusTill ? format(new Date(item.statusTill), 'dd.MM.yyyy') : '',
          textColor: 'danger600',
        };
      case NumberStatus.Bought:
        if (item.statusTill) {
          return {
            translate: 'NUMBER_STATUS_WillBeDeleted',
            date: item?.statusTill ? format(new Date(item.statusTill), 'dd.MM.yyyy') : '',
            textColor: 'danger600',
          };
        }

        return {
          translate: 'NUMBER_STATUS_Bought',
          date: item?.purchaseDate ? format(new Date(item.purchaseDate), 'dd.MM.yyyy') : '',
          textColor: 'inherit',
        };
      default:
        return {
          translate: `NUMBER_STATUS_${numberStatus}`,
          date: '',
          textColor: 'danger600',
        };
    }
  }

  const currentCellData = getStatusObject();
  return (
    <Typography type={'text3'} color={currentCellData.textColor}>
      {translate(currentCellData.translate)}
      {!!currentCellData.date && (
        <>
          <br />
          {translate(currentCellData.date)}
        </>
      )}
    </Typography>
  );
};
