import Drawer from '@components/Drawer';
import React, { useEffect, useMemo } from 'react';
import SwitchField from '@shared/components/SwitchField';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import SelectField from '@shared/components/SelectField';
import { useLazyQuery } from '@apollo/client';
import { GET_WORK_CALENDAR } from '@/client/queries';
import PreloaderPanel from '@shared/components/PreloaderPanel';
import PageError from '@shared/components/PageError';
import { FormProvider, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import WorkCalendarEventsGroup from '@/components/WorkCalendarEventsGroup';
import { WorkCalendarCategory } from '@/client/generated/graphql';
import { WorkCalendarSettingsDrawerProps } from './WorkCalendarSettingsDrawer.types';
import { useWorkCalendarSettingsDrawerStyles } from './WorkCalendarSettingsDrawer.styles';
import {
  getCorrectEventsShape,
  getWeekDaysData,
  getWeekDaysDataWithNonWorkingDays,
} from './WorkCalendarSettingsDrawer.utils';
import { IncomingNumberPageFormFields } from '../IncomingNumber.interfaces';

const WorkCalendarSettingsDrawer = ({
  isOpen,
  onClose,
  handleFormChange,
}: WorkCalendarSettingsDrawerProps) => {
  const classes = useWorkCalendarSettingsDrawerStyles();
  const [translate] = useTranslation();

  const formMethods = useFormContext<IncomingNumberPageFormFields>();

  const isWorkCalendarSettingEnabledInDrawer = formMethods.watch(
    'isWorkCalendarSettingEnabledInDrawer'
  );

  const [
    getWorkCalendar,
    {
      data,
      loading: getWorkCalendarQueryLoading,
      error: getWorkCalendarQueryError,
      called: getWorkCalendarQueryCalled,
    },
  ] = useLazyQuery(GET_WORK_CALENDAR);

  useEffect(() => {
    if (!getWorkCalendarQueryCalled && isOpen) {
      getWorkCalendar();
    }
  }, [getWorkCalendarQueryCalled, getWorkCalendar, isOpen]);

  const { customDaysEvents, holidayDaysEvents, rescheduledDaysEvents } = useMemo(
    () => getCorrectEventsShape(data?.getWorkCalendar || []),
    [data?.getWorkCalendar]
  );

  const weekDaysDataWithNonWorkingDays = useMemo(
    () => getWeekDaysDataWithNonWorkingDays(translate),
    [translate]
  );
  const weekDaysData = useMemo(() => getWeekDaysData(translate), [translate]);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      formMethods.setValue(
        'isWorkCalendarSettingEnabledInDrawer',
        formMethods.getValues().isWorkCalendarSettingEnabled
      );
      formMethods.setValue(
        'workCalendarSettingHolidayInDrawer',
        formMethods.getValues().workCalendarSettingHoliday
      );
      formMethods.setValue(
        'workCalendarSettingWorkingDayInDrawer',
        formMethods.getValues().workCalendarSettingWorkingDay
      );
    }, 250);
  };

  const handleSubmit = async (formFields: IncomingNumberPageFormFields) => {
    formMethods.setValue(
      'isWorkCalendarSettingEnabled',
      formFields.isWorkCalendarSettingEnabledInDrawer
    );
    formMethods.setValue(
      'workCalendarSettingHoliday',
      formFields.workCalendarSettingHolidayInDrawer
    );
    formMethods.setValue(
      'workCalendarSettingWorkingDay',
      formFields.workCalendarSettingWorkingDayInDrawer
    );
    handleClose();
  };

  const renderDrawerContent = () => {
    if (getWorkCalendarQueryLoading) {
      return <PreloaderPanel />;
    }

    if (getWorkCalendarQueryError) {
      return <PageError />;
    }

    return (
      <FormProvider {...formMethods}>
        <SwitchField
          name="isWorkCalendarSettingEnabledInDrawer"
          customLabel={
            <Typography pxToEmSize={18}>{translate('CONFIGURE_WORK_CALENDAR')}</Typography>
          }
          onChanges={handleFormChange}
        />

        <div className={classes.marginBottom1} />

        <div className={clsx(!isWorkCalendarSettingEnabledInDrawer && classes.disabledContent)}>
          <div className={classes.marginBottom05}>
            <Typography medium>{translate('WEEKENDS')}</Typography>
          </div>
          <div className={classes.marginBottom1}>
            <Typography color="tertiary600">
              {translate('SELECT_DAY_CALL_ROUTE_FOR_WEEKEND_EXCEPTIONS')}
            </Typography>
          </div>
          <SelectField
            className={classes.marginBottom2}
            name="workCalendarSettingHolidayInDrawer"
            fullWidth
            data={weekDaysDataWithNonWorkingDays}
            titleKey="titleCode"
            valueKey="value"
            onChange={handleFormChange}
          />

          <div className={classes.marginBottom05}>
            <Typography medium>{translate('WORKING_DAYS')}</Typography>
          </div>
          <div className={classes.marginBottom1}>
            <Typography color="tertiary600">
              {translate('SELECT_DAY_CALL_ROUTE_FOR_WORKING_EXCEPTIONS')}
            </Typography>
          </div>
          <SelectField
            className={classes.marginBottom2}
            name="workCalendarSettingWorkingDayInDrawer"
            fullWidth
            data={weekDaysData}
            titleKey="titleCode"
            valueKey="value"
            onChange={handleFormChange}
          />

          <div>
            <Typography pxToEmSize={18}>{translate('EVENTS')}</Typography>
          </div>
          <WorkCalendarEventsGroup
            category={WorkCalendarCategory.Custom}
            events={customDaysEvents}
          />
          <WorkCalendarEventsGroup
            category={WorkCalendarCategory.Holiday}
            events={holidayDaysEvents}
          />
          <WorkCalendarEventsGroup
            category={WorkCalendarCategory.Rescheduled}
            events={rescheduledDaysEvents}
          />
        </div>
      </FormProvider>
    );
  };

  return (
    <Drawer
      title="WORK_CALENDAR"
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
      primaryButton={{
        title: 'APPLY',
        props: { onClick: formMethods.handleSubmit(handleSubmit) },
      }}
      secondaryButton={{
        title: 'CANCEL',
        props: {
          onClick: handleClose,
        },
      }}
    >
      {renderDrawerContent()}
    </Drawer>
  );
};

export default WorkCalendarSettingsDrawer;
