import { IDomainNumber } from '@components/typings/interfaces/number';
import { deserializeEmployee, IEmployeeRaw } from './employee';
import { deserializeDepartment, IDepartmentRaw } from './department';

export enum OutgoingCallEntity {
  Employee = 'employee',
  Department = 'department',
}

export interface IOutgoingCall {
  id: number;
  name: string;
  phone: string;
  city: string;
  numberId: number;
  employeeId?: number;
  departmentId?: number;
  entity?: OutgoingCallEntity;
  employeesCount?: number;
  role?: string;
  employee?: IEmployeeRaw;
  department?: IDepartmentRaw;
  number?: IDomainNumber;
}

export interface IOutgoingCallRaw {
  id: number;
  domainId: number;
  departmentId?: number;
  employeeId?: number;
  employee?: IEmployeeRaw;
  department?: IDepartmentRaw;
  numberId: number;
  number: IDomainNumber;
}

export const deserializeOutgoingCall = (data: IOutgoingCallRaw): IOutgoingCall => {
  const { id, numberId, employee, department, employeeId, departmentId, number } = data || {};
  const { employeesCount = 0, name: deptName = '' } = department
    ? deserializeDepartment(department)
    : {};
  const { role = 'User', name: empName = '' } = employee ? deserializeEmployee(employee) : {};
  const { phone, cityName: city = '' } = number || {};

  return {
    id,
    phone,
    city,
    numberId,
    // eslint-disable-next-line no-nested-ternary
    name: employee ? empName : department ? deptName : '',
    // eslint-disable-next-line no-nested-ternary
    entity: employee
      ? OutgoingCallEntity.Employee
      : department
      ? OutgoingCallEntity.Department
      : undefined,
    employeeId,
    departmentId,
    employeesCount,
    role,
  };
};
