import React, { useMemo } from 'react';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_ACTIVE_BANNERS, USER_QUERY } from '@/client/queries';
import { getCurrentClient, getCurrentDomain, getRole } from '@/utils';
import { AUTHENTICATION_STATE_QUERY } from '@components/client/queries';
import BodyContainer from '@/layouts/BodyContainer';
import WelcomeDialog from '@/components/WelcomePage/WelcomeDialog';
import { DomainStatus, Role } from '@/client/generated/graphql';
import { useNavigate } from 'react-router-dom';
import { useDesktopPageStyle } from './DesktopPage.styles';
import {
  CallStatisticsSectionWidget,
  BalanceWidget,
  TelephonyWidget,
  CallHourlyStatisticsSectionWidget,
} from './modules';
import ContractStatusInfoBlock from './modules/ContractStatusInfoBlock';
import { sortBanners } from './DesktopPage.utils';

export const DesktopPage = () => {
  const classes = useDesktopPageStyle();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { data: dataAuthStateQuery } = useQuery<{ isAuthenticated?: boolean; role?: string }>(
    AUTHENTICATION_STATE_QUERY,
    { fetchPolicy: 'cache-only' }
  );
  const { data: bannersData } = useQuery(GET_ACTIVE_BANNERS);
  const { account = '' } = getCurrentClient(userData?.user) || {};
  const currentDomain = useMemo(() => getCurrentDomain(userData?.user), [userData?.user]);
  const status = currentDomain?.status;
  const role = getRole(dataAuthStateQuery?.role);
  const isPasswordRequired = userData?.user.isPasswordRequired;

  const handleNavigate = (url: string) => () => {
    const isExternal = /^http/i.test(url);
    if (isExternal) {
      window.open(url, '_blank');
    } else {
      navigate(url);
    }
  };

  const renderNotificationBanner = () => {
    if (status !== DomainStatus.Commercial) {
      return null;
    }
    const activeBanners = bannersData?.getActiveBanners;
    const currentBanner = activeBanners ? sortBanners(activeBanners)[0] : null;

    if (currentBanner) {
      const { background, header, description, button1, button1Link, button2, button2Link, image } =
        currentBanner;

      const renderImage = () => {
        if (image) {
          const blob = new Blob([image], { type: 'image/svg+xml' });
          const src = URL.createObjectURL(blob);

          return src ? (
            <div className={classes.notificationBannerRightBlock}>
              <img src={src} alt={header} />
            </div>
          ) : null;
        }
        return null;
      };

      return (
        <Flex
          className={classes.notificationBanner}
          style={{ backgroundColor: background || '#007474' }}
          alignItems={'center'}
        >
          <Flex direction="column" className={classes.notificationBannerLeftBlock}>
            <Typography type={'text2'} color={'base'}>
              {header}
            </Typography>
            <Typography type={'text3'} color={'base'}>
              {description}
            </Typography>
            {button1 && button1Link && (
              <Flex className={classes.notificationBannerButtonsRow}>
                <Button variant={'secondary'} onClick={handleNavigate(button1Link)} smallHeight>
                  {button1}
                </Button>
                {button2 && button2Link && (
                  <Button onClick={handleNavigate(button2Link)} smallHeight>
                    {button2}
                  </Button>
                )}
              </Flex>
            )}
          </Flex>
          {renderImage()}
          <div className={classes.notificationBannerCloseButton} />
        </Flex>
      );
    }
    return null;
  };

  const renderDesktopContent = () => (
    <Flex direction={'column'} className={classes.root}>
      <Flex className={classes.body}>
        <div className={classes.desktopDataRoot}>
          <div>
            {renderNotificationBanner()}
            <Flex className={classes.desktopPanelsCurrentPBS}>
              <Typography type={'text2'} color={'tertiary900'} bold>
                {`${translate('PBX_ACCOUNT')}:`}
              </Typography>
              <Typography
                className={classes.desktopPanelsCurrentPBSName}
                type={'text2'}
                color={'tertiary700'}
              >
                {account}
              </Typography>
            </Flex>
            <ContractStatusInfoBlock />
            <Flex justifyContent={'spaceBetween'} className={classes.desktopPanelsWrapper}>
              <BalanceWidget />
              <TelephonyWidget />
              <CallStatisticsSectionWidget />
              <CallHourlyStatisticsSectionWidget />
            </Flex>
          </div>
        </div>
      </Flex>
    </Flex>
  );

  return (
    <BodyContainer>
      {renderDesktopContent()}
      {role === Role.Owner && isPasswordRequired && <WelcomeDialog />}
    </BodyContainer>
  );
};

export default DesktopPage;
