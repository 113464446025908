import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMatch, useNavigate } from 'react-router-dom';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { UPDATE_END_USER } from '@/client/mutations';
import resultify from '@shared/utils/resultify';
import { useMutation, useQuery } from '@apollo/client';
import { dateOnlyStringToDate, dateToDateOnlyString } from '@shared/utils/dates';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import BodyContainer from '@/layouts/BodyContainer';
import BottomButtons from '@/layouts/BottomButtons';
import ControlButtons from '@shared/components/ControlButtons';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import MessageDialog from '@shared/components/MessageDialog';
import { GET_ALL_END_USERS_QUERY } from '@/client/queries';
import PagePreloader from '@components/PagePreloader';
import { EndUserDocumentType } from '@/client/generated/graphql';
import ResponsiblePersonDataForm, {
  ResponsiblePersonDataFormFields,
  getDefaultResponsiblePersonDataFormValues,
} from '../modules/ResponsiblePersonDataForm';
import ResponsibleAlreadyExistsDialog from '../modules/ResponsibleAlreadyExistsDialog';

type FieldsToSend = {
  [K in keyof ResponsiblePersonDataFormFields]:
    | ResponsiblePersonDataFormFields[K]
    | null
    | undefined;
};

const ModifyResponsiblePerson = () => {
  const navigate = useNavigate();
  const [translate] = useTranslation();

  const matchData = useMatch('/:pathPart1/:pathPart2/:id');
  const responsiblePersonId = Number(matchData?.params.id);

  const [isResponsibleAlreadyExistsDialogOpen, setIsResponsibleAlreadyExistsDialogOpen] =
    useState(false);

  const formMethods = useForm<ResponsiblePersonDataFormFields>({
    defaultValues: getDefaultResponsiblePersonDataFormValues(),
  });

  const [isClarifyingDialogOpen, setIsClarifyingDialogOpen] = useState(false);
  const [confirmState, setConfirmState] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });
  const [blockedPath, setBlockedPath] = useState<string | null>(null);

  const { data: endUsersQueryData, loading: responsiblePersonsQueryDataLoading } = useQuery(
    GET_ALL_END_USERS_QUERY,
    {
      fetchPolicy: 'cache-first',
      onCompleted(data) {
        if (Number.isNaN(responsiblePersonId)) {
          navigate('/numbers/responsible-persons');
          return;
        }

        const responsiblePersonData = data.getAllEndUsers.find(
          (responsibleData) => responsibleData.id === responsiblePersonId
        );
        if (!responsiblePersonData) {
          navigate('/numbers/responsible-persons');
          return;
        }

        formMethods.reset({
          block: responsiblePersonData.block || '',
          city: responsiblePersonData.city,
          country: responsiblePersonData.country,
          dateOfBirth: dateOnlyStringToDate(responsiblePersonData.dateOfBirth),
          dateOfIssue: responsiblePersonData.dateOfIssue
            ? dateOnlyStringToDate(responsiblePersonData.dateOfIssue)
            : null,
          departmentCode: responsiblePersonData.departmentCode || '',
          district: responsiblePersonData.district || '',
          documentType: EndUserDocumentType.InternalRfPassport,
          flat: responsiblePersonData.flat || '',
          house: responsiblePersonData.house || '',
          name: responsiblePersonData.name,
          number: responsiblePersonData.number,
          patronymic: responsiblePersonData.patronymic || '',
          placeOfIssue: responsiblePersonData.placeOfIssue || '',
          postalCode: responsiblePersonData.postalCode || '',
          region: responsiblePersonData.region || '',
          series: responsiblePersonData.series || '',
          street: responsiblePersonData.street || '',
          surname: responsiblePersonData.surname,
        });
      },
      onError() {
        navigate('/numbers/responsible-persons');
        globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      },
    }
  );

  const [updateEndUser, { loading: updateEndUserLoading }] = useMutation(UPDATE_END_USER);

  useEffect(() => {
    if (confirmState.action === ConfirmAction.Finish) {
      navigate(blockedPath || '/numbers/responsible-persons');
    }
  }, [blockedPath, confirmState.action, navigate]);

  const handleFormChange = () => {
    setConfirmState({ action: ConfirmAction.Edit, isBlocking: true });
  };

  const checkResponsibleDuplicate = () => {
    const responsibleWithTheSamePassportData = endUsersQueryData?.getAllEndUsers.find(
      (endUserData) => {
        const notCurrentUser =
          typeof responsiblePersonId !== 'number' || responsiblePersonId !== endUserData.id;
        return (
          endUserData.documentType === formMethods.getValues().documentType &&
          endUserData.series === formMethods.getValues().series &&
          endUserData.number === formMethods.getValues().number &&
          notCurrentUser
        );
      }
    );

    if (responsibleWithTheSamePassportData) {
      setIsResponsibleAlreadyExistsDialogOpen(true);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (checkResponsibleDuplicate()) {
      setIsClarifyingDialogOpen(true);
    }
  };

  const runMutation = async () => {
    const formFields: FieldsToSend = formMethods.getValues();
    const dateOfBirth = dateToDateOnlyString(formFields.dateOfBirth)!;
    const dateOfIssue = formFields.dateOfIssue
      ? dateToDateOnlyString(formFields.dateOfIssue)!
      : undefined;

    Object.keys(formFields).forEach((key) => {
      if (formFields[key as keyof typeof formFields] === '') {
        formFields[key as keyof typeof formFields] = null;
      }
    });

    const result = await resultify(
      updateEndUser({
        variables: {
          input: {
            ...formFields,
            id: responsiblePersonId,
            dateOfBirth,
            dateOfIssue,
          },
        },
      })
    );

    if (result.type === 'error') {
      globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      setIsClarifyingDialogOpen(false);
      return;
    }

    setConfirmState({ isBlocking: false, action: ConfirmAction.Finish });
  };

  const goBack = () => {
    navigate(-1);
  };

  const renderClarifyingDialogContent = () => {
    return <Typography>{translate('NEW_NUMBERS_REGISTRATION_INFO')}</Typography>;
  };

  const handleClarifyingDialogClose = () => {
    if (updateEndUserLoading) {
      return;
    }
    setIsClarifyingDialogOpen(false);
  };

  if (responsiblePersonsQueryDataLoading) {
    return <PagePreloader />;
  }

  return (
    <BodyContainer>
      <FormProvider {...formMethods}>
        <ResponsiblePersonDataForm
          handleSubmit={handleSubmit}
          handleFormChange={handleFormChange}
        />
        <BottomButtons>
          <ControlButtons
            confirmTitle="SAVE"
            cancelTitle="CANCEL"
            onConfirmClick={formMethods.handleSubmit(handleSubmit)}
            onCancelClick={goBack}
            cancelVariant="secondary"
            justifyContent="start"
          />
        </BottomButtons>
      </FormProvider>
      <ConfirmDialog
        isBlocked={confirmState.isBlocking}
        onNavigationBlocked={setBlockedPath}
        onSaveChanges={formMethods.handleSubmit(handleSubmit)}
      />
      <MessageDialog
        isOpen={isClarifyingDialogOpen}
        title={translate('ARE_YOU_SURE')}
        renderContent={renderClarifyingDialogContent()}
        onCancel={handleClarifyingDialogClose}
        buttonSuccessProps={{
          titleCode: 'CONFIRM',
          onClick: runMutation,
          loading: updateEndUserLoading,
        }}
        buttonCancelProps={{
          titleCode: 'CANCEL',
          onClick: handleClarifyingDialogClose,
          disabled: updateEndUserLoading,
        }}
      />
      <ResponsibleAlreadyExistsDialog
        isOpen={isResponsibleAlreadyExistsDialogOpen}
        onClose={() => setIsResponsibleAlreadyExistsDialogOpen(false)}
      />
    </BodyContainer>
  );
};

export default ModifyResponsiblePerson;
