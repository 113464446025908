import { makeStyles } from '@material-ui/core';

export const useWorkCalendarSettingsDrawerStyles = makeStyles(() => ({
  switchField: {
    display: 'flex',
    marginBottom: '1em',
  },
  disabledContent: {
    opacity: 0.5,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  marginBottom05: {
    marginBottom: '.5em',
  },
  marginBottom075: {
    marginBottom: '.75em',
  },
  marginBottom1: {
    marginBottom: '1em',
  },
  marginBottom2: {
    marginBottom: '2em',
  },
}));
