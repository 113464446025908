import React from 'react';
import { ArrowBackIcon } from '@shared/assets/images/icons';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import { useNotificationsStyles } from '../SettingsNotifications.styles';
import { TableDataObject } from '../SettingsNotifications.interface';

export const NotificationsArrowCell = ({ item }: ICellProps<TableDataObject>) => {
  const { receivers } = item || {};
  const classes = useNotificationsStyles();

  const arrowRender = () => {
    if (receivers) {
      if (receivers.length === 0) {
        return <ArrowBackIcon className={classes.arrow} />;
      }
      return receivers.map((rec) => (
        <div key={rec.id} className={classes.arrowsContainer}>
          <ArrowBackIcon className={classes.arrow} />
        </div>
      ));
    }
    return null;
  };

  return <Flex direction={'column'}>{arrowRender()}</Flex>;
};
