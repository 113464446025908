import { EndUserDocumentType } from '@/client/generated/graphql';
import { makeStyles } from '@material-ui/core';
import DateField from '@shared/components/DateField';
import FormFieldRhfControlled from '@shared/components/FormFieldRhfControlled';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import NumberField from '@shared/components/NumberField';
import SelectField from '@shared/components/SelectField';
import Typography from '@shared/components/Typography';
import { pxToEm } from '@shared/utils/styles';
import clsx from 'clsx';
import React, { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const responsiblePersonDataFormName = 'responsible-person-data-form';

type Props = {
  handleSubmit: (fields: ResponsiblePersonDataFormFields) => void;
  handleFormChange: () => void;
};

export type ResponsiblePersonDataFormFields = {
  name: string;
  surname: string;
  patronymic?: string;
  dateOfBirth: Date | null;
  documentType: EndUserDocumentType;
  series?: string;
  number: string;
  placeOfIssue: string;
  dateOfIssue: Date | null;
  departmentCode?: string;

  country: string;
  region?: string;
  city: string;
  district?: string;
  street?: string;
  postalCode?: string;
  house?: string;
  block?: string;
  flat?: string;
};

export const getDefaultResponsiblePersonDataFormValues = (): ResponsiblePersonDataFormFields => ({
  name: '',
  surname: '',
  patronymic: '',
  dateOfBirth: null,
  documentType: EndUserDocumentType.InternalRfPassport,
  series: '',
  number: '',
  placeOfIssue: '',
  dateOfIssue: null,
  departmentCode: '',
  country: 'Россия',
  region: '',
  city: '',
  district: '',
  street: '',
  postalCode: '',
  house: '',
  block: '',
  flat: '',
});

const documentsTypes = [
  { title: 'RF_CITIZEN_PASSPORT', value: EndUserDocumentType.InternalRfPassport },
  { title: 'RF_FOREIGN_PASSPORT', value: EndUserDocumentType.ForeignRfPassport },
  { title: 'FOREIGNERS_PASSPORT', value: EndUserDocumentType.NotRfPassport },
  { title: 'RESIDENCE_PERMIT', value: EndUserDocumentType.ResidencePermit },
];

const useStyles = makeStyles(({ color: { tertiary } }) => ({
  columnsWrapper: {
    display: 'flex',
    marginBottom: '7em',
  },
  column: {
    width: pxToEm(464),
    marginLeft: '2em',
  },
  columnFieldsWrapper: {
    padding: '1.5em',
    border: `1px solid ${tertiary[300]}`,
    borderRadius: '12px',
  },
  columnTitleWrapper: {
    marginBottom: '.75em',
  },
  marginLeft2: {
    marginLeft: '2em',
  },
  marginTop15: {
    marginTop: '1.5em',
  },
  fullWidth: {
    width: '100%',
  },
  fieldsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  twoFieldsRowField: {
    flex: '0 0 calc(50% - 1em)',
  },
  threeFieldsRowField: {
    flex: '0 0 calc(33% - 1em)',
  },
}));

/** This component must be wrapped in FormProvider */
const ResponsiblePersonDataForm = ({ handleSubmit, handleFormChange }: Props) => {
  const classes = useStyles();
  const formMethods = useFormContext<ResponsiblePersonDataFormFields>();
  const documentType = formMethods.watch('documentType');

  const [translate] = useTranslation();

  const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 6) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.slice(0, 6);
    }
    handleFormChange();
  };

  const handleSeriesChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      documentType === EndUserDocumentType.InternalRfPassport ||
      documentType === EndUserDocumentType.ForeignRfPassport ||
      documentType === EndUserDocumentType.ResidencePermit
    ) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/\D/g, '');

      if (
        documentType === EndUserDocumentType.InternalRfPassport &&
        event.target.value.length > 4
      ) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = event.target.value.slice(0, 4);
      }
      if (
        (documentType === EndUserDocumentType.ForeignRfPassport ||
          documentType === EndUserDocumentType.ResidencePermit) &&
        event.target.value.length > 2
      ) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = event.target.value.slice(0, 2);
      }
    } else if (event.target.value.length > 10) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.slice(0, 10);
    }
    handleFormChange();
  };

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      documentType === EndUserDocumentType.InternalRfPassport ||
      documentType === EndUserDocumentType.ForeignRfPassport ||
      documentType === EndUserDocumentType.ResidencePermit
    ) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/\D/g, '');
      if (
        documentType === EndUserDocumentType.InternalRfPassport &&
        event.target.value.length > 6
      ) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = event.target.value.slice(0, 6);
      } else if (
        (documentType === EndUserDocumentType.ForeignRfPassport ||
          documentType === EndUserDocumentType.ResidencePermit) &&
        event.target.value.length > 7
      ) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = event.target.value.slice(0, 7);
      }
    }

    if (event.target.value.length > 10) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.slice(0, 10);
    }
    handleFormChange();
  };

  const handleDocumentTypeChange = () => {
    formMethods.setValue('series', '');
    formMethods.setValue('number', '');
    formMethods.clearErrors('series');
    formMethods.clearErrors('number');

    handleFormChange();
  };

  return (
    <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
      <div className={classes.columnsWrapper}>
        <div className={classes.column}>
          <div className={classes.columnTitleWrapper}>
            <Typography pxToEmSize={18}>{translate('PASSPORT_DATA')}</Typography>
          </div>

          <div className={classes.columnFieldsWrapper}>
            <FormFieldRhfUncontrolled
              className={classes.fullWidth}
              name="surname"
              label={translate('SURNAME')}
              onChange={handleFormChange}
              validate={(value) => {
                if (!value) {
                  return translate('EMPTY_FIELD_SURNAME');
                }
                return true;
              }}
            />
            <FormFieldRhfUncontrolled
              className={clsx(classes.marginTop15, classes.fullWidth)}
              name="name"
              label={translate('NAME')}
              onChange={handleFormChange}
              validate={(value) => {
                if (!value) {
                  return translate('EMPTY_FIELD_NAME');
                }
                return true;
              }}
            />
            <FormFieldRhfUncontrolled
              className={clsx(classes.marginTop15, classes.fullWidth)}
              name="patronymic"
              label={translate('PATRONYMIC')}
              onChange={handleFormChange}
              validate={(value) => {
                if (
                  formMethods.getValues().documentType !== EndUserDocumentType.InternalRfPassport
                ) {
                  return true;
                }
                if (!value) {
                  return translate('EMPTY_FIELD_PATRONYMIC');
                }
                return true;
              }}
            />
            <DateField
              className={clsx(classes.marginTop15, classes.fullWidth)}
              name="dateOfBirth"
              label={translate('DATE_OF_BIRTH')}
              disableFuture
              validate={(value) => {
                if (!value) {
                  return translate('EMPTY_FIELD_DATE_OF_BIRTH');
                }
                return true;
              }}
            />
            <SelectField
              className={classes.marginTop15}
              fullWidth
              translating
              label={translate('DOCUMENT_TYPE')}
              data={documentsTypes}
              name="documentType"
              valueKey="value"
              titleKey="title"
              onChange={handleDocumentTypeChange}
            />
            <div className={clsx(classes.marginTop15, classes.fieldsRow)}>
              <FormFieldRhfControlled
                className={classes.twoFieldsRowField}
                name={'series'}
                label={translate('SERIES')}
                onChange={handleSeriesChange}
                validate={(value) => {
                  if (
                    formMethods.getValues().documentType !==
                      EndUserDocumentType.InternalRfPassport &&
                    formMethods.getValues().documentType !==
                      EndUserDocumentType.ForeignRfPassport &&
                    formMethods.getValues().documentType !== EndUserDocumentType.ResidencePermit
                  ) {
                    return true;
                  }

                  if (!value) {
                    return translate('EMPTY_FIELD_SERIES');
                  }

                  if (
                    formMethods.getValues().documentType ===
                      EndUserDocumentType.InternalRfPassport &&
                    value.length !== 4
                  ) {
                    return translate('ENTER_N_DIGITS', { n: 4 });
                  }
                  if (
                    (formMethods.getValues().documentType ===
                      EndUserDocumentType.ForeignRfPassport ||
                      formMethods.getValues().documentType ===
                        EndUserDocumentType.ResidencePermit) &&
                    value.length !== 2
                  ) {
                    return translate('ENTER_N_DIGITS', { n: 2 });
                  }
                  return true;
                }}
              />
              <FormFieldRhfControlled
                className={classes.twoFieldsRowField}
                name={'number'}
                label={translate('DOCUMENT_NUMBER')}
                onChange={handleNumberChange}
                validate={(value) => {
                  if (!value) {
                    return translate('EMPTY_FIELD_PASSPORT_NUMBER');
                  }
                  if (
                    formMethods.getValues().documentType !==
                      EndUserDocumentType.InternalRfPassport &&
                    formMethods.getValues().documentType !==
                      EndUserDocumentType.ForeignRfPassport &&
                    formMethods.getValues().documentType !== EndUserDocumentType.ResidencePermit
                  ) {
                    return true;
                  }
                  if (
                    formMethods.getValues().documentType ===
                      EndUserDocumentType.InternalRfPassport &&
                    value.length !== 6
                  ) {
                    return translate('ENTER_N_DIGITS', { n: 6 });
                  }
                  if (
                    (formMethods.getValues().documentType ===
                      EndUserDocumentType.ForeignRfPassport ||
                      formMethods.getValues().documentType ===
                        EndUserDocumentType.ResidencePermit) &&
                    value.length !== 7
                  ) {
                    return translate('ENTER_N_DIGITS', { n: 7 });
                  }

                  return true;
                }}
              />
            </div>
            <FormFieldRhfUncontrolled
              className={clsx(classes.marginTop15, classes.fullWidth)}
              name="placeOfIssue"
              label={translate('WHO_GIVEN')}
              onChange={handleFormChange}
              rows={3}
              validate={(value) => {
                if (!value) {
                  return translate('EMPTY_FIELD_WHOGIVEN');
                }
                return true;
              }}
            />
            <div className={clsx(classes.marginTop15, classes.fieldsRow)}>
              <DateField
                className={classes.twoFieldsRowField}
                name="dateOfIssue"
                label={translate('GIVEN_DATE')}
                disableFuture
                validate={(value) => {
                  if (!value) {
                    return translate('EMPTY_FIELD_DATE_OF_ISSUE');
                  }
                  return true;
                }}
              />
              <FormFieldRhfUncontrolled
                className={classes.twoFieldsRowField}
                name="departmentCode"
                label={translate('CODE')}
                onChange={handleFormChange}
                validate={(value) => {
                  if (
                    formMethods.getValues().documentType !== EndUserDocumentType.InternalRfPassport
                  ) {
                    return true;
                  }
                  if (!value) {
                    return translate('EMPTY_FIELD_CODE');
                  }
                  return true;
                }}
              />
            </div>
          </div>
        </div>

        <div className={classes.column}>
          <div className={classes.columnTitleWrapper}>
            <Typography pxToEmSize={18}>{translate('REGISTRATION_ADDRESS')}</Typography>
          </div>
          <div className={classes.columnFieldsWrapper}>
            <FormFieldRhfUncontrolled
              className={classes.fullWidth}
              name="country"
              label={translate('COUNTRY')}
              onChange={handleFormChange}
            />
            <FormFieldRhfUncontrolled
              className={clsx(classes.marginTop15, classes.fullWidth)}
              name="region"
              label={translate('REGION')}
              onChange={handleFormChange}
            />
            <FormFieldRhfUncontrolled
              className={clsx(classes.marginTop15, classes.fullWidth)}
              name="city"
              label={translate('SETTLEMENT')}
              onChange={handleFormChange}
              validate={(value) => {
                if (!value) {
                  return translate('ENTER_FIELD_SETTLEMENT');
                }
                return true;
              }}
            />
            <FormFieldRhfUncontrolled
              className={clsx(classes.marginTop15, classes.fullWidth)}
              name="district"
              label={translate('DISTRICT')}
              onChange={handleFormChange}
            />

            <FormFieldRhfUncontrolled
              className={clsx(classes.marginTop15, classes.fullWidth)}
              name="street"
              label={translate('STREET')}
              onChange={handleFormChange}
            />
            <div className={clsx(classes.marginTop15, classes.fieldsRow)}>
              <FormFieldRhfUncontrolled
                className={classes.threeFieldsRowField}
                name="house"
                label={translate('HOUSE')}
                onChange={handleFormChange}
              />
              <FormFieldRhfUncontrolled
                className={classes.threeFieldsRowField}
                name="block"
                label={translate('HOUSE_BUILDING_NUMBER')}
                onChange={handleFormChange}
              />
              <FormFieldRhfUncontrolled
                className={classes.threeFieldsRowField}
                name="flat"
                label={translate('APARTMENT')}
                onChange={handleFormChange}
              />
            </div>
            <NumberField
              className={classes.marginTop15}
              name="postalCode"
              fullWidth
              label={translate('INDEX')}
              onChange={handlePostalCodeChange}
              validate={(value) => {
                if (!value) {
                  return true;
                }
                if (String(value).length !== 6) {
                  return translate('CONFIRMATION_CODE_LENGTH');
                }
                return true;
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResponsiblePersonDataForm;
