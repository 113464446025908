import { observable } from '@legendapp/state';

const layoutsContext = observable({
  // This field is used as workaround to be able to change
  // height of dashboard header dynamically. It is hard to do
  // another way because a few components composition approach for layout building was used in project initially.
  // Make sure this variable changes correctly to prevent unexpected display shifts.
  dashboardHeaderHeight: '5em',
});

export default layoutsContext;
