import MaskedField from '@shared/components/MaskedField';
import clsx from 'clsx';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { normalizePhone } from '@shared/utils';
import { COUNTRIES_MASKS, DEFAULT_MASK, FIXED_MASK } from './PhoneField.constants';
import { PhoneFieldProps } from './PhoneField.interfaces';
import { usePhoneFieldStyles } from './PhoneField.styles';

const masksArray = (
  COUNTRIES_MASKS.map((item) => ({ code: item[3], mask: item[4] || null })).filter(
    (item) => item.mask
  ) as [{ code: string; mask: string }]
).sort((itemA, itemB) => itemA.code.length - itemB.code.length);

export const PhoneField = ({
  name = 'phone',
  mask,
  required,
  label,
  onChange,
  placeholder,
  className,
  fixedMask,
  validate,
  ...props
}: PhoneFieldProps) => {
  const [translate] = useTranslation();
  const classes = usePhoneFieldStyles();
  const {
    watch,
    formState: { dirtyFields },
  } = useFormContext();
  const formValue = watch(name || 'phone');
  const [currentMask, setCurrentMask] = useState(
    fixedMask && FIXED_MASK[fixedMask] ? FIXED_MASK[fixedMask]?.mask || DEFAULT_MASK : DEFAULT_MASK
  );

  const checkMask = useCallback(
    (value: string) => {
      if (fixedMask) return;
      if (value) {
        const normalizedPhone = normalizePhone(value);
        const maskItem = masksArray.find((item) => normalizedPhone.startsWith(item.code));
        if (maskItem) {
          setCurrentMask(`+${maskItem.code.replace(/\d/g, '9')} ${maskItem.mask}`);
          return;
        }
      }
      setCurrentMask(DEFAULT_MASK);
    },
    [fixedMask]
  );

  const handleChanges = useCallback(
    (e: ChangeEvent<HTMLInputElement> | undefined) => {
      onChange?.();
      checkMask(e?.target?.value ?? '');
    },
    [checkMask, onChange]
  );

  useEffect(() => {
    let state = false;
    const subName = name?.split('.');
    if (subName && typeof subName[0] === 'number' && typeof subName[1] === 'number') {
      state = dirtyFields[subName[0]]?.[parseInt(subName[1], 10)];
    } else {
      state = dirtyFields[name || 'phone'];
    }
    if (!state) {
      checkMask(formValue);
    }
  }, [checkMask, dirtyFields, formValue, name]);

  const validatePhone = (fieldValue: string) => {
    if (!fieldValue && !required) {
      return true;
    }
    if (!fieldValue && !fixedMask) {
      return translate('FORMFIELD_PHONE_EMPTY');
    }
    const phoneNormalize = fieldValue.replace(/[^\d]+/g, '');
    const maskNormalize = currentMask.replace(/[^\d]+/g, '');
    let passesValidation = true;

    if (fixedMask) {
      const validationFunc = FIXED_MASK[fixedMask]?.validation;
      if (validationFunc && !validationFunc(fieldValue)) {
        passesValidation = false;
      }
    }
    if ((fieldValue || (!fieldValue && required)) && fixedMask && !passesValidation) {
      return translate('FORMFIELD_PHONE_MASK_ERROR', {
        count: maskNormalize.length,
        mask: currentMask,
      });
    }
    if (maskNormalize.length < 15 && fieldValue && phoneNormalize.length < maskNormalize.length) {
      return translate('FORMFIELD_PHONE_MASK_ERROR', {
        count: maskNormalize.length,
        mask: currentMask,
      });
    }
    if (
      (fieldValue && (phoneNormalize.length < 8 || phoneNormalize.length > 21)) ||
      (!fieldValue && required)
    ) {
      return translate('FORMFIELD_PHONE_ERROR');
    }
    if (typeof validate === 'function') {
      return validate(fieldValue);
    }
    return true;
  };

  return (
    <MaskedField
      {...props}
      className={clsx(classes.root, className)}
      mask={mask || currentMask}
      label={label}
      name={name || 'phone'}
      placeholder={placeholder || '+7 (999) 123-45-67'}
      validate={validatePhone}
      onChanges={handleChanges}
    />
  );
};

export default PhoneField;
