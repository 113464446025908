import { ICellProps } from '@components/LazyTable';
import { PhoneIcon, UserIcon, UsersIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import React from 'react';
import { TableDataObject } from '../SettingsNotifications.interface';
import { useNotificationsStyles } from '../SettingsNotifications.styles';

export const NotificationsStatusCell = ({ item }: ICellProps<TableDataObject>) => {
  const { allNumbers, employee, department, domainNumber } = item || {};
  const classes = useNotificationsStyles();

  const renderIcon = () => {
    if (allNumbers || domainNumber) {
      return <PhoneIcon className={clsx(classes.links)} />;
    }
    if (employee) {
      return <UserIcon className={clsx(classes.primary)} />;
    }
    if (department) {
      return <UsersIcon className={clsx(classes.secondary)} />;
    }
    return null;
  };

  return <>{renderIcon()}</>;
};
