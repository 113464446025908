import { getDateList } from '@/utils';
import {
  ArrowUpDownIcon,
  PhoneIncomingIcon,
  PhoneMissedIcon,
  PhoneOutgoingIcon,
} from '@shared/assets/images/icons';
import { ElementIconType, numberKeyValueType } from '@components/typings/interfaces';
import Incoming from '@images/diagram/phone-incoming-blue.png';
import IncomingMissed from '@images/diagram/phone-incoming-red.png';
import Outgoing from '@images/diagram/phone-outgoing.png';
import OutgoingMissed from '@images/diagram/phone-missed.png';
import { HistoryDirection } from '@/features/History/HistoryPage.interfaces';
import { historyRedirectType } from './StatisticsByCalls.interfaces';
import { callType, periodShort, periodType } from '../Statistics.interfaces';

export const LINE_DIAGRAM_TENSION = 0.25;

export const filterOptionsShort = [
  {
    value: periodShort.hour,
    title: 'BY_HOURS',
  },
  {
    value: periodShort.day,
    title: 'BY_DAYS',
  },
  {
    value: periodShort.week,
    title: 'BY_WEEK',
  },
  {
    value: periodShort.month,
    title: 'BY_MONTH',
  },
];

export const filterTypeCallOptions = [
  {
    value: callType.allCalls,
    title: 'TOTAL_SUCCESSFUL',
  },
  {
    value: callType.incoming,
    title: 'INCOMING',
  },
  {
    value: callType.outgoing,
    title: 'OUTGOING',
  },
];

export const filterTypeAllCallsOptions = [
  {
    value: callType.allCalls,
    title: 'ALL_CALLS',
  },
  {
    value: callType.incoming,
    title: 'INCOMING',
  },
  {
    value: callType.missed,
    title: 'INCOMING_OUT',
  },
  {
    value: callType.outgoing,
    title: 'OUTGOING',
  },
  {
    value: callType.unsuccessful,
    title: 'OUTGOING_OUT',
  },
];

export const filterTypeCallIcons: ElementIconType = {
  [callType.allCalls]: ArrowUpDownIcon,
  [callType.incoming]: PhoneIncomingIcon,
  [callType.outgoing]: PhoneOutgoingIcon,
  [callType.missed]: PhoneMissedIcon,
  [callType.unsuccessful]: PhoneOutgoingIcon,
};

export const filterPeriodsEmployeesStatistic = [
  {
    value: periodType.today,
    title: 'PERIOD_OPT_TODAY',
  },
  {
    value: periodType.yesterday,
    title: 'PERIOD_OPT_PASTDAY',
  },
  {
    value: periodType.week,
    title: 'PERIOD_OPT_WEEK',
  },
  {
    value: periodType.pastWeek,
    title: 'PERIOD_OPT_PASTWEEK',
  },
  {
    value: periodType.month,
    title: 'PERIOD_OPT_MONTH',
  },
  {
    value: periodType.pastMonth,
    title: 'PERIOD_OPT_PASTMONTH',
  },
];

export const filterOptionsPeriod = [
  {
    value: periodType.week,
    title: 'PERIOD_OPT_WEEK',
  },
  {
    value: periodType.pastWeek,
    title: 'PERIOD_OPT_PASTWEEK',
  },
  {
    value: periodType.month,
    title: 'PERIOD_OPT_MONTH',
  },
  {
    value: periodType.pastMonth,
    title: 'PERIOD_OPT_PASTMONTH',
  },
  {
    value: periodType.year,
    title: 'PERIOD_OPT_YEAR',
  },
  {
    value: periodType.dayHour,
    title: 'PERIOD_OPT_DAY_HOUR',
  },
];

export const redirectValues: historyRedirectType = {
  0: { direction: HistoryDirection.In, missed: true },
  1: { direction: HistoryDirection.In, missed: null },
  2: { direction: HistoryDirection.Out, missed: true },
  3: { direction: HistoryDirection.Out, missed: null },
};

export const getSelectedOptionsType = (item: periodType): periodType => {
  if (item === periodType.dayHour) {
    return periodType.dayHour;
  }
  if (item === periodType.year) {
    return periodType.year;
  }
  if (item === periodType.month || item === periodType.pastMonth) {
    return periodType.month;
  }
  if (item === periodType.week || item === periodType.pastWeek) {
    return periodType.week;
  }
  return periodType.week;
};

export const getRedirectDates = (
  dateValue: string,
  period: periodShort
): { dateFrom: string; dateTo: string } => {
  let dateFrom = new Date();
  let dateTo = new Date();
  const { startDay, currentDay, currentWeek, endWeek, startMonth, endMonth } = getDateList(
    new Date(dateValue)
  );
  if (period === periodShort.day || period === periodShort.hour) {
    dateFrom = startDay;
    dateTo = currentDay;
  }
  if (period === periodShort.week) {
    dateFrom = currentWeek;
    dateTo = endWeek;
  }
  if (period === periodShort.month) {
    dateFrom = startMonth;
    dateTo = endMonth;
  }
  return { dateFrom: dateFrom.toISOString(), dateTo: dateTo.toISOString() };
};

export const arrowIcon: numberKeyValueType = {
  0: IncomingMissed,
  1: Incoming,
  2: OutgoingMissed,
  3: Outgoing,
};
