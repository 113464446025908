import { numberKeyValueType } from '@components/typings/interfaces';
import {
  AMOIconSmall,
  RESTIconSmall,
  GravitelIconSmall,
  Bitrix24IconSmall,
  MegaplanIconSmall,
  YclientsIconSmall,
  OmnideskIconSmall,
  PlanfixIconSmall,
  RoistatIconSmall,
  RetailIconMed,
  OdinSIconMed,
  KlienticsIconMed,
} from '@shared/assets/images/icons/integrations';
import { IIntegrationTypes, IntegrationList } from '@components/typings/interfaces/integrations';

export const integrationsLogoList: IIntegrationTypes = {
  [IntegrationList.AmoCrm]: AMOIconSmall,
  [IntegrationList.API]: RESTIconSmall,
  [IntegrationList.Gravitel]: GravitelIconSmall,
  [IntegrationList.Bitrix24]: Bitrix24IconSmall,
  [IntegrationList.Megaplan]: MegaplanIconSmall,
  [IntegrationList.YClients]: YclientsIconSmall,
  [IntegrationList.Omnidesk]: OmnideskIconSmall,
  [IntegrationList.Planfix]: PlanfixIconSmall,
  [IntegrationList.Roistat]: RoistatIconSmall,
  [IntegrationList.RetailCRM]: RetailIconMed,
  [IntegrationList.OdinS]: OdinSIconMed,
  [IntegrationList.Klientiks]: KlienticsIconMed,
};

export const waitingTimeOptions = [
  {
    key: 5,
    timeValue: 'FIVE_SECONDS',
  },
  {
    key: 10,
    timeValue: 'TEN_SECONDS',
  },
  {
    key: 15,
    timeValue: 'FIFTEEN_SECONDS',
  },
  {
    key: 20,
    timeValue: 'TWENTY_SECONDS',
  },
  {
    key: 25,
    timeValue: 'TWENTY_FIVE_SECONDS',
  },
  {
    key: 30,
    timeValue: 'THIRTY_SECONDS',
  },
  {
    key: 60,
    timeValue: 'MINUTE',
  },
];

export enum SizeUnits {
  Bytes = 'BYTES',
  Kilobytes = 'KB',
  Megabytes = 'MB',
  Gigabytes = 'GB',
  TeraBytes = 'TB',
  Petabytes = 'PB',
  Exabytes = 'EB',
  Zettabytes = 'ZB',
  Yottabytes = 'YB',
}

export const sizeUnitsArray = [
  SizeUnits.Bytes,
  SizeUnits.Kilobytes,
  SizeUnits.Megabytes,
  SizeUnits.Gigabytes,
  SizeUnits.TeraBytes,
  SizeUnits.Petabytes,
  SizeUnits.Exabytes,
  SizeUnits.Zettabytes,
  SizeUnits.Yottabytes,
];

export const uploadFieldsList = [
  'passportFrontFileHash',
  'passportFrontFileName',
  'passportFrontFileUrl',
  'passportAddressFileHash',
  'passportAddressFileName',
  'passportAddressFileUrl',
  'innFileHash',
  'innFileName',
  'innFileUrl',
  'kndFileHash',
  'kndFileName',
  'kndFileUrl',
  'passportDataPage',
  'passportAddressPage',
  'IsPostalAddressDuplicate',
  'personalDataAgreement',
] as const;

export const findWeekDay = (date: number) => {
  const weekDays: numberKeyValueType = {
    0: 'SUN',
    1: 'MON',
    2: 'TUE',
    3: 'WED',
    4: 'THU',
    5: 'FRI',
    6: 'SAT',
  };

  return weekDays[date];
};

// eslint-disable-next-line prefer-destructuring
const FILE_UPLOAD_MAX_SIZE = process.env.FILE_UPLOAD_MAX_SIZE;

const CommonConstants = {
  FileUploadMaxSize:
    FILE_UPLOAD_MAX_SIZE && Number.isFinite(Number(FILE_UPLOAD_MAX_SIZE))
      ? Number(FILE_UPLOAD_MAX_SIZE)
      : 1024 * 1024 * 20,
};

export const DocumentsMIMEType =
  'image/*,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const ImagesMIMEType = 'image/*,application/pdf';

export const SelfiesMIMETypes = 'image/jpeg,image/heif,image/heic';

export const PROMISE_PAYMENT_MIN_AMOUNT = 300;

export default CommonConstants;
