import MessageDialog from '@shared/components/MessageDialog';
import Typography from '@shared/components/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ResponsibleAlreadyExistsDialog = ({ isOpen, onClose }: Props) => {
  const [translate] = useTranslation();

  return (
    <MessageDialog
      isOpen={isOpen}
      title={translate('ERROR')}
      onCancel={onClose}
      renderContent={<Typography>{translate('EMPLOYEE_WITH_THIS_DATA_ALREADY_EXISTS')}</Typography>}
      buttonSuccessProps={{
        titleCode: 'CLOSE',
        onClick: onClose,
      }}
    />
  );
};

export default ResponsibleAlreadyExistsDialog;
