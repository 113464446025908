import { DomainTelNumbersQuery } from '@/client/generated/graphql';

export enum OpenModalType {
  Off,
  RefuseBought,
  AbortRefuse,
  Bought,
}

export interface INumberDiscountProps {
  discount: number;
  isAbsolute: boolean;
}

export type DomainNumber = Exclude<
  DomainTelNumbersQuery['getNumbers'][number],
  { __typename: 'FmcNumberModel' }
>;

export type PhoneNumbersContext = {
  isDialogSelectResponsibleOpen: boolean;
  isDialogShouldSelectResponsibleOpen: boolean;
  phoneNumber: string;
  domainNumberId: number;
  additionalNotification: string;
};

export type PhoneNumberFormFields = {
  search: string | null;
  endUserId: number | null;
};
