import React from 'react';
import { toPrecision } from '@components/utils/toPrecision';
import { useTranslation } from 'react-i18next';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import { DomainNumber } from '../PhoneNumbers.interfaces';
import { NumberDiscount } from '../PhoneNumbers.helpers';

export const NumberPerMonthCell = ({ item }: ICellProps<DomainNumber>) => {
  const [translate] = useTranslation();

  const { monthlyFee, monthlyDiscount, isMonthlyDiscountAbsolute } = item.tariff || {};

  return (
    <Typography type={'text3'} color={'inherit'}>
      {translate('RUB_PER_MONTH', {
        amount: monthlyFee ? toPrecision(monthlyFee, false) : '-',
      })}
      {!!monthlyDiscount && (
        <NumberDiscount discount={monthlyDiscount} isAbsolute={!!isMonthlyDiscountAbsolute} />
      )}
    </Typography>
  );
};
