/* eslint-disable no-plusplus */
import {
  WorkCalendarCategory,
  WorkCalendarModel,
  WorkCalendarType,
} from '@/client/generated/graphql';
import {
  ADD_WORK_CALENDAR_CUSTOM_DAY,
  DELETE_WORK_CALENDAR_CUSTOM_DAY,
  UPDATE_WORK_CALENDAR_CUSTOM_DAY,
} from '@/client/mutations';
import { GET_WORK_CALENDAR } from '@/client/queries';
import { monthsNamesInUppercase, weekDays, weekDaysFromSunday } from '@/common/constants';
import WorkCalendarEventsGroup from '@/components/WorkCalendarEventsGroup';
import BodyContainer from '@/layouts/BodyContainer';
import { getMonthTranslationCode } from '@/utils/getMonthTranslationCode';
import { useMutation, useQuery } from '@apollo/client';
import Drawer from '@components/Drawer';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import PagePreloader from '@components/PagePreloader';
import Tab from '@components/Tab/Tab';
import { observable } from '@legendapp/state';
import { useSelector } from '@legendapp/state/react';
import { Button as ButtonMUI, makeStyles } from '@material-ui/core';
import { EditIcon, TrashIcon } from '@shared/assets/images/icons';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import PageError from '@shared/components/PageError';
import Typography from '@shared/components/Typography';
import { dateOnlyStringToDate, dateToDateOnlyString } from '@shared/utils/dates';
import resultify from '@shared/utils/resultify';
import { pxToEm } from '@shared/utils/styles';
import cloneDeep from 'clone-deep';
import clsx from 'clsx';
import { endOfDay, isPast, startOfDay } from 'date-fns';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// `selectedDate` is retrieved from search params.
// However, value is used with observable for better performance and to prevent re-renders caused by useSearchParams hook.
// Still, it should be set to search params when user chooses a date allow sharing a link for that specific date.

/** String in format "yyyy-mm-dd" */
type DateShortString = string;

type WorkCalendarDaysMap = {
  [key: DateShortString]: WorkCalendarModel[] | undefined;
};

type Context = {
  currentTabYear: number;
  selectedDate: Date;
  /** This field stores work calendar data as a map for efficient and convenient
   * usage of data for calendar display.
   */
  days: WorkCalendarDaysMap;

  // Data shown in events blocks
  customDaysEvents: WorkCalendarModel[];
  holidayDaysEvents: WorkCalendarModel[];
  rescheduledDaysEvents: WorkCalendarModel[];

  exceptionDrawerDateType: WorkCalendarType;

  isExceptionDrawerOpen: boolean;
  shouldShowNextYearTab: boolean;
};

const getDefaultContextValues = (): Context => ({
  currentTabYear: new Date().getFullYear(),
  selectedDate: new Date(),
  days: {},

  customDaysEvents: [],
  holidayDaysEvents: [],
  rescheduledDaysEvents: [],

  exceptionDrawerDateType: WorkCalendarType.DayOff,

  isExceptionDrawerOpen: false,
  shouldShowNextYearTab: false,
});

const context$ = observable<Context>(getDefaultContextValues());

const sortByDateString = (a: WorkCalendarModel, b: WorkCalendarModel) => {
  return a.date.localeCompare(b.date);
};

const transformAndSetWorkCalendarData = (data: WorkCalendarModel[]) => {
  const days: WorkCalendarDaysMap = {};

  const customDaysEvents = [];
  const holidayDaysEvents = [];
  const rescheduledDaysEvents = [];

  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  let shouldShowNextYearTab = false;

  // cloneDeep usage is necessary to prevent unexpected results with legend app state manipulations,
  // if underlying object is the same across multiple data structures this may occur
  for (let i = 0; i < data.length; i += 1) {
    const category = data[i].category;

    switch (category) {
      case WorkCalendarCategory.Custom:
        customDaysEvents.push(cloneDeep(data[i]));
        break;
      case WorkCalendarCategory.Holiday:
        holidayDaysEvents.push(cloneDeep(data[i]));
        break;
      case WorkCalendarCategory.Rescheduled:
        rescheduledDaysEvents.push(cloneDeep(data[i]));
        break;
      default:
        category satisfies never;
    }

    if (days[data[i].date]) {
      days[data[i].date]!.push(cloneDeep(data[i]));
    } else {
      days[data[i].date] = [cloneDeep(data[i])];
    }

    const dateYearAsString = data[i].date.slice(0, 4);
    if (dateYearAsString === String(nextYear)) {
      shouldShowNextYearTab = true;
    }
  }

  context$.days.set(days);

  context$.customDaysEvents.set(customDaysEvents.sort(sortByDateString));
  context$.holidayDaysEvents.set(holidayDaysEvents.sort(sortByDateString));
  context$.rescheduledDaysEvents.set(rescheduledDaysEvents.sort(sortByDateString));

  context$.shouldShowNextYearTab.set(shouldShowNextYearTab);
};

const addExceptionDateInfoToState = (date: Date, exceptionInfo: WorkCalendarModel) => {
  const dateOnlyString = dateToDateOnlyString(date);

  // cloneDeep usage is necessary to prevent unexpected results with legend app state manipulations,
  // if underlying object is the same across multiple data structures this may occur

  const addToDays = () => {
    if (!dateOnlyString) {
      globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
      return;
    }

    const dateInfo = context$.days[dateOnlyString].peek() || [];
    if (dateInfo.length === 0) {
      context$.days[dateOnlyString].set([cloneDeep(exceptionInfo)]);
      return;
    }

    const exceptionDateInfoIndex = dateInfo.findIndex((info) => info.id === exceptionInfo.id);
    if (exceptionDateInfoIndex !== -1) {
      context$.days[dateOnlyString][exceptionDateInfoIndex].set(cloneDeep(exceptionInfo));
      return;
    }

    (context$.days[dateOnlyString] as unknown as Array<WorkCalendarModel>).push(
      cloneDeep(exceptionInfo)
    );
  };

  const addToEvents = () => {
    const customDaysEvents = context$.customDaysEvents.peek();

    for (let i = 0; i < customDaysEvents.length; i += 1) {
      if (dateOnlyStringToDate(customDaysEvents[i].date)! > startOfDay(date)) {
        context$.customDaysEvents.splice(i, 0, cloneDeep(exceptionInfo));
        return;
      }
    }

    context$.customDaysEvents.set([...customDaysEvents, cloneDeep(exceptionInfo)]);
  };

  addToDays();
  addToEvents();
};

const editExceptionNoteInState = (
  dateOnlyString: string,
  dateExceptionInfoIndex: number,
  dateExceptionInfoId: number,
  newName: string
) => {
  context$.days[dateOnlyString]?.[dateExceptionInfoIndex]?.note?.set(newName);

  const customDaysEvents = context$.customDaysEvents.peek();

  const indexInCustomDaysEvents = customDaysEvents.findIndex(
    (info) => info.id === dateExceptionInfoId
  );

  if (indexInCustomDaysEvents !== -1) {
    context$.customDaysEvents[indexInCustomDaysEvents].note.set(newName);
  }
};

const deleteExceptionNoteInState = (
  dateOnlyString: string,
  dateExceptionInfoIndex: number,
  dateExceptionInfoId: number
) => {
  (context$.days[dateOnlyString] as unknown as WorkCalendarModel[] | undefined)?.splice(
    dateExceptionInfoIndex,
    1
  );

  const customDaysEvents = context$.customDaysEvents.peek();

  const indexInCustomDaysEvents = customDaysEvents.findIndex(
    (info) => info.id === dateExceptionInfoId
  );

  if (indexInCustomDaysEvents !== -1) {
    context$.customDaysEvents.splice(indexInCustomDaysEvents, 1);
  }
};

const getPrioritizedDateType = (
  date: Date,
  dateInfo: WorkCalendarModel[] | null | undefined
): WorkCalendarType => {
  let exceptionInfo: WorkCalendarModel | undefined;
  let rescheduledInfo: WorkCalendarModel | undefined;
  let holidayInfo: WorkCalendarModel | undefined;

  const dateInfoLocal = dateInfo || [];

  for (let i = 0; i < dateInfoLocal.length; i += 1) {
    const category = dateInfoLocal[i].category;
    switch (category) {
      case WorkCalendarCategory.Custom:
        exceptionInfo = dateInfoLocal[i];
        break;
      case WorkCalendarCategory.Rescheduled:
        rescheduledInfo = dateInfoLocal[i];
        break;
      case WorkCalendarCategory.Holiday:
        holidayInfo = dateInfoLocal[i];
        break;
      default:
        category satisfies never;
    }
  }

  if (exceptionInfo) {
    return exceptionInfo.type;
  }
  if (rescheduledInfo) {
    return rescheduledInfo.type;
  }
  if (holidayInfo) {
    return holidayInfo.type;
  }

  const isSaturday = date.getDay() === 6;
  const isSunday = date.getDay() === 0;

  return isSaturday || isSunday ? WorkCalendarType.DayOff : WorkCalendarType.WorkingDay;
};

const widescreenModeBreakpointPx = '1820px';

const monthsIndexes = Array.from({ length: 12 }).map((_, index) => index);

type DateTypeTypographyProps = {
  dateType: WorkCalendarType;
};

const DateTypeTypography = ({ dateType }: DateTypeTypographyProps) => {
  const [translate] = useTranslation();

  switch (dateType) {
    case WorkCalendarType.WorkingDay:
      return <Typography color="tertiary500">{translate('WORKING_ADJECTIVE')}</Typography>;
    case WorkCalendarType.DayOff:
      return <Typography color="danger600">{translate('WEEKEND')}</Typography>;
    default:
      dateType satisfies never;
      return '';
  }
};

const useDayDetailsStyles = makeStyles(({ color: { tertiary } }) => ({
  wrapper: {
    padding: '1em',
    marginBottom: '1.5em',

    border: `1px solid ${tertiary[200]}`,
    borderRadius: '12px',
  },
  marginRight05: {
    marginRight: '.5em',
  },
  selectedDate: {
    lineHeight: 1,
  },
  addExceptionButton: {
    maxHeight: pxToEm(40),
  },
}));

const DayDetails = () => {
  const classes = useDayDetailsStyles();
  const [translate] = useTranslation();

  const today = new Date();

  const dateString = `${translate(getMonthTranslationCode(today.getMonth()))}, ${translate(
    weekDaysFromSunday[today.getDay()].abbreviationCode
  ).toLowerCase()}`;

  const dateType = getPrioritizedDateType(
    today,
    context$.days[dateToDateOnlyString(today)!].peek()
  );

  return (
    <div className={classes.wrapper}>
      <Flex>
        <div className={classes.marginRight05}>
          <Typography className={classes.selectedDate} pxToEmSize={44}>
            {today.getDate()}
          </Typography>
        </div>
        <Flex direction="column">
          <Typography pxToEmSize={18} bold>
            {dateString}
          </Typography>
          <DateTypeTypography dateType={dateType} />
        </Flex>
      </Flex>
      {/* // TODO (calendar) add "no exceptions" element */}
    </div>
  );
};

const Events = () => {
  const [translate] = useTranslation();

  const currentTabYear = useSelector(context$.currentTabYear);
  const customDaysEvents = useSelector(context$.customDaysEvents) as WorkCalendarModel[];
  const holidayDaysEvents = useSelector(context$.holidayDaysEvents) as WorkCalendarModel[];
  const rescheduledDaysEvents = useSelector(context$.rescheduledDaysEvents) as WorkCalendarModel[];

  const keepOnlyCurrentYearEvent = (event: WorkCalendarModel) => {
    return dateOnlyStringToDate(event.date)!.getFullYear() === currentTabYear;
  };

  const customDaysEventsFiltered = customDaysEvents.filter(keepOnlyCurrentYearEvent);

  const holidayDaysEventsFiltered = holidayDaysEvents.filter(keepOnlyCurrentYearEvent);

  const rescheduledDaysEventsFiltered = rescheduledDaysEvents.filter(keepOnlyCurrentYearEvent);

  const handleOpenExceptionDrawerButtonClick = (eventInfo: WorkCalendarModel) => {
    const date = dateOnlyStringToDate(eventInfo.date)!;

    context$.selectedDate.set(date);
    context$.isExceptionDrawerOpen.set(true);
    context$.exceptionDrawerDateType.set(
      getPrioritizedDateType(date, context$.days[eventInfo.date].peek() || [])
    );
  };

  return (
    <>
      <Typography pxToEmSize={18}>{translate('EVENTS')}</Typography>

      <WorkCalendarEventsGroup
        category={WorkCalendarCategory.Custom}
        events={customDaysEventsFiltered}
        handleEventClick={handleOpenExceptionDrawerButtonClick}
      />
      <WorkCalendarEventsGroup
        category={WorkCalendarCategory.Holiday}
        events={holidayDaysEventsFiltered}
        handleEventClick={handleOpenExceptionDrawerButtonClick}
      />
      <WorkCalendarEventsGroup
        category={WorkCalendarCategory.Rescheduled}
        events={rescheduledDaysEventsFiltered}
        handleEventClick={handleOpenExceptionDrawerButtonClick}
      />
    </>
  );
};

const useCalendarDayPlaceholderStyles = makeStyles(() => ({
  placeholder: {
    width: pxToEm(32),
    height: pxToEm(32),
    margin: '.25em .125em 0 .125em',
  },
}));

const CalendarDayPlaceholder = () => {
  const classes = useCalendarDayPlaceholderStyles();

  return <div className={classes.placeholder} />;
};

type CalendarDayProps = {
  date: Date;
};

const useCalendarDayButtonStyles = makeStyles(({ color: { tertiary, base, success } }) => ({
  dayButton: {
    width: pxToEm(32),
    height: pxToEm(32),
    margin: '.25em .125em 0 .125em',
    padding: 0,
    borderRadius: '6px',
    border: `1.5px solid ${base}`,
    backgroundColor: `${base}`,
    fontSize: '1em',
    minWidth: 'unset',
  },
  dayButtonCurrent: {
    border: `1.5px solid ${tertiary[500]}`,
  },
  dayButtonCustom: {
    backgroundColor: success[100],
  },
}));

const areDatesEqualByYearMonthAndDate = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const CalendarDayButton = ({ date }: CalendarDayProps) => {
  const classes = useCalendarDayButtonStyles();

  const days = useSelector(context$.days);

  const dateOnlyString = dateToDateOnlyString(date)!;

  const dateInfo = days[dateOnlyString || ''] || [];
  const exceptionInfo = dateInfo?.find((data) => data.category === WorkCalendarCategory.Custom);
  const rescheduledInfo = dateInfo?.find(
    (data) => data.category === WorkCalendarCategory.Rescheduled
  );
  const holidayInfo = dateInfo?.find((data) => data.category === WorkCalendarCategory.Holiday);

  const isCurrentDate = areDatesEqualByYearMonthAndDate(date, new Date());

  const handleDayButtonClick = () => {
    context$.selectedDate.set(date);
    context$.isExceptionDrawerOpen.set(true);
    context$.exceptionDrawerDateType.set(getPrioritizedDateType(date, dateInfo));
  };

  const getColorByDayType = (dayType: WorkCalendarType) => {
    switch (dayType) {
      case WorkCalendarType.DayOff:
        return 'danger600';
      case WorkCalendarType.WorkingDay:
        return 'tertiary900';
      default:
        dayType satisfies never;
        return 'tertiary900';
    }
  };

  const getTypographyColor = () => {
    if (exceptionInfo) {
      return getColorByDayType(exceptionInfo.type);
    }
    if (rescheduledInfo) {
      return getColorByDayType(rescheduledInfo.type);
    }
    if (holidayInfo) {
      return 'danger600';
    }

    const isSaturday = date.getDay() === 6;
    const isSunday = date.getDay() === 0;
    if (isSaturday || isSunday) {
      return 'danger600';
    }

    return 'tertiary900';
  };

  return (
    <ButtonMUI
      className={clsx(
        classes.dayButton,
        isCurrentDate && classes.dayButtonCurrent,
        exceptionInfo && classes.dayButtonCustom
      )}
      onClick={handleDayButtonClick}
    >
      <Typography color={getTypographyColor()}>{date.getDate()}</Typography>
    </ButtonMUI>
  );
};

const useCalendarMonthStyles = makeStyles(({ color: { secondary, tertiary } }) => ({
  month: {
    width: pxToEm(280),
    height: pxToEm(296),
    border: `1px solid ${tertiary[200]}`,
    borderRadius: '12px',
    marginBottom: '1.5em',
    marginRight: '1em',
    [`@media (min-width:${widescreenModeBreakpointPx})`]: {
      marginBottom: '2em',
      marginRight: '2em',
    },
  },
  header: {
    background: secondary[50],
    height: '2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },
  elementsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '.25em .75em .75em .75em',
  },
  weekDay: {
    width: '2em',
    height: '2em',
    margin: '0 .125em 0 .125em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type CalendarMonthProps = {
  monthIndex: number;
};

type MonthElement = { type: 'placeholder' } | { type: 'date'; date: Date };

const CalendarMonth = ({ monthIndex }: CalendarMonthProps) => {
  const classes = useCalendarMonthStyles();
  const [translate] = useTranslation();
  const currentTabYear = useSelector(context$.currentTabYear);

  const firstMonthDate = useMemo(
    () => new Date(currentTabYear, monthIndex, 1),
    [monthIndex, currentTabYear]
  );

  const monthElements = useMemo(() => {
    const result: MonthElement[] = [];

    const countOfPlaceholdersBeforeFirstMonthDate =
      firstMonthDate.getDay() === 0 ? 6 : Math.max(firstMonthDate.getDay() - 1, 0);

    for (let i = 0; i < countOfPlaceholdersBeforeFirstMonthDate; i += 1) {
      result.push({ type: 'placeholder' });
    }

    const currentIterationDate = new Date(firstMonthDate);
    while (currentIterationDate.getMonth() === firstMonthDate.getMonth()) {
      result.push({ type: 'date', date: new Date(currentIterationDate) });
      currentIterationDate.setDate(currentIterationDate.getDate() + 1);
    }

    return result;
  }, [firstMonthDate]);

  return (
    <div className={classes.month}>
      <div className={classes.header}>
        <Typography pxToEmSize={16} medium>
          {translate(monthsNamesInUppercase[monthIndex])}
        </Typography>
      </div>
      <div className={classes.elementsContainer}>
        {weekDays.map((el, index) => (
          <div className={classes.weekDay} key={index}>
            <Typography color={index === 6 || index === 5 ? 'danger600' : 'tertiary900'}>
              {translate(el.abbreviationCode)}
            </Typography>
          </div>
        ))}
        {monthElements.map((element, index) => {
          return element.type === 'date' ? (
            <CalendarDayButton key={element.type + Number(element.date)} date={element.date} />
          ) : (
            <CalendarDayPlaceholder key={element.type + index} />
          );
        })}
      </div>
    </div>
  );
};

const useCalendarStyles = makeStyles(() => ({
  monthsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const Calendar = () => {
  const classes = useCalendarStyles();

  return (
    <div className={classes.monthsWrapper}>
      {monthsIndexes.map((index) => (
        <CalendarMonth key={index} monthIndex={index} />
      ))}
    </div>
  );
};

const useSidePanelStyles = makeStyles(() => ({
  sidePanel: {
    flex: `0 0 ${pxToEm(256)}`,
    overflow: 'hidden',

    margin: '0 2em',
  },
}));

const SidePanel = () => {
  const classes = useSidePanelStyles();
  return (
    <div className={classes.sidePanel}>
      <DayDetails />
      <Events />
    </div>
  );
};

const useYearTabsStyles = makeStyles(() => ({
  tabsWrapper: {
    marginBottom: '1em',
  },
}));

const YearTabs = () => {
  const classes = useYearTabsStyles();

  const shouldShowNextYearTab = useSelector(context$.shouldShowNextYearTab);
  const currentTabYear = useSelector(context$.currentTabYear);
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  const getTabClickHandler = (year: number) => () => {
    context$.currentTabYear.set(year);
  };

  return (
    <Flex className={classes.tabsWrapper}>
      <Tab
        id={1}
        active={currentTabYear === currentYear}
        onClick={getTabClickHandler(currentYear)}
        tabTitle={String(currentYear)}
      />
      {shouldShowNextYearTab && (
        <Tab
          id={2}
          active={currentTabYear === nextYear}
          onClick={getTabClickHandler(nextYear)}
          tabTitle={String(nextYear)}
        />
      )}
    </Flex>
  );
};

const useCalendarPanelStyles = makeStyles(() => ({
  calendarPanel: {
    flex: `0 0 ${pxToEm(888)}`,

    [`@media (min-width:${widescreenModeBreakpointPx})`]: {
      flex: `0 0 ${pxToEm(1248)}`,
    },
  },
}));

const CalendarPanel = () => {
  const classes = useCalendarPanelStyles();

  return (
    <Flex className={classes.calendarPanel} direction="column">
      <YearTabs />
      <Calendar />
    </Flex>
  );
};

type ExceptionDrawerFields = {
  exceptionName: string;
};

type ExceptionDrawerModificationState =
  | 'add-exception'
  | 'edit-exception-name'
  | 'delete-exception'
  | null;

const useExceptionDrawerStyles = makeStyles(
  ({ color: { tertiary, success, secondary, danger } }) => ({
    wrapper: {
      padding: '1em',
      marginBottom: '1.5em',

      border: `1px solid ${tertiary[200]}`,
      borderRadius: '12px',
    },
    marginRight05: {
      marginRight: '.5em',
    },
    selectedDate: {
      lineHeight: 1,
    },
    marginBottom1: {
      marginBottom: '1em',
    },
    dateInfoRow: {
      display: 'flex',
      alignItems: 'center',
      '& + &': {
        marginTop: '.25em',
      },
    },
    dateInfoRowMarker: {
      borderRadius: '5px',
      flex: `0 0 ${pxToEm(14)}`,
      height: pxToEm(14),
      marginRight: '.5em',
    },
    backgroundSuccess: {
      backgroundColor: success[500],
    },
    backgroundDanger: {
      backgroundColor: danger[600],
    },
    backgroundSecondary: {
      backgroundColor: secondary[600],
    },
    marginTop1: {
      marginTop: '1em',
    },
    marginLeftAuto: {
      marginLeft: 'auto',
    },
    bottomButton: {
      marginTop: '1em',
      width: '100%',
      height: pxToEm(40),
    },
  })
);

const ExceptionDrawer = () => {
  const classes = useExceptionDrawerStyles();
  const [translate] = useTranslation();

  const selectedDate = startOfDay(useSelector(context$.selectedDate));
  const selectedDateDateOnlyString = dateToDateOnlyString(selectedDate) || '';
  const isExceptionDrawerOpen = useSelector(context$.isExceptionDrawerOpen);
  const dateInfo = useSelector(context$.days[selectedDateDateOnlyString]) as
    | WorkCalendarModel[]
    | undefined;

  const exceptionDrawerDateType = useSelector(context$.exceptionDrawerDateType);
  const isDatePast = isPast(endOfDay(selectedDate));

  const [modificationState, setModificationState] =
    useState<ExceptionDrawerModificationState>(null);

  let dateExceptionInfoIndex = -1;
  const dateExceptionInfo = dateInfo?.find((dateInfoElement, i) => {
    if (dateInfoElement.category === WorkCalendarCategory.Custom) {
      dateExceptionInfoIndex = i;
      return true;
    }
    return false;
  });

  const formMethods = useForm<ExceptionDrawerFields>({
    defaultValues: {
      exceptionName: '',
    },
  });

  const [addWorkCalendarCustomDay, { loading: loadingAddWorkCalendarCustomDay }] = useMutation(
    ADD_WORK_CALENDAR_CUSTOM_DAY
  );
  const [updateWorkCalendarCustomDay, { loading: loadingUpdateWorkCalendarCustomDay }] =
    useMutation(UPDATE_WORK_CALENDAR_CUSTOM_DAY);
  const [deleteWorkCalendarCustomDay, { loading: loadingDeleteWorkCalendarCustomDay }] =
    useMutation(DELETE_WORK_CALENDAR_CUSTOM_DAY);

  const loading =
    loadingAddWorkCalendarCustomDay ||
    loadingUpdateWorkCalendarCustomDay ||
    loadingDeleteWorkCalendarCustomDay;

  const dateString = `${translate(getMonthTranslationCode(selectedDate.getMonth()))}, ${translate(
    weekDaysFromSunday[selectedDate.getDay()].abbreviationCode
  ).toLowerCase()}`;

  const handleClose = () => {
    if (loading) {
      return;
    }

    context$.isExceptionDrawerOpen.set(false);
    setTimeout(() => {
      setModificationState(null);
      formMethods.reset();
    }, 200);
  };

  const getOppositeDateType = () => {
    if (exceptionDrawerDateType === WorkCalendarType.DayOff) {
      return WorkCalendarType.WorkingDay;
    }
    return WorkCalendarType.DayOff;
  };

  const handleMakeDayWorkingButtonClick = () => {
    setModificationState('add-exception');
    context$.exceptionDrawerDateType.set(getOppositeDateType());
  };

  const handleMakeDayWeekendButtonClick = () => {
    setModificationState('add-exception');
    context$.exceptionDrawerDateType.set(getOppositeDateType());
  };

  const handleRemoveExceptionButtonClick = () => {
    setModificationState('delete-exception');
    context$.exceptionDrawerDateType.set(getOppositeDateType());
  };

  const handleExceptionNameFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 40) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.slice(0, 40);
    }
  };

  const handleSaveButtonClick = async () => {
    switch (modificationState) {
      case 'add-exception': {
        const result = await resultify(
          addWorkCalendarCustomDay({
            variables: {
              data: {
                date: dateToDateOnlyString(selectedDate)!,
                type: exceptionDrawerDateType,
                note: formMethods.getValues().exceptionName || translate('EXCEPTION'),
              },
            },
          })
        );

        if (result.type === 'error' || !result.data.data?.addWorkCalendarCustomDay) {
          globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
          return;
        }

        addExceptionDateInfoToState(selectedDate, result.data.data.addWorkCalendarCustomDay);

        handleClose();
        return;
      }
      case 'edit-exception-name': {
        if (!dateExceptionInfo) {
          globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
          return;
        }

        const newExceptionName = formMethods.getValues().exceptionName || translate('EXCEPTION');

        const result = await resultify(
          updateWorkCalendarCustomDay({
            variables: {
              data: {
                id: dateExceptionInfo.id,
                date: dateExceptionInfo.date,
                type: dateExceptionInfo.type,
                note: newExceptionName,
              },
            },
          })
        );

        if (result.type === 'error' || !result.data.data?.updateWorkCalendarCustomDay.confirmed) {
          globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
          return;
        }

        editExceptionNoteInState(
          selectedDateDateOnlyString,
          dateExceptionInfoIndex,
          dateExceptionInfo.id,
          newExceptionName
        );

        handleClose();
        return;
      }
      case 'delete-exception': {
        if (!dateExceptionInfo) {
          globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
          return;
        }

        const result = await resultify(
          deleteWorkCalendarCustomDay({ variables: { customDayId: dateExceptionInfo.id } })
        ); // TODO (calendar) should be fixed, id may be custom due to local creation, which might result in error

        if (result.type === 'error' || !result.data.data?.deleteWorkCalendarCustomDay.confirmed) {
          globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
          return;
        }

        deleteExceptionNoteInState(
          selectedDateDateOnlyString,
          dateExceptionInfoIndex,
          dateExceptionInfo.id
        );

        handleClose();
        return;
      }
      case null: {
        return;
      }
      default: {
        modificationState satisfies never;
      }
    }
  };

  const handleEditExceptionNameButtonClick = () => {
    setModificationState('edit-exception-name');
    formMethods.setValue('exceptionName', dateExceptionInfo?.note || '');
  };

  const renderButton = () => {
    if (
      isDatePast ||
      modificationState === 'add-exception' ||
      modificationState === 'delete-exception'
    ) {
      return null;
    }
    if (dateExceptionInfo) {
      return (
        <Button
          className={classes.bottomButton}
          variant="secondaryFilled"
          startIcon={<TrashIcon />}
          loading={loadingDeleteWorkCalendarCustomDay}
          onClick={handleRemoveExceptionButtonClick}
        >
          <Typography>{translate('REMOVE_EXCEPTION')}</Typography>
        </Button>
      );
    }
    switch (exceptionDrawerDateType) {
      case WorkCalendarType.WorkingDay:
        return (
          <Button
            className={classes.bottomButton}
            variant="secondaryFilled"
            onClick={handleMakeDayWeekendButtonClick}
          >
            <Typography>{translate('MAKE_WEEKEND')}</Typography>
          </Button>
        );
      case WorkCalendarType.DayOff:
        return (
          <Button
            className={classes.bottomButton}
            variant="secondaryFilled"
            onClick={handleMakeDayWorkingButtonClick}
          >
            <Typography>{translate('MAKE_WORKING')}</Typography>
          </Button>
        );
      default:
        exceptionDrawerDateType satisfies never;
        return null;
    }
  };

  const renderedDateInfoArray = dateInfo?.map((info) => {
    switch (info.category) {
      case WorkCalendarCategory.Custom: {
        if (modificationState === 'add-exception' || modificationState === 'delete-exception') {
          return null;
        }
        return (
          <div key={info.id} className={classes.dateInfoRow}>
            <div className={clsx(classes.dateInfoRowMarker, classes.backgroundSuccess)} />
            {modificationState === 'edit-exception-name' ? (
              <FormProvider {...formMethods}>
                <FormFieldRhfUncontrolled
                  name="exceptionName"
                  placeholder={translate('EXCEPTION_NAME')}
                  onChange={handleExceptionNameFieldChange}
                />
              </FormProvider>
            ) : (
              <Typography>{info.note || translate('EXCEPTION')}</Typography>
            )}
            {modificationState !== 'edit-exception-name' && !isDatePast && (
              <Button
                className={classes.marginLeftAuto}
                variant="icon"
                onClick={handleEditExceptionNameButtonClick}
              >
                <EditIcon />
              </Button>
            )}
          </div>
        );
      }
      case WorkCalendarCategory.Holiday: {
        return (
          <div key={info.id} className={classes.dateInfoRow}>
            <div className={clsx(classes.dateInfoRowMarker, classes.backgroundDanger)} />
            <Typography>{info.note || translate('HOLIDAY')}</Typography>
          </div>
        );
      }
      case WorkCalendarCategory.Rescheduled: {
        return (
          <div key={info.id} className={classes.dateInfoRow}>
            <div className={clsx(classes.dateInfoRowMarker, classes.backgroundSecondary)} />
            <Typography>{info.note || translate('RESCHEDULED')}</Typography>
          </div>
        );
      }
      default: {
        info.category satisfies never;
        return null;
      }
    }
  });

  const isThereSomeElementsRenderedByDateInfoArray =
    renderedDateInfoArray?.length &&
    renderedDateInfoArray.some((renderedElement) => renderedElement !== null);

  const renderDateInfo = () => {
    return (
      <div className={clsx(isThereSomeElementsRenderedByDateInfoArray && classes.marginTop1)}>
        {renderedDateInfoArray}
        {modificationState === 'add-exception' && (
          <div
            className={clsx(
              classes.dateInfoRow,
              !isThereSomeElementsRenderedByDateInfoArray && classes.marginTop1
            )}
          >
            <div className={clsx(classes.dateInfoRowMarker, classes.backgroundSuccess)} />
            <FormProvider {...formMethods}>
              <FormFieldRhfUncontrolled
                name="exceptionName"
                placeholder={translate('EXCEPTION_NAME')}
                onChange={handleExceptionNameFieldChange}
              />
            </FormProvider>
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={classes.wrapper}>
        <Flex>
          <div className={classes.marginRight05}>
            <Typography className={classes.selectedDate} pxToEmSize={44}>
              {selectedDate.getDate()}
            </Typography>
          </div>
          <Flex direction="column">
            <Typography pxToEmSize={18} bold>
              {dateString}
            </Typography>
            <DateTypeTypography dateType={exceptionDrawerDateType} />
          </Flex>
        </Flex>
        {renderDateInfo()}
        {renderButton()}
      </div>
    );
  };

  return (
    <Drawer
      open={isExceptionDrawerOpen}
      title={'ADD_EXCEPTION'}
      onClose={handleClose}
      primaryButton={
        isDatePast || modificationState === null
          ? undefined
          : { title: 'SAVE', onClick: handleSaveButtonClick, props: { loading } }
      }
      secondaryButton={
        isDatePast
          ? {
              title: 'CLOSE',
              onClick: handleClose,
              props: { variant: 'secondary', disabled: loading },
            }
          : {
              title: 'CANCEL',
              onClick: handleClose,
              props: { variant: 'secondary', disabled: loading },
            }
      }
    >
      {renderContent()}
    </Drawer>
  );
};

const SettingsWorkCalendar = () => {
  const { loading, error } = useQuery(GET_WORK_CALENDAR, {
    onCompleted(data) {
      transformAndSetWorkCalendarData(data.getWorkCalendar);
    },
  });

  useEffect(() => {
    const cleanup = () => {
      context$.set(getDefaultContextValues());
    };
    return cleanup;
  }, []);

  if (loading) {
    return <PagePreloader />;
  }

  if (error) {
    return <PageError />;
  }

  return (
    <BodyContainer>
      <Flex>
        <SidePanel />
        <CalendarPanel />
        <ExceptionDrawer />
      </Flex>
    </BodyContainer>
  );
};

export default SettingsWorkCalendar;
