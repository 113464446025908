import { DomainStatus, Role } from '@/client/generated/graphql';
import { IS_UNASSIGNED_END_USERS_QUERY } from '@/client/queries';
import { getRole, hasSystemRole } from '@/utils';
import { useQuery } from '@apollo/client';
import { AUTHENTICATION_STATE_QUERY } from '@components/client/queries';
import { makeStyles } from '@material-ui/core';
import Alert, { AlertProps } from '@shared/components/Alert';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import Translate from '@shared/components/Translate';
import Typography from '@shared/components/Typography';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import layoutsContext from '../layoutsContext';

type Props = {
  domainStatus: DomainStatus | undefined;
  isModerated: boolean | null | undefined;
  loading: boolean;
};

const useDashboardNotificationBlockStyles = makeStyles(
  () => ({
    verificationButton: {
      padding: 0,
      marginBottom: '2px',
    },
    alertRoot: {
      borderRadius: 0,
      height: '2.5em',
      justifyContent: 'center',
    },
    alertLeftBlock: {
      marginTop: '.125em',
    },
    alertRightBlock: {
      flexGrow: 0,
      flexBasis: 'auto',

      marginTop: '-.125em',
    },
    alertDescriptionWrapper: {
      width: 'fit-content',
    },
    appointResponsiblePersonButton: {
      padding: 0,
    },
  }),
  { index: 10 }
);

const DashboardNotificationBlock = ({
  domainStatus,
  isModerated,
  loading: loadingFromProps,
}: Props) => {
  const classes = useDashboardNotificationBlockStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const { data: dataAuthenticationStateQuery } = useQuery<{ role?: string }>(
    AUTHENTICATION_STATE_QUERY,
    { fetchPolicy: 'cache-first' }
  );
  const role = getRole(dataAuthenticationStateQuery?.role);

  const { data: isUnassignedEndUsersQuery, loading: isUnassignedEndUsersQueryLoading } = useQuery(
    IS_UNASSIGNED_END_USERS_QUERY
  );
  const isUnassignedEndUsers = isUnassignedEndUsersQuery?.isUnassignedEndUsers;

  const loading = isUnassignedEndUsersQueryLoading || loadingFromProps;

  const shouldShowNotificationAboutRunexisNumbers =
    isUnassignedEndUsers &&
    domainStatus === DomainStatus.Commercial &&
    (role === Role.Owner || role === Role.Admin || hasSystemRole(role));

  const shouldShowNotificationBlock =
    !loading &&
    (domainStatus === DomainStatus.Demo ||
      domainStatus === DomainStatus.Moderating ||
      shouldShowNotificationAboutRunexisNumbers);

  // This useEffect tweaks dashboard header height for appropriate display.
  // As long as this component is rendered on screen, this modification will take effect.
  // See layoutsContext inner comment for more info.
  useEffect(() => {
    if (!shouldShowNotificationBlock && layoutsContext.dashboardHeaderHeight.get() !== '5em') {
      layoutsContext.dashboardHeaderHeight.set('5em');
      return;
    }

    if (shouldShowNotificationBlock && layoutsContext.dashboardHeaderHeight.get() !== '7.5em') {
      layoutsContext.dashboardHeaderHeight.set('7.5em');
    }
  }, [shouldShowNotificationBlock, isModerated, loadingFromProps]);

  if (!shouldShowNotificationBlock) {
    return null;
  }

  const handleVerificationButtonClick = () => {
    navigate('/register/personal-data');
  };

  const getAlertProps = (): AlertProps => {
    if (shouldShowNotificationAboutRunexisNumbers) {
      const handleButtonClick = () => {
        navigate('/numbers/numbers');
      };

      return {
        status: 'danger' as const,
        description: (
          <Translate
            i18nKey={'RESPONSIBLE_MANAGERS_MUST_BE_ASSIGNED_FOR_ALL_PURCHASED_PHONE_NUMBERS'}
            components={{
              typography: <Typography />,
              button:
                role === Role.Admin ? (
                  <Typography />
                ) : (
                  <Button
                    className={classes.appointResponsiblePersonButton}
                    variant="text"
                    onClick={handleButtonClick}
                  />
                ),
              buttonTypography:
                role === Role.Admin ? (
                  <Typography pxToEmSize={16} />
                ) : (
                  <Typography color="link600" />
                ),
            }}
          />
        ),
      };
    }

    if (
      isModerated === true &&
      (domainStatus === DomainStatus.Demo || domainStatus === DomainStatus.Moderating)
    ) {
      return {
        status: 'success' as const,
        description: <Typography>{translate('CALLS_RESTRICTIONS_HAVE_BEEN_LIFTED')}</Typography>,
      };
    }

    if (domainStatus === DomainStatus.Demo) {
      return {
        status: 'warn' as const,
        description: (
          <Flex>
            <Translate
              i18nKey={'DASHBOARD_HEADER_TOP_BLOCK_CALLS_AVAILABILITY_INFO'}
              components={{
                button: (
                  <Button
                    variant="text"
                    className={classes.verificationButton}
                    onClick={handleVerificationButtonClick}
                  />
                ),
                typography: <Typography />,
                buttonTypography: <Typography color={'link600'} pxToEmSize={16} />,
              }}
            />
          </Flex>
        ),
      };
    }

    return { status: 'info' };
  };

  return (
    <Alert
      {...getAlertProps()}
      classes={{
        root: classes.alertRoot,
        leftBlock: classes.alertLeftBlock,
        rightBlock: classes.alertRightBlock,
        descriptionWrapper: classes.alertDescriptionWrapper,
      }}
    />
  );
};

export default DashboardNotificationBlock;
