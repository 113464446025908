import { validateSearchParamsDate, validateSearchParamsEnum } from '@components/common';
import { ChartType, periodShort, periodType } from '../Statistics.interfaces';

export const searchParamsValidators = {
  from: { validate: validateSearchParamsDate },
  to: { validate: validateSearchParamsDate },
  period: { validate: validateSearchParamsEnum(periodType) },
  filterShort: { validate: validateSearchParamsEnum(periodShort) },
  chartType: { validate: validateSearchParamsEnum(ChartType) },
};
