import React from 'react';
import { ICellProps } from '@components/LazyTable';
import { makeStyles } from '@material-ui/core';
import { pxToEm } from '@shared/utils/styles';
import HighlightedText from '@components/HighlightedText';
import { useSearchParams } from 'react-router-dom';
import { EndUserModel } from '@/client/generated/graphql';
import Typography from '@shared/components/Typography';

const useStyles = makeStyles(({ color: { tertiary } }) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  phoneElement: {
    padding: `${pxToEm(8)} ${pxToEm(8)}`,
    backgroundColor: tertiary[50],
    borderRadius: '6px',
    margin: '0 .5em .5em 0',
    height: pxToEm(24),
    boxSizing: 'border-box',
  },
  typography: {
    display: 'block',
    marginTop: '-.4em',
  },
}));

export const NumbersCell = ({ item: { domainNumbers } }: ICellProps<EndUserModel>) => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('searchQuery');

  const render = () => {
    if (domainNumbers.length === 0) {
      return <Typography>-</Typography>;
    }

    return domainNumbers.map(({ id, phone }) => (
      <div key={id} className={classes.phoneElement}>
        <HighlightedText
          className={classes.typography}
          query={searchQuery}
          text={phone}
          type="phone"
        />
      </div>
    ));
  };

  return <div className={classes.wrapper}>{render()}</div>;
};
